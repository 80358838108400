export default class DocumentTitle {

  private readonly originalTitle: string

  constructor() {
    this.originalTitle = this.readTitle()
  }

  isOriginalTitle(): boolean {
    return this.originalTitle === this.readTitle()
  }

  resetTitle() {
    this.writeTitle(this.originalTitle)
  }

  setPageTitle(title: string) {
    this.writeTitle(title)
  }

  private writeTitle(title: string) {
    document.title = title
  }

  private readTitle(): string {
    return document.title
  }
}
