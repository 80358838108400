/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron_up': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.41 15.41L6 14l6-6 6 6-1.41 1.41L12 10.83l-4.59 4.58z" _fill="#132C45"/>'
  }
})
