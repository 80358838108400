

import Vue from 'vue'
import Component from 'vue-class-component'
import { Transport } from '@/application/deliveries/models/transport/types'
import { Prop } from 'vue-property-decorator'
import Icon from '@/components/media/Icon.vue'
import { State } from 'vuex-class'
import { disposeTransports } from '@/application/deliveries/services/deliveries-service'
import { pickupDone } from '@/application/deliveries/models/transport'
import DeliveryItemView from './DeliveryItemView.vue'
import DueDateTimeView from './DueDateTimeView.vue'
import { DeliveriesState } from '@/application/deliveries/store/types'
import SelectInput from '@/components/forms/SelectInput.vue'
import { DeliveryLocation } from '@/application/deliveries/models/DeliveryLocation'
import {
  OrderedSupplementalService,
  OrderedSupplementalServiceStatus
} from '@/application/deliveries/models/OrderedSupplementalService'
import DueDateDateView from '@/application/deliveries/ui/components/transport/DueDateDateView.vue'

@Component({
  components: {
    DueDateDateView,
    SelectInput,
    DueDateTimeView,
    DeliveryItemView,
    Icon
  }
})
export default class TransportAssignableView extends Vue {

  @Prop({
    type: Object,
    required: true
  })
    transport!: Transport

  @State('deliveries')
    state!: DeliveriesState

  titleAddressCity(deliveryAddress: DeliveryAddress) {
    return `${deliveryAddress.street}` + '\n' + `${deliveryAddress.city}`
  }

  get assignees() {
    const assignees: any = []
    const roles = this.state.roles
    const couriers = this.state.couriers

    assignees.push({
      assigneeId: 'DISPOSE_MANUAL',
      name: this.$t('deliveries.disposeManual')
    })
    assignees.push({
      assigneeId: 'DISPOSE_AUTO',
      name: this.$t('deliveries.disposeAuto', [this.derivedRoleNames])
    })
    roles.forEach((r) => {
      assignees.push({assigneeId: r.assigneeId, name: r.name})
    })
    couriers.forEach((c) => {
      assignees.push({assigneeId: c.assigneeId, name: `${c.additionalName} ${c.name}`})
    })
    return assignees
  }

  get pickupLocation(): DeliveryLocation {
    return this.transport.pickup.location
  }

  get dropOffLocation(): DeliveryLocation {
    return this.transport.dropOff.location
  }

  get derivedRoleNames(): string {
    if (!this.hasDerivedRoles) {
      return this.$t('deliveries.noRole') as string
    }

    return this.transport.derivedRoleIds.map((roleId: string) => {
      const role = this.state.roles.find((it) => {
        return roleId === it.assigneeId
      })
      if (role) {
        return role.name
      } else {
        return ''
      }
    }).join(', ')
  }

  get currentAssignee(): string {
    const assignee = this.state.couriers.find((courier) => {
      return courier.assigneeId === this.transport.assigneeId
    })
    if (assignee) {
      return `${assignee.additionalName} ${assignee.name}`
    } else {
      return ''
    }
  }

  get isInProgress() {
    return pickupDone(this.transport)
  }

  get selectedAssignee(): any {
    let currentAssignee = this.transport.assigneeId
    if (currentAssignee === null) {
      if (this.transport.autoDispose) {
        currentAssignee = 'DISPOSE_AUTO'
      } else {
        currentAssignee = 'DISPOSE_MANUAL'
      }
    }

    return this.assignees.find(
      (assignee: any) => assignee.assigneeId === currentAssignee
    )
  }

  get disabledAssignees(): any[] {
    if (!this.hasDerivedRoles) {
      return [
        this.assignees.find(
          (assignee: any) => assignee.assigneeId === 'DISPOSE_AUTO'
        )
      ]
    } else {
      return []
    }
  }

  get hasDerivedRoles(): boolean {
    return this.transport.derivedRoleIds.length > 0
  }

  get dispenseApartmentDoorSelected(): boolean {
    const deliveryDispenses = this.dropOffLocation.deliveryDispenses
    return deliveryDispenses != null && deliveryDispenses.apartmentDoor !== null
  }

  get dispenseApartmentDoor(): string | null {
    const deliveryDispenses = this.dropOffLocation.deliveryDispenses
    if (deliveryDispenses != null) {
      return this.$t('deliveries.dispenseApartmentDoor', [deliveryDispenses.apartmentDoor]) as string
    } else {
      return null
    }
  }

  get dispenseFrontDoorSelected(): boolean {
    const deliveryDispenses = this.dropOffLocation.deliveryDispenses
    return deliveryDispenses != null && deliveryDispenses.frontDoor
  }

  get dispenseCustomOptionSelected(): boolean {
    const deliveryDispenses = this.dropOffLocation.deliveryDispenses
    return deliveryDispenses != null && deliveryDispenses.custom != null
  }

  onAssigneeChange(assignee: any) {
    disposeTransports(
      this.transport.orderNumber,
      {assigneeId: assignee.assigneeId, transportIds: [this.transport.transportId]}
    )
  }

  iconValue(service: OrderedSupplementalService): string {
    let iconPostfix
    switch (service.status) {
    case OrderedSupplementalServiceStatus.DONE:
      iconPostfix = '_done'
      break
    case OrderedSupplementalServiceStatus.NOT_DONE:
      iconPostfix = '_not_done'
      break
    default:
      iconPostfix = ''
      break
    }
    return 'service_' + service.iconIndex + iconPostfix
  }

  iconFill(service: OrderedSupplementalService): string {
    let iconClass
    switch (service.status) {
    case OrderedSupplementalServiceStatus.DONE:
      iconClass = 'success-fill'
      break
    case OrderedSupplementalServiceStatus.NOT_DONE:
      iconClass = 'error-fill'
      break
    default:
      iconClass = 'primary-fill'
      break
    }
    return iconClass
  }
}
