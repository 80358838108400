/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_4_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.027 4.62C15.917 2.605 14.152 1 12 1S8.084 2.606 7.973 4.62H3.928c-.513 0-.928.392-.928.879V20.12c0 .486.415.879.928.879h9.413a5.98 5.98 0 01-.336-1.759h-8.15V6.378h3.113v1.473c0 .487.415.88.927.88.513 0 .928-.393.928-.88V6.378h4.354v1.473c0 .487.415.88.928.88.512 0 .927-.393.927-.88V6.378h3.113v6.624c.649.015 1.273.133 1.855.34V5.498c0-.487-.415-.88-.928-.88h-4.045zM12 2.758c1.13 0 2.06.818 2.167 1.86H9.833C9.94 3.577 10.871 2.76 12 2.76z" _fill="#132C45"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19 14.417a4.583 4.583 0 100 9.167 4.583 4.583 0 000-9.167zM21.083 18l-.65-.667h-.129l-1.774 1.808-.83-.845h-.129l-.654.662v.131l1.548 1.578h.13l2.488-2.535V18z" _fill="#29CC96"/>'
  }
})
