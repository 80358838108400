
import Vue from 'vue'
import Component from 'vue-class-component'
import PageTitle from '@/components/text/PageTitle.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import PageHead from '@/components/layout/PageHead.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import TimeInput from '@/components/forms/TimeInput.vue'
import Card from '@/components/layout/Card.vue'
import SwitcherInput from '@/components/forms/SwitcherInput.vue'
import TextInput from '@/components/forms/TextInput.vue'
import Icon from '@/components/media/Icon.vue'
import TextButton from '@/components/buttons/TextButton.vue'
import { SupplementalService } from '@/application/settings/models/types'
import deliverySettingsService from '@/application/settings/DeliverySettingsService'
import Divider from '@/components/layout/Divider.vue'
import SupplementalServiceCard from '@/application/settings/components/SupplementalServiceCard.vue'
import ModalConfirmation from '@/components/modal/ModalConfirmation.vue'

@Component({
  components: {
    ModalConfirmation,
    SupplementalServiceCard,
    Divider,
    TextButton,
    Icon,
    TextInput,
    SwitcherInput,
    Card,
    TimeInput,
    VerticalLayout,
    ScrollableSection,
    PageDetail,
    PageHead,
    DetailSection,
    PageTitle
  }
})
export default class SupplementalServicesView extends Vue {

  addButtonVisible = true

  supplementalServices: SupplementalService[] = []

  supplementalServicesToDelete: string  | null = null


  get showDeleteConfirmation(): boolean {
    return this.supplementalServicesToDelete !== null
  }

  mounted() {
    this.loadSupplementalServices()
  }

  loadSupplementalServices() {
    deliverySettingsService.supplementalServices().then((response) => {
      this.supplementalServices = response
    })
  }

  onClickAddButton() {
    this.addButtonVisible = false
  }

  onCancelCreateSupplementalService() {
    this.addButtonVisible = true
  }

  onCreateSupplementalService(supplementalServicesDraft: any) {
    this.addButtonVisible = true
    deliverySettingsService.createSupplementalService(supplementalServicesDraft).then(
      () => {
        this.loadSupplementalServices()
      }
    ).catch(
      () => this.loadSupplementalServices()
    )
  }

  onRemoveSupplementalService(supplementalServiceId: string) {
    this.supplementalServicesToDelete = supplementalServiceId
  }

  onClickConfirmationDeleteDiscard() {
    this.supplementalServicesToDelete = null
  }

  onClickConfirmationDeleteSave() {
    deliverySettingsService.removeSupplementalService(this.supplementalServicesToDelete!).then(() => {
      this.loadSupplementalServices()
    })
    this.supplementalServicesToDelete = null
  }

  onUpdateSupplementalService(supplementalService: SupplementalService) {
    deliverySettingsService.updateSupplementalService(supplementalService).then(
      () => {
        this.loadSupplementalServices()
      }
    ).catch(
      () => this.loadSupplementalServices()
    )
  }

}

