
import Vue from 'vue'
import Component from 'vue-class-component'
import PageTitle from '@/components/text/PageTitle.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import PageHead from '@/components/layout/PageHead.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import FormField from '@/components/forms/FormField.vue'
import { DeliveryAreaLinks } from '@/application/settings/models/types'
import InlineTextInput from '@/components/forms/InlineTextInput.vue'
import deliverySettingsService from '@/application/settings/DeliverySettingsService'
import BottomBar from '@/components/layout/BottomBar.vue'
import ContainedButton from '@/components/buttons/ContainedButton.vue'
import LinkedText from '@/components/text/LinkedText.vue'
import ExternalTextLink from '@/components/text/ExternalTextLink.vue'
import ModalNotification from '@/components/modal/ModalNotification.vue'

@Component({
  components: {
    ModalNotification,
    ExternalTextLink,
    LinkedText,
    ContainedButton,
    BottomBar,
    InlineTextInput,
    VerticalLayout,
    ScrollableSection,
    PageDetail,
    PageHead,
    DetailSection,
    PageTitle,
    FormField
  }
})
export default class DeliveryAreaView extends Vue {

  deliveryAreaLinks: DeliveryAreaLinks = {
    editLink: '',
    embedLink: ''
  }

  showMap = false

  get emailLink(): string {
    return '<a href="mailto:info@viavelo.ch" class="mail-link">info@viavelo.ch</a>'
  }

  mounted() {
    this.loadDeliveryAreaLinks()
  }

  private loadDeliveryAreaLinks() {
    deliverySettingsService.deliveryAreaLinks().then((response) => {
      this.deliveryAreaLinks = response
    })
  }

  onCloseMapClicked() {
    this.showMap = false
  }

  onOpenMapClicked() {
    this.showMap = true
  }

}
