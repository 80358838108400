/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_19_not_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.02 5.383h2.21c.874 0 1.562.696 1.603 1.562l.001 2.238c0 .823-.625 1.478-1.417 1.562v2.423a6.012 6.012 0 00-1.584-.166v-2.257H14.23v4.616A5.974 5.974 0 0013 19c0 .695.118 1.363.336 1.984H4.979a1.57 1.57 0 01-1.562-1.583v-8.656A1.572 1.572 0 012 9.183V6.967a1.57 1.57 0 011.563-1.584H5.77a2.529 2.529 0 01-.104-1.52c.209-.844.813-1.499 1.625-1.752a2.465 2.465 0 012.334.485l2.27 1.963 2.271-1.963A2.464 2.464 0 0116.5 2.11c.813.275 1.417.93 1.626 1.752a2.529 2.529 0 01-.105 1.52zm-3.791 3.8h6.021l-.02-2.238h-6.001v2.238zm-3.125-2.238v2.217h1.563V6.945h-1.563zm1.584 12.456v-8.656h-1.563v8.656h1.563zm3.354-15.77a.835.835 0 00-.854.169l-1.834 1.604h2.417a.904.904 0 00.708-.359.884.884 0 00.167-.78.959.959 0 00-.604-.634zm-8.875.612a.955.955 0 00.167.78c.166.233.416.36.686.339h2.418L8.604 3.779a.926.926 0 00-.562-.212c-.084 0-.188.021-.271.042-.313.085-.521.317-.604.634zm2.396 2.702h-6v2.217h6V6.945zm0 3.8H4.958v8.656h4.605v-8.656z" _fill="#132C45"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19 14.417a4.583 4.583 0 100 9.167 4.583 4.583 0 000-9.167zm.793 2.998a.281.281 0 01.396 0l.396.396a.281.281 0 010 .396l-.793.793.793.793a.281.281 0 010 .396l-.396.396a.281.281 0 01-.396 0L19 19.792l-.793.793a.281.281 0 01-.396 0l-.396-.396a.281.281 0 010-.396l.793-.793-.793-.793a.281.281 0 010-.396l.396-.396a.281.281 0 01.396 0l.793.793.793-.793z" _fill="#F24949"/>'
  }
})
