import { render, staticRenderFns } from "./DeliveriesCourier.vue?vue&type=template&id=3ba0d4c4&scoped=true&"
import script from "./DeliveriesCourier.vue?vue&type=script&lang=ts&"
export * from "./DeliveriesCourier.vue?vue&type=script&lang=ts&"
import style0 from "./DeliveriesCourier.vue?vue&type=style&index=0&id=3ba0d4c4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ba0d4c4",
  null
  
)

export default component.exports