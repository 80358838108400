/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_5_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.691 7.053a1.218 1.218 0 01-.448-1.668l1.929-3.331A2.094 2.094 0 0112.003 1c.764 0 1.448.393 1.831 1.054l3.575 6.172.13-.489a1.225 1.225 0 011.501-.863c.654.176 1.043.846.866 1.497l-.896 3.335c-.033.12-.088.235-.157.342l-.029.046a1.211 1.211 0 01-.297.29c-.03.02-.057.046-.09.065-.048.026-.098.038-.146.059-.038.015-.072.034-.11.046a1.21 1.21 0 01-.357.059c-.008 0-.015-.004-.023-.004a1.258 1.258 0 01-.293-.038h-.002l-3.348-.894a1.222 1.222 0 11.636-2.36l.49.13-3.283-5.666-1.636 2.826a1.227 1.227 0 01-1.674.446zm-5.58 13.495A2.085 2.085 0 011.286 19.5a2.085 2.085 0 010-2.107l3.576-6.172-.49.13a1.222 1.222 0 11-.636-2.36l3.342-.893c.079-.021.161-.035.244-.04.051-.004.101 0 .153.003.027.002.055 0 .082.004a1.227 1.227 0 01.655.296c.01.008.017.02.027.029.046.044.092.09.132.143.025.032.044.069.065.103.02.032.043.063.06.097.036.073.065.15.086.227l.896 3.335a1.22 1.22 0 01-1.183 1.54c-.542 0-1.039-.362-1.184-.908l-.13-.488-3.284 5.666h3.274c.676 0 1.226.548 1.226 1.222 0 .674-.55 1.221-1.226 1.221h-3.86z" _fill="#132C45"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19 14.417a4.583 4.583 0 100 9.167 4.583 4.583 0 000-9.167zM21.083 18l-.65-.667h-.129l-1.774 1.808-.83-.845h-.129l-.654.662v.131l1.548 1.578h.13l2.488-2.535V18z" _fill="#29CC96"/>'
  }
})
