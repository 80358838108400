
import Component from 'vue-class-component'
import { Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { routerNames } from '@/application/router/router-definitions'
import TextButton from '@/components/buttons/TextButton.vue'
import ContainedButton from '@/components/buttons/ContainedButton.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import PageTitle from '@/components/text/PageTitle.vue'
import { Address } from '@/application/shared/Address'
import {
  discardOrderDraft,
  loadOrderDraft,
  saveOrderDraft,
  shipOrder
} from '@/application/deliveries/services/order-service'
import OrderDraftConditionCard from '@/application/deliveries/ui/components/order-draft/OrderDraftConditionCard.vue'
import OrderDraftConditions from '@/application/deliveries/ui/components/order-draft/OrderDraftConditions.vue'
import BottomBar from '@/components/layout/BottomBar.vue'
import SaveDiscardButtons from '@/components/buttons/SaveDiscardButtons.vue'
import PageHead from '@/components/layout/PageHead.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import { DeliveriesState } from '@/application/deliveries/store/types'
import {
  changeCustomer, changePrice,
  changeShippingDate,
  mapToPlaceOrder,
  newOrderDraft,
  readyToShip
} from '@/application/deliveries/models/order-draft'
import { OrderDraft } from '@/application/deliveries/models/order-draft/types'
import customersService from '@/application/customers/CustomersService'
import { CustomerSummary } from '@/application/customers/models/CustomerSummary'
import SelectInput from '@/components/forms/SelectInput.vue'
import LinkedText from '@/components/text/LinkedText.vue'
import NavigationItem from '@/components/navigation/NavigationItem.vue'
import DateInput from '@/components/forms/DateInput.vue'
import { DateRange } from '@/components/forms/types/DateRange'
import InlineNumberInput from '@/components/forms/InlineNumberInput.vue'
import Icon from '@/components/media/Icon.vue'
import { DefaultDateRange } from '@/components/forms/types/DefaultDateRange'

@Component({
  components: {
    Icon,
    InlineNumberInput,
    DateInput,
    NavigationItem,
    LinkedText,
    SelectInput,
    SaveDiscardButtons,
    BottomBar,
    VerticalLayout,
    ScrollableSection,
    PageDetail,
    PageHead,
    OrderDraftConditions,
    OrderDraftConditionCard,
    TextButton,
    ContainedButton,
    PageTitle,
    DetailSection
  }
})
export default class CreateOrder extends Vue {
  @State('deliveries') state!: DeliveriesState

  customers: CustomerSummary[] = []
  orderDraft!: OrderDraft
  addresses: Address[] = []
  readyToShip = false

  addressesLoaded = false
  customersLoaded = false

  shippingDateRange!: DateRange

  data() {
    let draft = loadOrderDraft()
    if (!draft) {
      draft = newOrderDraft()
    }
    return {
      orderDraft: draft
    }
  }

  get customer(): CustomerSummary | null {
    if (this.orderDraft.customerId) {
      return this.customers.find((c) => c.customerId === this.orderDraft.customerId)!
    }
    return null
  }

  get dependentDataReady(): boolean {
    return this.addressesLoaded && this.customersLoaded
  }

  onClickCreateCustomer() {
    this.$router.push({name: routerNames.CREATE_CUSTOMER}).catch((err) => {})
  }

  mounted() {
    const currentDate = new Date()
    const todayAtMidnight = new Date()
    todayAtMidnight.setHours(0, 0)
    const inOneYear = new Date(new Date().setFullYear(currentDate.getFullYear() + 1))
    this.shippingDateRange = new DefaultDateRange(todayAtMidnight, inOneYear)

    customersService.addresses().then((addresses) => {
      this.addresses = addresses
      this.addressesLoaded = true
    })

    customersService.customers().then((customers) => {
      this.customers = customers
      this.customersLoaded = true
    })
    this.readyToShip = readyToShip(this.orderDraft)
  }

  @Watch('orderDraft', {deep: true})
  onChangedOrderDraft() {
    saveOrderDraft(this.orderDraft)
    this.readyToShip = readyToShip(this.orderDraft)
  }

  onClickDiscard() {
    discardOrderDraft()
    this.$router.push({name: routerNames.DELIVERIES})
  }

  onClickCreate() {
    shipOrder(mapToPlaceOrder(this.orderDraft)).then(() => {
      discardOrderDraft()
      this.$router.push({name: routerNames.DELIVERIES})
    })
  }

  onChangeCustomer(customers: CustomerSummary) {
    changeCustomer(this.orderDraft, customers.customerId)
  }

  onChangeShippingDate(shippingDate: Date) {
    changeShippingDate(this.orderDraft, shippingDate)
  }

  onChangePrice(price: number) {
    changePrice(this.orderDraft, price)
  }

}
