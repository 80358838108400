

import { Prop, Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { DeliveryItem } from '@/application/deliveries/models/DeliveryItem'
import Icon from '@/components/media/Icon.vue'
import { DeliveryItemStatus } from '@/application/deliveries/models/DeliveryItemStatus'
import QRCode from '@/components/media/QRCode.vue'
import Card from '@/components/layout/Card.vue'

class IconAttributes {

  name: string
  cssClass: string

  constructor(name: string, cssClass: string) {
    this.name = name
    this.cssClass = cssClass
  }
}

@Component({
  components: {
    Card,
    Icon,
    QRCode
  }
})
export default class DeliveryItemView extends Vue {

  qrCodeVisible = false

  @Prop({type: Object, required: true}) deliveryItem!: DeliveryItem

  get deliveryItemIsCanceled(): boolean {
    return this.deliveryItem.status === DeliveryItemStatus.CANCELED
  }

  get getTextCssClass() {
    return {
      canceled: this.deliveryItemIsCanceled,
      isClickable: this.hasQrCode
    }
  }

  get iconProperties(): IconAttributes {
    switch (this.deliveryItem.status) {
    case DeliveryItemStatus.OPEN:
      return new IconAttributes('deliveryobject', 'primary-fill')
    case DeliveryItemStatus.CONFIRMED:
      return new IconAttributes('deliveryobject_confirmed', 'success-fill')
    case DeliveryItemStatus.MISSED:
      return new IconAttributes('deliveryobject_missing', 'warning-fill')
    case DeliveryItemStatus.CANCELED:
      return new IconAttributes('deliveryobject_missing', 'canceled-fill')
    }
    return new IconAttributes('deliveryobject', 'primary-fill')
  }

  get hasQrCode(): boolean {
    return this.deliveryItem.qrCodeId !== null
  }

  toggleQrCodeVisibility(event: any) {
    if (!this.hasQrCode) {
      return
    }
    this.qrCodeVisible = !this.qrCodeVisible
    event.stopPropagation()
  }

  get deliveryItemQrCodeValue() {
    return {
      qrCodeId: this.deliveryItem.qrCodeId,
      label: this.deliveryItem.label
    }
  }
}

