import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { users } from '@/application/users/store'
import { deliveries } from '@/application/deliveries/store'
import { customers } from '@/application/customers/store'

Vue.use(Vuex)

const store: StoreOptions<any> = {
  state: {},
  modules: {
    users,
    deliveries,
    customers
  }
}

export default new Vuex.Store<any>(store)
