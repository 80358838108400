

import Vue from 'vue'
import Component from 'vue-class-component'
import Icon from '@/components/media/Icon.vue'
import { Prop } from 'vue-property-decorator'
import { DeliveryStatus} from '@/application/deliveries/models/DeliveryOverviewSummary'

@Component({
  name: 'Deliveries',
  components: {
    Icon
  }
})
export default class DeliveryStatusIndicator extends Vue {

  @Prop({
    type: String,
    required: true
  }) status!: DeliveryStatus

  get iconName() {
    if (this.isUnplanned) {
      return 'nav_dispo'
    } else if (this.isFulfilled) {
      return 'checkmark'
    } else {
      return 'inprogress'
    }
  }

  get indicatorStyleClass() {
    if (this.isUnplanned) {
      return 'unplanned'
    } else if (this.isFulfilled) {
      return 'fulfilled'
    } else {
      return 'in-progress'
    }
  }

  get isUnplanned(): boolean {
    return this.status === DeliveryStatus.UNPLANNED
  }


  get isFulfilled(): boolean {
    return this.status === DeliveryStatus.FULFILLED
  }

}

