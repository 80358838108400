
import Component from 'vue-class-component'
import { Prop, Vue, Watch } from 'vue-property-decorator'
import { ArrowDrawer } from './ArrowDrawer'
import { uuid } from '@/utils/uuid'
import { Address } from '@/application/shared/Address'
import TextButton from '@/components/buttons/TextButton.vue'
import DeliveryItemInput from './DeliveryItemInput.vue'
import OrderDraftConditionCard from './OrderDraftConditionCard.vue'
import { DragDeliveryItemEvent } from '@/application/deliveries/ui/types/DragDeliveryItemEvent'
import {
  OrderDraft,
  OrderDraftCondition,
  DeliveryItem
} from '@/application/deliveries/models/order-draft/types'
import {
  addPickup,
  dropOffDeliveryItem
} from '@/application/deliveries/models/order-draft'


@Component({
  components: {
    TextButton,
    OrderDraftConditionCard,
    DeliveryItemInput
  }
})
export default class OrderDraftConditions extends Vue {
  $refs!: {
    arrowGroup: HTMLElement,
    dragArrow: HTMLElement,
    conditionsArea: HTMLElement,
    pickupConditions: OrderDraftConditionCard[],
    dropOffConditions: OrderDraftConditionCard[],
  }

  @Prop({type: Object, required: true}) orderDraft!: OrderDraft

  @Prop({type: Array, required: true}) addresses!: Address[]

  activeDropOffArea = false
  activeCondition: OrderDraftCondition | null = null
  dragDeliveryItem: DragDeliveryItemEvent | null = null

  // non reactive
  arrowDrawer!: ArrowDrawer

  get addDropOffConditionAreaClasses(): any {
    return {
      'active-drop': this.dragDeliveryItem && this.activeDropOffArea,
      'hint-drop': this.dragDeliveryItem && !this.activeDropOffArea
    }
  }

  private isDragging(): boolean {
    return this.dragDeliveryItem !== null
  }

  mounted() {
    this.arrowDrawer = new ArrowDrawer(this.$refs.conditionsArea, this.$refs.arrowGroup)
    window.addEventListener('resize', this.onResize)
    this.drawConnectingArrows()
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  }

  @Watch('orderDraft', {deep: true})
  onOrderDraftChanged() {
    this.$nextTick(() => {
      this.drawConnectingArrows()
    })
  }

  onClickAddPickupCondition() {
    addPickup(this.orderDraft)
  }

  onDragDeliveryItem(event: DragDeliveryItemEvent) {
    window.addEventListener('mouseup', this.onMouseUp)
    this.dragDeliveryItem = event
    this.arrowDrawer.startDragFrom(event.anchor)
  }

  onMouseUp() {
    if (this.isDragging()) {
      const item = this.dragDeliveryItem!.deliveryItem
      if (this.activeCondition) {
        dropOffDeliveryItem(this.orderDraft, item, this.activeCondition)
      } else if (this.activeDropOffArea) {
        dropOffDeliveryItem(this.orderDraft, item)
      }
      this.arrowDrawer.endDrag()
      this.dragDeliveryItem = null
      this.activeDropOffArea = false
      this.activeCondition = null
      window.removeEventListener('mouseup', this.onMouseUp)
    }
  }

  onMouseMove(e: MouseEvent) {
    if (this.isDragging()) {
      this.arrowDrawer.onMoveDrage(e.x, e.y)
    }
  }

  onMouseOverDropOffArea() {
    if (this.isDragging()) {
      this.activeDropOffArea = true
    }
  }

  onMouseOutDropOffArea() {
    if (this.isDragging()) {
      this.activeDropOffArea = false
    }
  }

  dropOffOrderDraftConditionClasses(condition: OrderDraftCondition): object {
    return {
      'style-active-drop': this.isDragging() && this.activeCondition === condition
    }
  }

  onMouseOverCondition(condition: OrderDraftCondition) {
    if (this.dragDeliveryItem) {
      this.activeCondition = condition
    }
  }

  onMouseOutCondition(condition: OrderDraftCondition) {
    if (this.activeCondition != null && this.activeCondition === condition) {
      this.activeCondition = null
    }
  }

  onResize() {
    this.arrowDrawer.onResize()
  }

  generateUUID() {
    return uuid()
  }

  private drawConnectingArrows() {
    this.arrowDrawer.removeConnections()
    if (this.$refs.dropOffConditions) {
      this.orderDraft.pickupConditions.forEach((pickupCondition) => {
        pickupCondition.deliveryItems.forEach((deliveryItem) => {
          const pickupItemInput = this.findDeliveryItemInput(this.$refs.pickupConditions, deliveryItem)
          const dropOffItemInput = this.findDeliveryItemInput(this.$refs.dropOffConditions, deliveryItem)
          if (pickupItemInput && dropOffItemInput) {
            this.arrowDrawer.addConnecton(
              pickupItemInput!.$refs.dragAnchor,
              dropOffItemInput!.$refs.dragAnchor
            )
          }
        })
      })
    }
  }

  private findDeliveryItemInput(
    conditionCards: OrderDraftConditionCard[],
    deliveryItem: DeliveryItem
  ): DeliveryItemInput | undefined {
    for (const conditionCard of conditionCards) {
      if (conditionCard.$refs.deliveryItems) {
        for (const deliveryItemInput of conditionCard.$refs.deliveryItems) {
          if (deliveryItemInput.value === deliveryItem) {
            return deliveryItemInput
          }
        }
      }
    }
    return undefined
  }
}
