
import { freeDeliveryItems } from '@/application/freedeliveryitems/free-delivery-item-resource'
import Vue from 'vue'
import { FreeDeliveryItem } from '@/application/freedeliveryitems/types'
import FreeDeliveryItemsList from '@/application/freedeliveryitems/FreeDeliveryItemsList.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import { routerNames } from '@/application/router/router-definitions'
import BadgeContainer from '@/components/badge/BadgeContainer.vue'
import ContainedButton from '@/components/buttons/ContainedButton.vue'
import { eventConsumer } from '@/infrastructure/events/event-consumer'
import { FREE_DELIVERY_ITEMS_CHANGED } from '@/application/customers/events'

export default Vue.extend({
  name: 'FreeDeliveryItems',
  components: {
    DetailSection,
    BadgeContainer,
    ContainedButton,
    FreeDeliveryItemsList
  },
  async mounted() {
    this.freeDeliveryItems = await freeDeliveryItems()
  },
  methods: {
    onCreateFreeDeliveryItemClicked: function () {
      this.$router.push({name: routerNames.CREATE_FREE_DELIVYERY_ITEM}).catch(() => {
      })
    },
    loadData: async function() {
      this.freeDeliveryItems = await freeDeliveryItems()
    }
  },
  computed: {
    hasFreeDeliveryItems: function () {
      return this.freeDeliveryItems.length > 0
    }
  },
  data: () => ({
    freeDeliveryItems: [] as FreeDeliveryItem[]
  }),
  mixins: [eventConsumer([
    {
      key: FREE_DELIVERY_ITEMS_CHANGED,
      handler: 'loadData'
    }
  ])]
})
