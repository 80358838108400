
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class SlotButton extends Vue {

  private onClick(e: any) {
    this.$emit('click', e)
  }
}
