/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cross_circle_solid': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm3.922-13.77L14.77 8.077a.268.268 0 00-.379 0l-2.362 2.363a.04.04 0 01-.03.012.04.04 0 01-.03-.012L9.609 8.078a.268.268 0 00-.379 0L8.079 9.23a.268.268 0 000 .379l2.361 2.362a.043.043 0 010 .06l-2.362 2.362a.268.268 0 000 .379l1.15 1.15a.268.268 0 00.38 0l2.361-2.362a.043.043 0 01.06 0l.029.03 2.333 2.333a.268.268 0 00.379 0l1.151-1.151a.268.268 0 000-.379l-2.35-2.35-.011-.01a.042.042 0 01-.001-.061l2.363-2.363a.268.268 0 000-.379z"/>'
  }
})
