import { render, staticRenderFns } from "./DeliveryTimesView.vue?vue&type=template&id=52b93e5d&scoped=true&"
import script from "./DeliveryTimesView.vue?vue&type=script&lang=ts&"
export * from "./DeliveryTimesView.vue?vue&type=script&lang=ts&"
import style0 from "./DeliveryTimesView.vue?vue&type=style&index=0&id=52b93e5d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52b93e5d",
  null
  
)

export default component.exports