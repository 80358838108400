
import Vue from 'vue'
import Component from 'vue-class-component'
import userService from '../UsersService'
import PageTitle from '@/components/text/PageTitle.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import ContainedButton from '@/components/buttons/ContainedButton.vue'
import TextButton from '@/components/buttons/TextButton.vue'
import PageHead from '@/components/layout/PageHead.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import Divider from '@/components/layout/Divider.vue'
import { routerParameters } from '@/application/router/router-definitions'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import BottomBar from '@/components/layout/BottomBar.vue'
import SaveDiscardButtons from '@/components/buttons/SaveDiscardButtons.vue'
import FormField from '@/components/forms/FormField.vue'
import InlineTextInput from '@/components/forms/InlineTextInput.vue'
import MultiSelectChipInput from '@/components/forms/MultiSelectChipInput.vue'
import SwitcherInput from '@/components/forms/SwitcherInput.vue'
import UserForm from '@/application/users/templates/UserForm.vue'

@Component({
  components: {
    UserForm,
    SwitcherInput,
    MultiSelectChipInput,
    InlineTextInput,
    FormField,
    BottomBar,
    VerticalLayout,
    Divider,
    PageDetail,
    ScrollableSection,
    PageHead,
    ContainedButton,
    TextButton,
    DetailSection,
    PageTitle,
    SaveDiscardButtons
  }
})
export default class UserAdd extends Vue {

  user: any = {
    name: '',
    street: '',
    city: '',
    phone: '',
    courierAppAccess: false,
    roleIds: [],
    cockpitAccess: false,
    email: '',
    password: '',
    courierAppToken: null,
    isCockpitAdmin: false,
    ...this.$store.state.users.userDraft
  }

  roles: any[] = []
  rolesLoaded = false

  get disableAddUserButton() {
    return this.user.name !== '' && this.user.address !== null && this.user.phone !== '' && this.validCockpitAccess
  }

  get validCockpitAccess() {
    return !this.user.cockpitAccess || (this.user.email !== '' && this.user.password !== '')
  }

  mounted() {
    userService.userRoles().then((roles) => {
      this.roles = roles
      this.rolesLoaded = true
    })
  }

  onChangeUser(val: any) {
    userService.saveUserDraft(val)
    this.user = val
  }

  onClickDiscard() {
    userService.deleteUserDraft()
    this.$router
      .push({name: 'user-overview', params: {[routerParameters.DISABLE_ROUTE_TO_LAST_CHILD]: 'true'}})
      .catch((err) => {})
  }

  onClickCreate() {
    userService.createUser(this.user).then((userId: string) => {
      this.$router.push(
        {
          name: 'user-edit',
          params: {id: userId}
        }
      ).catch((err) => {})
    })
  }

  onChangeRoles(roles: any[]) {
    this.user.roleIds = roles.map((role) => role.roleId)
  }
}
