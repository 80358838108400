

import Component from 'vue-class-component'
import { Vue } from 'vue-property-decorator'
import * as map from './map'
import { MapCoordinate } from './types/MapCoordinate'
import { get as getProjection } from 'ol/proj'
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import WMTSTile from 'ol/source/WMTS'
import WMTSGrid from 'ol/tilegrid/WMTS'
import Attribution from 'ol/control/Attribution'


@Component
export default class MapView extends Vue {

  $refs!: {
    map: HTMLElement
  }

  // non reactive
  map!: any

  mounted() {
    const projection = getProjection(map.mapConfig.projection)
    projection.setExtent(map.mapConfig.extent)
    const matrixIds = []
    for (let i = 0; i < map.mapConfig.resolutions.length; i++) {
      matrixIds.push(i)
    }
    const layer = new TileLayer({
      extent: map.mapConfig.extent,
      source: new WMTSTile(({
        layer: map.mapConfig.layer,
        crossOrigin: 'anonymous',
        attributions: map.mapConfig.attributions,
        url: map.mapConfig.url,
        tileGrid: new WMTSGrid({
          origin: [map.mapConfig.extent[0], map.mapConfig.extent[3]],
          resolutions: map.mapConfig.resolutions,
          matrixIds: matrixIds
        }),
        dimensions: {
          Time: 'current'
        },
        requestEncoding: 'REST'
      }))
    })

    this.map = new Map({
      layers: [layer],
      target: this.$refs.map,
      controls: [new Attribution({collapsible: false})],
      view: new View({
        projection: projection,
        center: [2670000, 1160000],
        resolution: 500,
        extent: map.mapConfig.extent
      })
    })
  }

  addLayer(layer: any): void {
    this.map.addLayer(layer)
  }

  moveTo(coordinate: MapCoordinate, resolution: number) {
    this.map.getView().animate(
      {
        duration: 200,
        resolution: resolution,
        center: map.fromWSG84(coordinate)
      }
    )
  }
}
