/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_7_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.51 7.164c.298.133.49.423.49.745 0 .32-.19.61-.488.744l-2.916 1.301 2.916 1.301A.818.818 0 0122 12c0 .32-.19.611-.488.745l-1.002.447a6.012 6.012 0 00-6.841 3.052l-1.326.592a.85.85 0 01-.687 0l-4.24-1.892-2.57 1.147L12 19.285l1.003-.448a6.009 6.009 0 00.198 1.708l-.857.382a.85.85 0 01-.687 0l-9.168-4.09A.818.818 0 012 16.09c0-.32.19-.61.488-.744l2.917-1.301-2.917-1.301A.818.818 0 012 12c0-.32.19-.611.488-.745l2.917-1.3-2.917-1.302A.818.818 0 012 7.91c0-.32.19-.612.488-.745l9.168-4.091a.85.85 0 01.687 0l9.167 4.09zM12 15.194L19.15 12l-2.568-1.148-4.24 1.893a.85.85 0 01-.687 0l-4.24-1.893L4.845 12 12 15.193zM4.846 7.908l7.152 3.193 7.152-3.193L12 4.717 4.847 7.909z" _fill="#132C45"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19 14.417a4.583 4.583 0 100 9.167 4.583 4.583 0 000-9.167zM21.083 18l-.65-.667h-.129l-1.774 1.808-.83-.845h-.129l-.654.662v.131l1.548 1.578h.13l2.488-2.535V18z" _fill="#29CC96"/>'
  }
})
