import { render, staticRenderFns } from "./FreeDeliveryItemAdd.vue?vue&type=template&id=131f8cbf&scoped=true&"
import script from "./FreeDeliveryItemAdd.vue?vue&type=script&lang=ts&"
export * from "./FreeDeliveryItemAdd.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "131f8cbf",
  null
  
)

export default component.exports