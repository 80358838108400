
import Component from 'vue-class-component'
import { Prop, Vue, Watch } from 'vue-property-decorator'

export interface ItemState {
  selected: boolean
}

@Component
export default class ItemList extends Vue {

  $refs!: {
    itemsList: HTMLElement
  }

  @Prop({type: Array, required: true}) items!: any[]

  @Prop({type: Array, default: () => []}) disabledItems!: any[]

  @Prop({type: Object, default: null}) value!: any | null

  highlightedItem: any | null = null

  disabledMouseHighlight = false

  get header(): boolean {
    return this.$slots.header !== undefined
  }

  @Watch('items')
  onChangeItems() {
    this.disableMouseHighlight()
    this.highlightItem(null)
  }

  itemState(item: any) {
    return {
      selected: item === this.value,
      disabled: this.itemDisabled(item)
    }
  }

  onClickItem(item: any) {
    if (!this.itemDisabled(item)) {
      this.$emit('input', item)
    }
  }

  onMouseLeaveItem(item: any) {
    if (this.disabledMouseHighlight) {
      return
    }
    if (this.highlightedItem === item) {
      this.highlightItem(null)
    }
  }

  onMouseEnterItem(item: any) {
    if (this.disabledMouseHighlight) {
      return
    }
    this.highlightItem(item)
  }

  itemDisabled(item: any): boolean {
    return this.disabledItems.includes(item)
  }

  itemClass(item: any) {
    if (this.highlightedItem) {
      return {highlighted: item === this.highlightedItem}
    } else {
      return null
    }
  }

  noItems(): boolean {
    return this.items.length === 0
  }

  highlightFirstItem() {
    if (this.noItems()) {
      return
    }
    this.highlightItemAtIndex(0)
  }

  highlightLastItem() {
    if (this.noItems()) {
      return
    }
    this.highlightItemAtIndex(this.items.length - 1)
  }

  highlightNextItem(endless = false) {
    if (this.noItems()) {
      return
    }
    if (this.highlightedItem) {
      const index = this.highlightedIndex()
      if (index < this.items.length - 1) {
        this.highlightItemAtIndex(index + 1)
      } else {
        if (endless) {
          this.highlightFirstItem()
        } else {
          this.highlightItem(null)
        }
      }
    } else {
      this.highlightFirstItem()
    }
  }

  highlightPreviousItem(endless = true) {
    if (this.noItems()) {
      return
    }
    if (this.highlightedItem) {
      const index = this.highlightedIndex()
      if (index > 0) {
        this.highlightItemAtIndex(index - 1)
      } else {
        if (endless) {
          this.highlightLastItem()
        } else {
          this.highlightItem(null)
        }
      }
    } else {
      this.highlightLastItem()
    }
  }

  highlightItem(item: any | null) {
    this.highlightedItem = item
    this.$emit('highlight', item)
  }

  highlightItemAtIndex(index: number) {
    this.highlightItem(this.items[index])
    this.$nextTick(() => {
      this.scrollToItemAtIndex(index)
    })
  }

  highlightedIndex(): number {
    return this.items.findIndex((item) => item === this.highlightedItem)
  }

  scrollToItemAtIndex(index: number) {
    const element = this.$refs.itemsList.childNodes.item(index) as HTMLElement
    const containerTop = this.$refs.itemsList.scrollTop
    const containerBottom = containerTop + this.$refs.itemsList.clientHeight
    const elementTop = element.offsetTop - this.$refs.itemsList.offsetTop
    const elementBottom = elementTop + element.clientHeight
    if (elementTop < containerTop) {
      this.scrollTo(containerTop - (containerTop - elementTop))
    } else if (elementBottom > containerBottom) {
      this.scrollTo(containerTop + (elementBottom - containerBottom))
    }
  }

  scrollTo(position: number) {
    this.$refs.itemsList.scrollTop = position
    this.disableMouseHighlight()
  }

  disableMouseHighlight() {
    this.disabledMouseHighlight = true
    setTimeout(() => {
      this.disabledMouseHighlight = false
    }, 100)
  }
}
