import Vue from 'vue'
import App from './application/App.vue'
import router from './application/router'
import i18n from '@/i18n'
import store from './application/store'
import './assets/styles/base.scss'
import { getNestedValue } from '@/utils/json-utils'
import { environment } from '@/infrastructure/Environment'
import { featureToggles, featureTogglesConfiguration } from '@/infrastructure/featuretoggles/FeatureToggles'


const loadAndStart = async () => {
  await environment.load()
  featureToggles.initialize(featureTogglesConfiguration)
  await featureToggles.loadFromRemote()

  Vue.config.productionTip = false

  Vue.prototype.$nestedVal = (obj: any, path: string) => getNestedValue(obj, path)

  new Vue({
    router,
    i18n,
    store,
    render: (h) => h(App)
  }).$mount('#app')
}

loadAndStart()
