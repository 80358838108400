/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_8': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.046 7.122c-.9 0-1.637-.702-1.637-1.561S16.145 4 17.045 4c.9 0 1.637.702 1.637 1.561s-.736 1.561-1.636 1.561zm-8.591 5.073c2.29 0 4.09 1.717 4.09 3.903 0 2.185-1.8 3.902-4.09 3.902-2.291 0-4.091-1.717-4.091-3.902 0-2.186 1.8-3.903 4.09-3.903zm0 6.634c1.554 0 2.863-1.248 2.863-2.731 0-1.483-1.309-2.732-2.863-2.732-1.555 0-2.864 1.249-2.864 2.732s1.309 2.731 2.864 2.731zm4.745-7.805L15 12.82v4.838h-1.636v-3.902l-2.619-2.185c-.327-.234-.49-.625-.49-1.093 0-.39.163-.78.49-1.093L13.037 7.2c.246-.312.655-.468 1.146-.468.49 0 .9.156 1.309.468l1.555 1.483a4.163 4.163 0 002.945 1.17v1.562c-1.718 0-3.11-.625-4.173-1.64l-.654-.624-1.964 1.873zm6.71 1.171c2.29 0 4.09 1.717 4.09 3.903C24 18.283 22.2 20 19.91 20c-2.292 0-4.092-1.717-4.092-3.902 0-2.186 1.8-3.903 4.091-3.903zm0 6.634c1.554 0 2.863-1.248 2.863-2.731 0-1.483-1.31-2.732-2.864-2.732-1.554 0-2.863 1.249-2.863 2.732s1.309 2.731 2.863 2.731zM.532 8.267h7.66a.533.533 0 010 1.066H.534a.533.533 0 110-1.066zM2.715 10.4h4.388a.533.533 0 010 1.067H2.715a.533.533 0 010-1.067zm2.182-4.267h4.388a.533.533 0 010 1.067H4.897a.533.533 0 010-1.067z" _fill="#132C45"/>'
  }
})
