/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'order_customer': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.056 7.104a.5.5 0 01.092-.148l4.62-4.805a.503.503 0 01.111-.085.612.612 0 01.16-.064.552.552 0 01.102 0h10.33a.54.54 0 01.529.551v6.921a5.309 5.309 0 00-1.692-.619V3.906H9.883V7.77a.51.51 0 01-.14.354.472.472 0 01-.34.147H5.691v11.816h2.632a2.88 2.88 0 00-.348 1.365V22H4.529a.517.517 0 01-.374-.16.565.565 0 01-.155-.39V7.343a.548.548 0 01.056-.24zm2.486-.303h1.962V4.756L6.542 6.8z" _fill="#132C45"/><path pid="1" d="M12.585 16.546c.084.164.176.324.277.478-1.038.338-2.043.785-2.869 1.34H7.537v-1.819h5.048zM12.078 13.2a5.382 5.382 0 00-.002 1.818h-4.54V13.2h4.542zM13.825 10.09a5.36 5.36 0 00-1.351 1.82H7.537v-1.82h6.288zM17.401 12.37a1.732 1.732 0 110 3.463 1.732 1.732 0 010-3.463zm0 7.423c2.45 0 5.032 1.204 5.032 1.732v.908H12.37v-.908c0-.527 2.581-1.732 5.031-1.732zm0-8.99a3.299 3.299 0 100 6.598 3.299 3.299 0 100-6.599zm0 7.423c-2.202 0-6.599 1.105-6.599 3.3V24H24v-2.475c0-2.194-4.396-3.299-6.599-3.299z" _fill="#132C45"/>'
  }
})
