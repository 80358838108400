interface Connection {
  fromAnchor: HTMLElement
  toAnchor: HTMLElement
  arrow: SVGPathElement
}

export class ArrowDrawer {

  drawArea: HTMLElement
  arrowGroup: HTMLElement
  connections: Connection[] = []
  offset!: { x: number, y: number }
  dragFrom: { x: number, y: number } | null = null
  dragArrow: SVGPathElement | null = null

  constructor(drawArea: HTMLElement, arrowGroup: HTMLElement) {
    this.drawArea = drawArea
    this.arrowGroup = arrowGroup
    this.calculateOffset()
  }

  startDragFrom(anchor: HTMLElement) {
    this.dragFrom = this.anchorCoordinates(anchor)
    this.dragArrow = this.createArrow()
  }

  onMoveDrage(x: number, y: number) {
    this.calculateOffset()
    const stroke = this.arrowStroke(
      this.dragFrom,
      {
        x: x - this.offset.x,
        y: y - this.offset.y
      }
    )
    this.dragArrow!.setAttribute('d', stroke)
  }

  endDrag() {
    this.dragArrow!.remove()
    this.dragFrom = null
    this.dragArrow = null
  }


  addConnecton(fromAnchor: HTMLElement, toAnchor: HTMLElement) {
    const connection = {fromAnchor: fromAnchor, toAnchor: toAnchor, arrow: this.createArrow()}
    this.connections.push(connection)
    this.drawConnection(connection)
  }

  removeConnections() {
    this.connections.forEach((c) => {
      c.arrow.remove()
    })
    this.connections = []
  }

  onResize() {
    this.calculateOffset()
    this.connections.forEach((c) => {
      this.drawConnection(c)
    })
  }

  private calculateOffset() {
    const offset = {
      x: this.drawArea.offsetLeft,
      y: this.drawArea.offsetTop
    }
    let parent = this.drawArea.offsetParent as HTMLElement | null
    while (parent) {
      offset.x += parent.offsetLeft
      offset.y += parent.offsetTop
      parent = parent.offsetParent as HTMLElement | null
    }
    parent = this.drawArea
    while (parent) {
      offset.y -= parent.scrollTop
      parent = parent.parentElement
    }
    this.offset = offset
  }

  private drawConnection(connection: Connection) {
    const from = this.anchorCoordinates(connection.fromAnchor as HTMLElement)
    const to = this.anchorCoordinates(connection.toAnchor as HTMLElement)
    connection.arrow.setAttribute('d', this.arrowStroke(from, to, true))
  }

  private arrowStroke(from: any, to: any, curved = false): string {
    if (curved) {
      // TODO: implement Bezier
      const middle = (from.x + to.x) / 2
      return `M${from.x},${from.y}L${middle},${from.y},${middle},${to.y},${to.x},${to.y}`
    }

    return `M${from.x},${from.y}L${to.x},${to.y}`
  }

  private createArrow(): SVGPathElement {
    const arrow = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    this.arrowGroup.appendChild(arrow)
    return arrow
  }

  private anchorCoordinates(anchor: HTMLElement): { x: number, y: number } {
    return {
      x: anchor.offsetLeft + anchor.offsetWidth / 2,
      y: anchor.offsetTop + anchor.offsetHeight / 2
    }
  }
}
