
import Vue from 'vue'
import Component from 'vue-class-component'
import PageTitle from '@/components/text/PageTitle.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import PageHead from '@/components/layout/PageHead.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import TimeInput from '@/components/forms/TimeInput.vue'
import Card from '@/components/layout/Card.vue'
import SwitcherInput from '@/components/forms/SwitcherInput.vue'
import TextInput from '@/components/forms/TextInput.vue'
import Icon from '@/components/media/Icon.vue'
import TextButton from '@/components/buttons/TextButton.vue'
import { DeliveryDispenseSettings } from '@/application/settings/models/types'
import deliverySettingsService from '@/application/settings/DeliverySettingsService'
import Divider from '@/components/layout/Divider.vue'

@Component({
  components: {
    Divider,
    TextButton,
    Icon,
    TextInput,
    SwitcherInput,
    Card,
    TimeInput,
    VerticalLayout,
    ScrollableSection,
    PageDetail,
    PageHead,
    DetailSection,
    PageTitle
  }
})
export default class DeliveryDispenseView extends Vue {

  deliveryDispense: DeliveryDispenseSettings | null = null

  mounted() {
    this.loadDeliveryDispense()
  }

  loadDeliveryDispense() {
    deliverySettingsService.deliveryDispense().then((response) => {
      this.deliveryDispense = response
    })
  }

  onChange(deliveryDispense: any) {
    const updatedDeliveryDispense: DeliveryDispenseSettings = Object.assign({}, this.deliveryDispense, deliveryDispense)

    deliverySettingsService.updateDeliveryDispense(updatedDeliveryDispense).then(() => {
      this.loadDeliveryDispense()
    }).catch(() => {
      this.loadDeliveryDispense()
    })
  }

}

