import axios from '@/infrastructure/vld-webservice'
import { AxiosRequestConfig } from 'axios'

export const login = (email: string, password: string): Promise<void> => {
  return axios.post(
    'auth/login',
    {
      username: email,
      password
    },
    {ignoreGlobalErrorHandler: true} as AxiosRequestConfig)
    .then(
      () => {
        return
      },
      (error) => {
        if (error.response.status === 401) {
          const apiError = new Error('Invalid credentials')
          // @ts-ignore
          apiError.errorKey = 'invalidCredentials'
          throw apiError
        }
      }
    )
}

export const logout = (): Promise<void> => {
  return axios.get('auth/logout').then(() => {
    return
  })
}
