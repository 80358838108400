import { RawLocation, Route } from 'vue-router'
import { Dictionary } from 'vue-router/types/router'

export class RouterMock {

  currentRoute: Route = {
    path: '',
    hash: '',
    query: {},
    params: {},
    fullPath: '',
    matched: []
  }

  push(location: RawLocation): Promise<Route> {
    if (typeof location === 'string') {
      this.currentRoute.name = location
      this.currentRoute.params = {}
    } else {
      this.currentRoute.name = (location.name) ? location.name : ''
      this.currentRoute.fullPath = (location.name) ? location.name : ''
      this.currentRoute.params = (location.params) ? location.params : {}
      this.currentRoute.query = this.mapToRouterQuery(location.query)
    }
    return Promise.resolve(this.currentRoute)
  }

  mapToRouterQuery(
    locationQuery: Dictionary<string | Array<string | null> | null | undefined> | undefined
  ): Dictionary<string | Array<string | null>> {
    const routerQuery: Dictionary<string | Array<string | null>> = {}
    if (locationQuery !== undefined) {
      for (const key of Object.keys(locationQuery)) {
        const value = locationQuery[key]
        if (value !== undefined && value !== null) {
          routerQuery[key] = value
        }
      }
      this.currentRoute.query = routerQuery
    }
    return routerQuery
  }

  reset() {
    this.currentRoute.path = ''
    this.currentRoute.fullPath = ''
    this.currentRoute.params = {}
  }
}
