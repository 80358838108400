export type Procedure = (...args: any[]) => void

export function debounce<F extends Procedure>(
  func: F,
  waitMilliseconds = 100
): F {

  let timeoutId: ReturnType<typeof setTimeout> | undefined

  return function (this: any, ...args: any[]) {
    const context = this

    const doLater = function () {
      timeoutId = undefined
      func.apply(context, args)
    }

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId)
    }

    timeoutId = setTimeout(doLater, waitMilliseconds)
  } as any

}
