import {
  customers,
  customerDetail,
  createCustomer,
  editCustomer,
  addresses,
  retailers,
  createRetailer,
  retailerDetail,
  editRetailer,
  editIndividualPricing
} from '@/application/customers/resources/customers-resource'
import { CustomerSummary } from '@/application/customers/models/CustomerSummary'
import { store } from '../../../tests/vue-environment'
import { commits } from '@/application/customers/store/mutations'
import { CUSTOMERS_CHANGED, RETAILERS_CHANGED } from '@/application/customers/events'
import eventBus from '@/infrastructure/events/event-bus'
import { CustomerAdd } from '@/application/customers/models/CustomerAdd'
import { Customer } from '@/application/customers/models/Customer'
import { Address } from '@/application/shared/Address'
import { RetailerSummary } from '@/application/customers/models/RetailerSummary'
import { RetailerAdd } from '@/application/customers/models/RetailerAdd'
import { Retailer } from '@/application/customers/models/Retailer'
import { createEventEmittingPromise } from '@/infrastructure/events/create-event-emitting-promise'
import { IndividualPricingSetting } from '@/application/customers/models/IndividualPricingSetting'

class CustomersService {

  saveCustomerDraft(draft: any) {
    store.commit(commits.CUSTOMER_DRAFT, draft)
  }

  deleteCustomerDraft() {
    store.commit(commits.CUSTOMER_DRAFT, null)
  }

  customers(): Promise<CustomerSummary[]> {
    return customers()
  }

  customer(customerId: string): Promise<Customer> {
    return customerDetail(customerId)
  }

  createCustomer(customerData: CustomerAdd): Promise<string> {
    return new Promise(
      (resolve, reject) => {
        return createCustomer(customerData).then((customerId: string) => {
          this.deleteCustomerDraft()
          eventBus.$emit(CUSTOMERS_CHANGED)
          resolve(customerId)
        }
        )
      }
    )
  }

  editCustomer(customerId: string, data: Customer): Promise<Customer> {
    return new Promise((resolve, reject) => {
      return editCustomer(customerId, data).then((aData: any) => {
        eventBus.$emit(CUSTOMERS_CHANGED)
        return customerDetail(customerId).then((updatedCustomer: Customer) => {
          resolve(updatedCustomer)
        }
        )
      }, () => {
        return customerDetail(customerId).then((updatedCustomer: Customer) => {
          resolve(updatedCustomer)
        }
        )
      }
      )
    }
    )
  }

  addresses(): Promise<Address[]> {
    return addresses()
  }

  saveRetailerDraft(draft: any) {
    store.commit(commits.RETAILER_DRAFT, draft)
  }

  deleteRetailerDraft() {
    store.commit(commits.RETAILER_DRAFT, null)
  }

  retailers(): Promise<RetailerSummary[]> {
    return retailers()
  }

  retailer(retailerId: string): Promise<Retailer> {
    return retailerDetail(retailerId)
  }

  createRetailer(customerData: RetailerAdd): Promise<string> {
    return new Promise(
      (resolve, reject) => {
        return createRetailer(customerData).then((retailerId: string) => {
          this.deleteRetailerDraft()
          eventBus.$emit(RETAILERS_CHANGED)
          resolve(retailerId)
        }
        )
      }
    )
  }

  editRetailer(retailerId: string, data: Retailer) {
    return createEventEmittingPromise(
      editRetailer(retailerId, data),
      RETAILERS_CHANGED
    )
  }

  editRetailerIndividualPricingSettings(retailerId: string, data: IndividualPricingSetting) {
    return createEventEmittingPromise(
      editIndividualPricing(retailerId, data),
      RETAILERS_CHANGED
    )
  }
}

export default new CustomersService()
