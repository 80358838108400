/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'help_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#132C45" fill-rule="evenodd"><path pid="0" fill-rule="nonzero" d="M12 3c-4.968 0-9 4.032-9 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9zm0 16.2c-3.969 0-7.2-3.231-7.2-7.2 0-3.969 3.231-7.2 7.2-7.2 3.969 0 7.2 3.231 7.2 7.2 0 3.969-3.231 7.2-7.2 7.2z"/><path pid="1" d="M11.25 16.5V15h1.5v1.5h-1.5zm.75-9a3 3 0 013 3c0 1.875-2.25 2.063-2.25 3.75h-1.5c0-2.438 2.25-2.25 2.25-3.75 0-.825-.675-1.5-1.5-1.5s-1.5.675-1.5 1.5H9a3 3 0 013-3z"/></g>'
  }
})
