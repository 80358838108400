export enum DeliveryStatus {
  UNPLANNED = 'UNPLANNED',
  IN_PROGRESS = 'IN_PROGRESS',
  FULFILLED = 'FULFILLED'
}

export enum TransportStatus {
  TODO = 'TODO',
  PICKED_UP = 'PICKED_UP',
  FULFILLED = 'FULFILLED'
}

export interface TransportSummary {
  status: TransportStatus,
  hasMissingLegs: boolean
}

export interface DeliveryOverviewSummary {
  orderNumber: string,
  deliveryStatus: DeliveryStatus,
  customer: string,
  transports: TransportSummary[]
}

