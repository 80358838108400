

import BadgeContainer from '@/components/badge/BadgeContainer.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {
    BadgeContainer
  }
})
export default class NavigationItem extends Vue {
  @Prop({
    type: String,
    default: null
  }) private badge!: string | null
}
