/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'order_courier': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.148 6.956a.5.5 0 00-.092.148.548.548 0 00-.056.24V21.45c0 .146.056.287.155.39.1.104.233.161.374.161h2.124a4.912 4.912 0 01-.025-1.914h-.936V8.27h3.71c.127 0 .25-.053.34-.147a.51.51 0 00.141-.354V3.906h8.425V9.35l.119-.002c.581 0 1.122.174 1.573.472V2.553a.54.54 0 00-.529-.55H9.141a.552.552 0 00-.103 0 .612.612 0 00-.159.063.503.503 0 00-.11.085L4.147 6.956zm4.356-.155H6.542l1.962-2.045V6.8zm-.967 11.226a4.91 4.91 0 011.83-1.482h-1.83v1.482zm5.779-4.827l-1.818 1.818H7.537V13.2h5.779zm1.29-1.29l1.773-1.773v-.046H7.537v1.818h7.07zm3.727 1.627c.734 0 1.334-.571 1.334-1.269 0-.697-.6-1.268-1.334-1.268C17.6 11 17 11.57 17 12.268c0 .698.6 1.269 1.333 1.269zm-7 4.121C9.467 17.659 8 19.055 8 20.83 8 22.605 9.467 24 11.333 24c1.867 0 3.334-1.395 3.334-3.17 0-1.776-1.467-3.171-3.334-3.171zm2.042 3.16a2 2 0 11-4 0 2 2 0 014 0zm3.958-5.125l-.533-.508-1.6 1.522 1.467 1.459v3.932h-1.334v-3.171L13.2 17.15c-.267-.19-.4-.507-.4-.888 0-.317.133-.634.4-.887l1.867-1.776c.2-.254.533-.38.933-.38s.733.126 1.067.38l1.266 1.205c.6.57 1.4.951 2.4.951v1.268c-1.4 0-2.533-.507-3.4-1.331zm3.334 1.966c-1.867 0-3.334 1.395-3.334 3.17 0 1.776 1.467 3.171 3.334 3.171C22.533 24 24 22.605 24 20.83c0-1.776-1.467-3.171-3.333-3.171zm.008 5.16a2 2 0 100-4 2 2 0 000 4z" _fill="#132C45"/>'
  }
})
