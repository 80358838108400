/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_17_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.9 16.5v-1.8h-1.8v1.8h1.8zm0-3.6V7.5h-1.8v5.4h1.8zM3 12c0-4.968 4.023-9 8.991-9C16.968 3 21 7.032 21 12c0 .444-.032.882-.095 1.309A5.987 5.987 0 0019.131 13 7.198 7.198 0 0012 4.8 7.198 7.198 0 004.8 12a7.198 7.198 0 008.201 7.131 5.99 5.99 0 00.308 1.773c-.43.063-.87.096-1.318.096C7.023 21 3 16.968 3 12z" _fill="#132C45"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19 14.417a4.583 4.583 0 100 9.167 4.583 4.583 0 000-9.167zM21.083 18l-.65-.667h-.129l-1.774 1.808-.83-.845h-.129l-.654.662v.131l1.548 1.578h.13l2.488-2.535V18z" _fill="#29CC96"/>'
  }
})
