import { AddressInput } from '@/components/forms/address/types/AddressInput'

export const parseAddress = (geoLocation: any): AddressInput => {
  const matches = /(.*)<b>(.*)<\/b>/g.exec(geoLocation.label)
  let street = ''
  let city = ''
  if (matches) {
    street = matches[1].trim()
    city = matches[2].trim()
  }
  return {
    street: street,
    city: city,
    coordinate: {
      latitude: geoLocation.lat,
      longitude: geoLocation.lon
    }
  }
}

export const formatAddress = (address: AddressInput): string => {
  return `${address.street}, ${address.city}`
}
