


import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import TimeInput from '@/components/forms/TimeInput.vue'
import { TimeRange } from './types/TimeRange'
import { Time } from './types/Time'

@Component({
  components: {TimeInput}
})
export default class TimeRangeInput extends Vue {

  @Prop({
    type: Object,
    default: () => {
      return {hour: 0, minute: 0}
    }
  })
    value!: Time | TimeRange
  showTimeRange: boolean = ('from' in this.value)

  get timeOnlyClass() {
    return {
      'control-item': true,
      'active': !this.showTimeRange
    }
  }

  get timeRangeClass() {
    return {
      'control-item': true,
      'active': this.showTimeRange
    }
  }

  onClickTimeOnly() {
    if (this.showTimeRange) {
      this.showTimeRange = false
      const timeRangeValue = this.value as TimeRange
      this.$emit('input', Object.assign({}, timeRangeValue.from))
    }
  }

  onClickTimeRange() {
    if (!this.showTimeRange) {
      this.showTimeRange = true
      const timeValue = this.value as Time
      this.$emit('input', {
        from: this.value,
        until: {hour: timeValue.hour + 1, minute: timeValue.minute}
      })
    }
  }

  onChangeTime(value: Time) {
    this.$emit('input', value)
  }

  onChangeTimeFrom(time: Time) {
    this.$emit('input', Object.assign({}, this.value, {from: time}))
  }

  onChangeTimeUntil(time: Time) {
    this.$emit('input', Object.assign({}, this.value, {until: time}))
  }
}
