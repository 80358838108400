/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ordertype_hld': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M12.182 1c-1.933 0-3.507 1.612-3.507 3.593v.093h-4.09A.591.591 0 004 5.284v14.89c0 .331.261.599.584.599H19.78a.591.591 0 00.585-.6V5.285a.591.591 0 00-.585-.598h-4.09v-.093c0-1.98-1.574-3.593-3.507-3.593zM9.844 4.593c0-1.32 1.048-2.395 2.338-2.395s2.337 1.074 2.337 2.395v.093H9.844v-.093zm9.35 1.29v13.692H5.17V5.883h3.506V7.68c0 .331.261.599.585.599a.591.591 0 00.584-.599V5.883h4.675V7.68c0 .331.262.599.585.599a.591.591 0 00.584-.599V5.883h3.507z"/>'
  }
})
