

import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Icon from '@/components/media/Icon.vue'

@Component({
  components: {Icon}
})
export default class ChipValue extends Vue {

  @Prop({type: [Object, String, Number], required: true}) value!: any

  private active = false

  private get classField() {
    return {
      'remove-active': this.active
    }
  }

  mounted() {
    window.addEventListener('mouseup', this.onMouseUp)
  }

  beforeDestroy() {
    window.removeEventListener('mouseup', this.onMouseUp)
  }

  onIconMouseDown() {
    this.active = true
  }

  onMouseUp() {
    this.active = false
  }

  onIconClick() {
    this.$emit('click', this.value)
  }
}
