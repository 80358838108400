
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import Icon from '@/components/media/Icon.vue'
import { OrderDraft, DeliveryItem } from '@/application/deliveries/models/order-draft/types'
import {
  changeDeliveryItem,
  removeDeliveryItem,
  revertDropOffForDeliveryItem
} from '@/application/deliveries/models/order-draft'
import { DragDeliveryItemEvent } from '@/application/deliveries/ui/types/DragDeliveryItemEvent'
import TextInput from '@/components/forms/TextInput.vue'

@Component({
  components: {TextInput, Icon}
})
export default class DeliveryItemInput extends Vue {
  $refs!: {
    dragAnchor: HTMLElement
  }

  @Prop({type: Object, required: true}) orderDraft!: OrderDraft

  @Prop({type: Object, required: true}) value!: DeliveryItem

  @Prop({type: Boolean, default: false}) dropOffDeliveryItem!: boolean

  onChangeLabel(label: string) {
    changeDeliveryItem(this.value, label)
  }

  onMouseDownDragAnchor() {
    const event: DragDeliveryItemEvent = {anchor: this.$refs.dragAnchor, deliveryItem: this.value}
    this.$emit('mousedown-item', event)
  }

  onClickRemove() {
    if (this.dropOffDeliveryItem) {
      revertDropOffForDeliveryItem(this.orderDraft, this.value)
    } else {
      removeDeliveryItem(this.orderDraft, this.value)
    }
  }
}
