


import { FreeDeliveryItem } from '@/application/freedeliveryitems/types'
import DataTable from '@/components/table/DataTable.vue'
import TableCell from '@/components/table/TableCell.vue'
import { deleteFreeDeliveryItem } from '@/application/freedeliveryitems/free-delivery-item-resource'
import { FREE_DELIVERY_ITEMS_CHANGED } from '@/application/customers/events'
import eventBus from '@/infrastructure/events/event-bus'
import Icon from '@/components/media/Icon.vue'

export default {
  name: 'FreeDeliveryItemsList',
  components: {
    DataTable,
    TableCell,
    Icon
  },
  props: {
    freeDeliveryItems: {
      type: Array as () => FreeDeliveryItem[],
      required: true
    }
  },
  methods: {
    deleteItem: async function (id: string) {
      await deleteFreeDeliveryItem(id)
      eventBus.$emit(FREE_DELIVERY_ITEMS_CHANGED)
    }
  }
}
