import store from '@/application/store'
import eventBus from '@/infrastructure/events/event-bus'
import { USERS_CHANGED } from '@/application/users/events'
import {
  createUser,
  deleteUser,
  editUser,
  generateToken,
  roles,
  userDetail,
  users,
  authenticatedUserSummary
} from '@/application/users/users-resource'
import { commits } from '@/application/users/store/mutations'
import {User, UserSummary} from '@/application/users/models/types'
import { createEventEmittingPromise } from '@/infrastructure/events/create-event-emitting-promise'

class UsersService {

  saveUserDraft(draft: any) {
    store.commit(commits.USER_DRAFT, draft)
  }

  deleteUserDraft() {
    store.commit(commits.USER_DRAFT, null)
  }

  createUser(userData: any): Promise<string> {
    return new Promise(
      (resolve) => {
        return createUser(userData).then(
          (userId: string) => {
            this.deleteUserDraft()
            eventBus.$emit(USERS_CHANGED)
            resolve(userId)
          }
        )
      }
    )
  }

  editUser(userId: string, data: any): Promise<User> {
    return new Promise((resolve) => {
      return editUser(userId, data).then(() => {
        eventBus.$emit(USERS_CHANGED)
        return userDetail(userId).then((updatedUser: User) => {
          resolve(updatedUser)
        }
        )
      }, () => {
        return userDetail(userId).then((updatedUser: User) => {
          resolve(updatedUser)
        }
        )
      }
      )
    }
    )
  }

  deleteUser(userId: any): Promise<void> {
    return createEventEmittingPromise(
      deleteUser(userId),
      USERS_CHANGED
    )
  }

  userRoles(): Promise<any> {
    return roles()
  }

  users(): Promise<any> {
    return users()
  }

  user(userId: any): Promise<any> {
    return userDetail(userId)
  }

  generateToken(userId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      return generateToken(userId).then(
        () => {
          resolve()
        }
      )
    }
    )
  }

  authenticatedUserSummary(): Promise<UserSummary> {
    return authenticatedUserSummary()
  }
}

export default new UsersService()
