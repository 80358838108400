
import Vue from 'vue'
import Component from 'vue-class-component'
import PageTitle from '@/components/text/PageTitle.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import PageHead from '@/components/layout/PageHead.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import Divider from '@/components/layout/Divider.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import FormField from '@/components/forms/FormField.vue'
import SwitcherInput from '@/components/forms/SwitcherInput.vue'
import AddressSelectInput from '@/components/forms/address/AddressSelectInput.vue'
import companySettingsService from '@/application/settings/CompanySettingsService'
import { BusinessHourEdit, BusinessHours, Weekday } from '@/application/settings/models/types'
import Card from '@/components/layout/Card.vue'
import MultiSelectChipInput from '@/components/forms/MultiSelectChipInput.vue'
import BusinessHourCard from '@/application/settings/components/BusinessHourCard.vue'
import TextButton from '@/components/buttons/TextButton.vue'
import ModalConfirmation from '@/components/modal/ModalConfirmation.vue'

@Component({
  components: {
    ModalConfirmation,
    Card,
    SwitcherInput,
    VerticalLayout,
    Divider,
    ScrollableSection,
    PageDetail,
    PageHead,
    DetailSection,
    PageTitle,
    FormField,
    AddressSelectInput,
    MultiSelectChipInput,
    BusinessHourCard,
    TextButton
  }
})
export default class BusinessHoursView extends Vue {

  businessHours: BusinessHourEdit[] = []

  weekdays: Weekday[] = []

  businessHoursPositionToDelete: number  | null = null

  get addButtonVisible(): boolean {
    for (const businessHour of this.businessHours) {
      if (businessHour.isNewRecord) {
        return false
      }
    }
    return true
  }

  get showDeleteConfirmation(): boolean {
    return this.businessHoursPositionToDelete !== null
  }

  created() {
    this.weekdays = [
      {
        key: 'MONDAY',
        value: this.$t('weekdays.monday')
      },
      {
        key: 'TUESDAY',
        value: this.$t('weekdays.tuesday')
      },
      {
        key: 'WEDNESDAY',
        value: this.$t('weekdays.wednesday')
      },
      {
        key: 'THURSDAY',
        value: this.$t('weekdays.thursday')
      },
      {
        key: 'FRIDAY',
        value: this.$t('weekdays.friday')
      },
      {
        key: 'SATURDAY',
        value: this.$t('weekdays.saturday')
      },
      {
        key: 'SUNDAY',
        value: this.$t('weekdays.sunday')
      }
    ] as Weekday[]
  }

  mounted() {
    this.loadBusinessHours()
  }

  loadBusinessHours() {
    companySettingsService.businessHours().then((response) => {
      this.businessHours = this.mapBusinessHours(response)
    })
  }

  selectedWeekdays(businessHour: BusinessHourEdit): any {
    return this.mapWeekdays(businessHour.days)
  }

  selectableWeekdays(businessHour: BusinessHourEdit): any {
    const selectedWeekdaysForBusinessHour = businessHour.days

    let allSelectedWeekdays: string[] = []
    this.businessHours.forEach((bh: BusinessHourEdit) => allSelectedWeekdays = allSelectedWeekdays.concat(bh.days))

    const unselectedDays: string[] = this.weekdays.filter((weekday: Weekday) => {
      return !allSelectedWeekdays.includes(weekday.key)
    }).map((weekday: Weekday) => weekday.key)

    return this.mapWeekdays(selectedWeekdaysForBusinessHour.concat(unselectedDays))
  }

  mapWeekdays(weekdays: string[]): Weekday[] {
    return weekdays.map((day: string) => {
      return this.weekdays.find((weekday: any) => weekday.key === day) as Weekday
    }
    )
  }

  mapBusinessHours(openingHours: BusinessHours[]): BusinessHourEdit[] {
    return openingHours.map((businessHour: BusinessHours, index: number) => {
      return {
        position: index,
        days: businessHour.days.map((x) => x),
        from: businessHour.from,
        to: businessHour.to,
        isNewRecord: false
      }
    }
    )
  }

  onClickAddButton() {
    const newRecord: BusinessHourEdit = {
      position: -1,
      days: [],
      from: '08:00',
      to: '18:00',
      isNewRecord: true
    }

    this.businessHours.push(newRecord)
  }

  deleteBusinessHours() {
    const clone = this.businessHours.slice(0)
    clone.splice(this.businessHoursPositionToDelete!, 1)
    const data = {
      openingPeriods: clone
    }

    companySettingsService.updateBusinessHours(data).then(() =>
      this.loadBusinessHours()
    )
  }

  onDeleteBusinessHour(position: number) {
    this.businessHoursPositionToDelete = position
  }

  onClickConfirmationDeleteDiscard() {
    this.businessHoursPositionToDelete = null
  }

  onClickConfirmationDeleteSave() {
    this.deleteBusinessHours()
    this.businessHoursPositionToDelete = null
  }

  onUpdateBusinessHour(businessHourEdit: BusinessHourEdit) {

    const clone = this.businessHours.slice(0)
    clone[businessHourEdit.position] = businessHourEdit

    const data = {
      openingPeriods: clone.map((hourEdit: BusinessHourEdit) => {
        return {
          days: hourEdit.days,
          from: hourEdit.from,
          to: hourEdit.to
        } as BusinessHours
      })
    }

    companySettingsService.updateBusinessHours(data).then(() =>
      this.loadBusinessHours()
    ).catch(() => this.loadBusinessHours())
  }

  onCreateBusinessHour(businessHourEdit: BusinessHourEdit) {
    this.onUpdateBusinessHour(businessHourEdit)

  }

  onCancelCreateBusinessHour(position: number) {
    const clone = this.businessHours.slice(0)
    clone.splice(position, 1)
    this.businessHours = clone
  }

}
