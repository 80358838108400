
import Component from 'vue-class-component'
import { Prop, Vue, Watch } from 'vue-property-decorator'
import Icon from '@/components/media/Icon.vue'
import TextInput from '@/components/forms/TextInput.vue'
import IconButton from '@/components/buttons/IconButton.vue'
import { Validator } from '@/components/forms/Validator'

@Component({
  components: {
    IconButton,
    TextInput,
    Icon
  }
})
export default class InlineTextInput extends Vue {

  $refs!: {
    textInput: TextInput
  }

  @Prop({type: String, default: 'text'}) type!: string

  @Prop({type: Boolean, default: false}) required!: boolean

  @Prop({type: String, default: ''}) value!: string

  @Prop({type: String, default: null}) placeholder!: string | null

  @Prop({type: String, default: null}) inputClass!: string | null

  @Prop({type: Number, default: 1}) lines!: number

  @Prop({type: Number, default: 255}) maxLength!: number

  @Prop({type: Object, default: null}) errorValidator!: Validator | null

  localValue: string = this.value

  editing = false

  mouseDownControl = false

  get showAdd(): boolean {
    return !this.required && !this.editing && (this.localValue === null || this.localValue.length === 0)
  }

  get existingValue(): boolean {
    return this.localValue !== null && this.localValue.length > 0
  }

  get textInputClasses(): string | null {
    const classes = []
    if (this.inputClass) {
      classes.push(this.inputClass)
    }
    if (this.existingValue) {
      classes.push('style-inline existing-value')
    }
    return classes.length > 0 ? classes.join(' ') : null
  }

  get error(): string | null {
    if (this.required && this.existingValue && this.localValue.length === 0) {
      return this.$tc('InlineTextInput.fieldRequired')
    }
    if (this.localValue && this.errorValidator && this.errorValidator.isInvalid(this.localValue)) {
      return this.errorValidator.errorMessage
    }
    return null
  }

  get confirmButtonDisabled(): boolean {
    return this.error !== null
  }

  get isMultiline(): boolean {
    return this.lines > 1
  }

  @Watch('value')
  onChangeValue() {
    this.localValue = this.value
  }

  beforeDestroy() {
    window.removeEventListener('mouseup', this.onMouseUp)
  }

  onClickAdd() {
    this.beginEdit()
  }

  onBlur() {
    if (!this.mouseDownControl && this.editing) {
      this.confirm()
    }
  }

  onFocus() {
    this.editing = true
  }

  onKeyEnter() {
    if (this.isMultiline) {
      return
    }

    if (!this.editing) {
      this.beginEdit()
    } else {
      this.confirm()
    }
  }

  onKeyEsc() {
    this.cancel()
  }

  onClickCancel() {
    this.cancel()
  }

  onClickConfirm() {
    this.confirm()
  }

  onClickTextInput() {
    if (!this.editing) {
      this.editing = true
    }
  }

  onMouseDownControl(event: MouseEvent) {
    event.preventDefault()
    this.mouseDownControl = true
    window.addEventListener('mouseup', this.onMouseUp)
  }

  onMouseUp() {
    this.mouseDownControl = false
    window.removeEventListener('mouseup', this.onMouseUp)
  }

  private confirm() {
    if (this.error) {
      this.cancel()
    } else {
      this.$emit('input', this.localValue)
      this.endEdit()
    }
  }

  private cancel() {
    this.localValue = this.value
    this.endEdit()
  }

  private beginEdit() {
    this.editing = true
    this.$nextTick(() => {
      this.$refs.textInput.focus()
    })
  }

  private endEdit() {
    this.editing = false
  }

}

