
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import Icon from '@/components/media/Icon.vue'

@Component({
  components: {
    Icon
  }
})
export default class ModalWindow extends Vue {

  @Prop({
    type: Boolean,
    default: false
  })
    show!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
    fullWidth!: boolean

  get styleClasses() {
    return {
      'full-width': this.fullWidth === true
    }
  }

}
