/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav_analytics': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#132C45" fill-rule="evenodd"><path pid="0" d="M12 22C6.5 22 2 17.5 2 12S6.5 2 12 2s10 4.5 10 10-4.5 10-10 10zm0-18.334c-4.584 0-8.334 3.75-8.334 8.334s3.75 8.334 8.334 8.334 8.334-3.75 8.334-8.334S16.584 3.666 12 3.666z"/><path pid="1" d="M17.916 19.084l-6.75-6.75v-9.5h1.668v8.832l6.25 6.25z"/><path pid="2" d="M15.881 3.427l1.178 1.178-4.478 4.478-1.178-1.178zM18.779 5.968l1.178 1.178-6.246 6.246-1.178-1.178zM20.321 9.78l1.178 1.179-5.126 5.126-1.179-1.178z"/></g>'
  }
})
