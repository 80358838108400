/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_13_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.319 17.066L12 18.02c.224.487.591.807 1 .927.006-.658.117-1.29.319-1.881zm.325-.773L6.906 20l-.436-.72.67-2.288-2.394-3.952c-1.337-2.207-.82-4.955 1.096-6.66l-.297-.49c-.362-.597-.137-1.363.503-1.716.64-.352 1.449-.154 1.81.444l.297.489c2.507-.728 5.263.218 6.6 2.426l2.395 3.952 2.414.59.436.72-.421.232a6 6 0 00-5.935 3.265z" _fill="#132C45"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19 14.417a4.583 4.583 0 100 9.167 4.583 4.583 0 000-9.167zM21.083 18l-.65-.667h-.129l-1.774 1.808-.83-.845h-.129l-.654.662v.131l1.548 1.578h.13l2.488-2.535V18z" _fill="#29CC96"/>'
  }
})
