/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'price': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M3.357 2C2.065 2 1 3.099 1 4.432v15.406C1 21.02 1.948 22 3.095 22h17.81C22.052 22 23 21.021 23 19.838V7.405c0-1.183-.948-2.162-2.095-2.162h-1.048V2.811c0-.425-.374-.811-.786-.811H3.357zm0 1.622h14.929v1.621H3.357c-.449 0-.786-.347-.786-.81 0-.464.337-.811.786-.811zm-.786 3.1c.247.09.51.143.786.143h17.548c.304 0 .524.227.524.54v2.703h-7.334c-.857 0-1.571.737-1.571 1.622v3.784c0 .884.714 1.621 1.571 1.621h7.334v2.703c0 .313-.22.54-.524.54H3.095c-.304 0-.524-.227-.524-.54V6.72zm11.524 5.008h7.334v3.784h-7.334V11.73zm2.62.81c-.58 0-1.048.484-1.048 1.082 0 .597.468 1.08 1.047 1.08.58 0 1.048-.483 1.048-1.08 0-.598-.469-1.081-1.048-1.081z"/>'
  }
})
