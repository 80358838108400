/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkmark': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M9.143 19L2 12.269l2-1.884 5.143 4.846L20 5l2 1.885z"/>'
  }
})
