/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_19': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.23 5.383h-2.21c.188-.486.23-1.013.105-1.52a2.486 2.486 0 00-1.626-1.752 2.464 2.464 0 00-2.333.485l-2.27 1.963-2.27-1.963A2.465 2.465 0 007.29 2.11a2.412 2.412 0 00-1.625 1.752 2.529 2.529 0 00.104 1.52H3.563A1.57 1.57 0 002 6.967v2.216c0 .823.625 1.478 1.417 1.562v8.656a1.57 1.57 0 001.562 1.583h13.876a1.57 1.57 0 001.562-1.583v-8.656a1.572 1.572 0 001.417-1.562V6.945c-.042-.866-.73-1.562-1.605-1.562zm.02 3.8H14.23V6.945h6l.021 2.238zm-9.146 0V6.945h1.563v2.217h-1.563v.021zm1.584 1.562v8.656h-1.563v-8.656h1.563zm2.5-6.945a.835.835 0 01.854-.17c.291.106.521.339.604.634a.884.884 0 01-.167.781.904.904 0 01-.708.359h-2.417L15.188 3.8zM7.334 5.024a.955.955 0 01-.167-.781c.083-.317.291-.55.604-.634a1.18 1.18 0 01.27-.042c.209 0 .396.085.563.212l1.834 1.583H8.02a.764.764 0 01-.686-.338zM3.562 6.945h6v2.217h-6V6.945zm1.396 3.8h4.605v8.656H4.958v-8.656zm9.27 8.656v-8.656h4.606v8.656h-4.605z" _fill="#132C45"/>'
  }
})
