/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_16_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20.96 13.327c.118-1-.024-2.03-.435-2.986a.15.15 0 00-.023-.039c-.3-.68-.729-1.299-1.262-1.823a.802.802 0 00-1.124 0 .776.776 0 000 1.11 4.307 4.307 0 011.276 3.424 5.976 5.976 0 011.568.314zm-7.944 5.228c.04-.54.15-1.06.324-1.55H9.868l1.238-1.219a.395.395 0 000-.564l-.555-.546a.405.405 0 00-.569 0l-2.864 2.827a.395.395 0 00.001.563l2.864 2.818a.402.402 0 00.568 0l.555-.547a.395.395 0 000-.564l-1.238-1.218h3.148zM4 8.09a5.832 5.832 0 00-.524 5.568.148.148 0 00.023.039c.3.68.729 1.299 1.263 1.823a.802.802 0 001.123 0 .776.776 0 000-1.11 4.307 4.307 0 01-1.29-3.071 4.31 4.31 0 011.292-3.071 4.452 4.452 0 013.12-1.274h3.126l-1.238 1.219a.395.395 0 000 .564l.555.546a.405.405 0 00.569 0l2.864-2.827a.395.395 0 00-.001-.563l-2.864-2.817a.402.402 0 00-.568 0l-.555.546a.395.395 0 000 .563l1.238 1.22H9.001c-2.012 0-3.89.993-5.002 2.645z" _fill="#132C45"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19 14.417a4.583 4.583 0 100 9.167 4.583 4.583 0 000-9.167zM21.083 18l-.65-.667h-.129l-1.774 1.808-.83-.845h-.129l-.654.662v.131l1.548 1.578h.13l2.488-2.535V18z" _fill="#29CC96"/>'
  }
})
