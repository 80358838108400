
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import Icon from '@/components/media/Icon.vue'
import ModalWindow from '@/components/modal/ModalWindow.vue'
import SaveDiscardButtons from '@/components/buttons/SaveDiscardButtons.vue'
import BottomBar from '@/components/layout/BottomBar.vue'

@Component({
  components: {
    BottomBar,
    SaveDiscardButtons,
    ModalWindow,
    Icon
  }
})
export default class ModalConfirmation extends Vue {

  @Prop({
    type: Boolean,
    default: false
  })
    show!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
    disableSaveButton!: boolean

  onClickDiscard() {
    this.$emit('click-discard')
  }

  onClickSave() {
    this.$emit('click-save')
  }

}
