


import Vue from 'vue'
import Component from 'vue-class-component'
import Icon from '@/components/media/Icon.vue'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {
    Icon
  }
})
export default class ContainedButton extends Vue {
  @Prop({
    type: String,
    default: 'primary',
    validator: function (value: string) {
      return [
        'primary',
        'accent',
        'white',
        'outlined'
      ].indexOf(value) !== -1
    }
  }) private appearance!: string

  @Prop({
    type: String,
    default: null
  }) private icon!: string | null

  private get iconClass() {
    return {'icon-with-text': this.$slots.default !== undefined}
  }

  private onClick(e: any) {
    this.$emit('click', e)
  }
}
