import { environment } from '@/infrastructure/Environment'

class RemoteConfigurationSettings {

  private readonly TWELVE_HOURS = 12 * 60 * 60 * 1000

  fetchMinimumIntervalMillis(): number {
    const fetchMinimumIntervalMillis = environment.get('VUE_APP_FIREBASE_FETCH_MINIMUM_INTERVAL_MILLIS')
    if (fetchMinimumIntervalMillis) {
      const radix = 10
      const fetchMinimumIntervalMillisNumber = parseInt(fetchMinimumIntervalMillis, radix)
      if (!Number.isNaN(fetchMinimumIntervalMillisNumber)) {
        return fetchMinimumIntervalMillisNumber
      }
    }

    return this.TWELVE_HOURS
  }

  fetchTimeoutMillis(): number {
    return 60000
  }
}

export const remoteConfigurationSettings = new RemoteConfigurationSettings()
