

import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'

@Component
export default class BadgeContainer extends Vue {
  @Prop({type: [String, Number], default: null}) private value!: string | number | null
}

