/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'due_time': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21 5.446l-4.14-3.453-1.16 1.369 4.14 3.452L21 5.446zM8.292 3.362L7.14 1.993 3 5.437l1.161 1.368 4.131-3.443zm4.158 4.123H11.1v5.366l4.275 2.55.675-1.1-3.6-2.12V7.485zM12 3.907c-4.473 0-8.1 3.605-8.1 8.05s3.618 8.05 8.1 8.05c4.473 0 8.1-3.605 8.1-8.05s-3.627-8.05-8.1-8.05zm0 14.31c-3.483 0-6.3-2.799-6.3-6.26s2.817-6.26 6.3-6.26c3.483 0 6.3 2.799 6.3 6.26s-2.817 6.26-6.3 6.26z" _fill="#132C45"/>'
  }
})
