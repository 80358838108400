import { render, staticRenderFns } from "./OrderSettingsOverview.vue?vue&type=template&id=636ac996&scoped=true&"
import script from "./OrderSettingsOverview.vue?vue&type=script&lang=ts&"
export * from "./OrderSettingsOverview.vue?vue&type=script&lang=ts&"
import style0 from "./OrderSettingsOverview.vue?vue&type=style&index=0&id=636ac996&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "636ac996",
  null
  
)

export default component.exports