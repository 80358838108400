
import Vue from 'vue'
import Component from 'vue-class-component'
import DetailSection from '@/components/layout/DetailSection.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import PageTitle from '@/components/text/PageTitle.vue'
import PageHead from '@/components/layout/PageHead.vue'
import FormField from '@/components/forms/FormField.vue'
import {DateRange} from '@/components/forms/types/DateRange'
import {Prop} from 'vue-property-decorator'
import CalendarRangePicker from '@/components/forms/CalendarRangePicker.vue'

  @Component({
    components: {
      CalendarRangePicker,
      PageDetail,
      PageTitle,
      PageHead,
      ScrollableSection,
      VerticalLayout,
      DetailSection,
      FormField
    }
  })
export default class AnalyticsFilter extends Vue {

    @Prop({
      type: Object,
      required: true
    })
      value!: DateRange

    onValueChanged(dateRange: DateRange) {
      this.$emit('input', dateRange)
    }


}
