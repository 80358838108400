/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_4': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 1c2.152 0 3.916 1.606 4.027 3.62h4.045c.513 0 .928.392.928.879V20.12c0 .486-.415.879-.928.879H3.928C3.415 21 3 20.607 3 20.12V5.5c0-.487.415-.88.928-.88h4.045C8.083 2.606 9.848 1 12 1zm2.167 3.62C14.06 3.576 13.129 2.758 12 2.758c-1.13 0-2.06.818-2.167 1.86h4.334zm1.865 1.758v1.473c0 .487-.415.88-.927.88-.513 0-.928-.393-.928-.88V6.378H9.823v1.473c0 .487-.415.88-.928.88-.512 0-.927-.393-.927-.88V6.378H4.856v12.863h14.289V6.378h-3.113z" _fill="#132C45"/>'
  }
})
