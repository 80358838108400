import { render, staticRenderFns } from "./Avatar.vue?vue&type=template&id=044f27cb&scoped=true&"
import script from "./Avatar.vue?vue&type=script&lang=ts&"
export * from "./Avatar.vue?vue&type=script&lang=ts&"
import style0 from "./Avatar.vue?vue&type=style&index=0&id=044f27cb&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "044f27cb",
  null
  
)

export default component.exports