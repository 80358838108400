
import Component from 'vue-class-component'
import { Prop, Vue, Watch } from 'vue-property-decorator'
import Icon from '@/components/media/Icon.vue'
import ItemList from '@/components/menus/ItemList.vue'
import VNode from '@/components/forms/VNode.vue'
import SelectElement from '@/components/forms/SelectElement.vue'

@Component({
  components: {
    SelectElement,
    ItemList,
    Icon,
    VNode
  }
})
export default class SelectInput extends Vue {

  $refs!: {
    selectElement: SelectElement,
    contextMenu: ItemList
  }

  @Prop({type: Array, required: true}) items!: any[]

  @Prop({type: Object, default: null}) value!: any | null

  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
    disabledItems!: any[]

  open = false

  outputNode: any | null = null

  highlighted: any | null

  @Watch('value')
  onChangeValue() {
    this.renderValue()
  }

  mounted() {
    this.renderValue()
  }

  beforeDestroy() {
    document.body.removeEventListener('click', this.onClickOutside)
  }

  onClickSelectElement() {
    if (this.open) {
      this.closeMenu()
    } else {
      this.openMenu()
    }
  }

  onSelectItem(item: any) {
    this.$emit('input', item)
    this.open = false
  }

  onHighlightItem(item: any) {
    this.highlighted = item
  }

  onClickOutside(event: MouseEvent) {
    const clickOnSelectElement = this.$refs.selectElement.$el === event.target
      || this.$refs.selectElement.$el.contains(event.target as Node)
    if (!clickOnSelectElement) {
      this.cancelInput()
    }
  }

  onKeyUp() {
    if (this.open) {
      this.$refs.contextMenu.highlightPreviousItem()
    }
  }

  onKeyDown() {
    if (this.open) {
      this.$refs.contextMenu.highlightNextItem()
    }
  }

  onKeyEnter() {
    if (this.open) {
      this.onSelectItem(this.highlighted)
    } else {
      this.openMenu()
    }
  }

  onKeyEsc() {
    this.cancelInput()
  }

  cancelInput() {
    this.$emit('cancel', event)
    this.closeMenu()
  }

  openMenu() {
    document.body.addEventListener('click', this.onClickOutside)
    this.open = true
    this.$refs.contextMenu.highlightFirstItem()
  }

  closeMenu() {
    document.body.removeEventListener('click', this.onClickOutside)
    this.open = false
  }

  focus() {
    this.$refs.selectElement.focus()
    this.openMenu()
  }

  itemStyleClasses(state: any) {
    return {
      selected: state.selected,
      disabled: state.disabled
    }
  }

  renderValue() {
    if (this.value) {
      this.outputNode = this.$scopedSlots.default!(
        {
          item: this.value,
          state: {selected: false, highlighted: false}
        }
      )
    } else {
      this.outputNode = null
    }
  }
}
