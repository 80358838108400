
import DataTable from '@/components/table/DataTable.vue'
import TableCell from '@/components/table/TableCell.vue'
import analyticsService from '@/application/analytics/AnalyticsService'
import Vue from 'vue'
import Component from 'vue-class-component'
import DetailSection from '@/components/layout/DetailSection.vue'
import {DeliveriesPerRetailer} from '@/application/analytics/models/DeliveriesPerRetailer'
import {Prop, Watch} from 'vue-property-decorator'
import {DateRange} from '@/components/forms/types/DateRange'
import Icon from '@/components/media/Icon.vue'

  @Component({
    components: {
      Icon,
      DetailSection,
      DataTable,
      TableCell
    }
  })
export default class AnalyticsResult extends Vue {

    @Prop({
      type: Object,
      required: true
    })
      dateRange!: DateRange

    deliveriesPerRetailer: DeliveriesPerRetailer[] = []

    mounted() {
      this.loadData()
    }

    @Watch('dateRange')
    loadData() {
      analyticsService.deliveriesPerRetailer(this.dateRange).then((deliveriesPerRetailer) => {
        this.deliveriesPerRetailer = deliveriesPerRetailer
      })
    }

    formatTotalNumber(total: number): number | string {
      return (total === 0) ? '–' : total
    }

    get formattedFrom(): string {
      return this.$d(this.dateRange.from, 'short', 'de')
    }

    get formattedTo(): string {
      return this.$d(this.dateRange.to, 'short', 'de')
    }

    get totalDeliveries(): number {
      return this.deliveriesPerRetailer
        .map((deliveryPerRetailer) => deliveryPerRetailer.totalDeliveries)
        .reduce((sum: number, item: number) => sum + item, 0)
    }

    get totalOrders(): number {
      return this.deliveriesPerRetailer
        .map((deliveryPerRetailer) => deliveryPerRetailer.totalOrders)
        .reduce((sum: number, item: number) => sum + item, 0)
    }

}
