/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav_orders': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#132C45" fill-rule="evenodd"><path pid="0" d="M5.052 7.594A.48.48 0 005 7.81v12.694a.497.497 0 00.496.496h14.008a.495.495 0 00.496-.496V3.498a.495.495 0 00-.496-.496H9.82a.54.54 0 00-.097 0 .589.589 0 00-.149.057.47.47 0 00-.104.077L5.138 7.46a.447.447 0 00-.086.134zm2.331-.273l1.84-1.84v1.84h-1.84zm11.03 11.956H6.587V8.643h3.479a.452.452 0 00.45-.45V4.715h7.899v14.561z"/><path pid="1" d="M9 10h7v1.313H9V10zm0 5.688h7V17H9v-1.313zm0-2.844h7v1.312H9v-1.312z"/></g>'
  }
})
