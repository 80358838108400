/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_16': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.525 10.34a5.832 5.832 0 01-.524 5.569 6.025 6.025 0 01-5.002 2.646h-3.131l1.238 1.218a.395.395 0 010 .564l-.555.546a.405.405 0 01-.568 0l-2.864-2.817a.394.394 0 010-.563l2.863-2.826a.403.403 0 01.569 0l.554.545a.394.394 0 01.001.564l-1.238 1.219h3.125a4.452 4.452 0 003.121-1.274 4.31 4.31 0 001.293-3.07 4.307 4.307 0 00-1.29-3.072.776.776 0 010-1.11.802.802 0 011.122 0 5.885 5.885 0 011.263 1.823.15.15 0 01.023.039zm-17.05 3.32a5.832 5.832 0 01.524-5.569 6.025 6.025 0 015.002-2.646h3.131l-1.238-1.219a.395.395 0 010-.563l.555-.546a.405.405 0 01.568 0l2.864 2.817a.394.394 0 010 .563l-2.863 2.826a.403.403 0 01-.569 0l-.554-.545a.393.393 0 01-.001-.564l1.238-1.219H9.007a4.452 4.452 0 00-3.121 1.274 4.31 4.31 0 00-1.293 3.07c0 1.152.464 2.257 1.29 3.072a.776.776 0 010 1.11.802.802 0 01-1.122 0 5.885 5.885 0 01-1.263-1.823.148.148 0 01-.023-.039z" _fill="#132C45"/>'
  }
})
