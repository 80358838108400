import Vue from 'vue'
import { VueClass, Wrapper, mount as vueMount } from '@vue/test-utils'
import { RouterMock } from './mocks/RouterMock'
import vuexStore from '../src/application/store'
import { mockWebsocket, WebsocketMock } from './mocks/websocket-mock'
import { applicationService } from '@/application/ApplicationService'
import { mockFormatter } from './mocks/formatter-mock'
import { mockRandomizer } from './mocks/randomizer-mock'
import { mockUuid } from './mocks/uuid-mock'
import { mockDate, resetDateMock } from './mocks/date-mock'
import { VueI18nMock } from './mocks/VueI18nMock'
import { getNestedValue } from '@/utils/json-utils'


export const store = vuexStore
export const router = new RouterMock()
export const vueI18n = new VueI18nMock()
export let websocketMock: WebsocketMock

export const mockEnvironment = (loggedIn = true) => {
  if (loggedIn) {
    jest.spyOn(applicationService, 'isLoggedIn').mockImplementation(() => true)
  } else {
    jest.spyOn(applicationService, 'isLoggedIn').mockImplementation(() => false)
  }
  websocketMock = mockWebsocket()
  mockFormatter()
  mockRandomizer()
  mockUuid()
  mockDate(new Date(2018, 11, 13, 9, 0, 0))
}

export const resetMockedEnvironment = () => {
  router.reset()
  resetDateMock()
  jest.restoreAllMocks()
}

export const mount = <V extends Vue>(component: VueClass<V>, props: any = {}): Wrapper<V> => {
  return vueMount(component, {
    propsData: props,
    mocks: {
      $router: router,
      $route: router.currentRoute,
      $t: vueI18n.t,
      $d: vueI18n.d,
      $store: vuexStore,
      $nestedVal: getNestedValue
    },
    stubs: ['router-link', 'router-view']
  })
}
