/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_3_not_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.285 3.833h-2.032V2.358c0-.21-.169-.358-.36-.358h-3.787a.356.356 0 00-.36.358v1.475H7.715C6.762 3.833 6 4.593 6 5.541v14.752C6 21.24 6.762 22 7.715 22h6.088a5.958 5.958 0 01-.64-1.603h-5.47c-.064 0-.106-.042-.106-.105V5.54c0-.064.042-.105.106-.105h8.57c.064 0 .107.042.107.105v8.066a5.955 5.955 0 011.63-.523V5.541c0-.95-.762-1.708-1.715-1.708zm-3.168 4.363a.187.187 0 00-.148.088l-3.4 5.126a.212.212 0 00-.014.208.188.188 0 00.167.109h1.816l-.957 3.05a.207.207 0 00.091.247.182.182 0 00.243-.065l3.494-5.126a.212.212 0 00.014-.208.188.188 0 00-.167-.11h-1.824l.87-3.059a.212.212 0 00-.03-.181.185.185 0 00-.155-.08z" _fill="#132C45"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19 14.417a4.583 4.583 0 100 9.167 4.583 4.583 0 000-9.167zm.793 2.998a.281.281 0 01.396 0l.396.396a.281.281 0 010 .396l-.793.793.793.793a.281.281 0 010 .396l-.396.396a.281.281 0 01-.396 0L19 19.792l-.793.793a.281.281 0 01-.396 0l-.396-.396a.281.281 0 010-.396l.793-.793-.793-.793a.281.281 0 010-.396l.396-.396a.281.281 0 01.396 0l.793.793.793-.793z" _fill="#F24949"/>'
  }
})
