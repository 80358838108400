

import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import roleService from '../RolesService'
import PageTitle from '@/components/text/PageTitle.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import PageHead from '@/components/layout/PageHead.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import Divider from '@/components/layout/Divider.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import { routerNames, routerParameters } from '@/application/router/router-definitions'
import { OptionsMenuItem } from '@/components/menus/types/OptionsMenuItem'
import OptionsMenu from '@/components/menus/OptionsMenu.vue'
import ModalConfirmation from '@/components/modal/ModalConfirmation.vue'
import Icon from '@/components/media/Icon.vue'
import LinkedText from '@/components/text/LinkedText.vue'
import RoleForm from '@/application/users/templates/RoleForm.vue'
import { Role } from '@/application/users/models/types'

@Component({
  components: {
    LinkedText,
    VerticalLayout,
    Divider,
    ScrollableSection,
    PageDetail,
    PageHead,
    DetailSection,
    PageTitle,
    OptionsMenu,
    ModalConfirmation,
    Icon,
    RoleForm
  }
})
export default class RolesEdit extends Vue {

  // non reactive
  routes: any

  role: any | null = null
  zones: any[] = []
  couriers: any[] = []
  showDeleteConfirmation = false

  get roleId() {
    return this.$route.params.id
  }

  get options(): OptionsMenuItem [] {
    return [
      {
        identifier: 'remove',
        text: this.$t('users.deleteRole') as string,
        icon: 'trash',
        disabled: this.hasAssignedTransports
      }
    ]
  }

  get hasAssignedTransports(): boolean {
    return this.role.assignedTransports > 0
  }

  mounted() {
    this.routes = routerNames
    this.loadRole()
  }

  @Watch('roleId')
  onChangeRoleId() {
    this.loadRole()
  }

  onChangeRole(value: any) {
    roleService.updateRole(this.roleId, Object.assign({}, this.role, value)).then((updatedRole: Role) => {
      this.role = updatedRole
    })
  }

  onChangeCouriers(couriers: any[]) {
    this.role.courierIds = couriers.map((courier) => courier.courierId)
  }

  onChangeZones(zones: any[]) {
    this.role.monitoringZones = zones.map((zone) => zone.name)
  }

  loadRole() {
    roleService.zones().then((zones) => {
      this.zones = zones
    })
    roleService.couriers().then((couriers) => {
      this.couriers = couriers
    })
    roleService.role(this.roleId).then((data) => {
      this.role = data
    })
  }

  onClickMenuItem() {
    this.showDeleteConfirmation = true
  }

  onClickConfirmationDeleteDiscard() {
    this.showDeleteConfirmation = false
  }

  onClickConfirmationDeleteSave() {
    this.showDeleteConfirmation = false
    roleService.deleteRole(this.roleId).then(() => {
      this.$router.push({name: 'role-overview', params: {[routerParameters.DISABLE_ROUTE_TO_LAST_CHILD]: 'true'}})
    })
  }
}
