

import qrcode from 'qrcode'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component
export default class QRCode extends Vue {

  @Prop({type: [Object, String], required: true}) private value!: any

  private qrCodeURL: string | null = null

  $el!: HTMLElement

  mounted() {
    this.generateQRCode()
  }

  @Watch('value')
  private onValueChanged() {
    this.generateQRCode()
  }

  private generateQRCode() {
    qrcode.toDataURL(
      JSON.stringify(this.value),
      {
        width: this.$el.offsetWidth * window.devicePixelRatio,
        margin: 2
      })
      .then((url: string) => {
        this.qrCodeURL = url
      })
  }

}
