/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'refresh': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M17.64 6.35A7.958 7.958 0 0011.99 4C7.57 4 4 7.58 4 12s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08a5.99 5.99 0 01-5.65 4c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L12.99 11h7V4l-2.35 2.35z"/>'
  }
})
