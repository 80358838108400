/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron_right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.295 7.115l1.41-1.41 6 6-6 6-1.41-1.41 4.58-4.59-4.58-4.59z" _fill="#132C45"/>'
  }
})
