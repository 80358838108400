/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_10_not_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M22 5.833L17.4 2l-1.29 1.52 4.6 3.833L22 5.833zM7.88 3.52L6.6 2 2 5.823l1.29 1.52 4.59-3.824zm4.62 4.578H11v5.959h4.6a5.983 5.983 0 011.452-.733v-.646H12.5v-4.58zM19 13c-.034-3.814-3.151-6.889-7-6.889-3.87 0-7 3.108-7 6.952 0 3.843 3.13 6.95 7 6.95a7.12 7.12 0 001.072-.08c.106.68.326 1.322.64 1.905A9.113 9.113 0 0112 22c-4.98 0-9-4.002-9-8.937 0-4.936 4.03-8.938 9-8.938s9 4.002 9 8.938c0 .092-.001.185-.004.277A5.991 5.991 0 0019 13z" _fill="#132C45"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19 14.417a4.583 4.583 0 100 9.167 4.583 4.583 0 000-9.167zm.793 2.998a.281.281 0 01.396 0l.396.396a.281.281 0 010 .396l-.793.793.793.793a.281.281 0 010 .396l-.396.396a.281.281 0 01-.396 0L19 19.792l-.793.793a.281.281 0 01-.396 0l-.396-.396a.281.281 0 010-.396l.793-.793-.793-.793a.281.281 0 010-.396l.396-.396a.281.281 0 01.396 0l.793.793.793-.793z" _fill="#F24949"/>'
  }
})
