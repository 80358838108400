
import Vue from 'vue'
import Component from 'vue-class-component'
import PageTitle from '@/components/text/PageTitle.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import PageHead from '@/components/layout/PageHead.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import deliverySettingsService from '@/application/settings/DeliverySettingsService'
import TimeInput from '@/components/forms/TimeInput.vue'
import { DeliveryTime, DeliveryWindow } from '@/application/settings/models/types'
import Card from '@/components/layout/Card.vue'
import Icon from '@/components/media/Icon.vue'
import TextButton from '@/components/buttons/TextButton.vue'
import DeliveryWindowCard from '@/application/settings/components/DeliveryWindowCard.vue'
import ModalConfirmation from '@/components/modal/ModalConfirmation.vue'

@Component({
  components: {
    ModalConfirmation,
    Card,
    TimeInput,
    VerticalLayout,
    ScrollableSection,
    PageDetail,
    PageHead,
    DetailSection,
    PageTitle,
    Icon,
    TextButton,
    DeliveryWindowCard
  }
})
export default class DeliveryTimesView extends Vue {

  addButtonVisible = true

  deliveryTime: DeliveryTime | null = null

  deliveryWindows: DeliveryWindow[] = []

  deliveryWindowIdToDelete: string | null = null

  get guaranteedDeliveryTime() {
    return {
      hour: this.deliveryTime!.guaranteedDeliveryTimeHours,
      minute: this.deliveryTime!.guaranteedDeliveryTimeMinutes
    }
  }

  get showDeleteConfirmation(): boolean {
    return this.deliveryWindowIdToDelete !== null
  }

  mounted() {
    this.loadDeliveryTimes()
    this.loadDeliveryWindows()
  }

  onChangedDeliveryTime(newDeliveryTime: any) {
    const deliveryTime: DeliveryTime = {
      guaranteedDeliveryTimeHours: newDeliveryTime.hour,
      guaranteedDeliveryTimeMinutes: newDeliveryTime.minute
    }
    deliverySettingsService.updateDeliveryTime(deliveryTime).then(() => this.loadDeliveryTimes())
  }

  loadDeliveryTimes() {
    deliverySettingsService.deliveryTime().then((response) => {
      this.deliveryTime = response
    })
  }

  loadDeliveryWindows() {
    deliverySettingsService.deliveryWindows().then((response) => {
      this.deliveryWindows = response
    })
  }

  onRemoveDeliveryWindow(deliveryWindowId: string) {
    this.deliveryWindowIdToDelete = deliveryWindowId
  }

  onClickConfirmationDeleteDiscard() {
    this.deliveryWindowIdToDelete = null
  }

  onClickConfirmationDeleteSave() {
    deliverySettingsService.removeDeliveryWindow(this.deliveryWindowIdToDelete!).then(() => {
      this.loadDeliveryWindows()
    })
    this.deliveryWindowIdToDelete = null
  }

  onClickAddButton() {
    this.addButtonVisible = false
  }

  onCancelCreateDeliveryWindow() {
    this.addButtonVisible = true
  }

  onCreateDeliveryWindow(deliveryWindowDraft: any) {
    this.addButtonVisible = true
    deliverySettingsService.createDeliveryWindow(deliveryWindowDraft).then(() => {
      this.loadDeliveryWindows()
    })
  }

  onUpdateDeliveryWindow(deliveryWindow: DeliveryWindow) {
    deliverySettingsService.updateDeliveryWindow(deliveryWindow).then(() => {
      this.loadDeliveryWindows()
    })
  }
}
