import { Route } from 'vue-router'
import { VueRouter } from 'vue-router/types/router'
import { routerParameters } from '@/application/router/router-definitions'

export function routeToLastChildInHistory(to: Route, next: any, aRouter: VueRouter, history: Route[]) {
  if (to.params && to.params[routerParameters.DISABLE_ROUTE_TO_LAST_CHILD]) {
    next()
    return
  }
  const lastItem = history.find((item) => item.fullPath.startsWith(to.fullPath + '/'))
  if (lastItem) {
    const name = (lastItem.name != null) ? lastItem.name : undefined
    aRouter.push({
      name: name,
      params: {...lastItem.params}
    }).catch((err) => {})
  } else {
    next()
  }
}

export function removeSubpathFromHistory(subpath: string, history: Route[]) {
  const regex = new RegExp(`${subpath}[\/|?]+`, 'mi')
  for (let i = history.length - 1; i >= 0; i--) {
    const historyPath = history[i].fullPath
    if (regex.test(historyPath) || historyPath.endsWith(subpath)) {
      history.splice(i, 1)
    }
  }
}
