/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'business': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" d="M12 7.556V4H3v16h18V7.556h-9zM6.6 18.222H4.8v-1.778h1.8v1.778zm0-3.555H4.8v-1.778h1.8v1.778zm0-3.556H4.8V9.333h1.8v1.778zm0-3.555H4.8V5.778h1.8v1.778zm3.6 10.666H8.4v-1.778h1.8v1.778zm0-3.555H8.4v-1.778h1.8v1.778zm0-3.556H8.4V9.333h1.8v1.778zm0-3.555H8.4V5.778h1.8v1.778zm9 10.666H12v-1.778h1.8v-1.777H12v-1.778h1.8V11.11H12V9.333h7.2v8.89zm-1.8-7.11h-1.8v1.777h1.8V11.11zm0 3.555h-1.8v1.777h1.8v-1.777z"/>'
  }
})
