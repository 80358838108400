

import Component from 'vue-class-component'
import { Vue } from 'vue-property-decorator'
import Icon from '@/components/media/Icon.vue'

@Component({
  components: {Icon}
})
export default class SelectElement extends Vue {

  $el!: HTMLElement

  focus() {
    this.$el.focus()
  }
}
