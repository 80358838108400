/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav_customers': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M16 7.158v-1.58C16 4.704 15.288 4 14.4 4H9.6C8.712 4 8 4.703 8 5.579v1.579H5.6c-.888 0-1.6.703-1.6 1.579v8.684C4 18.297 4.712 19 5.6 19h12.8c.888 0 1.6-.703 1.6-1.579V8.737c0-.876-.712-1.58-1.6-1.58H16zm-1.6-1.58v1.58H9.6v-1.58h4.8zM5.6 17.422v-2.233h12.8v2.233H5.6zm0-3.947V8.737H8v1.579h1.6v-1.58h4.8v1.58H16v-1.58h2.4v4.738H5.6z"/>'
  }
})
