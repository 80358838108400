
import { Transport } from '@/application/deliveries/models/transport/types'
import TransferTransport from '@/application/deliveries/ui/types/TransferTransport'
import TransportCard from '@/application/deliveries/ui/components/transport/TransportCard.vue'
import TransportView from '@/application/deliveries/ui/components/transport/TransportView.vue'
import TransportAssignableView from '@/application/deliveries/ui/components/transport/TransportAssignableView.vue'
import { State } from 'vuex-class'
import { DeliverySummary } from '@/application/deliveries/models/DeliverySummary'
import { disposeTransports, edit } from '@/application/deliveries/services/deliveries-service'
import { randomNumber } from '@/utils/randomizer'
import {
  default as DispoSwitcher,
  DispoOptionChangedEvent
} from '@/application/deliveries/ui/components/transport/DispoSwitcherView.vue'
import { UpdateDeliveryRequest } from '@/application/deliveries/resources/UpdateDeliveryRequest'
import { DeliveriesState } from '@/application/deliveries/store/types'
import { routerNames, routerQueries } from '@/application/router/router-definitions'
import {
  default as TransportDraggableTargetMixin,
  TransportDraggableTarget
} from '@/application/deliveries/models/transport/TransportDraggableTargetMixin'
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import TransportDropZone from '@/application/deliveries/ui/components/transport/TransportDropZone.vue'
import Card from '@/components/layout/Card.vue'
import { formatOrderNumber } from '@/application/deliveries/utils/formatter'
import ContainedButton from '@/components/buttons/ContainedButton.vue'
import TransportMissingView from '@/application/deliveries/ui/components/transport/TransportMissingView.vue'
import { DeliveryItem } from '@/application/deliveries/models/DeliveryItem'
import { DeliveryItemStatus } from '@/application/deliveries/models/DeliveryItemStatus'


@Component({
  components: {
    TransportMissingView,
    ContainedButton,
    Card,
    TransportDropZone,
    DispoSwitcher,
    TransportView,
    TransportCard,
    TransportAssignableView
  }
})
export default class DeliveriesUnplanned extends Mixins(TransportDraggableTargetMixin)
  implements TransportDraggableTarget {

  @State('deliveries') state!: DeliveriesState

  imgIndex: number = randomNumber(1, 5)

  get deliveries() {
    return this.state.unplanned
  }

  get hasUnplannedDeliveries(): boolean {
    return this.deliveries.length > 0
  }

  get missingDeliveries() {
    return this.state.missing
  }

  get hasMissingDeliveries(): boolean {
    return this.missingDeliveries.length > 0
  }

  get imagePath(): string {
    return `/images/deliveries_unplanned_emptystate_${this.imgIndex}.svg`
  }

  missingDeliveryItems(transport: Transport): Array<{ pickup: DeliveryItem, dropOff: DeliveryItem }> {
    return transport.pickup.deliveryItems.map((item, index) => {
      return {
        pickup: item,
        dropOff: transport.dropOff.deliveryItems[index]
      }
    }).filter((item) => {
      return item.dropOff.status === DeliveryItemStatus.MISSED || item.pickup.status === DeliveryItemStatus.MISSED
    })
  }

  onClickDelivery(orderNumber: string) {
    this.$router.push({
      name: routerNames.DELIVERIES_DELIVERY,
      params: {orderNumber: orderNumber}
    }).catch((err) => {})
  }

  onClickTransport(transport: Transport) {
    this.$router.push({
      name: routerNames.DELIVERIES_DELIVERY,
      params: {orderNumber: transport.orderNumber},
      query: {[routerQueries.SELECTED_TRANSPORT_ID]: transport.transportId}
    }).catch((err) => {})
  }

  onDispoOptionChanged(event: DispoOptionChangedEvent) {
    const delivery: UpdateDeliveryRequest = {
      viaDispo: event.dispoOption
    }
    edit(event.orderNumber, delivery)
  }

  selectedDelivery(delivery: DeliverySummary): string | null {
    if (this.$route.params.orderNumber) {
      return (this.$route.params.orderNumber === delivery.orderNumber) ? 'selected' : null
    }
    return null
  }

  onDroppedTransport(transfer: TransferTransport) {
    disposeTransports(
      transfer.orderNumber,
      {
        assigneeId: 'DISPOSE_MANUAL',
        transportIds: [transfer.transportId]
      }
    )
  }

  redirectAfterDrop(): boolean {
    return true
  }

  formattedOrderNumber(orderNumber: string) {
    return formatOrderNumber(orderNumber)
  }
}
