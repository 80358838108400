/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'person_block': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" d="M8 14c2.67 0 8 1.34 8 4v3H0v-3c0-2.66 5.33-4 8-4zm0 1.9c-2.97 0-6.1 1.46-6.1 2.1v1.1h12.2V18c0-.64-3.13-2.1-6.1-2.1zM8 5c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm0 1.9a2.1 2.1 0 100 4.2 2.1 2.1 0 000-4.2zm16-1.598L21.301 8 24 10.699l-1.301 1.3L20 9.301 17.301 12 16 10.699 18.7 8 16 5.3 17.301 4l2.7 2.7L22.698 4 24 5.303z"/>'
  }
})
