
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Calendar from '@/components/forms/Calendar.vue'
import { DateRange } from '@/components/forms/types/DateRange'
import { CalendarDay } from '@/components/forms/types/CalendarDay'
import { DefaultDateRange } from '@/components/forms/types/DefaultDateRange'

@Component({
  components: {Calendar}
})
export default class CalendarRangePicker extends Calendar {

  @Prop({
    type: Object,
    required: false,
    default: null
  })
    value!: DateRange | null

  private dragRange: DateRange | null = null

  onConfigureDay(day: CalendarDay) {
    const dateTime = day.date.getTime()

    const dragRange = (this.dragRange !== null) ? this.dragRange.normalizedRange() : null
    const valueRange = (this.value !== null) ? this.value.normalizedRange() : null

    day.isSelected = valueRange != null && valueRange.isTimeInRange(dateTime)
    day.dragActive = dragRange !== null
    day.isDragged = dragRange != null && dragRange.isTimeInRange(dateTime)
    day.isFirstInRange = (
      dragRange === null
        && valueRange !== null
        && valueRange.firstInRange(dateTime)
        && valueRange.notSameDates()
    )
      || (
        dragRange !== null
        && dateTime === dragRange.from.getTime()
      )
    day.isLastInRange = (
      dragRange === null
        && valueRange !== null
        && valueRange.lastInRange(dateTime)
        && valueRange.notSameDates()
    )
      || (
        dragRange !== null
        && dragRange.notSameDates()
        && dateTime === dragRange.to.getTime()
      )
    day.isInRange = valueRange !== null && valueRange.isTimeInRange(dateTime) && valueRange.notSameDates()
  }

  onSelectDay(day: CalendarDay) {
    if (!this.dragRange) {
      this.dragRange = new DefaultDateRange(day.date, day.date)
    } else {
      this.$emit('input', this.dragRange.normalizedRange())
      this.dragRange = null
    }
  }

  onSelectToday(today: Date) {
    this.$emit('input', new DefaultDateRange(today, today))
  }

  onEnterDay(day: CalendarDay) {
    if (!this.dragRange) {
      return
    }
    this.dragRange = new DefaultDateRange(this.dragRange.from, day.date)
  }

}
