
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import { SupplementalServiceIcon } from '@/application/settings/models/types'
import Card from '@/components/layout/Card.vue'
import MultiSelectChipInput from '@/components/forms/MultiSelectChipInput.vue'
import TimeInput from '@/components/forms/TimeInput.vue'
import Icon from '@/components/media/Icon.vue'
import BottomBar from '@/components/layout/BottomBar.vue'
import SaveDiscardButtons from '@/components/buttons/SaveDiscardButtons.vue'
import Divider from '@/components/layout/Divider.vue'
import SelectInput from '@/components/forms/SelectInput.vue'
import InlineTextInput from '@/components/forms/InlineTextInput.vue'
import InlineNumberInput from '@/components/forms/InlineNumberInput.vue'

@Component({
  components: {
    InlineTextInput,
    SelectInput,
    Divider,
    Icon,
    TimeInput,
    MultiSelectChipInput,
    Card,
    BottomBar,
    SaveDiscardButtons,
    InlineNumberInput
  }
})
export default class SupplementalServiceCard extends Vue {

  icons: SupplementalServiceIcon[] = []

  @Prop({
    type: Object,
    default: () => {
      return {name: null, price: 0, iconIndex: 1}
    }
  })
    supplementalService!: any

  @Prop({
    type: Boolean,
    default: false
  })
    isNewRecord!: boolean

  get iconValue(): SupplementalServiceIcon {
    return this.icons[this.supplementalService.iconIndex]
  }

  get readyToSave(): boolean {
    return this.supplementalService.name !== null
  }

  created() {
    for (let i = 0; i <= 19; i++) {
      this.icons.push(
        {
          index: i,
          path: 'service_' + i
        } as SupplementalServiceIcon
      )
    }
  }

  onChangeIcon(index: number) {
    this.supplementalService.iconIndex = index
    this.onUpdated()
  }

  onChangeName(name: string) {
    this.supplementalService.name = name
    this.onUpdated()
  }

  onChangePrice(price: number) {
    this.supplementalService.price = price
    this.onUpdated()
  }

  onClickDiscard() {
    this.$emit('cancel')
  }

  onClickCreate() {
    this.$emit('save', this.supplementalService)
  }

  onClickRemove() {
    this.$emit('delete', this.supplementalService.supplementalServiceId)
  }

  onUpdated() {
    if (this.isNewRecord) {
      return
    }

    this.$emit('input', this.supplementalService)
  }

}
