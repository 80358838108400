

import { Prop, Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import DetailSection from '@/components/layout/DetailSection.vue'
import CalendarPicker from '@/components/forms/CalendarPicker.vue'


@Component({
  components: {
    CalendarPicker,
    DetailSection
  }
})
export default class DeliveriesOverviewFilter extends Vue {

  @Prop({
    type: Date,
    required: true
  })
    date!: Date

  onDateChange(date: Date) {
    this.$emit('dateChanged', date)
  }

}

