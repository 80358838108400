
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import PageTitle from '@/components/text/PageTitle.vue'
import Icon from '@/components/media/Icon.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import QRCode from '@/components/media/QRCode.vue'
import Divider from '@/components/layout/Divider.vue'
import ContainedButton from '@/components/buttons/ContainedButton.vue'
import InlineTextInput from '@/components/forms/InlineTextInput.vue'
import FormField from '@/components/forms/FormField.vue'
import MultiSelectChipInput from '@/components/forms/MultiSelectChipInput.vue'
import SwitcherInput from '@/components/forms/SwitcherInput.vue'
import AddressSelectInput from '@/components/forms/address/AddressSelectInput.vue'
import { Retailer } from '@/application/customers/models/Retailer'

@Component({
  components: {
    SwitcherInput,
    MultiSelectChipInput,
    FormField,
    InlineTextInput,
    Divider,
    QRCode,
    DetailSection,
    PageTitle,
    Icon,
    ContainedButton,
    AddressSelectInput
  }
})
export default class RetailerForm extends Vue {

  @Prop({
    type: Object,
    required: true
  })
    retailer!: Retailer

  get isInEditMode(): boolean {
    return this.retailer.retailerId !== undefined
  }

  get nameInputStyle(): string | null {
    return (this.isInEditMode) ? 'font-large' : null
  }

  onChangeRetailer(value: any) {
    const updatedRetailer = Object.assign({}, this.retailer, value)
    if (updatedRetailer.address) {
      updatedRetailer.address.name = this.retailer.name
    }
    this.$emit('input', updatedRetailer)
  }

}
