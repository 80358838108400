import { DueDate } from '@/application/deliveries/models/DueDate'
import i18n from '@/i18n'
import { isSameDay } from '@/utils/date-util'

export const formatDueDateTime = (dueDate: DueDate): string => {
  let until = ''
  if (dueDate.dateUntil) {
    until = ' - ' + formatTime(dueDate.dateUntil)
  }
  return formatTime(dueDate.date) + until
}

export const formatDueDate = (dueDate: DueDate): string => {
  let until = ''
  if (dueDate.dateUntil) {
    until = ' – ' + formatDate(dueDate.dateUntil) + '\n' + formatTime(dueDate.dateUntil)
  }
  return formatDate(dueDate.date) + '\n' + formatTime(dueDate.date) + until
}

export const formatDueDateTitle = (dueDate: DueDate, confirmationDate: Date | null): string => {
  const dueDateUntil = formatDueDateUntil(dueDate)
  const initialDueDate = `${i18n.t('deliveries.initialTime')}\n${formatDateTime(dueDate.date)}${dueDateUntil}`

  if (confirmationDate !== null) {
    const confirmationTime = `\n${i18n.t('deliveries.confirmationTime')}\n${formatDateTime(confirmationDate)}`
    return `${initialDueDate}\n${confirmationTime}`
  } else {
    return initialDueDate
  }
}

export const formatTime = (date: Date): string => {
  const hour = leadingZero(date.getHours())
  const minute = leadingZero(date.getMinutes())
  return `${hour}:${minute}`
}

export const formatDate = (date: Date): string => {
  const weekday = i18n.t('weekdays.short' + date.getDay())
  const day = leadingZero(date.getDate())
  const month = leadingZero(date.getMonth() + 1)
  const year = date.getFullYear()
  return `${weekday} ${day}.${month}.${year}`
}

export const formatCurrency = (amount: number): string => {
  return Number(amount).toFixed(2)
}

const leadingZero = (value: number) => {
  if (value < 10) {
    return `0${value}`
  }
  return value
}

const formatDueDateUntil = (dueDate: DueDate): string => {
  if (dueDate.dateUntil) {
    if (isSameDay(dueDate.date, dueDate.dateUntil)) {
      return ` – ${formatTime(dueDate.dateUntil)}`
    } else {
      return ` –\n${formatDate(dueDate.dateUntil)} ${formatTime(dueDate.dateUntil)}`
    }
  }
  return ''
}

const formatDateTime = (date: Date) =>  {
  return `${formatDate(date)} ${formatTime(date)}`
}
