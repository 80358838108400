
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import { Time } from './types/Time'
import { debounce } from '@/components/forms/debounce'


@Component
export default class TimeInput extends Vue {
  $refs!: {
    inputMinute: HTMLInputElement,
    inputHour: HTMLInputElement
  }

  @Prop({
    type: Object,
    default: () => {
      return {hour: 0, minute: 0}
    }
  })
    value!: Time

  get hourValue(): string {
    return this.formatTimeNumber(this.value.hour)
  }

  get minuteValue(): string {
    return this.formatTimeNumber(this.value.minute)
  }

  onChangeHour() {
    const hourValue = Number(this.$refs.inputHour.value)
    if (isNaN(hourValue) || hourValue > 23) {
      this.$refs.inputHour.value = '23'
    }
    if (this.$refs.inputHour.value.length > 1) {
      this.$refs.inputMinute.focus()
    }
    debounce(this.emitLocalValue, 400).call(this)
  }

  onFocusHour() {
    this.$refs.inputHour.setSelectionRange(0, this.$refs.inputHour.value.length)
  }

  onBlurHour() {
    const value = this.$refs.inputHour.value
    this.$refs.inputHour.value = this.formatTimeNumber(Number(value))
  }

  onChangeMinute() {
    const minuteValue = Number(this.$refs.inputMinute.value)
    if (isNaN(minuteValue) || minuteValue > 59) {
      this.$refs.inputMinute.value = '59'
    }
    debounce(this.emitLocalValue, 400).call(this)
  }

  emitLocalValue() {
    this.$emit('input', this.localValue())
  }

  onFocusMinute() {
    this.$refs.inputMinute.setSelectionRange(0, this.$refs.inputMinute.value.length)
  }

  onBlurMinute() {
    const value = this.$refs.inputMinute.value
    this.$refs.inputMinute.value = this.formatTimeNumber(Number(value))
  }

  private localValue(): Time {
    return {
      hour: Number(this.$refs.inputHour.value),
      minute: Number(this.$refs.inputMinute.value)
    }
  }

  private formatTimeNumber(aNumber: number) {
    if (aNumber < 10) {
      return `0${aNumber}`
    }
    return `${aNumber}`
  }

}
