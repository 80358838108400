import { GetterTree } from 'vuex'
import { CustomersState } from '@/application/customers/store/types'

export enum getterKeys {
  CUSTOMER_DRAFT = 'customers/hasCustomerDraft',
  RETAILER_DRAFT = 'customers/hasRetailerDraft',
}

export const getters: GetterTree<CustomersState, any> = {

  hasCustomerDraft(state) {
    return state.customerDraft !== null
  },

  hasRetailerDraft(state) {
    return state.retailerDraft !== null
  }

}
