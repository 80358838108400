
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import { BusinessHourEdit, Weekday } from '@/application/settings/models/types'
import Card from '@/components/layout/Card.vue'
import MultiSelectChipInput from '@/components/forms/MultiSelectChipInput.vue'
import TimeInput from '@/components/forms/TimeInput.vue'
import Icon from '@/components/media/Icon.vue'
import BottomBar from '@/components/layout/BottomBar.vue'
import SaveDiscardButtons from '@/components/buttons/SaveDiscardButtons.vue'
import Divider from '@/components/layout/Divider.vue'

@Component({
  components: {
    Divider,
    Icon,
    TimeInput,
    MultiSelectChipInput,
    Card,
    BottomBar,
    SaveDiscardButtons
  }
})
export default class BusinessHourCard extends Vue {

  @Prop({
    type: Object,
    required: true
  })
    businessHour!: BusinessHourEdit

  @Prop({
    type: Array,
    required: true
  })
    selectedWeekdays!: Weekday[]

  @Prop({
    type: Array,
    required: true
  })
    selectableWeekdays!: Weekday[]

  get timeOpen(): any {
    return this.splitToTime(this.businessHour.from)
  }

  get timeClose(): any {
    return this.splitToTime(this.businessHour.to)
  }

  get readyToSave(): boolean {
    if (this.businessHour.days.length === 0) {
      return false
    }
    return !(this.businessHour.from === null || this.businessHour.to === null)
  }

  onChangedTimeFrom(time: any) {
    this.businessHour.from = this.timeToString(time)
    this.onUpdated()
  }

  onChangedTimeTo(time: any) {
    this.businessHour.to = this.timeToString(time)
    this.onUpdated()
  }

  onChangeWeekdays(days: any) {
    this.businessHour.days = days.map((day: any) => day.key)
    this.onUpdated()
  }

  timeToString(time: any): string {
    const hour = ('0' + time.hour).slice(-2)
    const minute = ('0' + time.minute).slice(-2)

    return `${hour}:${minute}`
  }

  splitToTime(value: string): object {
    const splitTime = value.split(':')
    return {
      hour: +splitTime[0],
      minute: +splitTime[1]
    }
  }

  onClickRemove() {
    this.$emit('delete', this.businessHour.position)
  }

  onUpdated() {
    if (this.businessHour.isNewRecord) {
      return
    }

    this.$emit('input', this.businessHour)
  }

  onClickDiscard() {
    this.$emit('cancel', this.businessHour.position)
  }

  onClickCreate() {
    this.$emit('save', this.businessHour)
  }

}
