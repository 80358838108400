/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M12 2c3.86 0 7 3.14 7 7 0 5.25-7 13-7 13S5 14.25 5 9c0-3.86 3.14-7 7-7zm0 4a3 3 0 100 6 3 3 0 000-6z"/>'
  }
})
