
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import PageTitle from '@/components/text/PageTitle.vue'
import Icon from '@/components/media/Icon.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import QRCode from '@/components/media/QRCode.vue'
import Divider from '@/components/layout/Divider.vue'
import ContainedButton from '@/components/buttons/ContainedButton.vue'
import InlineTextInput from '@/components/forms/InlineTextInput.vue'
import FormField from '@/components/forms/FormField.vue'
import MultiSelectChipInput from '@/components/forms/MultiSelectChipInput.vue'
import SwitcherInput from '@/components/forms/SwitcherInput.vue'
import { Role } from '@/application/users/models/types'

@Component({
  components: {
    SwitcherInput,
    MultiSelectChipInput,
    FormField,
    InlineTextInput,
    Divider,
    QRCode,
    DetailSection,
    PageTitle,
    Icon,
    ContainedButton
  }
})
export default class RoleForm extends Vue {

  @Prop({
    type: Object,
    required: true
  })
    role!: Role

  @Prop({
    type: Array,
    required: true
  })
    couriers!: any[]

  @Prop({
    type: Array,
    required: true
  })
    zones!: any[]

  activateZoneSelectField = false

  get selectedCouriers(): any {
    return this.role.courierIds.map(
      (courierId: string) => this.couriers.find((courier) => courier.courierId === courierId)
    )!
  }

  get selectedZones(): any {
    return this.role.monitoringZones.map(
      (zoneName: string) => this.zones.find((zone) => zone.name === zoneName)
    )!
  }

  get isInEditMode(): boolean {
    return this.role.roleId !== undefined
  }

  get formFieldStyle(): string | null {
    return (this.isInEditMode) ? 'style-hidden' : null
  }

  get formLayout(): string | null {
    return (this.isInEditMode) ? null : 'role-inputs'
  }

  mounted() {
    this.activateZoneSelectField = this.role.monitoringZones.length > 0
  }

  @Watch('role.roleId')
  onRoleIdChange() {
    this.activateZoneSelectField = this.role.monitoringZones.length > 0
  }

  onRoleChange(value: any) {
    const updatedRole = Object.assign({}, this.role, value)
    this.$emit('input', updatedRole)
  }

  onChangeCouriers(couriers: any[]) {
    const courierIds = couriers.map((courier) => courier.courierId)
    this.onRoleChange({courierIds: courierIds})
  }

  onChangeZones(zones: any[]) {
    const monitoringZones = zones.map((zone) => zone.name)
    this.onRoleChange({monitoringZones: monitoringZones})
  }

  onClickMonitoringDropoffs(value: boolean) {
    this.activateZoneSelectField = value
    if (!value) {
      this.onRoleChange({monitoringZones: []})
    }
  }

}
