


import Vue from 'vue'
import Component from 'vue-class-component'
import DeliveriesUnplanned from '@/application/deliveries/ui/templates/DeliveriesUnplanned.vue'
import DeliveriesAssignees from '@/application/deliveries/ui/templates/DeliveriesAssignees.vue'
import PageLayout from '@/components/layout/PageLayout.vue'
import PageTitle from '@/components/text/PageTitle.vue'
import PageHead from '@/components/layout/PageHead.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import { Watch } from 'vue-property-decorator'
import { routerNames } from '@/application/router/router-definitions'
import {
  selectCourier,
  selectDelivery,
  selectRole,
  unselect
} from '@/application/deliveries/services/deliveries-service'

@Component({
  name: 'Deliveries',
  components: {
    VerticalLayout,
    PageDetail,
    ScrollableSection,
    PageHead,
    PageTitle,
    PageLayout,
    DeliveriesUnplanned,
    DeliveriesAssignees
  }
})
export default class Deliveries extends Vue {

  @Watch('$route')
  onChangeRoute() {
    switch (this.$route.name) {
    case routerNames.DELIVERIES_COURIER: {
      selectCourier(this.$route.params.courierId).then()
      break
    }
    case routerNames.DELIVERIES_COURIER_DELIVERY: {
      selectDelivery(this.$route.params.orderNumber).then()
      break
    }
    case routerNames.DELIVERIES_ROLE: {
      selectRole(this.$route.params.roleId).then()
      break
    }
    case routerNames.DELIVERIES_ROLE_DELIVERY: {
      selectDelivery(this.$route.params.orderNumber).then()
      break
    }
    case routerNames.DELIVERIES_DELIVERY: {
      selectDelivery(this.$route.params.orderNumber).then()
      break
    }
    default: {
      unselect()
    }
    }
  }

  mounted() {
    this.onChangeRoute()
  }
}
