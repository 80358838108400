/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'order_unknown': {
    width: 24,
    height: 25,
    viewBox: '0 0 24 25',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.056 7.886a.5.5 0 01.092-.148l4.62-4.805a.504.504 0 01.111-.085.613.613 0 01.16-.064.554.554 0 01.102 0h10.33a.54.54 0 01.529.551V8.55a6.836 6.836 0 00-1.692.022V4.688H9.883v3.863a.51.51 0 01-.14.354.472.472 0 01-.34.147H5.691v11.816h10.36v1.914H4.53a.517.517 0 01-.374-.16.565.565 0 01-.155-.39V8.125a.548.548 0 01.056-.24zm2.486-.303h1.962V5.538L6.542 7.583z" _fill="#132C45"/><path pid="1" d="M16.379 19.078a7.56 7.56 0 00-.022.068h-8.82v-1.819h8.842v1.751zM12.606 13.982c-.08.416-.122.845-.122 1.284v.534H7.537v-1.818h5.069zM14.106 10.873a6.755 6.755 0 00-1.114 1.818H7.537v-1.818h6.57z" _fill="#132C45"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M18.224 22.754h2.036v-2.037h-2.036v2.037zm1.018-12.218a4.071 4.071 0 00-4.072 4.073h2.036c0-1.12.916-2.036 2.036-2.036s2.036.916 2.036 2.036c0 2.036-3.054 1.781-3.054 5.09h2.036c0-2.29 3.054-2.545 3.054-5.09a4.071 4.071 0 00-4.072-4.072z" _fill="#132C45"/>'
  }
})
