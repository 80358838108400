import {
  getDeliveriesPerRetailer
} from '@/application/analytics/resources/analytics-resource'
import { DeliveriesPerRetailer } from '@/application/analytics/models/DeliveriesPerRetailer'
import { DateRange } from '@/components/forms/types/DateRange'

class AnalyticsService {

  deliveriesPerRetailer(dateRange: DateRange): Promise<DeliveriesPerRetailer[]> {
    return getDeliveriesPerRetailer(dateRange.from, dateRange.to)
  }

}

export default new AnalyticsService()
