import { FirebaseApp, initializeApp } from 'firebase/app'
import 'firebase/remote-config'
import { getRemoteConfig } from 'firebase/remote-config'
import { RemoteConfig } from '@firebase/remote-config'
import { environment } from '@/infrastructure/Environment'

interface FirebaseConfig {
  enabled: boolean
  apiKey: string | undefined
  authDomain: string | undefined
  projectId: string | undefined
  storageBucket: string | undefined
  messagingSenderId: string | undefined
  appId: string | undefined
}

export class Firebase {
  private firebaseApp: FirebaseApp | null = null

  private firebaseConfig: FirebaseConfig | null = null

  initialize = (): void => {
    this.firebaseConfig = {
      enabled: environment.get('VUE_APP_FIREBASE_ENABLED') === 'true',
      apiKey: environment.get('VUE_APP_FIREBASE_API_KEY'),
      authDomain: environment.get('VUE_APP_FIREBASE_AUTH_DOMAIN'),
      projectId: environment.get('VUE_APP_FIREBASE_PROJECT_ID'),
      storageBucket: environment.get('VUE_APP_FIREBASE_STORAGE_BUCKET'),
      messagingSenderId: environment.get('VUE_APP_FIREBASE_MESSAGING_SENDER_ID'),
      appId: environment.get('VUE_APP_FIREBASE_APP_ID')
    }

    if (this.firebaseConfig.enabled) {
      this.firebaseApp = initializeApp(this.firebaseConfig)
    }
  }

  remoteConfig = (): RemoteConfig | null => {
    if (!this.firebaseApp) {
      return null
    }
    return getRemoteConfig(this.firebaseApp)
  }
}
