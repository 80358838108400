

import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class Card extends Vue {

  @Prop ({
    type: Boolean,
    default: false
  })
    bottomBar!: boolean

  @Prop ({
    type: Boolean,
    default: false
  })
    header!: boolean

  onClick(e: any) {
    this.$emit('click', e)
  }
}

