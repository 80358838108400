
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import customerService from '@/application/customers/CustomersService'
import PageTitle from '@/components/text/PageTitle.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import PageHead from '@/components/layout/PageHead.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import Divider from '@/components/layout/Divider.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import OptionsMenu from '@/components/menus/OptionsMenu.vue'
import { OptionsMenuItem } from '@/components/menus/types/OptionsMenuItem'
import ModalConfirmation from '@/components/modal/ModalConfirmation.vue'
import ModalWindow from '@/components/modal/ModalWindow.vue'
import { Customer } from '@/application/customers/models/Customer'
import FormField from '@/components/forms/FormField.vue'
import InlineTextInput from '@/components/forms/InlineTextInput.vue'
import AddressSelectInput from '@/components/forms/address/AddressSelectInput.vue'
import CustomerForm from '@/application/customers/ui/templates/CustomerForm.vue'

@Component({
  components: {
    CustomerForm,
    AddressSelectInput,
    InlineTextInput,
    FormField,
    ModalWindow,
    ModalConfirmation,
    VerticalLayout,
    Divider,
    ScrollableSection,
    PageDetail,
    PageHead,
    DetailSection,
    PageTitle,
    OptionsMenu
  }
})
export default class CustomerEdit extends Vue {

  customer: Customer | null = null
  showDeleteConfirmation = false

  get customerId() {
    return this.$route.params.id
  }

  get options(): OptionsMenuItem [] {
    return [
      {
        identifier: 'remove',
        text: this.$t('customers.deleteCustomer') as string,
        icon: 'trash',
        disabled: this.hasAssignedTransports
      }
    ]
  }

  get hasAssignedTransports(): boolean {
    return true
  }

  mounted() {
    this.loadCustomer()
  }

  @Watch('customerId')
  onChangeCustomerId() {
    this.loadCustomer()
  }

  onChangeCustomer(val: Customer) {
    customerService.editCustomer(val.customerId, val).then((updatedCustomer: Customer) => {
      this.customer = updatedCustomer
    })
  }

  loadCustomer() {
    customerService.customer(this.customerId).then((response) => {
      this.customer = response
    })
  }

  onClickMenuItem() {
    this.showDeleteConfirmation = true
  }

  onClickConfirmationDeleteDiscard() {
    this.showDeleteConfirmation = false
  }

  onClickConfirmationDeleteSave() {
    // this.showDeleteConfirmation = false
    // customerService.deleteCustomer(this.customerId).then(() => {
    //   this.$router.push({name: 'customer-overview', params: {
    //   [routingParameters.DISABLE_ROUTE_TO_LAST_CHILD]: 'true'}
    //   })
    // })
  }

}
