/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ordertype_repeating': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M20.364 11h-1.17V5.988H5.17v13.58H12v1.131H4.584A.575.575 0 014 20.133V5.423c0-.123.04-.236.108-.328l-.004-.005.016-.012a.583.583 0 01.251-.183l2.457-1.79A.545.545 0 017.149 3h10.12c.12 0 .237.04.333.113l2.274 1.751a.58.58 0 01.444.342l.006.005-.003.003a.55.55 0 01.04.208V11zm-2.286-6.143l-.995-.766H7.327l-1.05.766h11.801zM7.273 7.762h4.909c.301 0 .545.244.545.545v2.723a.545.545 0 01-.545.545h-4.91a.545.545 0 01-.545-.545V8.307c0-.3.244-.545.546-.545zm.436.982v1.85h4.036v-1.85H7.71zm7.893 4.859a4.521 4.521 0 013.21-1.336 4.54 4.54 0 014.54 4.546 4.54 4.54 0 01-4.54 4.545 4.536 4.536 0 01-4.392-3.409h1.182a3.404 3.404 0 003.21 2.273c1.881 0 3.41-1.528 3.41-3.41 0-1.88-1.529-3.408-3.41-3.408a3.36 3.36 0 00-2.397 1.011l1.83 1.83h-3.978v-3.978l1.335 1.336z"/>'
  }
})
