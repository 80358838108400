
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import TextButton from '@/components/buttons/TextButton.vue'
import SelectInput from '@/components/forms/SelectInput.vue'
import ChipValue from '@/components/forms/ChipValue.vue'
import VNode from '@/components/forms/VNode.vue'

@Component({
  components: {
    VNode,
    ChipValue,
    SelectInput,
    TextButton
  }
})
export default class MultiSelectChipInput extends Vue {

  $refs!: {
    selectInput: SelectInput,
    addButton: TextButton
  }

  @Prop({type: Array, default: () => []}) value!: any[]

  @Prop({type: Array, default: () => []}) items!: any[]

  @Prop({type: String, default: ''}) placeholder!: string

  addingItem = false

  get showAdd(): boolean {
    return !this.addingItem && this.itemsToAdd.length > 0
  }

  get showAddItem(): boolean {
    return this.addingItem && this.itemsToAdd.length > 0
  }

  get itemsToAdd() {
    return this.items.filter((item) => !this.value.includes(item))
  }

  onClickAddButton() {
    this.addItem()
  }

  onChangeAddValue(item: any) {
    const newValue = Object.assign([], this.value)
    newValue.push(item)
    this.$emit('input', newValue)
    this.endAddItem()
  }

  onCancelAddValue() {
    this.endAddItem()
  }

  onClickRemoveItem(item: any) {
    const newValue = Object.assign([], this.value)
    newValue.splice(newValue.findIndex((i) => i === item), 1)
    this.$emit('input', newValue)
  }

  endAddItem() {
    this.addingItem = false
    this.$nextTick(() => {
      if (this.$refs.addButton) {
        this.$refs.addButton.focus()
      }
    })
  }

  renderItem(item: any) {
    return this.$scopedSlots.default!(
      {
        item: item,
        state: {selected: false, highlighted: false}
      }
    )
  }

  private addItem() {
    this.addingItem = true
    this.$nextTick(() => {
      this.$refs.selectInput.focus()
    })
  }
}
