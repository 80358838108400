

import Component from 'vue-class-component'
import PageTitle from '@/components/text/PageTitle.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import { State } from 'vuex-class'
import PageHead from '@/components/layout/PageHead.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import { DeliveriesState } from '@/application/deliveries/store/types'
import { Transport, TransportStatus } from '@/application/deliveries/models/transport/types'
import { pickupDone } from '@/application/deliveries/models/transport'
import TransportCardSection from '@/application/deliveries/ui/templates/TransportCardSection.vue'
import { Mixins } from 'vue-property-decorator'
import {
  default as TransportDraggableTargetMixin,
  TransportDraggableTarget
} from '@/application/deliveries/models/transport/TransportDraggableTargetMixin'
import TransferTransport from '@/application/deliveries/ui/types/TransferTransport'
import { disposeTransports } from '@/application/deliveries/services/deliveries-service'
import TransportDropZone from '@/application/deliveries/ui/components/transport/TransportDropZone.vue'

@Component({
  components: {
    VerticalLayout,
    PageDetail,
    ScrollableSection,
    PageHead,
    DetailSection,
    PageTitle,
    TransportCardSection,
    TransportDropZone
  }
})
export default class DeliveriesCourier extends Mixins(TransportDraggableTargetMixin)
  implements TransportDraggableTarget {

  @State('deliveries') state!: DeliveriesState

  get courierId(): string | null {
    return this.$route.params.courierId
  }

  get courierName(): string {
    const courier = this.state.couriers.find((c) => c.assigneeId === this.courierId)
    return (courier) ? `${courier.additionalName} ${courier.name}` : ''
  }

  get transportsInProgress(): Transport[] {
    return this.state.courierTransports.filter((transport) => transport.status === TransportStatus.IN_PROGRESS)
  }

  get transportsToDo(): Transport[] {
    return this.state.courierTransports.filter((transport) => transport.status === TransportStatus.TODO)
  }

  get transportsUpcoming(): Transport[] {
    return this.state.courierTransports.filter((transport) => transport.status === TransportStatus.UPCOMING)
  }

  transportInProgress(transport: Transport) {
    return pickupDone(transport)
  }


  onDroppedTransport(transfer: TransferTransport) {
    disposeTransports(
      transfer.orderNumber,
      {
        assigneeId: this.courierId!,
        transportIds: [transfer.transportId]
      }
    )
  }

  redirectAfterDrop(): boolean {
    return false
  }

}
