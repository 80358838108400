/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camera': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M9 3h6l1.83 2H20c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V7c0-1.1.9-2 2-2h3.17L9 3zm3 15c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm0-1.8a3.2 3.2 0 110-6.4 3.2 3.2 0 010 6.4z"/>'
  }
})
