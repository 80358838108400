/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ads': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.927 6.855a1 1 0 00-1 1v10.518l-.446.297-1.037-4.15a1 1 0 10-1.94.486l1.143 4.575a1.69 1.69 0 002.578.997l.95-.633a1.69 1.69 0 00.752-1.407V7.855a1 1 0 00-1-1z" _fill="#132C45"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M20.312 3.056a1.69 1.69 0 00-1.801.239 15.635 15.635 0 01-5.93 3.121c-.816.222-1.495.332-1.963.387a8.785 8.785 0 01-.663.052H6.473a4.454 4.454 0 100 8.908H9.955l.13.006c.118.007.299.02.533.047a13.41 13.41 0 011.963.386c1.632.445 3.8 1.336 5.93 3.122a1.69 1.69 0 002.779-1.293V4.588a1.693 1.693 0 00-.978-1.532zm-1.022 2.18a17.596 17.596 0 01-6.183 3.11c-.925.252-1.704.379-2.259.443a10.783 10.783 0 01-.894.066h-.025s-.002 0-.002-.993v.993H6.473a2.454 2.454 0 000 4.908h3.454v0H9.954a4.301 4.301 0 01.238.01c.157.008.378.024.656.056.555.064 1.334.192 2.259.444a17.595 17.595 0 016.183 3.109V5.237zm.508 12.557l-.001-.001z" _fill="#132C45"/>'
  }
})
