/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_11': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21 19.182V24l-3.075-3H4.636A1.636 1.636 0 013 19.364V4.636C3 3.733 3.733 3 4.636 3h14.728C20.267 3 21 3.733 21 4.636v14.546zM5 5v14h14V5H5zm3.5 5.833A1.17 1.17 0 019.667 12 1.17 1.17 0 018.5 13.167 1.17 1.17 0 017.333 12 1.17 1.17 0 018.5 10.833zm7 0A1.17 1.17 0 0116.667 12a1.17 1.17 0 01-1.167 1.167A1.17 1.17 0 0114.333 12a1.17 1.17 0 011.167-1.167zm-3.5 0A1.17 1.17 0 0113.167 12 1.17 1.17 0 0112 13.167 1.17 1.17 0 0110.833 12 1.17 1.17 0 0112 10.833z" _fill="#132C45"/>'
  }
})
