/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_2_not_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)" fill-rule="evenodd" clip-rule="evenodd"><path pid="0" d="M23.954 12.034c.197-.608-.285-1.275-1.377-2.011-.92-.62-2.2-1.239-3.607-1.743-1.404-.504-2.779-.836-3.87-.937-1.292-.118-2.062.1-2.259.708-.148.458.097.953.69 1.48l-1.003 6.142-.004.026-.002.055c.006.435.232.92.767 1.4.148-.458.35-.892.598-1.294a.854.854 0 01-.112-.288l.839-5.074c.828.542 1.952 1.079 3.183 1.52 1.23.442 2.433.739 3.406.842l-.236.47c.37.128.722.291 1.054.485l.528-1.049c.776-.037 1.257-.274 1.405-.732zm-2.243-1.315c.656.441.93.787.913.839-.015.048-.433.146-1.206.074-.91-.083-2.075-.366-3.276-.796-1.2-.43-2.287-.954-3.057-1.473-.654-.44-.93-.787-.913-.838.005-.016.252-.1.713-.1.151 0 .314.008.492.025.911.084 2.076.367 3.277.797 1.2.43 2.287.953 3.057 1.472zM1.548 5.06C.405 5.703-.126 6.329.025 6.949c.115.469.577.743 1.347.844l2.351 5.718.03.06c.271.42.896.76 2.017.76.577 0 1.253-.098 2.035-.307 2.36-.635 3.27-1.59 3.345-2.426l.002-.028-.548-6.26c.63-.478.91-.951.796-1.42-.15-.62-.903-.902-2.199-.89-1.096.01-2.49.23-3.927.617-1.442.388-2.764.9-3.726 1.442zm7.384-.992c.742 0 1.132.13 1.144.179.07.297-1.76 1.348-4.164 1.995-2.403.646-4.489.649-4.56.353-.014-.053.288-.378.98-.768.811-.458 1.944-.895 3.183-1.229 1.238-.333 2.43-.52 3.352-.53h.065zm-1.458 8.77c-1.675.45-2.537.274-2.844-.07l-1.952-4.77c.987-.022 2.217-.221 3.485-.563 1.271-.342 2.44-.788 3.313-1.264l.46 5.225c-.02.035-.1.181-.265.354-.389.407-1.092.79-2.197 1.088z" _fill="#132C45"/><path pid="1" d="M19 14.417a4.583 4.583 0 100 9.167 4.583 4.583 0 000-9.167zm.793 2.998a.281.281 0 01.396 0l.396.396a.281.281 0 010 .396l-.793.793.793.793a.281.281 0 010 .396l-.396.396a.281.281 0 01-.396 0L19 19.792l-.793.793a.281.281 0 01-.396 0l-.396-.396a.281.281 0 010-.396l.793-.793-.793-.793a.281.281 0 010-.396l.396-.396a.281.281 0 01.396 0l.793.793.793-.793z" _fill="#F24949"/></g><defs><clipPath id="clip0"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
