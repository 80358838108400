
import Vue from 'vue'
import Component from 'vue-class-component'
import Card from '@/components/layout/Card.vue'
import orderSettingsService from '@/application/settings/OrderSettingsService'
import { OrderSettings } from '@/application/settings/models/types'
import Icon from '@/components/media/Icon.vue'
import { ORDER_SETTINGS_CHANGED } from '@/application/settings/events'
import { eventConsumer } from '@/infrastructure/events/event-consumer'
import { routerNames } from '@/application/router/router-definitions'
import { formatCurrency } from '@/utils/formatter'

@Component({
  components: {
    Icon,
    Card
  },
  mixins: [eventConsumer([
    {
      key: ORDER_SETTINGS_CHANGED,
      handler: 'loadOrderSettings'
    }
  ])]
})
export default class OrderSettingsOverview extends Vue {

  orderSettings: OrderSettings | null = null

  get hldCardStyleClass(): any {
    return {
      selected: this.$route.name === routerNames.SETTINGS_ORDER_HLD
    }
  }

  get hldPrice(): string {
    return formatCurrency(this.orderSettings!.orderTypeHLD.pricePerDelivery)
  }

  mounted() {
    this.loadOrderSettings()
  }

  onSelectHLD() {
    this.$router.push({name: routerNames.SETTINGS_ORDER_HLD}).catch((err) => {})
  }


  loadOrderSettings() {
    orderSettingsService.orderSettingsOverview().then((result) => {
      this.orderSettings = result
    })
  }
}
