/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'signature': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M11 14.292V17h2.708l7.987-7.987-2.708-2.708L11 14.292zm12.789-7.373l-1.322 1.322-2.708-2.708 1.322-1.322a.72.72 0 011.018 0l1.69 1.69a.72.72 0 010 1.018zM1.06 12.11L0 11.065c.133-.165.315-.349.533-.57.151-.152.848-.764 1.648-.764.527 0 1.52.422 1.52 1.75 0 .832-.315 1.309-.787 2.006-.273.404-.909 1.487-1.121 2.154-.218.667-.055 1.175.218 1.175.26 0 .52-.33.672-.514a52.908 52.908 0 001.387-1.664c.46-.57 1.63-1.738 2.993-1.738 1.78 0 2.35 1.56 2.44 2.57H11v1.53H9.51c-.242 2.917-1.854 3.731-2.84 3.731-1.073 0-1.945-.85-1.945-1.89s.969-2.894 3.258-3.286c-.066-.477-.078-1.095-1.066-1.095-.757 0-1.738 1.193-2.471 2.105-.672.838-1.205 1.505-1.853 1.694a1.8 1.8 0 01-1.6-.281c-.52-.392-.811-1.04-.811-1.823 0-1.48 1.58-3.604 1.732-3.86.182-.3.485-.808.182-.93-.188-.08-.612.311-1.036.746zm5.627 7.134c.424 0 1.109-.483 1.29-2.129-1.296.343-1.738 1.322-1.738 1.689 0 .281.26.44.448.44z"/>'
  }
})
