

import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'

@Component
export default class StatusBadge extends Vue {
  @Prop({
    type: String,
    default: 'grey_light',
    validator: function (value: any) {
      return [
        'grey_light',
        'accent'
      ].indexOf(value) !== -1
    }
  }) private appearance!: string
}

