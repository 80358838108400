
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class TableCell extends Vue {

  @Prop({
    type: Boolean,
    default: true
  })
    padding!: boolean

  get styleClass() {
    return {
      'table-cell-with-padding': this.padding
    }
  }

}
