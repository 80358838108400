
import { uuid } from '@/utils/uuid'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class SwitcherInput extends Vue {

  $refs!: {
    checkbox: HTMLInputElement
  }

  @Prop({
    type: String,
    default: () => uuid()
  }) private id!: string

  @Prop({
    type: Boolean,
    default: false
  })
    value!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
    disabled!: boolean

  @Prop({
    type: String,
    default: 'normal',
    validator: (value: any) => {
      return [
        'small',
        'normal'
      ].indexOf(value) !== -1
    }
  })
  private size!: string

  get styleClass() {
    return {
      disabled: this.disabled
    }
  }

  get controlStyleClass() {
    return {
      small: this.size === 'small'
    }
  }

  onInput() {
    this.$emit('input', this.$refs.checkbox.checked)
  }
}
