/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location_unknown': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M18.994 8.706c.004.097.006.195.006.294 0 5.25-7 13-7 13s-1.306-1.446-2.79-3.511l-4.642 4.643-1.507-1.508L22.373 2.312 23.88 3.82l-4.886 4.886zM17.089 4.19l-3.187 3.187a2.5 2.5 0 10-3.524 3.524l-3.646 3.646C5.737 12.67 5 10.703 5 9c0-3.87 3.13-7 7-7a6.98 6.98 0 015.09 2.19z"/>'
  }
})
