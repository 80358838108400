
import DataTable from '@/components/table/DataTable.vue'
import TableCell from '@/components/table/TableCell.vue'
import ContainedButton from '@/components/buttons/ContainedButton.vue'
import BadgeContainer from '@/components/badge/BadgeContainer.vue'
import { eventConsumer } from '@/infrastructure/events/event-consumer'
import customerService from '@/application/customers/CustomersService'
import { RETAILERS_CHANGED } from '@/application/customers/events'
import Vue from 'vue'
import Component from 'vue-class-component'
import DetailSection from '@/components/layout/DetailSection.vue'
import { RetailerSummary } from '@/application/customers/models/RetailerSummary'
import Icon from '@/components/media/Icon.vue'

@Component({
  components: {
    DetailSection,
    BadgeContainer,
    ContainedButton,
    DataTable,
    Icon,
    TableCell
  },
  mixins: [eventConsumer([
    {
      key: RETAILERS_CHANGED,
      handler: 'loadData'
    }
  ])]
})
export default class RetailersOverview extends Vue {

  retailers: RetailerSummary[] = []

  get buttonColor() {
    return this.$store.getters['customers/hasRetailerDraft'] ? 'outlined' : 'primary'
  }

  get badgeValue() {
    return this.$store.getters['customers/hasRetailerDraft'] ? '1' : null
  }

  get selectedRetailerId() {
    return this.$route.params.id
  }

  set selectedRetailerId(retailerId: string) {
    if (!retailerId) {
      return
    }
    this.$router.push({name: 'retailer-edit', params: {id: retailerId}}).catch((err) => {})
  }

  mounted() {
    this.loadData()
  }

  loadData() {
    customerService.retailers().then((retailers) => {
      this.retailers = retailers
    })
  }

  onCreateRetailerClicked() {
    this.$router.push({name: 'retailer-add'}).catch((err) => {})
  }

  individualPriceingIconClass(retailer: RetailerSummary) {
    return retailer.individualPricing ? 'icon-enabled' : 'icon-disabled'
  }


  individualPriceingIconName(retailer: RetailerSummary) {
    return retailer.individualPricing ? 'checkmark_circle_solid' : 'cross_circle_solid'
  }
}
