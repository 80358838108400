/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_6_not_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)" fill-rule="evenodd" clip-rule="evenodd"><path pid="0" d="M11 15.654v-1.196l7.987-8.115 2.708 2.751-3.98 4.044a6.01 6.01 0 00-4.443 4.072H11v-.002H9.51C9.268 20.173 7.656 21 6.67 21c-1.073 0-1.945-.864-1.945-1.92 0-1.058.969-2.941 3.258-3.34a10.009 10.009 0 01-.012-.095c-.06-.468-.13-1.017-1.054-1.017-.757 0-1.738 1.212-2.471 2.139l-.008.01c-.668.846-1.2 1.52-1.845 1.711a1.777 1.777 0 01-1.6-.285c-.52-.398-.811-1.057-.811-1.853 0-1.31 1.199-3.115 1.608-3.732.061-.092.105-.157.124-.19.182-.305.485-.821.182-.946-.188-.08-.612.317-1.036.759L0 11.178c.133-.168.315-.355.533-.578.151-.156.848-.778 1.648-.778.527 0 1.52.43 1.52 1.778 0 .846-.315 1.33-.787 2.04-.273.41-.909 1.51-1.121 2.188-.218.677-.055 1.193.218 1.193.25 0 .5-.308.653-.498l.02-.024c.139-.149 1.035-1.237 1.386-1.69.46-.579 1.63-1.766 2.993-1.766 1.78 0 2.35 1.585 2.44 2.61H11zm11.467-7.345l1.322-1.343a.74.74 0 000-1.034l-1.69-1.717a.711.711 0 00-1.018 0l-1.322 1.342 2.708 2.752zm-14.49 9.017c-.181 1.672-.866 2.163-1.29 2.163-.188 0-.448-.161-.448-.447 0-.373.442-1.368 1.738-1.716z" _fill="#132C45"/><path pid="1" d="M19 14.417a4.583 4.583 0 100 9.167 4.583 4.583 0 000-9.167zm.793 2.998a.281.281 0 01.396 0l.396.396a.281.281 0 010 .396l-.793.793.793.793a.281.281 0 010 .396l-.396.396a.281.281 0 01-.396 0L19 19.792l-.793.793a.281.281 0 01-.396 0l-.396-.396a.281.281 0 010-.396l.793-.793-.793-.793a.281.281 0 010-.396l.396-.396a.281.281 0 01.396 0l.793.793.793-.793z" _fill="#F24949"/></g><defs><clipPath id="clip0"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
