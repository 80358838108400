/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'person_assign': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M8 5.9a2.1 2.1 0 110 4.2 2.1 2.1 0 010-4.2zm0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H1.9V17c0-.64 3.13-2.1 6.1-2.1zM8 4C5.79 4 4 5.79 4 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c2.67 0 8 1.34 8 4v3H0v-3c0-2.66 5.33-4 8-4zm10.837-5.327V3h1.738v4.674l1.994-1.99 1.227 1.226-4.09 4.09-4.089-4.089 1.225-1.233 1.995 1.995z"/>'
  }
})
