/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'deliveryobject_missing': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.4 2A1.6 1.6 0 0118 3.6V10h2a1 1 0 110 2h-2v6.4a1.6 1.6 0 01-1.6 1.6H1.6A1.6 1.6 0 010 18.4V3.6A1.6 1.6 0 011.6 2h14.8zm5.6 9a1 1 0 112 0 1 1 0 01-2 0zm-12 .117H8V7h2v4.117zm0 3.117H8v-2.059h2v2.059z" _fill="#F2C12C"/>'
  }
})
