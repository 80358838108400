/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M16.313 8.322L13.5 5.472l-7.5 7.6v2.85h2.813l7.5-7.6zm2.22-2.25l-1.47 1.49-2.813-2.85 1.47-1.49a.74.74 0 011.057 0l1.755 1.779a.764.764 0 010 1.071zM3 18.202h18v3.04H3v-3.04z"/>'
  }
})
