import axios from '@/infrastructure/vld-webservice'
import { DeliveriesPerRetailer } from '@/application/analytics/models/DeliveriesPerRetailer'
import { toIsoDate } from '@/utils/date-util'

export const getDeliveriesPerRetailer = (start: Date, end: Date): Promise<DeliveriesPerRetailer[]> => {
  return axios
    .get('/cockpit/analytics/retailers/deliveries', {
      params: {
        start: toIsoDate(start),
        end: toIsoDate(end)
      }
    }
    )
    .then((response) => response.data)
}
