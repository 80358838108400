

import { Prop, Vue} from 'vue-property-decorator'
import Component from 'vue-class-component'
import DetailSection from '@/components/layout/DetailSection.vue'
import DataTable from '@/components/table/DataTable.vue'
import TableCell from '@/components/table/TableCell.vue'
import TransportProgressBar from '@/application/deliveries/ui/components/transport/TransportProgressBar.vue'
import DeliveryStatusIndicator from '@/application/deliveries/ui/components/transport/DeliveryStatusIndicator.vue'
import { DeliveryOverviewSummary, TransportStatus } from '@/application/deliveries/models/DeliveryOverviewSummary'
import { routerNames } from '@/application/router/router-definitions'
import { randomNumber } from '@/utils/randomizer'
import { formatOrderNumber } from '@/application/deliveries/utils/formatter'


@Component({
  components: {
    DeliveryStatusIndicator,
    DetailSection,
    DataTable,
    TableCell,
    TransportProgressBar
  }
})
export default class DeliveriesOverviewList extends Vue {

  imgIndex: number = randomNumber(1, 2)

  @Prop({
    type: Array,
    required: true,
    default: []
  })
    deliveries!: DeliveryOverviewSummary[]

  get selectedDeliveryId() {
    return this.$route.params.orderNumber
  }

  set selectedDeliveryId(orderNumber: any) {
    if (!orderNumber) {
      return
    }
    this.$router.push({
      name: routerNames.DELIVERIES_OVERVIEW_DELIVERY,
      params: {orderNumber: orderNumber}
    })
  }

  get hasDeliveriesForDate(): boolean {
    return this.deliveries.length > 0
  }

  get imagePath(): string {
    return `/images/deliveries_overview_emptystate_${this.imgIndex}.svg`
  }

  onChangeDeliveryOverview(deliverySummaries: DeliveryOverviewSummary[]) {
    this.deliveries = deliverySummaries
  }

  tooManyTransports(delivery: DeliveryOverviewSummary) {
    return delivery.transports.length > 8
  }

  transportsCount(delivery: DeliveryOverviewSummary) {
    return delivery.transports.length
  }

  transportsDoneCount(delivery: DeliveryOverviewSummary) {
    return delivery.transports.filter((transport) => transport.status === TransportStatus.FULFILLED).length
  }

  formattedOrderNumber(orderNumber: string) {
    return formatOrderNumber(orderNumber)
  }

}

