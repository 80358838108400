
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import ItemList from '@/components/menus/ItemList.vue'
import MapMarkerView from '@/components/maps/MapMarkerView.vue'
import { AddressInput } from '@/components/forms/address/types/AddressInput'
import { MapCoordinate } from '@/components/maps/types/MapCoordinate'

@Component({
  components: {
    MapMarkerView,
    ItemList
  }
})
export default class AddressList extends Vue {

  $refs!: {
    addressList: ItemList
  }

  @Prop({type: Array, default: () => []}) addresses!: AddressInput[]

  marker: MapCoordinate | null = null

  onSelectAddress(address: AddressInput) {
    this.$emit('input', address)
  }

  onHighlightAddress(address: AddressInput) {
    if (address) {
      this.marker = {latitude: address.coordinate.latitude, longitude: address.coordinate.longitude}
    } else {
      this.marker = null
    }
    this.$emit('highlight', address)
  }

  highlightPrevious() {
    this.$refs.addressList.highlightPreviousItem(true)
  }

  highlightNext() {
    this.$refs.addressList.highlightNextItem(true)
  }

  highlightFirst() {
    this.$refs.addressList.highlightFirstItem()
  }
}
