/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ordertype_individual': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M18.078 4.857l-.995-.766H7.327l-1.05.766h11.801zm1.798.007a.58.58 0 01.444.342l.006.005-.003.003a.55.55 0 01.04.208v14.711a.575.575 0 01-.584.566H4.584A.575.575 0 014 20.133V5.423c0-.123.04-.236.108-.328l-.004-.005.016-.012a.583.583 0 01.251-.183l2.457-1.79A.545.545 0 017.149 3h10.12c.12 0 .237.04.333.113l2.274 1.751zm-.681 1.124v13.58H5.169V5.987H19.195zM7.273 7.762h4.909c.301 0 .545.244.545.545v2.723a.545.545 0 01-.545.545h-4.91a.545.545 0 01-.545-.545V8.307c0-.3.244-.545.546-.545zm.436.982v1.85h4.036v-1.85H7.71z"/>'
  }
})
