/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_18_not_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.9 15.6v1.8h-1.8v-1.8h1.8zM3 12c0-4.968 4.032-9 9-9s9 4.032 9 9c0 .444-.032.882-.095 1.309A5.984 5.984 0 0019.131 13c.045-.327.069-.661.069-1.001 0-3.969-3.231-7.2-7.2-7.2-3.969 0-7.2 3.231-7.2 7.2 0 3.969 3.231 7.2 7.2 7.2.34 0 .674-.024 1.001-.07.014.62.12 1.216.308 1.775A9.07 9.07 0 0112 21c-4.968 0-9-4.032-9-9zm5.4-1.8c0-1.989 1.611-3.6 3.6-3.6s3.6 1.611 3.6 3.6c0 1.155-.711 1.776-1.403 2.381-.657.574-1.297 1.133-1.297 2.119h-1.8c0-1.64.848-2.29 1.593-2.86.585-.449 1.107-.849 1.107-1.64 0-.99-.81-1.8-1.8-1.8s-1.8.81-1.8 1.8H8.4z" _fill="#132C45"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19 14.417a4.583 4.583 0 100 9.167 4.583 4.583 0 000-9.167zm.793 2.998a.281.281 0 01.396 0l.396.396a.281.281 0 010 .396l-.793.793.793.793a.281.281 0 010 .396l-.396.396a.281.281 0 01-.396 0L19 19.792l-.793.793a.281.281 0 01-.396 0l-.396-.396a.281.281 0 010-.396l.793-.793-.793-.793a.281.281 0 010-.396l.396-.396a.281.281 0 01.396 0l.793.793.793-.793z" _fill="#F24949"/>'
  }
})
