
import { Vue} from 'vue-property-decorator'
import Component from 'vue-class-component'
import { routerParameters } from '@/application/router/router-definitions'
import { Location } from 'vue-router'
import roleService from '../RolesService'
import PageTitle from '@/components/text/PageTitle.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import PageHead from '@/components/layout/PageHead.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import BottomBar from '@/components/layout/BottomBar.vue'
import SaveDiscardButtons from '@/components/buttons/SaveDiscardButtons.vue'
import RoleForm from '@/application/users/templates/RoleForm.vue'


@Component({
  components: {
    VerticalLayout,
    ScrollableSection,
    PageDetail,
    PageHead,
    DetailSection,
    PageTitle,
    BottomBar,
    SaveDiscardButtons,
    RoleForm
  }
})
export default class RolesAdd extends Vue {
  role: any = {
    name: '',
    courierIds: [],
    monitoringZones: [],
    monitoringPickups: false,
    ...this.$store.state.users.roleDraft
  }
  couriers: any[] = []
  zones: any[] = []

  couriersLoaded = false
  zonesLoaded = false

  get disabledAddButton() {
    return this.role.name === ''
  }

  get loaded(): boolean {
    return this.zonesLoaded && this.couriersLoaded
  }

  mounted() {
    roleService.zones().then((zones) => {
      this.zones = zones
      this.zonesLoaded = true
    })
    roleService.couriers().then((couriers) => {
      this.couriers = couriers
      this.couriersLoaded = true
    })
  }

  onRoleChanged(value: any) {
    roleService.saveRoleDraft({...this.role})
    this.role = value
  }

  onClickAdd() {
    roleService.addRole({...this.role}).then((roleId) => {
      const location = {
        name: 'role-edit',
        params: {
          id: roleId
        }
      } as Location

      this.$router.push(location).catch((err) => {})
    })
  }

  onClickDiscard() {
    this.$store.commit('users/roleDraft', null)
    this.$router
      .push({name: 'role-overview', params: {[routerParameters.DISABLE_ROUTE_TO_LAST_CHILD]: 'true'}})
      .catch((err) => {})
  }


}
