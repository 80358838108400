
import VueSVGIcon from 'vue-svgicon'
import Vue from 'vue'
import '@/assets/icons/index'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {
    VueSVGIcon
  }
})
export default class Icon extends Vue {
  @Prop({type: String, required: true}) private name!: string
  @Prop({type: Boolean, required: false, default: false}) private originalFill!: boolean
}
