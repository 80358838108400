
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import PageTitle from '@/components/text/PageTitle.vue'
import Icon from '@/components/media/Icon.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import QRCode from '@/components/media/QRCode.vue'
import Divider from '@/components/layout/Divider.vue'
import ContainedButton from '@/components/buttons/ContainedButton.vue'
import InlineTextInput from '@/components/forms/InlineTextInput.vue'
import FormField from '@/components/forms/FormField.vue'
import MultiSelectChipInput from '@/components/forms/MultiSelectChipInput.vue'
import SwitcherInput from '@/components/forms/SwitcherInput.vue'
import { User } from '@/application/users/models/types'
import AddressSelectInput from '@/components/forms/address/AddressSelectInput.vue'
import InlinePhoneNumberInput from '@/components/forms/InlinePhoneNumberInput.vue'

@Component({
  components: {
    InlinePhoneNumberInput,
    SwitcherInput,
    MultiSelectChipInput,
    FormField,
    InlineTextInput,
    Divider,
    QRCode,
    DetailSection,
    PageTitle,
    Icon,
    ContainedButton,
    AddressSelectInput
  }
})
export default class UserForm extends Vue {

  @Prop({
    type: Object,
    required: true
  })
    user!: User

  @Prop({
    type: Array,
    required: true
  })
    roles!: any[]

  get qrCode() {
    return {
      loginToken: this.user.courierAppToken,
      name: `${this.user.firstName} ${this.user.name}`
    }
  }

  get hasCourierAppAccess() {
    return this.user.courierAppAccess
  }

  get hasCourierAppToken() {
    return this.hasCourierAppAccess && this.user.courierAppToken !== null
  }

  get selectedRoles() {
    return this.user.roleIds.map(
      (roleId: string) => this.roles.find((role) => role.roleId === roleId)
    )
  }

  get isInEditMode(): boolean {
    return this.user.id !== undefined
  }

  get nameInputStyle(): string | null {
    return (this.isInEditMode) ? 'font-large' : null
  }

  onChangeUser(value: any) {
    const updatedUser = Object.assign({}, this.user, value)
    this.$emit('input', updatedUser)
  }

  onChangeRoles(roles: any[]) {
    const roleIds = roles.map((role) => role.roleId)
    this.onChangeUser({roleIds: roleIds})
  }

  onClickCockpitAccess(value: boolean) {
    this.user.cockpitAccess = value
    if (!value) {
      this.onChangeUser({cockpitAccess: false})
    }
    if (value && this.user.email !== '') {
      this.onChangeUser({cockpitAccess: true})
    }
  }

  onChangeEmail(value: string) {
    if (value !== '' || !this.isInEditMode) {
      this.onChangeUser({cockpitAccess: true, email: value})
    }
  }

  onChangePassword(value: string) {
    if (value !== '' || !this.isInEditMode) {
      this.onChangeUser({cockpitAccess: true, password: value})
    }
  }

  onClickGenerateToken() {
    this.$emit('click-generate-token')
  }

}
