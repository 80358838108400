/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'personal-assignment': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#29CC96" d="M3.429 0H24L0 24V3.429A3.429 3.429 0 013.429 0zM7 4a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm0 3.375c-1.001 0-3 .503-3 1.5V10h6V8.875c0-.997-1.999-1.5-3-1.5z" fill-rule="evenodd"/>'
  }
})
