

import Vue from 'vue'
import Component from 'vue-class-component'
import UserOverview from '@/application/users/templates/UserOverview.vue'
import RolesOverview from '@/application/users/templates/RolesOverview.vue'
import PageLayout from '@/components/layout/PageLayout.vue'
import TabNavigation from '@/components/navigation/TabNavigation.vue'
import TabNavigationContent from '@/components/navigation/TabNavigationContent.vue'
import PageHead from '@/components/layout/PageHead.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import { routerNames } from '@/application/router/router-definitions'

@Component({
  components: {
    ScrollableSection,
    PageDetail,
    PageHead,
    UserOverview,
    PageLayout,
    TabNavigation,
    TabNavigationContent,
    RolesOverview
  }
})
export default class Settings extends Vue {

  get showDetail() {
    return true
  }

  get settingsCompanyRoute() {
    const path = this.$router.resolve({
      name: routerNames.SETTINGS_COMPANY
    })
    return path.href
  }

  get settingsDeliveryRoute() {
    const path = this.$router.resolve({
      name: routerNames.SETTINGS_DELIVERY
    })
    return path.href
  }

  get settingsOrderRoute() {
    const path = this.$router.resolve({
      name: routerNames.SETTINGS_ORDER
    })
    return path.href
  }

}
