/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'warning': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" d="M3 20h18L12 4 3 20zm9.818-2.526h-1.636v-1.685h1.636v1.685zm0-3.369h-1.636v-3.368h1.636v3.368z"/>'
  }
})
