/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_15_not_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18 4.671v4.994a5.903 5.903 0 01-1.422 3.844 6.018 6.018 0 00-2.136 1.59c-.502.22-1.04.375-1.604.452v3.816h.173c.034.57.148 1.117.33 1.633H8.522c-.473 0-.847-.361-.847-.817 0-.456.375-.816.847-.816h2.621v-3.835a5.938 5.938 0 01-3.251-1.5A5.69 5.69 0 016 9.817V4.67C6 3.759 6.769 3 7.734 3h8.532C17.212 3 18 3.741 18 4.671zm-10.285-.02l-.04 5.184h.001c0 1.159.493 2.222 1.38 3.001a4.373 4.373 0 003.23 1.12c2.246-.133 4.02-2.031 4.02-4.31V4.672l-3.804-.02-.532 2.165h.809c.255 0 .511.114.67.304.157.208.216.455.157.702l-.71 2.943a.837.837 0 01-.827.627c-.06 0-.138 0-.197-.02a.835.835 0 01-.63-.986l.472-1.919h-.827a.885.885 0 01-.67-.304c-.158-.208-.217-.455-.158-.702l.67-2.791-3.014-.02z" _fill="#132C45"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19 14.417a4.583 4.583 0 100 9.167 4.583 4.583 0 000-9.167zm.793 2.998a.281.281 0 01.396 0l.396.396a.281.281 0 010 .396l-.793.793.793.793a.281.281 0 010 .396l-.396.396a.281.281 0 01-.396 0L19 19.792l-.793.793a.281.281 0 01-.396 0l-.396-.396a.281.281 0 010-.396l.793-.793-.793-.793a.281.281 0 010-.396l.396-.396a.281.281 0 01.396 0l.793.793.793-.793z" _fill="#F24949"/>'
  }
})
