

import { Assignee } from '../../../models/Assignee'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Icon from '@/components/media/Icon.vue'
import StatusBadge from '@/components/badge/StatusBadge.vue'

@Component({
  components: {StatusBadge, Icon}
})
export default class RoleView extends Vue {
  @Prop({type: Object, required: true}) private role!: Assignee
}
