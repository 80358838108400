/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" d="M11 4h2v12.17l5.59-5.58L20 12l-8 8-8-8 1.41-1.42L11 16.17z"/>'
  }
})
