/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'due_date': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.556 2v1.8H8.444V2H6.667v1.8h-.89c-.986 0-1.768.81-1.768 1.8L4 18.2c0 .99.791 1.8 1.778 1.8h12.444C19.2 20 20 19.19 20 18.2V5.6c0-.99-.8-1.8-1.778-1.8h-.889V2h-1.777zm2.666 16.2H5.778V8.3h12.444v9.9z" _fill="#132C45"/>'
  }
})
