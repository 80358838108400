import axios from 'axios'
import { parseAddress } from '@/components/forms/address/address-helper'
import { AddressInput } from '@/components/forms/address/types/AddressInput'

const axiosInstance = axios.create({
  baseURL: 'https://api3.geo.admin.ch/rest/services/api/',
  timeout: 2000
})

export const findAddress = (searchText: string): Promise<AddressInput[]> => {
  if (searchText.length === 0) {
    return Promise.resolve([])
  }
  return axiosInstance.get('/SearchServer', {
    params: {
      sr: 2056,
      searchText: searchText,
      lang: 'de',
      type: 'locations',
      origins: 'address'
    }
  }).then((response) => {
    return response.data.results.map((entry: any) => {
      return parseAddress(entry.attrs)
    })
  })
}
