/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'biker': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" d="M15.5 5.902c1.1 0 2-.878 2-1.95 0-1.074-.9-1.952-2-1.952s-2 .878-2 1.951.9 1.951 2 1.951zM5 12.244c-2.8 0-5 2.146-5 4.878S2.2 22 5 22s5-2.146 5-4.878-2.2-4.878-5-4.878zm0 8.293c-1.9 0-3.5-1.561-3.5-3.415s1.6-3.415 3.5-3.415 3.5 1.561 3.5 3.415-1.6 3.415-3.5 3.415zm5.8-9.757l2.4-2.341.8.78c1.3 1.269 3 2.05 5.1 2.05V9.316c-1.5 0-2.7-.585-3.6-1.463L13.6 6c-.5-.39-1-.585-1.6-.585-.6 0-1.1.195-1.4.585L7.8 8.732c-.4.39-.6.878-.6 1.366 0 .585.2 1.073.6 1.365l3.2 2.732v4.878h2v-6.049l-2.2-2.244zm8.2 1.464c-2.8 0-5 2.146-5 4.878S16.2 22 19 22s5-2.146 5-4.878-2.2-4.878-5-4.878zm0 8.293c-1.9 0-3.5-1.561-3.5-3.415s1.6-3.415 3.5-3.415 3.5 1.561 3.5 3.415-1.6 3.415-3.5 3.415z"/>'
  }
})
