/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notification': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" d="M10.55 19.896a1.73 1.73 0 01-1.021-2.212l3.214 1.18a1.71 1.71 0 01-2.192 1.032zm6.577-3.095l1.022 2.213-.293.81L5 15.102l.292-.811 2.192-1.032 1.463-4.056c.9-2.498 2.959-4.094 5.464-3.798l.199-.551a1.278 1.278 0 011.644-.774c.667.245 1.01.986.766 1.66l-.199.55c2.1 1.397 2.666 3.966 1.768 6.456l-1.462 4.056z"/>'
  }
})
