import { CalendarDay } from '@/components/forms/types/CalendarDay'
import { CalendarWeek } from '@/components/forms/types/CalendarWeek'

export class CalendarSheetBuilder {

  private days: CalendarDay[] = []

  addCalendarDay(day: CalendarDay) {
    this.days.push(day)
  }

  daysCount() {
    return this.days.length
  }

  build(): CalendarWeek[] {
    if (this.daysCount() !== 42) {
      throw new Error('There must be exactly 42 days in a CalendarSheet')
    }
    const weeks: CalendarWeek[] = []
    const linesInCalendar = 6
    for (let weekInCalendar = 0; weekInCalendar < linesInCalendar; weekInCalendar++) {
      const calendarWeek: CalendarWeek = {days: []} as CalendarWeek
      for (let dayInWeek = 0; dayInWeek < 7; dayInWeek++) {
        calendarWeek.days.push(this.days[7 * weekInCalendar + dayInWeek])
      }
      weeks.push(calendarWeek)
    }
    return weeks
  }

}
