/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'save': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M2.925 1A1.94 1.94 0 001 2.925v18.15A1.94 1.94 0 002.925 23h18.15A1.94 1.94 0 0023 21.075V6.225c0-.22-.086-.43-.24-.584l-4.4-4.4A.826.826 0 0017.774 1H2.925zm0 1.65h2.2v6.875c0 .455.37.825.825.825h11c.455 0 .825-.37.825-.825V2.994l3.575 3.575v14.506c0 .167-.108.275-.275.275H2.925c-.167 0-.275-.108-.275-.275V2.925c0-.167.108-.275.275-.275zm3.85 0h9.35V8.7h-9.35V2.65zM12 12.275a3.587 3.587 0 00-3.575 3.575A3.587 3.587 0 0012 19.425a3.587 3.587 0 003.575-3.575A3.587 3.587 0 0012 12.275zm0 1.65c1.073 0 1.925.852 1.925 1.925A1.912 1.912 0 0112 17.775a1.912 1.912 0 01-1.925-1.925c0-1.073.852-1.925 1.925-1.925z"/>'
  }
})
