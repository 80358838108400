import eventBus from './event-bus'

export function createEventEmittingPromise(operation: Promise<any>, event: string): Promise<void> {
  return new Promise((resolve, reject) => {
    return operation.then(
      () => {
        eventBus.$emit(event)
        resolve()
      },
      () => {
        reject()
      }
    )
  })
}
