import { Module } from 'vuex'
import { mutations } from '@/application/users/store/mutations'
import { getters } from '@/application/users/store/getters'
import localStorage, {authenticatedUserSummaryKey, roleDraftKey, userDraftKey} from '@/infrastructure/local-storage'
import { UsersState } from '@/application/users/store/types'


export const state: UsersState = {
  userDraft: localStorage.objectForKey(userDraftKey),
  roleDraft: localStorage.objectForKey(roleDraftKey),
  authenticatedUserSummary: localStorage.objectForKey(authenticatedUserSummaryKey)
}

export const users: Module<UsersState, any> = {
  namespaced: true,
  state,
  mutations,
  getters
}
