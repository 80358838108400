
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import TextInput from '@/components/forms/TextInput.vue'
import { AddressInput } from '@/components/forms/address/types/AddressInput'
import ItemList from '@/components/menus/ItemList.vue'
import Icon from '@/components/media/Icon.vue'
import MapMarkerView from '@/components/maps/MapMarkerView.vue'
import { findAddress } from '@/components/forms/address/address-resource'
import AddressList from '@/components/forms/address/AddressList.vue'
import { formatAddress } from '@/components/forms/address/address-helper'

@Component({
  components: {
    AddressList,
    MapMarkerView,
    Icon,
    ItemList,
    TextInput
  }
})
export default class AddressSuggestionInput extends Vue {

  $refs!: {
    textInput: TextInput
    addressSuggestions: AddressList
  }

  @Prop({type: [String, Object], default: null}) value!: string | AddressInput | null

  @Prop({type: String, default: null}) inputClass!: string | null

  suggestionsOpen = false

  addresses: AddressInput[] = []

  highlightedAddress: AddressInput | null = null

  mouseDownSuggestions = false

  get showSuggestions() {
    return this.suggestionsOpen && this.addresses.length > 0
  }

  get displayedValue(): string {
    if (this.highlightedAddress) {
      return formatAddress(this.highlightedAddress)
    }
    if (!this.value) {
      return ''
    }
    if (typeof this.value === 'string') {
      return this.value
    }
    return formatAddress(this.value)
  }

  beforeDestroy() {
    document.body.removeEventListener('mouseup', this.onMouseUpAfterMouseDownSuggestions)
  }

  onFocusTextInput() {
    this.suggestionsOpen = true
    if (this.value) {
      if (typeof this.value === 'string') {
        this.loadAddressSuggestions(this.value)
      } else {
        this.loadAddressSuggestions(`${this.value.street}, ${this.value.city}`)
      }
    }
  }

  onBlurTextInput() {
    if (!this.mouseDownSuggestions) {
      this.suggestionsOpen = false
    }
  }

  onKeyUp() {
    this.$refs.addressSuggestions.highlightPrevious()
  }

  onKeyDown() {
    this.$refs.addressSuggestions.highlightNext()
  }

  onKeyEnter() {
    if (this.highlightedAddress) {
      this.selectAddress(this.highlightedAddress)
      this.highlightedAddress = null
    } else {
      this.$refs.textInput.blur()
    }
  }

  onSelectAddress(address: AddressInput) {
    this.selectAddress(address)
  }

  onHighlightAddress(address: AddressInput | null) {
    this.highlightedAddress = address
  }

  onChangeInputValue(value: string) {
    this.$emit('input', value)
    this.highlightedAddress = null
    this.loadAddressSuggestions(value)
  }

  onMouseDownSuggestions() {
    window.addEventListener('mouseup', this.onMouseUpAfterMouseDownSuggestions)
    this.mouseDownSuggestions = true
  }

  onMouseUpAfterMouseDownSuggestions(event: MouseEvent) {
    window.removeEventListener('mouseup', this.onMouseUpAfterMouseDownSuggestions)
    const el = this.$refs.addressSuggestions.$el
    if (!(el === event.target || el.contains(event.target as Node))) {
      this.suggestionsOpen = false
    }
    this.mouseDownSuggestions = false
  }

  selectAddress(address: AddressInput) {
    this.$emit('input', address)
    this.suggestionsOpen = false
    this.$refs.textInput.blur()
  }

  loadAddressSuggestions(value: string) {
    findAddress(value).then((addresses) => {
      this.addresses = addresses
    })
  }
}
