import {
  createOrder
} from '@/application/deliveries/resources/deliveries-resource'
import store from '@/application/store'
import { commits } from '@/application/deliveries/store/mutations'
import localStorage, { shippingOrderDraftKey } from '@/infrastructure/local-storage'
import {
  DeliveryItem,
  OrderDraft,
  OrderDraftCondition,
  PlaceOrderCommand
} from '@/application/deliveries/models/order-draft/types'
import {
  DeliveryItemData,
  OrderDraftConditionData,
  OrderDraftData
} from '@/application/deliveries/services/types'
import { uuid } from '@/utils/uuid'

interface DeliveryItemMap {
  identifier: string
  deliveryItem: DeliveryItem
}

export const loadOrderDraft = (): OrderDraft | null => {
  const orderDraftData: OrderDraftData | null = localStorage.objectForKey(shippingOrderDraftKey)
  if (!orderDraftData) {
    return null
  }
  const deliveryItemMaps: DeliveryItemMap[] = []
  const pickupConditions = mapConditionFromData(orderDraftData.pickupConditions, deliveryItemMaps)
  const dropOffConditions = mapConditionFromData(orderDraftData.dropOffConditions, deliveryItemMaps)
  return {
    customerId: orderDraftData.customerId,
    price: orderDraftData.price,
    pickupConditions,
    dropOffConditions,
    shippingDate: orderDraftData.shippingDate
  }
}

const mapConditionFromData = (
  conditions: OrderDraftConditionData[],
  deliveryItemMaps: DeliveryItemMap[]
): OrderDraftCondition[] => {
  return conditions.map((conditionData) => {
    return {
      dueDate: conditionData.dueDate,
      deliveryLocation: conditionData.deliveryLocation,
      address: conditionData.address,
      deliveryItems: conditionData.deliveryItems.map((deliveryItemData) => {
        return lookupOrAddDeliveryItem(deliveryItemMaps, deliveryItemData)
      })
    }
  })
}

export const lookupOrAddDeliveryItem = (
  deliveryItemMaps: DeliveryItemMap[],
  deliveryItemData: DeliveryItemData
): DeliveryItem => {
  let deliveryItemMap = deliveryItemMaps.find((map) => map.identifier === deliveryItemData.identifier)
  if (!deliveryItemMap) {
    deliveryItemMap = {identifier: deliveryItemData.identifier, deliveryItem: {label: deliveryItemData.label}}
    deliveryItemMaps.push(deliveryItemMap)
  }
  return deliveryItemMap.deliveryItem
}

export const saveOrderDraft = (orderDraft: OrderDraft) => {
  const deliveryItemMaps: DeliveryItemMap[] = []
  const pickupConditions = mapConditionToData(orderDraft.pickupConditions, deliveryItemMaps)
  const dropOffConditions = mapConditionToData(orderDraft.dropOffConditions, deliveryItemMaps)
  const orderDraftData: OrderDraftData = {
    customerId: orderDraft.customerId,
    price: orderDraft.price,
    pickupConditions: pickupConditions,
    dropOffConditions: dropOffConditions,
    shippingDate: orderDraft.shippingDate
  }
  localStorage.setObjectForKey(shippingOrderDraftKey, orderDraftData)
  store.commit(commits.SHIPPING_ORDER_DRAFT, true)
}

const mapConditionToData = (
  conditions: OrderDraftCondition[],
  deliveryItemMaps: DeliveryItemMap[]
): OrderDraftConditionData[] => {
  return conditions.map((condition) => {
    const deliveryItems = condition.deliveryItems.map((deliveryItem) => {
      return lookupOrAddDeliveryItemData(deliveryItemMaps, deliveryItem)
    })
    return {
      dueDate: condition.dueDate,
      deliveryLocation: condition.deliveryLocation,
      address: condition.address,
      deliveryItems: deliveryItems
    }
  })
}

export const lookupOrAddDeliveryItemData = (
  deliveryItemMaps: DeliveryItemMap[],
  deliveryItem: DeliveryItem
): DeliveryItemData => {
  let deliveryItemMap = deliveryItemMaps.find((map) => map.deliveryItem === deliveryItem)
  if (!deliveryItemMap) {
    deliveryItemMap = {identifier: uuid(), deliveryItem: deliveryItem}
    deliveryItemMaps.push(deliveryItemMap)
  }
  return {
    identifier: deliveryItemMap.identifier,
    label: deliveryItemMap.deliveryItem.label
  }
}

export const discardOrderDraft = () => {
  store.commit(commits.SHIPPING_ORDER_DRAFT, false)
  localStorage.setObjectForKey(shippingOrderDraftKey, null)
}

export const shipOrder = (placeOrderCommand: PlaceOrderCommand): Promise<void> => {
  return createOrder(placeOrderCommand)
}
