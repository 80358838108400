
import Vue from 'vue'
import Component from 'vue-class-component'
import Card from '@/components/layout/Card.vue'
import { eventConsumer } from '@/infrastructure/events/event-consumer'
import { COMPANY_SETTINGS_CHANGED } from '@/application/settings/events'
import { routerNames } from '@/application/router/router-definitions'
import companySettingsService from '@/application/settings/CompanySettingsService'
import { CompanySettings } from '@/application/settings/models/types'
import Icon from '@/components/media/Icon.vue'

@Component({
  components: {
    Card,
    Icon
  },
  mixins: [eventConsumer([
    {
      key: COMPANY_SETTINGS_CHANGED,
      handler: 'loadCompanySettings'
    }
  ])]
})
export default class CompanySettingsOverview extends Vue {

  companySettings: CompanySettings | null = null

  get businessHourCardStyle(): object {
    return {
      selected: this.$route.name === routerNames.SETTINGS_DELIVERY_BUSINESS_HOURS
    }
  }

  get companyInfosCardStyle(): object {
    return {
      selected: this.$route.name === routerNames.SETTINGS_COMPANY_INFO
    }
  }

  get adInfosCardStyle(): object {
    return {
      selected: this.$route.name === routerNames.SETTINGS_COMPANY_ADS
    }
  }

  get businessHours(): any[] {
    if (this.companySettings == null) {
      return []
    }
    return this.companySettings!.businessHours.map((businessHour) => {
      const translated = this.translateWeekday(businessHour.day)
      return {
        day: translated,
        openingPeriods: businessHour.openingPeriods
      }
    })
  }

  mounted() {
    this.loadCompanySettings()
  }

  translateWeekday(weekday: string): string {
    return this.$t('weekdays.' + weekday.toLowerCase()) as string
  }

  onSelectBusinessHours() {
    this.$router.push({name: routerNames.SETTINGS_DELIVERY_BUSINESS_HOURS}).catch((err) => {})
  }

  onSelectCompanyInfos() {
    this.$router.push({name: routerNames.SETTINGS_COMPANY_INFO}).catch((err) => {})
  }

  onSelectAdInfos() {
    this.$router.push({name: routerNames.SETTINGS_COMPANY_ADS}).catch((err) => {})
  }

  loadCompanySettings() {
    companySettingsService.companySettingsOverview().then((result) => {
      this.companySettings = result
    })
  }

}
