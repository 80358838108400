import { render, staticRenderFns } from "./MainLayout.vue?vue&type=template&id=5f2fe56d&scoped=true&"
import script from "./MainLayout.vue?vue&type=script&lang=ts&"
export * from "./MainLayout.vue?vue&type=script&lang=ts&"
import style0 from "./MainLayout.vue?vue&type=style&index=0&id=5f2fe56d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f2fe56d",
  null
  
)

export default component.exports