
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import userService from '@/application/users/UsersService'
import PageTitle from '@/components/text/PageTitle.vue'
import Icon from '@/components/media/Icon.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import PageHead from '@/components/layout/PageHead.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import Divider from '@/components/layout/Divider.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import ContainedButton from '@/components/buttons/ContainedButton.vue'
import OptionsMenu from '@/components/menus/OptionsMenu.vue'
import { OptionsMenuItem } from '@/components/menus/types/OptionsMenuItem'
import ModalConfirmation from '@/components/modal/ModalConfirmation.vue'
import ModalWindow from '@/components/modal/ModalWindow.vue'
import { routerParameters } from '@/application/router/router-definitions'
import InlineTextInput from '@/components/forms/InlineTextInput.vue'
import FormField from '@/components/forms/FormField.vue'
import MultiSelectChipInput from '@/components/forms/MultiSelectChipInput.vue'
import SwitcherInput from '@/components/forms/SwitcherInput.vue'
import LinkedText from '@/components/text/LinkedText.vue'
import { routerNames } from '@/application/router/router-definitions'
import { User } from '@/application/users/models/types'
import UserForm from '@/application/users/templates/UserForm.vue'

@Component({
  components: {
    UserForm,
    SwitcherInput,
    MultiSelectChipInput,
    FormField,
    InlineTextInput,
    ModalWindow,
    ModalConfirmation,
    VerticalLayout,
    Divider,
    ScrollableSection,
    PageDetail,
    PageHead,
    DetailSection,
    PageTitle,
    Icon,
    ContainedButton,
    OptionsMenu,
    LinkedText
  }
})
export default class UserEdit extends Vue {

  // non reactive
  routes: any

  user: User | null = null
  roles: any[] = []
  rolesLoaded = false
  showDeleteConfirmation = false

  get userId() {
    return this.$route.params.id
  }

  get options(): OptionsMenuItem [] {
    return [
      {
        identifier: 'remove',
        text: this.$t('users.deleteUser') as string,
        icon: 'trash',
        disabled: this.hasAssignedTransports
      }
    ]
  }

  get hasAssignedTransports(): boolean {
    return this.user !== null && this.user.assignedTransports > 0
  }

  mounted() {
    this.routes = routerNames
    this.loadUser()
    this.loadRoles()
  }

  @Watch('userId')
  onChangeUserId() {
    this.user = null
    this.loadUser()
    this.loadRoles()
  }

  onChangeUser(value: any) {
    userService.editUser(this.userId, Object.assign({}, this.user, value)).then((updatedUser: User) => {
      this.user = updatedUser
    })
  }

  loadUser() {
    userService.user(this.userId).then((response) => {
      this.user = response
    })
  }

  loadRoles() {
    userService.userRoles().then((roles) => {
      this.roles = roles
      this.rolesLoaded = true
    })
  }

  onClickGenerateToken() {
    userService.generateToken(this.userId).then(() => {
      this.loadUser()
    })
  }

  onClickMenuItem() {
    this.showDeleteConfirmation = true
  }

  onClickConfirmationDeleteDiscard() {
    this.showDeleteConfirmation = false
  }

  onClickConfirmationDeleteSave() {
    this.showDeleteConfirmation = false
    userService.deleteUser(this.userId).then(() => {
      this.$router
        .push({name: 'user-overview', params: {[routerParameters.DISABLE_ROUTE_TO_LAST_CHILD]: 'true'}})
        .catch((err) => {})
    })
  }

}
