/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_10': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.4 2L22 5.833l-1.29 1.52-4.6-3.834L17.4 2zM6.6 2l1.28 1.52-4.59 3.823L2 5.823 6.6 2zM11 8.097h1.5v4.58h4.552v1.379H11V8.097zm-8 4.966c0-4.936 4.03-8.938 9-8.938s9 4.002 9 8.938C21 17.998 16.97 22 12 22c-4.98 0-9-4.002-9-8.937zm2 0c0 3.843 3.13 6.95 7 6.95s7-3.107 7-6.95c0-3.844-3.13-6.952-7-6.952S5 9.22 5 13.063z" _fill="#132C45"/>'
  }
})
