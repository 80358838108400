
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'

@Component
export default class FormField extends Vue {

  @Prop({
    type: String,
    required: true
  }) label!: string


}
