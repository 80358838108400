/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_17': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.1 14.7h1.8v1.8h-1.8v-1.8zm0-7.2h1.8v5.4h-1.8V7.5zm.891-4.5C7.023 3 3 7.032 3 12s4.023 9 8.991 9C16.968 21 21 16.968 21 12s-4.032-9-9.009-9zM12 19.2A7.198 7.198 0 014.8 12c0-3.978 3.222-7.2 7.2-7.2s7.2 3.222 7.2 7.2-3.222 7.2-7.2 7.2z" _fill="#132C45"/>'
  }
})
