

import Vue from 'vue'

export default Vue.extend({
  functional: true,
  render(h: any, ctx: any): any {
    return ctx.props.vnode
  }
})
