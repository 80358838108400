/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_12_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.83 11H22v2h-4.17l.096.096a5.961 5.961 0 00-2.05.78L15 13h-2v2l.876.876a5.961 5.961 0 00-.78 2.05L13 17.83V22h-2v-4.17l-3.24 3.24-1.42-1.41L11 15v-2H9l-4.66 4.66-1.41-1.42L6.17 13H2v-2h4.17L2.93 7.76l1.41-1.42L9 11h2V9L6.34 4.34l1.42-1.41L11 6.17V2h2v4.17l3.24-3.24 1.42 1.41L13 9v2h2l4.66-4.66 1.41 1.42L17.83 11z" _fill="#132C45"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19 14.417a4.583 4.583 0 100 9.167 4.583 4.583 0 000-9.167zM21.083 18l-.65-.667h-.129l-1.774 1.808-.83-.845h-.129l-.654.662v.131l1.548 1.578h.13l2.488-2.535V18z" _fill="#29CC96"/>'
  }
})
