
import { Vue, Prop, Component } from 'vue-property-decorator'
import { Transport} from '@/application/deliveries/models/transport/types'
import TransportStatusView from '@/application/deliveries/ui/components/transport/TransportStatusView.vue'
import TransportCard from '@/application/deliveries/ui/components/transport/TransportCard.vue'
import PageTitle from '@/components/text/PageTitle.vue'
import DetailSection from '@/components/layout/DetailSection.vue'

@Component({
  components: {
    PageTitle,
    TransportStatusView,
    TransportCard,
    DetailSection
  }
})
export default class TransportCardSection extends Vue {

  @Prop({
    type: Array,
    required: true
  })
    transports!: Transport[]

  @Prop({
    type: Boolean,
    required: true
  })
    draggable!: boolean

  @Prop({
    type: String,
    required: false,
    default: null
  })
    assignee!: string | null

  @Prop({
    type: String,
    required: false,
    default: null
  })
    title!: string | null

  @Prop({
    type: Function,
    required: true
  })
    onDragStart!: (event: DragEvent, transport: Transport) => void

  get transportsCount(): number {
    return this.transports.length
  }

  get hasTransports(): boolean {
    return this.transportsCount > 0
  }

  transportFlagIcon(transport: Transport) {
    if (!this.assignee) {
      return null
    }
    const isDirectlyAssigned = (this.assignee === transport.assigneeId)
    return (isDirectlyAssigned) ? 'personal-assignment' : null
  }


}
