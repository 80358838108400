
import Vue from 'vue'
import Component from 'vue-class-component'
import userService from '../UsersService'
import { eventConsumer } from '@/infrastructure/events/event-consumer'
import { ROLES_CHANGED } from '../events'
import DataTable from '@/components/table/DataTable.vue'
import TableCell from '@/components/table/TableCell.vue'
import ContainedButton from '@/components/buttons/ContainedButton.vue'
import BadgeContainer from '@/components/badge/BadgeContainer.vue'
import DetailSection from '@/components/layout/DetailSection.vue'

@Component({
  components: {
    DetailSection,
    DataTable,
    TableCell,
    ContainedButton,
    BadgeContainer
  },
  mixins: [eventConsumer([
    {
      key: ROLES_CHANGED,
      handler: 'loadRoles'
    }
  ])]
})
export default class RolesOverview extends Vue {

  roles: any[] = []

  get addRoleAppearance() {
    return this.$store.getters['users/hasRoleDraft'] ? 'outlined' : 'primary'
  }

  get addRoleBadgeValue() {
    return this.$store.getters['users/hasRoleDraft'] ? '1' : null
  }

  get selectedRoleId() {
    return this.$route.params.id
  }

  set selectedRoleId(roleId: any) {
    if (!roleId) {
      return
    }
    this.$router.push({name: 'role-edit', params: {id: roleId}}).catch((err) => {})
  }

  mounted() {
    this.loadRoles()
  }

  onClickAddRole() {
    this.$router.push({name: 'role-add'}).catch((err) => {})
  }

  loadRoles() {
    userService.userRoles().then((roles) => {
      this.roles = roles
    })
  }
}
