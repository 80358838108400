

import Vue from 'vue'
import Component from 'vue-class-component'
import PageLayout from '@/components/layout/PageLayout.vue'
import PageTitle from '@/components/text/PageTitle.vue'
import PageHead from '@/components/layout/PageHead.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import AnalyticsFilter from '@/application/analytics/ui/template/AnalyticsFilter.vue'
import { DateRange } from '@/components/forms/types/DateRange'
import AnalyticsResult from '@/application/analytics/ui/template/AnalyticsResults.vue'
import { endOfCurrentMonth, startOfCurrentMonth } from '@/utils/date-util'
import { DefaultDateRange } from '@/components/forms/types/DefaultDateRange'
import Card from '@/components/layout/Card.vue'
import SlotButton from '@/components/buttons/SlotButton.vue'
import ContainedButton from '@/components/buttons/ContainedButton.vue'

@Component({
  name: 'Analytics',
  components: {
    ContainedButton,
    SlotButton,
    Card,
    AnalyticsResult,
    AnalyticsFilter,
    VerticalLayout,
    ScrollableSection,
    PageDetail,
    PageHead,
    PageTitle,
    PageLayout
  }
})
export default class Analytics extends Vue {

  dateRange: DateRange = new DefaultDateRange(
    startOfCurrentMonth(),
    endOfCurrentMonth()
  )

  onDateRangeChange(dateRange: DateRange) {
    this.dateRange = dateRange
  }

}
