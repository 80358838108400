/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'map': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.486 2.005a.66.66 0 00-.107.024L1.535 4.081A.765.765 0 001 4.826v16.408a.78.78 0 00.292.611c.183.144.42.191.64.126l6.646-1.995 6.646 1.995c.13.038.267.038.397 0l6.844-2.05a.765.765 0 00.535-.738V2.775a.785.785 0 00-.288-.616.71.71 0 00-.644-.13l-6.646 1.995L8.776 2.03a.712.712 0 00-.29-.024zm-.642 1.78v14.813l-5.377 1.61V5.403l5.377-1.618zm1.467 0l5.378 1.617v14.815L9.31 18.598V3.784zm12.222.007v14.806l-5.378 1.619V5.403l5.378-1.61z" _fill="#132C45"/>'
  }
})
