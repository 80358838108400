

import { Vue, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import Icon from '@/components/media/Icon.vue'
import { TransportSegment } from '@/application/deliveries/models/TransportSegment'
import { formatTime, formatDueDateTime, formatDueDateTitle } from '@/utils/formatter'

@Component({
  components: {
    Icon
  }
})
export default class DueDateTimeView extends Vue {
  @Prop({type: Object, required: true}) transportSegment!: TransportSegment

  get time() {
    if (this.isDone) {
      return formatTime(this.transportSegment.confirmationDate!)
    } else {
      return formatDueDateTime(this.transportSegment.dueDate)
    }
  }

  get initialTime() {
    return formatDueDateTitle(this.transportSegment.dueDate, this.transportSegment.confirmationDate)
  }

  get icon(): string {
    return this.isDone ? 'due_time_done' : 'due_time'
  }

  get isDone(): boolean {
    return this.transportSegment.confirmationDate !== null
  }

  get iconClass(): any {
    return (this.isDone) ? 'success-fill' : 'primary-fill'
  }
}
