/* eslint-disable */
require('./ads')
require('./arrow_head')
require('./arrow')
require('./biker')
require('./business')
require('./calendar')
require('./camera_add')
require('./camera')
require('./checkbox_selected')
require('./checkbox')
require('./checkmark_bold')
require('./checkmark_circle_solid')
require('./checkmark')
require('./chevron_down')
require('./chevron_left')
require('./chevron_right')
require('./chevron_up')
require('./close')
require('./comment')
require('./cross_circle_solid')
require('./customer')
require('./deliveryobject_confirmed')
require('./deliveryobject_missing')
require('./deliveryobject')
require('./dispo-location')
require('./due_date_done')
require('./due_date')
require('./due_time_done')
require('./due_time')
require('./edit')
require('./email')
require('./external_link')
require('./filter')
require('./fragile')
require('./group')
require('./help_circle')
require('./icon_bag_empty')
require('./image')
require('./info_circle')
require('./inprogress')
require('./list')
require('./location_ready')
require('./location_unknown')
require('./location')
require('./map')
require('./minus')
require('./more')
require('./nav_analytics')
require('./nav_businesssettings')
require('./nav_customers')
require('./nav_dispo')
require('./nav_orders')
require('./nav_users')
require('./notification')
require('./order_api')
require('./order_cancel')
require('./order_cockpit')
require('./order_courier')
require('./order_customer')
require('./order_new')
require('./order_unknown')
require('./order')
require('./ordertype_hld')
require('./ordertype_individual')
require('./ordertype_repeating')
require('./person_assign')
require('./person_block')
require('./person')
require('./personal-assignment')
require('./phone')
require('./plus')
require('./power')
require('./price')
require('./print')
require('./radiobutton_selected')
require('./radiobutton')
require('./refresh')
require('./save')
require('./search')
require('./service_0_done')
require('./service_0_not_done')
require('./service_0')
require('./service_1_done')
require('./service_1_not_done')
require('./service_1')
require('./service_10_done')
require('./service_10_not_done')
require('./service_10')
require('./service_11_done')
require('./service_11_not_done')
require('./service_11')
require('./service_12_done')
require('./service_12_not_done')
require('./service_12')
require('./service_13_done')
require('./service_13_not_done')
require('./service_13')
require('./service_14_done')
require('./service_14_not_done')
require('./service_14')
require('./service_15_done')
require('./service_15_not_done')
require('./service_15')
require('./service_16_done')
require('./service_16_not_done')
require('./service_16')
require('./service_17_done')
require('./service_17_not_done')
require('./service_17')
require('./service_18_done')
require('./service_18_not_done')
require('./service_18')
require('./service_19_done')
require('./service_19_not_done')
require('./service_19')
require('./service_2_done')
require('./service_2_not_done')
require('./service_2')
require('./service_3_done')
require('./service_3_not_done')
require('./service_3')
require('./service_4_done')
require('./service_4_not_done')
require('./service_4')
require('./service_5_done')
require('./service_5_not_done')
require('./service_5')
require('./service_6_done')
require('./service_6_not_done')
require('./service_6')
require('./service_7_done')
require('./service_7_not_done')
require('./service_7')
require('./service_8_done')
require('./service_8_not_done')
require('./service_8')
require('./service_9_done')
require('./service_9_not_done')
require('./service_9')
require('./signature')
require('./sort')
require('./sync')
require('./trash')
require('./triangle')
require('./upload')
require('./visibility_off')
require('./visibility_on')
require('./warning')
