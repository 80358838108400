
import Vue from 'vue'
import Component from 'vue-class-component'
import DetailSection from '@/components/layout/DetailSection.vue'
import FormField from '@/components/forms/FormField.vue'
import TextInput from '@/components/forms/TextInput.vue'
import QRCode from '@/components/media/QRCode.vue'
import { uuidV4 } from '@/utils/uuid'

@Component({
  components: {
    DetailSection,
    FormField,
    TextInput,
    QRCode
  }
})
export default class DeliveryItemQrCodes extends Vue {

  quantity = '20'

  sequence = '1234'

  prefix = 'A'

  deliveryItemQrCodeTitle(index: number) {
    return this.prefix + '-' + (Number(this.sequence) + index)
  }

  deliveryItemQrCodeValue(index: number) {
    return {
      qrCodeId: uuidV4(),
      label: this.deliveryItemQrCodeTitle(index)
    }
  }


}
