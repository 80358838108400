import Vue from 'vue'
import VueI18n, { DateTimeFormats } from 'vue-i18n'
import locales from '@/locales'

Vue.use(VueI18n)

export const availableLanguages = ['de', 'fr', 'it']

const dateTimeFormats: DateTimeFormats = {
  de: {
    short: {
      day: 'numeric', month: 'numeric', year: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    }
  }
}

function getUserLocale() {
  return (navigator.languages && navigator.languages[0]) ||
    navigator.language
}

function getInitialLanguage() {
  const userLocale = getUserLocale().slice(0, 2)
  if (availableLanguages.includes(userLocale)) {
    return userLocale
  } else {
    return process.env.VUE_APP_I18N_FALLBACK_LOCALE
  }
}

export default new VueI18n({
  locale: getInitialLanguage(),
  dateTimeFormats,
  messages: locales
})
