
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Calendar from '@/components/forms/Calendar.vue'
import { CalendarDay } from '@/components/forms/types/CalendarDay'
import { DateRange } from '@/components/forms/types/DateRange'

@Component({
  components: {Calendar}
})
export default class CalendarPicker extends Calendar {

  @Prop({
    type: Date,
    required: true
  })
    value!: Date

  @Prop({
    type: Object,
    required: false,
    default: null
  })
    allowedRange!: DateRange | null

  onConfigureDay(day: CalendarDay) {
    day.isSelected = day.date.getTime() === this.value.getTime()
    day.isDisabled = !this.isDateInAllowedRange(day.date)
  }

  onSelectDay(day: CalendarDay) {
    if (!day.isDisabled) {
      this.$emit('input', day.date)
    }
  }

  onSelectToday(today: Date) {
    if (this.isDateInAllowedRange(today)) {
      this.$emit('input', today)
    }
  }

  isDateInAllowedRange(date: Date): boolean {
    if (this.allowedRange !== null) {
      return this.allowedRange.isTimeInRange(date.getTime())
    }
    return true
  }

}
