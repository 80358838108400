/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'print': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" d="M18.3 8.444H5.7c-1.494 0-2.7 1.192-2.7 2.667v5.333h3.6V20h10.8v-3.556H21v-5.333c0-1.475-1.206-2.667-2.7-2.667zm-2.7 9.778H8.4v-4.444h7.2v4.444zM18.3 12c-.495 0-.9-.4-.9-.889s.405-.889.9-.889.9.4.9.89a.897.897 0 01-.9.888zm-.9-8H6.6v3.556h10.8V4z"/>'
  }
})
