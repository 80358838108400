export interface IntervalTimerCallback {
  onIntervalTimeout(): void
}

export default class Interval {

  private timeoutId: number | null = null

  private isRunning = false

  constructor(
    private runningCallback: IntervalTimerCallback,
    private timeout: number = 1500
  ) {
  }

  start(): void {
    if (this.isRunning) {
      return
    }
    this.isRunning = true
    this.startTimeout()
  }

  private startTimeout() {
    this.timeoutId = window.setTimeout(() => {
      this.runningCallback.onIntervalTimeout()
      this.startTimeout()
    }, this.timeout)
  }

  stop() {
    this.isRunning = false
    if (this.timeoutId != null) {
      window.clearTimeout(this.timeoutId)
    }
  }

}
