

import Icon from '../media/Icon.vue'
import IconButton from './IconButton.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import TextButton from './TextButton.vue'
import ContainedButton from './ContainedButton.vue'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {
    Icon,
    IconButton,
    TextButton,
    ContainedButton
  }
})

export default class SaveDiscardButtons extends Vue {

  @Prop({
    type: String,
    required: true
  })
    discardButtonTitle!: string

  @Prop({
    type: String,
    required: true
  })
    saveButtonTitle!: string

  @Prop({
    type: String,
    default: 'plus'
  })
    saveButtonIcon!: string

  @Prop({
    type: Boolean,
    default: false
  })
    disableSaveButton!: boolean

  onClickDiscard() {
    this.$emit('click-discard')
  }

  onClickSave() {
    this.$emit('click-save')
  }

}
