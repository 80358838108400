import { LocationHelper } from '@/infrastructure/location-helper'
import { Client, IFrame, StompSubscription } from '@stomp/stompjs'

export declare type subscribeCallbackType = (message: string) => void

interface Subscription {
  callbacks: subscribeCallbackType[]
  stompSubscription?: StompSubscription
}

export enum WebsocketStatus {
  CLOSED,
  CONNECTING,
  CONNECTED,
  CONNECTION_LOST
}

export declare type statusObserverType = (status: WebsocketStatus) => void

export class Websocket {

  private connectionStatusObservers: statusObserverType[] = []
  private stompClient: Client
  private destinationSubscriptions: { [destination: string]: Subscription } = {}

  private connectionStatus: WebsocketStatus = WebsocketStatus.CLOSED

  private get status(): WebsocketStatus {
    return this.connectionStatus
  }

  private set status(status: WebsocketStatus) {
    this.connectionStatus = status
    this.notifyConnectionStatusObservers()
  }

  private notifyConnectionStatusObservers() {
    this.connectionStatusObservers.forEach((observer) => {
      observer(this.status)
    })
  }

  constructor(client: Client) {
    this.stompClient = client
  }

  addStatusChangeObserver(observer: (status: any) => void) {
    this.connectionStatusObservers.push(observer)
  }


  removeStatusChangeObserver(observer: (status: any) => void) {
    const index = this.connectionStatusObservers.indexOf(observer)
    if (index > -1) {
      this.connectionStatusObservers.splice(index, 1)
    }
  }

  subscribe(destination: string, callback: subscribeCallbackType) {
    if (this.destinationSubscriptions[destination]) {
      this.destinationSubscriptions[destination].callbacks.push(callback)
    } else {
      this.destinationSubscriptions[destination] = {stompSubscription: undefined, callbacks: [callback]}
      if (this.status === WebsocketStatus.CONNECTED) {
        this.subscribeDestination(destination)
      }
    }
    if (this.status === WebsocketStatus.CLOSED) {
      this.connect()
    }
  }

  subscribeDestination(destination: string) {
    const subscription = this.stompClient.subscribe(destination, (message) => {
      this.destinationSubscriptions[destination].callbacks.forEach((callback) => {
        callback(message.body)
      })
    })
    this.destinationSubscriptions[destination].stompSubscription = subscription
  }

  private connect() {
    this.status = WebsocketStatus.CONNECTING
    this.stompClient.onConnect = () => {
      Object.keys(this.destinationSubscriptions).forEach((destination) => {
        this.subscribeDestination(destination)
      })
      this.status = WebsocketStatus.CONNECTED
    }
    this.stompClient.onDisconnect = () => {
      this.status = WebsocketStatus.CLOSED
    }
    this.stompClient.onWebSocketClose = () => {
      this.status = (this.status === WebsocketStatus.CLOSED) ? WebsocketStatus.CLOSED : WebsocketStatus.CONNECTION_LOST
    }
    this.stompClient.activate()
  }

  unsubscribe(callback: subscribeCallbackType) {
    Object.keys(this.destinationSubscriptions).forEach((destination) => {
      const subscription = this.destinationSubscriptions[destination]
      const index = subscription.callbacks.indexOf(callback)
      if (index > -1) {
        subscription.callbacks.splice(index, 1)
        if (subscription.callbacks.length === 0) {
          subscription.stompSubscription!.unsubscribe()
          delete this.destinationSubscriptions[destination]
        }
      }
    })
    if (!this.hasSubscriptions()) {
      this.stompClient.deactivate()
    }
  }

  private hasSubscriptions(): boolean {
    return Object.keys(this.destinationSubscriptions).length > 0
  }
}

const wsProtocol = LocationHelper.protocol().startsWith('https') ? 'wss' : 'ws'
const wsHost = LocationHelper.host()
const wsSocketUrl = `${wsProtocol}://${wsHost}/api/websocket`


export default new Websocket(new Client({
  brokerURL: wsSocketUrl,
  reconnectDelay: 5000,
  heartbeatIncoming: 0,
  heartbeatOutgoing: 25000
}))
