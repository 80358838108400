/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'comment': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M21 17.182V22l-3.075-3H4.636A1.636 1.636 0 013 17.364V2.636C3 1.733 3.733 1 4.636 1h14.728C20.267 1 21 1.733 21 2.636v14.546zM5 3v14h14V3H5zm3.5 5.833A1.17 1.17 0 019.667 10 1.17 1.17 0 018.5 11.167 1.17 1.17 0 017.333 10 1.17 1.17 0 018.5 8.833zm7 0A1.17 1.17 0 0116.667 10a1.17 1.17 0 01-1.167 1.167A1.17 1.17 0 0114.333 10 1.17 1.17 0 0115.5 8.833zm-3.5 0A1.17 1.17 0 0113.167 10 1.17 1.17 0 0112 11.167 1.17 1.17 0 0110.833 10 1.17 1.17 0 0112 8.833z"/>'
  }
})
