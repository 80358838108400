/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_1': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.452 6.95l1.657-3.99c-1.852-1.623-4.259-.05-4.48.008C5.547 2.326 4.703 2 4.12 2c-.679 0-1.1.43-1.1 1.121 0 1.409.918 3.197 1.607 4.338C1.803 9.298 0 13.425 0 16.697 0 21.047 2.938 22 7.515 22c3.883 0 7.517-.576 7.517-5.303 0-3.027-1.792-8.145-5.58-9.746zM6.805 4.39c.68-.108 1.976-.867 2.423-.73L7.84 7s-.976-.048-1.717.232C5.72 6.555 5.091 5.325 4.785 4.47c.663.11 1.624.006 2.02-.081zm.71 16.187c-4.57 0-6.039-.943-6.039-3.88 0-3.52 2.383-7.906 5.236-8.625-.727 1.008-1.217 2.38-.68 3.93l.954-.308c-.425-1.227-.03-2.261.5-3.007.256.335.533.803.533 1.221h1.006c0-.736-.433-1.426-.77-1.853 2.882.668 5.298 5.095 5.298 8.642.001 2.937-1.466 3.88-6.038 3.88z" _fill="#132C45"/><path pid="1" d="M13.766 4.735c0-.614.391-1.008 1.01-1.008.506 0 1.22.272 2.058.796-.003 0 .413-.203.652-.299.74-.296 1.46-.405 2.147-.225a2.5 2.5 0 011.01.537l.025.1-1.342 3.186c2.747 1.203 4.605 4.927 4.605 8.02 0 3.385-2.012 4.43-6.336 4.43-1.858 0-3.226-.19-4.22-.649-.238-.11.352-1.223.604-2.424.202-.96.055-2.023.08-2.327.008-.097.011-.194.01-.294-.006-.625-.16-1.224-.497-2.152-.592-1.63-.662-1.935-.345-2.366.543-.74 1.165-1.354 1.841-1.802-.792-1.31-1.302-2.547-1.302-3.523zm4.17.822c-.5.2-.706.273-.91.305a5.76 5.76 0 01-1.554.088c.235.602.66 1.449 1.01 2.037.097-.032.2-.06.31-.082a4.611 4.611 0 011.008-.089l1.081-2.564c-.162.008-.434.1-.944.305zM13.98 11.45c-.17.283-.125.466.293 1.576.287.762.41 1.281.378 1.857-.01.209-.05.43-.114.664-.1.366.223.966 0 1.425-.041.084-.711 1.358-.5 1.455.752.347 1.882.5 3.559.499 3.749 0 4.918-.74 4.917-3.083 0-2.884-1.919-6.199-4.064-6.909.307.437.497.897.497 1.355l-.096.09h-.836l-.096-.09c0-.244-.13-.539-.343-.838-.465.697-.613 1.458-.327 2.27l-.061.115-.792.251-.122-.057c-.38-1.08-.187-2.135.429-3.082-.998.344-1.964 1.245-2.722 2.502z" _fill="#132C45"/>'
  }
})
