/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav_dispo': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M13.22 8l.903 1.064-3.22 2.683L10 10.683 13.22 8zm7.56 0L24 10.676l-.903 1.064-3.213-2.676L20.78 8zm-3.046 5.17v3.846H13.91v-.965h2.775v-2.88h1.05zM23.5 16c0 3.59-2.903 6.5-6.5 6.5a6.5 6.5 0 116.5-6.5zM7 2h3.25a1 1 0 012 0h3.994c1.05 0 1.9.85 1.9 1.9v2.406h-1.8V3.9a.1.1 0 00-.1-.1H12.25v.567a1 1 0 01-2 0V3.8H7v.567a1 1 0 11-2 0V3.8H1.9a.1.1 0 00-.1.1v14.031a.1.1 0 00.1.1h5.844v1.8H1.9a1.9 1.9 0 01-1.9-1.9V3.9C0 2.85.85 2 1.9 2H5a1 1 0 112 0zm14.75 14A4.747 4.747 0 0117 20.75 4.747 4.747 0 0112.25 16 4.747 4.747 0 0117 11.25 4.747 4.747 0 0121.75 16z"/>'
  }
})
