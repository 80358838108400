

import { Transport } from '@/application/deliveries/models/transport/types'
import { Vue, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import Icon from '@/components/media/Icon.vue'
import DueDateTimeView from './DueDateTimeView.vue'
import { DeliveryLocation } from '@/application/deliveries/models/DeliveryLocation'
import DueDateDateView from '@/application/deliveries/ui/components/transport/DueDateDateView.vue'

@Component({
  components: {
    DueDateDateView,
    Icon,
    DueDateTimeView
  }
})
export default class TransportView extends Vue {

  @Prop({
    type: Object,
    required: true
  })
    transport!: Transport

  concatAddressCity(deliveryAddress: DeliveryAddress)  {
    return `${deliveryAddress.street}, ${deliveryAddress.city}`
  }

  titleAddressCity(deliveryAddress: DeliveryAddress)  {
    return `${deliveryAddress.street}` + '\n' + `${deliveryAddress.city}`
  }

  get pickupLocation(): DeliveryLocation {
    return this.transport.pickup.location
  }

  get dropOffLocation(): DeliveryLocation {
    return this.transport.dropOff.location
  }

}
