import TransferTransport from '@/application/deliveries/ui/types/TransferTransport'
import { Transport } from '@/application/deliveries/models/transport/types'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'


export interface TransportDraggableTarget {

  onDroppedTransport(transport: TransferTransport): void

  redirectAfterDrop(): boolean
}

@Component
export default class TransportDraggableTargetMixin extends Vue {

  transportOver = false

  isDragSource = false

  get isDragTarget() {
    return this.transportOver && !this.isDragSource
  }

  onDragStart(event: DragEvent, transport: Transport) {
    const transfer = new TransferTransport(
      transport.transportId,
      transport.orderNumber,
      // @ts-ignore
      this.redirectAfterDrop()
    )
    event.dataTransfer!.setData(
      'text/plain',
      JSON.stringify(transfer)
    )
    this.isDragSource = true
  }

  onDragEnd(event: DragEvent) {
    this.isDragSource = false
  }

  onDrop(e: DragEvent) {
    if (this.isDragSource) {
      return
    }
    try {
      const transfer = JSON.parse(e.dataTransfer!.getData('text/plain')) as TransferTransport
      // @ts-ignore
      this.onDroppedTransport(transfer)
    } finally {
      this.transportOver = false
      e.preventDefault()
    }
  }

  onDragLeave(e: DragEvent) {
    this.transportOver = false
    e.preventDefault()
  }

  onDragOver(e: DragEvent) {
    if (this.isDragSource) {
      e.stopPropagation()
      return
    }

    e.preventDefault()
    this.transportOver = true
  }

}
