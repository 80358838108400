
import Component from 'vue-class-component'
import { Prop, Vue, Watch } from 'vue-property-decorator'
import OptionsButton from '@/components/buttons/OptionsButton.vue'
import ItemList from '@/components/menus/ItemList.vue'
import Icon from '@/components/media/Icon.vue'
import { OptionsMenuItem } from '@/components/menus/types/OptionsMenuItem'


@Component({
  components: {
    ItemList,
    OptionsButton,
    Icon
  }
})
export default class OptionsMenu extends Vue {

  $refs!: {
    button: OptionsButton,
    contextMenu: ItemList
  }

  @Prop({type: Array, required: true}) options!: OptionsMenuItem[]

  showMenu = false

  get disabledMenuItems(): OptionsMenuItem[] {
    return this.options.filter((i) => i.disabled)
  }

  @Watch('showMenu')
  onChangeShowMenu() {
    if (this.showMenu) {
      window.addEventListener('click', this.onClickOutside)
    } else {
      window.removeEventListener('click', this.onClickOutside)
    }
  }

  onClickButton() {
    this.showMenu = !this.showMenu
  }

  onClickOutside(e: MouseEvent) {
    const target = e.target as HTMLElement

    if (!this.insideButton(target) && !this.insideContextMenu(target)) {
      this.showMenu = false
    }
  }

  onClickMenuItem(item: OptionsMenuItem) {
    this.$emit('click', item)
    this.showMenu = false
  }

  insideButton(element: HTMLElement) {
    return this.$refs.button.$el === element || this.$refs.button.$el.contains(element)
  }

  insideContextMenu(element: HTMLElement) {
    return this.$refs.contextMenu.$el === element || this.$refs.contextMenu.$el.contains(element)
  }
}
