/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'triangle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" d="M5 9l7 7 7-7z"/>'
  }
})
