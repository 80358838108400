/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'order': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.148 6.956a.5.5 0 00-.092.148.548.548 0 00-.056.24V21.45c0 .146.056.287.155.39.1.104.233.161.374.161H19.47c.14 0 .275-.057.374-.16a.565.565 0 00.155-.39V2.552a.54.54 0 00-.529-.55H9.141a.552.552 0 00-.103 0 .612.612 0 00-.159.063.503.503 0 00-.11.085L4.147 6.956zm4.356-.155H6.542l1.962-2.045V6.8zm9.804 13.284V3.907H9.883V7.77a.51.51 0 01-.14.354.472.472 0 01-.34.147H5.691v11.816h12.616zM7.537 10.091h8.842v1.818H7.537v-1.818zm8.842 6.455H7.537v1.818h8.842v-1.819zM7.537 13.2h8.842v1.818H7.537V13.2z" _fill="#132C45"/>'
  }
})
