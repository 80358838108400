
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import TextInput from '@/components/forms/TextInput.vue'
import ItemList from '@/components/menus/ItemList.vue'

@Component({
  components: {
    TextInput,
    ItemList
  }
})
export default class TextSuggestionInput extends Vue {

  $refs!: {
    contextMenu: ItemList,
    textInput: TextInput
  }

  @Prop({type: String, default: ''}) value!: string

  @Prop({type: String, default: ''}) placeholder!: string

  @Prop({type: Function, required: true}) suggestionRenderer!: (suggestion: any) => string

  @Prop({type: Array, required: true}) suggestions!: any[]

  @Prop({type: String, default: null}) inputClass!: string | null

  suggestionsOpen = false

  highlightedSuggestion: any | null = null

  get displayedValue(): string {
    if (this.highlightedSuggestion) {
      return this.suggestionRenderer(this.highlightedSuggestion)
    }
    return this.value
  }

  beforeDestroy() {
    document.body.removeEventListener('click', this.onClickOutside)
  }

  onClick() {
    if (this.suggestionsOpen) {
      this.closeSuggestions()
    } else {
      this.openSuggestions()
    }
  }

  onChangeValue(value: string) {
    this.clearHighlightedSuggestion()
    this.$emit('input', value)
  }

  onSelectSuggestion(suggestion: any) {
    this.selectedSuggestion(suggestion)
  }

  onHighlightSuggestion(suggestion: any | null) {
    this.highlightedSuggestion = suggestion
  }

  onKeyUp() {
    if (this.suggestionsOpen) {
      this.$refs.contextMenu.highlightPreviousItem()
    } else {
      this.openSuggestions()
    }
  }

  onKeyDown() {
    if (this.suggestionsOpen) {
      this.$refs.contextMenu.highlightNextItem()
    } else {
      this.openSuggestions()
    }
  }

  onKeyEnter() {
    if (this.suggestionsOpen) {
      if (this.highlightedSuggestion) {
        this.selectedSuggestion(this.highlightedSuggestion)
      } else {
        this.closeSuggestions()
      }
    } else {
      this.openSuggestions()
    }
  }

  onKeyEsc() {
    this.closeSuggestions()
  }

  onClickOutside(event: MouseEvent) {
    if (!(this.$refs.textInput.$el === event.target || this.$refs.textInput.$el.contains(event.target as Node))) {
      this.closeSuggestions()
    }
  }

  focus() {
    this.$refs.textInput.focus()
  }

  selectedSuggestion(suggestion: any) {
    this.$emit('suggestion', suggestion)
    this.closeSuggestions()
  }

  clearHighlightedSuggestion() {
    this.highlightedSuggestion = null
    this.$refs.contextMenu.highlightItem(null)
  }

  openSuggestions() {
    document.body.addEventListener('click', this.onClickOutside)
    this.suggestionsOpen = true
  }

  closeSuggestions() {
    document.body.removeEventListener('click', this.onClickOutside)
    this.suggestionsOpen = false
  }

}
