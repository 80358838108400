/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_11_not_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.341 21A5.99 5.99 0 0113 19H5V5h14v8c.701 0 1.374.12 2 .341V4.636C21 3.733 20.267 3 19.364 3H4.636C3.733 3 3 3.733 3 4.636v14.728C3 20.267 3.733 21 4.636 21h8.705zm-3.674-9A1.17 1.17 0 008.5 10.833 1.17 1.17 0 007.333 12 1.17 1.17 0 008.5 13.167 1.17 1.17 0 009.667 12zm7 0a1.17 1.17 0 00-1.167-1.167A1.17 1.17 0 0014.333 12a1.17 1.17 0 001.167 1.167A1.17 1.17 0 0016.667 12zm-3.5 0A1.17 1.17 0 0012 10.833 1.17 1.17 0 0010.833 12 1.17 1.17 0 0012 13.167 1.17 1.17 0 0013.167 12z" _fill="#132C45"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19 14.417a4.583 4.583 0 100 9.167 4.583 4.583 0 000-9.167zm.793 2.998a.281.281 0 01.396 0l.396.396a.281.281 0 010 .396l-.793.793.793.793a.281.281 0 010 .396l-.396.396a.281.281 0 01-.396 0L19 19.792l-.793.793a.281.281 0 01-.396 0l-.396-.396a.281.281 0 010-.396l.793-.793-.793-.793a.281.281 0 010-.396l.396-.396a.281.281 0 01.396 0l.793.793.793-.793z" _fill="#F24949"/>'
  }
})
