/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'external_link': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 1.501A1.5 1.5 0 011.5 0h6.563a1.5 1.5 0 010 3.002H3v17.99l18-.066V15.95a1.5 1.5 0 113 0v6.47a1.5 1.5 0 01-1.495 1.502l-21 .077A1.499 1.499 0 010 22.499V1.5zM21.046 5.06v5.473c0 .81.661 1.468 1.477 1.468.816 0 1.477-.657 1.477-1.468V1.468C24 .658 23.339 0 22.523 0h-9.046C12.66 0 12 .657 12 1.468c0 .81.661 1.467 1.477 1.467h5.483L8.436 13.46a1.488 1.488 0 002.105 2.105L21.046 5.06z" _fill="#132C45"/>'
  }
})
