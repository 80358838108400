

import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class ScrollableSection extends Vue {

  @Prop({
    type: String,
    default: 'white',
    validator: function (value: string) {
      return [
        'white',
        'grey'
      ].indexOf(value) !== -1
    }
  }) private appearance!: string

}

