

import { Assignee } from '@/application/deliveries/models/Assignee'
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import Icon from '@/components/media/Icon.vue'
import Avatar from '@/components/media/Avatar.vue'
import StatusBadge from '@/components/badge/StatusBadge.vue'

@Component({
  components: {StatusBadge, Avatar, Icon}
})
export default class CourierView extends Vue {

  @Prop({type: Object, required: true}) private courier!: Assignee

  private get avatarLetters() {
    return this.courier.additionalName!.charAt(0).toUpperCase() +
      this.courier.name!.charAt(0).toUpperCase()
  }

}
