
import Component from 'vue-class-component'
import { Prop, Vue, Watch } from 'vue-property-decorator'
import Icon from '@/components/media/Icon.vue'
import IconButton from '@/components/buttons/IconButton.vue'
import TextInput from '@/components/forms/TextInput.vue'
import { removeAllWhitespaces, removeLeadingZero } from '@/utils/string-utils'
import { debounce } from '@/components/forms/debounce'

@Component({
  components: {
    TextInput,
    IconButton,
    Icon
  }
})
export default class InlinePhoneNumberInput extends Vue {

  static readonly areaCode: string = '+41'

  $refs!: {
    textInput: TextInput
  }

  @Prop({
    type: String,
    default: null
  })
    value!: string | null

  @Prop({
    type: String,
    default: '79 123 45 67'
  })
    placeholder!: string

  @Prop({
    type: String,
    default: null
  })
    inputClass!: string | null

  localValue: string | null = this.value

  editing = false

  mouseDownControl = false

  error: string | null = null

  debouncedValidateFunction: any = debounce(this.validate, 400)

  get placeHolderAsString() {
    return (this.placeholder !== null) ? this.formatPhoneNumber(this.placeholder.toString()) : null
  }

  get existingValue(): boolean {
    return this.value !== null
  }

  get textInputClasses(): string | null {
    const classes = []
    if (this.inputClass) {
      classes.push(this.inputClass)
    }
    if (this.existingValue) {
      classes.push('style-inline existing-value')
    }
    return classes.length > 0 ? classes.join(' ') : null
  }

  validate() {
    if (this.localValue === null) {
      this.error = null
    }

    const phoneNumberRegex = new RegExp(/^\d{9}$/)
    const validFormat = phoneNumberRegex.test(this.localValue!)
    if (!validFormat) {
      this.error = this.$tc('InlinePhoneNumberInput.invalidPhoneNumber')
    } else {
      this.error = null
    }
  }

  get confirmButtonDisabled(): boolean {
    return this.error !== null
  }

  get formattedValue(): string {
    if (this.localValue === null) {
      return ''
    }

    return this.formatPhoneNumber(this.localValue)
  }

  private formatPhoneNumber(phoneNumberWithAreaCode: string): string {
    let numberWithoutAreaCode = this.removeAreaCode(phoneNumberWithAreaCode)
    if (this.lengthIgnoringLeadingZero(numberWithoutAreaCode) >= 9) {
      numberWithoutAreaCode = removeLeadingZero(numberWithoutAreaCode)
      numberWithoutAreaCode = numberWithoutAreaCode.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4')
    } else {
      numberWithoutAreaCode = removeAllWhitespaces(numberWithoutAreaCode)
    }
    return numberWithoutAreaCode
  }

  removeAreaCode(phoneNumberWithAreaCode: string): string {
    return phoneNumberWithAreaCode.replace(InlinePhoneNumberInput.areaCode, '')
  }

  lengthIgnoringLeadingZero(phoneNumber: string) {
    return removeLeadingZero(phoneNumber).length
  }

  onChangeValue(displayedPhoneNumber: string | null) {
    if (displayedPhoneNumber === null) {
      this.localValue = null
      return
    }

    this.debouncedValidateFunction.call(this)

    let unformattedPhoneNumber = this.removeAreaCode(displayedPhoneNumber)
    unformattedPhoneNumber = removeAllWhitespaces(unformattedPhoneNumber)
    if (this.lengthIgnoringLeadingZero(unformattedPhoneNumber) >= 9) {
      unformattedPhoneNumber = removeLeadingZero(unformattedPhoneNumber)
    }
    this.localValue = unformattedPhoneNumber
  }

  @Watch('value')
  onChangePropertyValue() {
    this.onChangeValue(this.value)
  }

  beforeDestroy() {
    window.removeEventListener('mouseup', this.onMouseUp)
  }

  onBlur() {
    if (!this.mouseDownControl && this.editing) {
      this.confirm()
    }
  }

  onFocus() {
    this.editing = true
  }

  onKeyEnter() {
    if (!this.editing) {
      this.beginEdit()
    } else {
      this.confirm()
    }
  }

  onKeyEsc() {
    this.cancel()
  }

  onClickCancel() {
    this.cancel()
  }

  onClickConfirm() {
    this.confirm()
  }

  onClickTextInput() {
    if (!this.editing) {
      this.editing = true
    }
  }

  onMouseDownControl(event: MouseEvent) {
    event.preventDefault()
    this.mouseDownControl = true
    window.addEventListener('mouseup', this.onMouseUp)
  }

  onMouseUp() {
    this.mouseDownControl = false
    window.removeEventListener('mouseup', this.onMouseUp)
  }

  private confirm() {
    this.validate()
    if (this.error || !this.localValue) {
      this.cancel()
    } else {
      this.$emit('input', `${InlinePhoneNumberInput.areaCode}${this.localValue}`)
      this.endEdit()
    }
  }

  private cancel() {
    this.onChangeValue(this.value)
    this.endEdit()
  }

  private beginEdit() {
    this.editing = true
    this.$nextTick(() => {
      this.$refs.textInput.focus()
    })
  }

  private endEdit() {
    this.editing = false
  }

}
