export default {
  de: {
    TimeRangeInput: {
      timeOnly: 'Exakte Uhrzeit',
      timeRange: 'Zeitraum (von/bis)'
    },
    DateInput: {
      language: 'German',
      months: [
        'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli',
        'August', 'September', 'Oktober', 'November', 'Dezember'
      ],
      monthsAbbr: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
      days: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      rtl: false,
      ymd: false,
      yearSuffix: ''
    },
    Calendar: {
      months: [
        'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli',
        'August', 'September', 'Oktober', 'November', 'Dezember'
      ],
      today: 'Heute',
      monthsAbbr: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
      days: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So']
    },
    AddressSuggestionInput: {
      placeholder: 'Strasse, Ort oder Beschreibung'
    },
    InlineTextInput: {
      fieldRequired: 'Bitte Wert angeben'
    },
    InlineNumberInput: {
      invalidNumber: 'Ungültige Zahl',
      onlyInteger: 'Nur Ganzahlen erlaubt'
    },
    InlinePhoneNumberInput: {
      invalidPhoneNumber: 'Ungültige Telefonnummer'
    }
  },
  fr: {
    TimeRangeInput: {
      timeOnly: 'L\'heure exacte',
      timeRange: 'Période (du/au)'
    },
    DateInput: {
      language: 'French',
      months: [
        'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet',
        'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
      ],
      monthsAbbr: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
      days: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      rtl: false,
      ymd: false,
      yearSuffix: ''
    },
    Calendar: {
      months: [
        'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet',
        'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
      ],
      today: 'Aujourd\'hui ',
      monthsAbbr: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
      days: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim']
    },
    AddressSuggestionInput: {
      placeholder: 'Rue, lieu ou description'
    },
    InlineTextInput: {
      fieldRequired: 'Veuillez indiquer la valeur'
    }
  },
  it: {
    TimeRangeInput: {
      timeOnly: 'Exakte Uhrzeit (IT)',
      timeRange: 'Zeitraum (von/bis) (IT)'
    },
    DateInput: {
      language: 'Italian',
      months: [
        'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio',
        'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
      ],
      monthsAbbr: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
      days: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      rtl: false,
      ymd: false,
      yearSuffix: ''
    },
    AddressSuggestionInput: {
      placeholder: 'Strasse, Ort oder Beschreibung (IT)'
    },
    InlineTextInput: {
      fieldRequired: 'Bitte Wert angeben (IT)'
    }
  }
}
