/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_head': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M11.59 11L6 5.41 7.42 4l8 8-8 8-1.41-1.41L11.59 13z"/>'
  }
})
