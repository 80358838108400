export const routerNames = {
  DELIVERIES: 'deliveries',
  DELIVERIES_OVERVIEW: 'deliveries-overview',
  DELIVERIES_OVERVIEW_DELIVERY: 'deliveries-overview-delivery',
  DELIVERIES_ROLE: 'deliveries-role',
  DELIVERIES_ROLE_DELIVERY: 'deliveries-role-delivery',
  DELIVERIES_COURIER: 'deliveries-courier',
  DELIVERIES_COURIER_DELIVERY: 'deliveries-courier-delivery',
  DELIVERIES_DELIVERY: 'deliveries-delivery',
  CREATE_ORDER: 'create-order',
  CUSTOMERS: 'customers',
  CREATE_CUSTOMER: 'create-customer',
  FREE_DELIVERY_ITEMS: 'promotions',
  CREATE_FREE_DELIVYERY_ITEM: 'promotions-create',
  COCKPIT: 'cockpit',
  LOGIN: 'login',
  SETTINGS: 'settings',
  SETTINGS_COMPANY: 'settings-company',
  SETTINGS_COMPANY_INFO: 'settings-company-info',
  SETTINGS_COMPANY_ADS: 'settings-company-ads',
  SETTINGS_DELIVERY: 'settings-delivery',
  SETTINGS_DELIVERY_DISPO: 'settings-delivery-dispo',
  SETTINGS_DELIVERY_AREA: 'settings-delivery-area',
  SETTINGS_DELIVERY_DELIVERY_TIME: 'settings-delivery-deliverytimes',
  SETTINGS_DELIVERY_SUPPLEMENTAL_SERVICE: 'settings-delivery-supplementalservice',
  SETTINGS_DELIVERY_BUSINESS_HOURS: 'settings-company-businesshours',
  SETTINGS_ORDER: 'settings-order',
  SETTINGS_ORDER_HLD: 'settings-order-hld',
  SETTINGS_DELIVERY_DELIVERY_DISPENSE: 'settings-delivery-deliverydispense',
  SETTINGS_DELIVERY_DELIVERY_NOTIFICATIONS: 'settings-delivery-notifications',
  ANALYTICS: 'analytics'
}

export const routerQueries = {
  SELECTED_TRANSPORT_ID: 'transportId'
}

export const routerParameters = {
  DISABLE_ROUTE_TO_LAST_CHILD: 'routeToLastChild'
}
