import {
  OrderDraft,
  OrderDraftCondition,
  DeliveryItem,
  PlaceOrderCommand,
  Shipping,
  ShippingCondition
} from '@/application/deliveries/models/order-draft/types'
import { DueDate } from '@/application/deliveries/models/DueDate'
import { Address } from '@/application/shared/Address'

export const newOrderDraft = (): OrderDraft => {
  return {
    customerId: null,
    pickupConditions: [
      emptyPickupCondition()
    ],
    dropOffConditions: [],
    shippingDate: new Date(),
    price: 5
  }
}

export const changeCustomer = (orderDraft: OrderDraft, customerId: string) => {
  orderDraft.customerId = customerId
}

export const changeShippingDate = (orderDraft: OrderDraft, shippingDate: Date) => {
  orderDraft.shippingDate = shippingDate
}

export const changePrice = (orderDraft: OrderDraft, price: number) => {
  orderDraft.price = price
}

export const addPickup = (orderDraft: OrderDraft) => {
  const dueDateDate = new Date()
  dueDateDate.setHours(dueDateDate.getHours() + 1)
  orderDraft.pickupConditions.push(emptyPickupCondition())
}

const emptyPickupCondition = () => {
  const dueDateDate = new Date()
  dueDateDate.setHours(dueDateDate.getHours() + 1)
  return emptyCondition({date: dueDateDate, dateUntil: null})
}

export const pickupDeliveryItemAt = (orderDraft: OrderDraft, condition: OrderDraftCondition) => {
  condition.deliveryItems.push({
    label: ''
  })
}

export const dropOffDeliveryItem = (
  orderDraft: OrderDraft,
  deliveryItem: DeliveryItem,
  atCondition?: OrderDraftCondition
) => {
  orderDraft.dropOffConditions.forEach((dropOffCondition) => {
    const indexToRemove = dropOffCondition.deliveryItems.findIndex((existingItem) => existingItem === deliveryItem)
    if (indexToRemove > -1) {
      dropOffCondition.deliveryItems.splice(
        indexToRemove,
        1
      )
    }
  })
  if (atCondition) {
    atCondition.deliveryItems.push(deliveryItem)
  } else {
    emptyDropOffCondition(orderDraft, deliveryItem)
  }
}

const emptyDropOffCondition = (orderDraft: OrderDraft, deliveryItem: DeliveryItem): ShippingCondition => {
  const pickupCondition = orderDraft.pickupConditions.find(
    (condition) => condition.deliveryItems.includes(deliveryItem)
  )!
  const latestPickupDate = (pickupCondition.dueDate.dateUntil)
    ? pickupCondition.dueDate.dateUntil
    : pickupCondition.dueDate.date
  const dropOffDate = new Date(latestPickupDate)
  dropOffDate.setHours(dropOffDate.getHours() + 1)
  const dropOffCondition = emptyCondition({date: dropOffDate, dateUntil: null}, [deliveryItem])
  orderDraft.dropOffConditions.push(dropOffCondition)
  return dropOffCondition
}

const emptyCondition = (dueDate: DueDate, deliveryItems: DeliveryItem[] = []): OrderDraftCondition => {
  return {
    dueDate: dueDate,
    address: null,
    deliveryLocation: null,
    deliveryItems: deliveryItems
  }
}

export const changeConditionDueDate = (condition: ShippingCondition, dueDate: DueDate) => {
  condition.dueDate = dueDate
}

export const changeConditionAddress = (condition: ShippingCondition, address: Address) => {
  condition.address = address
  condition.deliveryLocation = null
}

export const changeConditionLocation = (condition: ShippingCondition, name: string) => {
  if (condition.deliveryLocation) {
    condition.deliveryLocation.name = name
  } else {
    condition.deliveryLocation = {
      name: name,
      address: null,
      description: null,
      coordinate: null,
      deliveryDispenses: null
    }
  }
  condition.address = null
}

export const changeConditionLocationAddress = (
  condition: ShippingCondition,
  address: DeliveryAddress,
  coordinate: Coordinate
) => {
  if (!condition.deliveryLocation) {
    throw Error('Can\'t change location address when no location is defined')
  }
  condition.deliveryLocation!.address = address
  condition.deliveryLocation!.coordinate = coordinate
  condition.deliveryLocation!.description = null
}

export const changeConditionLocationDescription = (condition: ShippingCondition, description: string) => {
  if (!condition.deliveryLocation) {
    throw Error('Can\'t change location description when no location is defined')
  }
  condition.deliveryLocation!.description = description
  condition.deliveryLocation!.address = null
  condition.deliveryLocation!.coordinate = null
}

export const changeDeliveryItem = (deliveryItem: DeliveryItem, label: string) => {
  deliveryItem.label = label
}

export const revertDropOffForDeliveryItem = (orderDraft: OrderDraft, deliveryItem: DeliveryItem) => {
  removeDeliveryItemFromConditions(deliveryItem, orderDraft.dropOffConditions)
}

export const removeDeliveryItem = (orderDraft: OrderDraft, deliveryItem: DeliveryItem) => {
  removeDeliveryItemFromConditions(deliveryItem, orderDraft.pickupConditions)
  removeDeliveryItemFromConditions(deliveryItem, orderDraft.dropOffConditions)
}

const removeDeliveryItemFromConditions = (deliveryItem: DeliveryItem, conditions: OrderDraftCondition[]) => {
  conditions.forEach((condition) => {
    const index = condition.deliveryItems.indexOf(deliveryItem)
    if (index > -1) {
      condition.deliveryItems.splice(
        index,
        1
      )
    }
  })
}

export const conditionRemovable = (orderDraft: OrderDraft, condition: ShippingCondition): boolean => {
  return orderDraft.pickupConditions.length > 1 || orderDraft.pickupConditions[0] !== condition
}

export const removeCondition = (orderDraft: OrderDraft, condition: OrderDraftCondition) => {
  if (!conditionRemovable(orderDraft, condition)) {
    throw Error('Can\'t remove shipping condition')
  }
  removePickupCondition(orderDraft, condition)
  removeDropOffCondition(orderDraft, condition)
}

const removePickupCondition = (orderDraft: OrderDraft, condition: OrderDraftCondition) => {
  const index = orderDraft.pickupConditions.indexOf(condition)
  if (index > -1) {
    orderDraft.pickupConditions.splice(index, 1)
  }
}

const removeDropOffCondition = (orderDraft: OrderDraft, condition: OrderDraftCondition) => {
  const index = orderDraft.dropOffConditions.indexOf(condition)
  if (index > -1) {
    orderDraft.dropOffConditions.splice(index, 1)
  }
}

export const readyToShip = (orderDraft: OrderDraft): boolean => {
  if (!orderDraft.customerId) {
    return false
  }
  if (orderDraft.dropOffConditions.length === 0) {
    return false
  }
  if (incompleteConditions(orderDraft.pickupConditions)) {
    return false
  }
  if (incompleteConditions(orderDraft.dropOffConditions)) {
    return false
  }

  return true
}

const incompleteConditions = (conditions: OrderDraftCondition[]): boolean => {
  const incomplete = conditions.filter((condition) => {
    if (condition.address === null &&
      condition.deliveryLocation === null) {
      return true
    }
    if (condition.deliveryItems.length === 0) {
      return true
    }
    for (const deliveryItem of condition.deliveryItems) {
      if (deliveryItem.label === '') {
        return true
      }
    }
    if (condition.deliveryLocation &&
      !condition.deliveryLocation.address &&
      !condition.deliveryLocation.coordinate &&
      !condition.deliveryLocation.description
    ) {
      return true
    }
    return false
  })
  return incomplete.length > 0
}

export const mapToPlaceOrder = (orderDraft: OrderDraft): PlaceOrderCommand => {
  if (!readyToShip(orderDraft)) {
    throw Error('Can\'t map not ready to ship order draft')
  }
  const shippings: Shipping[] = []
  orderDraft.pickupConditions.forEach((pickupCondition) => {
    orderDraft.dropOffConditions.forEach((dropOffCondition) => {
      const deliveryItems = dropOffCondition.deliveryItems.filter(
        (deliveryItem) => pickupCondition.deliveryItems.includes(deliveryItem)
      )
      if (deliveryItems.length > 0) {
        shippings.push({
          pickupCondition: {
            dueDate: mapDueDate(orderDraft.shippingDate, pickupCondition.dueDate),
            deliveryLocation: pickupCondition.deliveryLocation,
            address: pickupCondition.address
          },
          dropOffCondition: {
            dueDate: mapDueDate(orderDraft.shippingDate, dropOffCondition.dueDate),
            deliveryLocation: dropOffCondition.deliveryLocation,
            address: dropOffCondition.address
          },
          deliveryItems: deliveryItems
        })
      }
    })
  })
  return {
    customerId: orderDraft.customerId!,
    price: orderDraft.price,
    shippings: shippings
  }
}

const mapDueDate = (shippingDate: Date, dueDate: DueDate): DueDate => {
  const dateUntil = (dueDate.dateUntil !== null) ? mapDate(shippingDate, dueDate.dateUntil) : null
  return {
    date: mapDate(shippingDate, dueDate.date),
    dateUntil: dateUntil
  }
}

const mapDate = (shippingDate: Date, date: Date): Date => {
  return new Date(
    shippingDate.getFullYear(),
    shippingDate.getMonth(),
    shippingDate.getDate(),
    date.getHours(),
    date.getMinutes()
  )
}
