
import Icon from '@/components/media/Icon.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {Icon}
})
export default class PasswordInput extends Vue {
  $refs!: {
    input: HTMLInputElement
  }

  @Prop({type: String, default: ''}) value!: string

  @Prop({type: String, default: ''}) label!: string

  @Prop({type: String, default: ''}) placeholder!: string

  showPassword = false

  get passwordType() {
    return this.showPassword ? 'text' : 'password'
  }

  get iconName() {
    return this.showPassword ? 'visibility_on' : 'visibility_off'
  }

  onInput(e: any) {
    this.$emit('input', e.target.value)
  }

  onClickShowPassword() {
    this.showPassword = !this.showPassword
    this.$refs.input.focus()
    this.$emit('showPasswordClicked', this.showPassword)
  }
}
