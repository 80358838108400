import { MapCoordinate } from '@/components/maps/types/MapCoordinate'
import { transform } from 'ol/proj'
import proj4 from 'proj4'
import { register as registerProj4 } from 'ol/proj/proj4'

proj4.defs(
  'EPSG:2056',
  '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 ' +
  '+x_0=2600000 +y_0=1200000 +ellps=bessel ' +
  '+towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs'
)
registerProj4(proj4)

export const mapConfig = {
  layer: 'ch.swisstopo.pixelkarte-farbe',
  url: 'https://wmts{5-9}.geo.admin.ch/1.0.0/{Layer}/default/{Time}/2056/{TileMatrix}/{TileCol}/{TileRow}.jpeg',
  extent: [242E4, 103E4, 29E5, 135E4],
  projection: 'EPSG:2056',
  resolutions: [
    4000.0, 3750.0, 3500.0, 3250.0, 3000.0, 2750.0, 2500.0, 2250.0, 2000.0, 1750.0, 1500.0, 1250.0, 1000.0,
    750.0, 650.0, 500.0, 250.0, 100.0,
    50.0, 20.0, 10.0,
    5.0, 2.5, 2.0, 1.5, 1.0, 0.5, 0.25],
  attributions: [
    '<a href="https://www.swisstopo.admin.ch/" target="new">swisstopo</a>'
  ]
}

export const fromWSG84 = (coordinate: MapCoordinate): any => {
  return transform([coordinate.longitude, coordinate.latitude], 'EPSG:4326', 'EPSG:2056')
}
