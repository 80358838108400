
import Vue from 'vue'
import Component from 'vue-class-component'
import customerService from '../../CustomersService'
import PageTitle from '@/components/text/PageTitle.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import ContainedButton from '@/components/buttons/ContainedButton.vue'
import TextButton from '@/components/buttons/TextButton.vue'
import PageHead from '@/components/layout/PageHead.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import Divider from '@/components/layout/Divider.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import SaveDiscardButtons from '@/components/buttons/SaveDiscardButtons.vue'
import { routerParameters } from '@/application/router/router-definitions'
import FormField from '@/components/forms/FormField.vue'
import InlineTextInput from '@/components/forms/InlineTextInput.vue'
import AddressSelectInput from '@/components/forms/address/AddressSelectInput.vue'
import { RetailerAdd } from '@/application/customers/models/RetailerAdd'
import RetailerForm from '@/application/customers/ui/templates/RetailerForm.vue'

@Component({
  components: {
    AddressSelectInput,
    InlineTextInput,
    SaveDiscardButtons,
    VerticalLayout,
    Divider,
    PageDetail,
    ScrollableSection,
    PageHead,
    ContainedButton,
    TextButton,
    DetailSection,
    PageTitle,
    FormField,
    RetailerForm
  }
})
export default class RetailersAdd extends Vue {

  retailer: RetailerAdd = {
    name: '',
    address: null,
    ...this.$store.state.customers.retailerDraft
  } as RetailerAdd

  get disableAddRetailerButton() {
    const hasAddress = this.retailer.address !== null
    const hasName = this.retailer.name !== ''
    return hasAddress && hasName
  }

  onChangeRetailer(retailer: RetailerAdd) {
    customerService.saveRetailerDraft(retailer)
    this.retailer = retailer
  }

  onClickDiscard() {
    customerService.deleteRetailerDraft()
    this.$router
      .push({name: 'retailer-overview', params: {[routerParameters.DISABLE_ROUTE_TO_LAST_CHILD]: 'true'}})
      .catch((err) => {})
  }

  onClickCreate() {
    customerService.createRetailer(this.retailer).then((retailerId: string) => {
      this.$router.push(
        {
          name: 'retailer-edit',
          params: {id: retailerId}
        }
      )
    })
  }

}
