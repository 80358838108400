
import Icon from '@/components/media/Icon.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component({
  components: {
    Icon
  }
})
export default class TextButton extends Vue {

  @Prop({
    type: String, default: 'primary',
    validator: function (value: string) {
      return [
        'primary',
        'accent'
      ].indexOf(value) !== -1
    }
  }) private appearance!: string

  @Prop({type: Boolean, default: false}) private centered!: boolean

  @Prop({type: Boolean, default: false}) private focused!: boolean

  @Prop({type: String, default: null}) private icon!: string | null

  $el!: HTMLElement

  private get buttonClass() {
    return {
      [this.appearance]: true,
      centered: this.centered
    }
  }

  @Watch('focused')
  private onFocusedChanged() {
    this.focusButton()
  }

  mounted() {
    this.focusButton()
  }

  focus() {
    // TODO: for now get it working, must refactor buttons to behave the same way
    this.$el.focus()
  }

  private focusButton() {
    if (this.focused) {
      this.$el.focus()
    }
  }

  private onClick(e: any) {
    this.$emit('click', e)
  }
}
