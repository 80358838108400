import store from '@/application/store'
import eventBus from '@/infrastructure/events/event-bus'
import { ROLES_CHANGED} from '@/application/users/events'
import {
  createRole,
  deleteRole,
  editRole,
  roleDetail,
  rolesCouriers,
  rolesZones} from '@/application/users/users-resource'
import { commits } from '@/application/users/store/mutations'
import { Role } from '@/application/users/models/types'
import { createEventEmittingPromise } from '@/infrastructure/events/create-event-emitting-promise'

class RolesService {

  addRole(role: any): Promise<string> {
    return createRole(role).then((roleId) => {
      store.commit(commits.ROLE_DRAFT, null)
      eventBus.$emit(ROLES_CHANGED)
      return roleId
    })
  }

  saveRoleDraft(draft: any) {
    store.commit(commits.ROLE_DRAFT, draft)
  }

  role(roleId: string, ignoreGlobalErrorHandler?: boolean): Promise<any> {
    return roleDetail(roleId, ignoreGlobalErrorHandler)
  }

  updateRole(roleId: string, role: Role): Promise<Role> {
    return new Promise((resolve) => {
      return editRole(roleId, role).then(() => {
        eventBus.$emit(ROLES_CHANGED)
        return roleDetail(roleId).then((updatedRole: Role) => {
          resolve(updatedRole)
        }
        )
      },
      () => {
        return roleDetail(roleId).then((updatedRole: Role) => {
          resolve(updatedRole)
        })
      })
    }
    )
  }

  deleteRole(roleId: any): Promise<void> {
    return createEventEmittingPromise(
      deleteRole(roleId),
      ROLES_CHANGED
    )
  }

  couriers(): Promise<any[]> {
    return rolesCouriers()
  }

  zones(): Promise<ZoneSummary[]> {
    return rolesZones()
  }
}

export default new RolesService()
