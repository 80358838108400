/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M7.797 4c1.189 0 2.174.765 2.523 1.813h10.695a.71.71 0 01.724.708.71.71 0 01-.724.709H10.32a2.585 2.585 0 01-2.493 1.812c-1.16 0-2.174-.764-2.464-1.812H2.725A.71.71 0 012 6.52c0-.396.29-.708.695-.708h2.638A2.575 2.575 0 017.797 4zm8.725 5.667c1.188 0 2.174.764 2.494 1.813h2a.71.71 0 01.724.708.71.71 0 01-.725.708h-2a2.585 2.585 0 01-2.493 1.813c-1.16 0-2.174-.765-2.463-1.813H2.725A.71.71 0 012 12.188c0-.396.29-.708.725-.708h11.334a2.575 2.575 0 012.463-1.813zM11.275 15.9c1.19 0 2.174.765 2.494 1.813h7.246a.71.71 0 01.725.708.71.71 0 01-.725.709h-7.217a2.585 2.585 0 01-2.493 1.812c-1.16 0-2.174-.764-2.464-1.812H2.725A.71.71 0 012 18.42c0-.396.29-.708.725-.708h6.087a2.575 2.575 0 012.463-1.813zM7.827 5.417c-.61 0-1.132.481-1.13 1.104 0 .623.52 1.105 1.13 1.105.608 0 1.13-.482 1.13-1.105s-.521-1.104-1.13-1.104zm8.724 5.666c-.638 0-1.13.482-1.13 1.105s.52 1.104 1.13 1.104c.608 0 1.13-.481 1.13-1.104 0-.623-.521-1.105-1.13-1.105zm-5.246 6.234c-.61 0-1.102.481-1.13 1.104 0 .623.52 1.105 1.13 1.105.608 0 1.13-.482 1.13-1.105s-.521-1.104-1.13-1.104z"/>'
  }
})
