import axios from 'axios'
import { parser } from '@/infrastructure/json-parser'

export declare type GlobalErrorHandler = (error: any) => void
const globalErrorObservers: GlobalErrorHandler[] = []

export declare type AuthenticationErrorHandler = () => void
let authenticationErrorHandler: AuthenticationErrorHandler | null = null

export function notifyGlobalRequestErrorObsevers(error: any) {
  globalErrorObservers.forEach((observer) => {
    observer(error)
  })
}

export function addGlobalRequestErrorObserver(observer: GlobalErrorHandler) {
  globalErrorObservers.push(observer)
}


export function removeGlobalRequestErrorObserver(observer: GlobalErrorHandler) {
  const index = globalErrorObservers.indexOf(observer)
  if (index > -1) {
    globalErrorObservers.splice(index, 1)
  }
}

export function setAuthenticationErrorHandler(handler: AuthenticationErrorHandler) {
  authenticationErrorHandler = handler
}

const axiosInstance = axios.create({
  baseURL: '/api',
  withCredentials: true,
  transformResponse: (data: any, headers?: any): any => {
    if (typeof data === 'string' && data !== '') {
      return JSON.parse(data, parser)
    }
    return data
  }
})
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers['X-COCKPIT-WEB-VERSION'] = process.env.VUE_APP_COCKPIT_TECHNICAL_VERSION
    return config
  },
  (error) => Promise.reject(error)
)
axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (401 === error.response.status && !error.config.url.endsWith('auth/login')) {
      if (authenticationErrorHandler) {
        authenticationErrorHandler()
      }
    } else if (
      error.config.hasOwnProperty('ignoreGlobalErrorHandler')
      && error.config.ignoreGlobalErrorHandler === true
    ) {
      return Promise.reject(error)
    } else {
      if (error.response) {
        notifyGlobalRequestErrorObsevers(error.response)
      }
    }
  }
)

export default axiosInstance
