/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'location_ready': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M19 13h-4.986C12.086 17.476 8 22 8 22S1 14.25 1 9c0-3.86 3.14-7 7-7s7 3.14 7 7c0 .636-.103 1.309-.283 2H19a1 1 0 010 2zm3-2a1 1 0 110 2 1 1 0 010-2zM8 6a3 3 0 100 6 3 3 0 000-6z"/>'
  }
})
