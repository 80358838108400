

import { Transport, TransportStatus } from '@/application/deliveries/models/transport/types'
import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import TransportStatusView from '@/application/deliveries/ui/components/transport/TransportStatusView.vue'
import PageTitle from '@/components/text/PageTitle.vue'
import { State } from 'vuex-class'
import PageDetail from '@/components/layout/PageDetail.vue'
import PageHead from '@/components/layout/PageHead.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import { DeliveriesState } from '@/application/deliveries/store/types'
import TransportCardSection from '@/application/deliveries/ui/templates/TransportCardSection.vue'
import TransportDropZone from '@/application/deliveries/ui/components/transport/TransportDropZone.vue'
import {
  default as TransportDraggableTargetMixin,
  TransportDraggableTarget
} from '@/application/deliveries/models/transport/TransportDraggableTargetMixin'
import TransferTransport from '@/application/deliveries/ui/types/TransferTransport'
import { disposeTransports } from '@/application/deliveries/services/deliveries-service'

@Component({
  components: {
    VerticalLayout,
    DetailSection,
    ScrollableSection,
    PageHead,
    PageDetail,
    PageTitle,
    TransportStatusView,
    TransportCardSection,
    TransportDropZone
  }
})
export default class DeliveriesRole extends Mixins(TransportDraggableTargetMixin) implements TransportDraggableTarget {

  @State('deliveries') state!: DeliveriesState

  get roleId(): string {
    return this.$route.params.roleId
  }

  get roleName(): string {
    const role = this.state.roles.find((r) => r.assigneeId === this.roleId)
    return (role) ? role.name : ''
  }

  get transportsToDo(): Transport[] {
    return this.state.roleTransports.filter((transport) => transport.status === TransportStatus.TODO)
  }

  get transportsUpcoming(): Transport[] {
    return this.state.roleTransports.filter((transport) => transport.status === TransportStatus.UPCOMING)
  }

  onDroppedTransport(transfer: TransferTransport) {
    disposeTransports(
      transfer.orderNumber,
      {
        assigneeId: this.roleId,
        transportIds: [transfer.transportId]
      }
    )
  }

  redirectAfterDrop(): boolean {
    return false
  }

}
