
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component
export default class DataTable extends Vue {
  @Prop({
    type: Array,
    required: true
  }) private items!: any[]
  @Prop({
    type: String,
    default: 'value'
  }) private identifierKey!: string
  @Prop({
    type: [String, Number],
    default: null
  }) private value!: string | number | null

  private localValue: string | number | null = this.value

  private get showTable(): boolean {
    return this.items.length > 0
  }

  private get headerRows(): any[] {
    const headerRows: any[] = []
    const firstRow = this.$scopedSlots.default!({item: this.items[0]}) as any[]
    firstRow.forEach((columnNode) => {
      if (columnNode.tag) {
        headerRows.push({label: columnNode.data.attrs.label})
      }
    })
    return headerRows
  }

  @Watch('localValue')
  private onLocalValueChanged() {
    this.$emit('input', this.localValue)
  }

  @Watch('value')
  private onValueChanged() {
    this.localValue = this.value
  }

  private rowClass(item: any) {
    return {selected: item[this.identifierKey] === this.value}
  }

  private onClickItem(item: any) {
    this.localValue = item[this.identifierKey]
  }
}
