
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import Icon from '@/components/media/Icon.vue'
import ModalWindow from '@/components/modal/ModalWindow.vue'

@Component({
  components: {
    ModalWindow,
    Icon
  }
})
export default class ModalNotification extends Vue {

  @Prop({
    type: Boolean,
    default: false
  })
    show!: boolean

  @Prop({
    type: Boolean,
    default: true
  })
    showCloseIcon!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
    fullWidth!: boolean

  onClickClose() {
    this.$emit('click-close')
  }

}
