
import { Prop, Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import Icon from '@/components/media/Icon.vue'
import { CalendarDay } from '@/components/forms/types/CalendarDay'
import { CalendarMonth } from '@/components/forms/types/CalendarMonth'
import { CalendarDate } from '@/components/forms/types/CalendarDate'
import { CalendarSheetBuilder } from '@/components/forms/CalendarSheetBuilder'
import { CalendarWeek } from '@/components/forms/types/CalendarWeek'

@Component({
  components: { Icon }
})
export default class Calendar extends Vue {

  @Prop({
    type: Date,
    required: false,
    default: () => new Date(Date.now())
  })
    initialDate!: Date

  month: number = this.initialDate.getMonth()
  year: number = this.initialDate.getFullYear()

  get previousMonth(): CalendarMonth {
    if (this.month === 0) {
      return {
        days: this.daysInMonth(11),
        month: 11,
        year: this.year - 1
      } as CalendarMonth
    } else {
      return {
        days: this.daysInMonth(this.month - 1),
        month: this.month - 1,
        year: this.year
      } as CalendarMonth
    }
  }

  get nextMonth(): CalendarMonth {
    if (this.month === 11) {
      return {
        days: this.daysInMonth(0),
        month: 0,
        year: this.year + 1
      } as CalendarMonth
    } else {
      return {
        days: this.daysInMonth(this.month + 1),
        month: this.month + 1,
        year: this.year
      } as CalendarMonth
    }
  }

  get isLeapYear(): boolean {
    return (this.year % 4 === 0 && this.year % 100 !== 0) || this.year % 400 === 0
  }

  get today(): CalendarDate {
    const currentDate = new Date(Date.now())
    return {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth(),
      day: currentDate.getDate()
    } as CalendarDate
  }

  get firstWeekdayInMonth(): number {
    const weekday = new Date(this.year, this.month, 1).getDay()
    const isSunday = weekday === 0
    if (isSunday) {
      return 7
    } else {
      return weekday
    }
  }

  get monthName() {
    const months = this.$t('Calendar.months')
    if (Array.isArray(months)) {
      return months[this.month]
    }
    throw new Error('invalid translation string for Calendar.months')
  }

  get weekdayNames(): string[] {
    const days = this.$t('Calendar.days')
    if (Array.isArray(days)) {
      return days
    }
    throw new Error('invalid translation string for Calendar.days')
  }

  get weeks(): CalendarWeek[] {
    const calendarSheet = new CalendarSheetBuilder()
    this.addDaysInPreviousMonth(calendarSheet)
    this.addDaysInCurrentMonth(calendarSheet)
    this.addDaysInNextMonth(calendarSheet)
    return calendarSheet.build()
  }

  onMoveToday() {
    this.month = this.today.month
    this.year = this.today.year
    const date = new Date(
      Date.UTC(
        this.today.year,
        this.today.month,
        this.today.day
      )
    )
    this.$emit('selectToday', date)
  }

  onMoveNextMonth() {
    const { month, year } = this.nextMonth
    this.month = month
    this.year = year
  }

  onMovePreviousMonth() {
    const { month, year } = this.previousMonth
    this.month = month
    this.year = year
  }

  onMoveNextYear() {
    this.year++
  }

  onMovePreviousYear() {
    this.year--
  }

  private daysInMonth(month: number): number {
    if (month === 1 && this.isLeapYear) {
      return 29
    }
    const daysInMonths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    return daysInMonths[month]
  }

  private addDaysInPreviousMonth(calendarSheet: CalendarSheetBuilder) {
    let dayOfMonth = this.previousMonth.days - this.firstWeekdayInMonth + 2
    for (let dayIndex = 1; dayIndex < this.firstWeekdayInMonth; dayIndex++) {
      calendarSheet.addCalendarDay(
        this.buildCalendarDay(
          dayOfMonth,
          this.previousMonth.month,
          this.previousMonth.year
        )
      )
      dayOfMonth++
    }
  }

  private addDaysInCurrentMonth(calendarSheet: CalendarSheetBuilder) {
    for (let dayOfMonth = 1; dayOfMonth <= this.daysInMonth(this.month); dayOfMonth++) {
      calendarSheet.addCalendarDay(
        this.buildCalendarDay(dayOfMonth, this.month, this.year)
      )
    }
  }

  private addDaysInNextMonth(calendarSheet: CalendarSheetBuilder) {
    const daysToGenerate = 42 - calendarSheet.daysCount()
    for (let dayOfMonth = 1; dayOfMonth <= daysToGenerate; dayOfMonth++) {
      calendarSheet.addCalendarDay(
        this.buildCalendarDay(
          dayOfMonth,
          this.nextMonth.month,
          this.nextMonth.year
        )
      )
    }
  }

  private buildCalendarDay(
    dayOfMonth: number,
    month: number,
    year: number
  ): CalendarDay {
    const dayInfo = {
      dayOfMonth: dayOfMonth,
      month: month,
      year: year,
      date: new Date(Date.UTC(year, month, dayOfMonth)),
      inMonth: month === this.month && year === this.year,
      isToday:
        dayOfMonth === this.today.day &&
        month === this.today.month &&
        year === this.today.year,
      isSelected: false,
      dragActive: false,
      isDragged: false,
      isInRange: false,
      isFirstInRange: false,
      isLastInRange: false
    } as CalendarDay
    this.$emit('configureDay', dayInfo)
    return dayInfo
  }
}

