import { OrderSettings, OrderTypeHLD } from '@/application/settings/models/types'
import {
  orderSettingsOverview,
  orderTypeHLD,
  updateOrderTypeHLD
} from '@/application/settings/resources/settings-resource'
import { ORDER_SETTINGS_CHANGED } from '@/application/settings/events'
import { createEventEmittingPromise } from '@/infrastructure/events/create-event-emitting-promise'


class OrderSettingsService {

  orderSettingsOverview(): Promise<OrderSettings> {
    return orderSettingsOverview()
  }

  orderTypeHLD(): Promise<OrderTypeHLD> {
    return orderTypeHLD()
  }

  updateOrderTypeHLD(orderType: OrderTypeHLD): Promise<void> {
    return createEventEmittingPromise(
      updateOrderTypeHLD(orderType),
      ORDER_SETTINGS_CHANGED
    )
  }
}

export default new OrderSettingsService()
