/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'power': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" d="M13 4h-2v10h2V4zm3.56 2.44l-1.45 1.45A5.969 5.969 0 0118 13c0 3.31-2.69 6-6 6s-6-2.69-6-6c0-2.17 1.16-4.06 2.88-5.12L7.44 6.44A7.961 7.961 0 004 13c0 4.42 3.58 8 8 8s8-3.58 8-8c0-2.72-1.36-5.12-3.44-6.56z"/>'
  }
})
