import {
  DeliveryAreaLinks,
  DeliveryDispenseSettings,
  DeliveryNotificationSettings,
  DeliverySettings,
  DeliveryTime,
  DeliveryWindow,
  DispoSettings,
  SupplementalService
} from '@/application/settings/models/types'
import {
  createDeliveryWindow,
  createSupplementalService,
  deliveryAreaLinks,
  deliveryDispense,
  deliveryNotificationSettings,
  deliverySettingsOverview,
  deliveryTime,
  deliveryWindows,
  dispo,
  removeDeliveryWindow,
  removeSupplementalService,
  supplementalServices,
  updateDeliveryDispense, updateDeliveryNotificationSettings,
  updateDeliveryTime,
  updateDeliveryWindow,
  updateDispoAddress,
  updateDispoStatus,
  updateSupplementalService
} from '@/application/settings/resources/settings-resource'
import { AddressInput } from '@/components/forms/address/types/AddressInput'
import { DELIVERY_SETTINGS_CHANGED } from '@/application/settings/events'
import { createEventEmittingPromise } from '@/infrastructure/events/create-event-emitting-promise'


class DeliverySettingsService {

  deliverySettingsOverview(): Promise<DeliverySettings> {
    return deliverySettingsOverview()
  }

  dispo(): Promise<DispoSettings> {
    return dispo()
  }

  enableDispo(): Promise<void> {
    return this.updateDispoStatus(true)
  }

  disableDispo(): Promise<void> {
    return this.updateDispoStatus(false)
  }

  private updateDispoStatus(dispoEnabled: boolean): Promise<void> {
    return createEventEmittingPromise(
      updateDispoStatus({enabled: dispoEnabled}),
      DELIVERY_SETTINGS_CHANGED
    )
  }

  updateDispoAddress(addressInput: AddressInput): Promise<void> {
    return createEventEmittingPromise(
      updateDispoAddress({
        name: 'Dispo',
        ...addressInput
      }),
      DELIVERY_SETTINGS_CHANGED
    )
  }

  deliveryTime(): Promise<DeliveryTime> {
    return deliveryTime()
  }

  updateDeliveryTime(time: DeliveryTime): Promise<void> {
    return createEventEmittingPromise(
      updateDeliveryTime(time),
      DELIVERY_SETTINGS_CHANGED
    )
  }

  deliveryDispense(): Promise<DeliveryDispenseSettings> {
    return deliveryDispense()
  }

  deliveryNotificationSettings(): Promise<DeliveryNotificationSettings> {
    return deliveryNotificationSettings()
  }

  updateDeliveryNotificationSettings(deliveryNotificationSettings: DeliveryNotificationSettings): Promise<void> {
    return createEventEmittingPromise(
      updateDeliveryNotificationSettings(deliveryNotificationSettings),
      DELIVERY_SETTINGS_CHANGED
    )
  }

  updateDeliveryDispense(deliveryDispenseData: DeliveryDispenseSettings): Promise<void> {
    return createEventEmittingPromise(
      updateDeliveryDispense(deliveryDispenseData),
      DELIVERY_SETTINGS_CHANGED
    )
  }

  deliveryWindows(): Promise<DeliveryWindow[]> {
    return deliveryWindows()
  }

  createDeliveryWindow(deliveryWindow: any) {
    return createEventEmittingPromise(
      createDeliveryWindow(deliveryWindow),
      DELIVERY_SETTINGS_CHANGED
    )
  }

  updateDeliveryWindow(deliveryWindow: DeliveryWindow) {
    return createEventEmittingPromise(
      updateDeliveryWindow(deliveryWindow.deliveryWindowId, deliveryWindow),
      DELIVERY_SETTINGS_CHANGED
    )
  }

  removeDeliveryWindow(deliveryWindowId: string): Promise<void> {
    return createEventEmittingPromise(
      removeDeliveryWindow(deliveryWindowId),
      DELIVERY_SETTINGS_CHANGED
    )
  }

  supplementalServices(): Promise<SupplementalService[]> {
    return supplementalServices()
  }

  deliveryAreaLinks(): Promise<DeliveryAreaLinks> {
    return deliveryAreaLinks()
  }

  removeSupplementalService(supplementalServiceId: string): Promise<void> {
    return createEventEmittingPromise(
      removeSupplementalService(supplementalServiceId),
      DELIVERY_SETTINGS_CHANGED
    )
  }

  createSupplementalService(supplementalService: any) {
    return createEventEmittingPromise(
      createSupplementalService(supplementalService),
      DELIVERY_SETTINGS_CHANGED
    )
  }

  updateSupplementalService(supplementalService: SupplementalService) {
    return createEventEmittingPromise(
      updateSupplementalService(supplementalService.supplementalServiceId, supplementalService),
      DELIVERY_SETTINGS_CHANGED
    )
  }
}

export default new DeliverySettingsService()
