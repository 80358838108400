
import { Transport } from '@/application/deliveries/models/transport/types'
import { pickupDone } from '@/application/deliveries/models/transport/index'
import { Prop, Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import Icon from '@/components/media/Icon.vue'
import DueDateTimeView from './DueDateTimeView.vue'
import { routerNames, routerQueries } from '@/application/router/router-definitions'
import {DeliveryLocation} from '@/application/deliveries/models/DeliveryLocation'
import DueDateDateView from '@/application/deliveries/ui/components/transport/DueDateDateView.vue'

@Component({
  components: {
    DueDateDateView,
    Icon,
    DueDateTimeView
  }
})
export default class TransportStatusView extends Vue {
  @Prop({type: Object, required: true}) transport!: Transport

  get statusIcon(): string {
    return pickupDone(this.transport) ? 'biker' : 'location_ready'
  }

  get iconClass(): any {
    return {'success-fill': pickupDone(this.transport)}
  }

  concatAddressCity(deliveryAddress: DeliveryAddress)  {
    return `${deliveryAddress.street}, ${deliveryAddress.city}`
  }

  titleAddressCity(deliveryAddress: DeliveryAddress)  {
    return `${deliveryAddress.street}` + '\n' + `${deliveryAddress.city}`
  }

  get pickupLocation(): DeliveryLocation {
    return this.transport.pickup.location
  }

  get dropOffLocation(): DeliveryLocation {
    return this.transport.dropOff.location
  }

  onTransportClick() {
    switch (this.$route.name) {
    case routerNames.DELIVERIES_COURIER:
      this.$router.push({
        name: routerNames.DELIVERIES_COURIER_DELIVERY,
        params: {courierId: this.$route.params.courierId, orderNumber: this.transport.orderNumber},
        query: {[routerQueries.SELECTED_TRANSPORT_ID]: this.transport.transportId}
      }).catch((err) => {})
      break
    case routerNames.DELIVERIES_ROLE:
      this.$router.push({
        name: routerNames.DELIVERIES_ROLE_DELIVERY,
        params: {roleId: this.$route.params.roleId, orderNumber: this.transport.orderNumber},
        query: {[routerQueries.SELECTED_TRANSPORT_ID]: this.transport.transportId}
      }).catch((err) => {})
      break
    default:
      this.$router.push({
        name: routerNames.DELIVERIES_DELIVERY,
        params: {orderNumber: this.transport.orderNumber},
        query: {[routerQueries.SELECTED_TRANSPORT_ID]: this.transport.transportId}
      }).catch((err) => {})
    }
  }
}
