import {
  assignees,
  delivery,
  deliverySummaries,
  dispose,
  transportForCourier,
  transportForRole,
  unplanned,
  missing,
  updateDelivery,
  cancelDeliveryItem,
  repopulateConfiguration,
  removeDelivery
} from '@/application/deliveries/resources/deliveries-resource'
import websocket, { statusObserverType, WebsocketStatus } from '@/infrastructure/websocket'
import store from '@/application/store'
import { DisposeTransportRequest } from '@/application/deliveries/resources/DisposeTransportRequest'
import { UpdateDeliveryRequest } from '@/application/deliveries/resources/UpdateDeliveryRequest'
import { commits } from '@/application/deliveries/store/mutations'
import { DeliveryOverviewSummary } from '@/application/deliveries/models/DeliveryOverviewSummary'

export const MESSAGE_UPDATE = 'UPDATED'

export declare type LoadDeliveriesOverviewCallback = (deliverySummaries: DeliveryOverviewSummary[]) => void

interface DeliveryOverviewCallback {
  date: Date
  callback: LoadDeliveriesOverviewCallback
}

let deliveryOverviewCallback: DeliveryOverviewCallback | null = null
let additionalRefresh: (() => Promise<void>) | null = null

const refreshStateCallback = async (message: string) => {
  if (message === MESSAGE_UPDATE) {
    await refreshState()
    loadDeliveriesOverview()
  }
}

export const loadDeliveriesState = (statusChangeCallback?: statusObserverType) => {
  if (statusChangeCallback) {
    websocket.addStatusChangeObserver(statusChangeCallback)
  }
  websocket.addStatusChangeObserver(onWebsocketConnectedObserver)
  websocket.subscribe('/topic/deliveries', refreshStateCallback)
}

export const stopLoadDeliveriesState = (statusChangeCallback?: statusObserverType) => {
  websocket.unsubscribe(refreshStateCallback)
  websocket.removeStatusChangeObserver(onWebsocketConnectedObserver)
  if (statusChangeCallback) {
    websocket.removeStatusChangeObserver(statusChangeCallback)
  }
}

const onWebsocketConnectedObserver = (status: WebsocketStatus) => {
  if (status === WebsocketStatus.CONNECTED) {
    refreshState()
  }
}

export const selectCourier = (assigneeId: string): Promise<void> => {
  store.commit(commits.ROLE_TRANSPORTS, [])
  store.commit(commits.DELIVERY, null)
  additionalRefresh = () => {
    return loadCourier(assigneeId)
  }
  return additionalRefresh()
}

export const selectRole = (assigneeId: string): Promise<void> => {
  store.commit(commits.COURIER_TRANSPORTS, [])
  store.commit(commits.DELIVERY, null)
  additionalRefresh = () => {
    return loadRole(assigneeId)
  }
  return additionalRefresh()
}

export const selectDelivery = (orderNumber: string): Promise<void> => {
  store.commit(commits.ROLE_TRANSPORTS, [])
  store.commit(commits.COURIER_TRANSPORTS, [])
  additionalRefresh = () => {
    return loadDelivery(orderNumber)
  }
  return additionalRefresh()
}

export const unselect = () => {
  additionalRefresh = null
  store.commit(commits.COURIER_TRANSPORTS, [])
  store.commit(commits.ROLE_TRANSPORTS, [])
  store.commit(commits.DELIVERY, null)
}

export const disposeTransports = (orderNumber: string, action: DisposeTransportRequest): Promise<void> => {
  return dispose(orderNumber, action)
}

export const edit = (orderNumber: string, deliveryUpdate: UpdateDeliveryRequest): Promise<void> => {
  return updateDelivery(orderNumber, deliveryUpdate)
}

export const remove = (orderNumber: string): Promise<void> => {
  return removeDelivery(orderNumber)
}

export const setDeliveryItemCanceled = (deliveryItemId: string): Promise<void> => {
  return cancelDeliveryItem(deliveryItemId)
}

const refreshState = (): Promise<void> => {
  const promises = [
    loadAssignees(),
    loadUnplanned(),
    loadMissing(),
    loadRepopulateConfiguration()
  ]
  if (additionalRefresh !== null) {
    promises.push(additionalRefresh())
  }
  return Promise.all(promises).then(() => {
    return
  })
}

const loadAssignees = (): Promise<void> => {
  return assignees().then((assigneesResponse) => {
    store.commit(commits.COURIERS, assigneesResponse.couriers)
    store.commit(commits.ROLES, assigneesResponse.roles)
  })
}

const loadUnplanned = (): Promise<void> => {
  return unplanned().then((deliveries) => {
    store.commit(commits.UNPLANNED_DELIVERIES, deliveries)
  })
}

const loadMissing = (): Promise<void> => {
  return missing().then((deliveries) => {
    store.commit(commits.MISSING_DELIVERIES, deliveries)
  })
}

const loadRepopulateConfiguration = (): Promise<void> => {
  return repopulateConfiguration().then((configuration) => {
    store.commit(commits.REPOPULATE_CONFIGURATION, configuration)
  })
}

const loadRole = (assigneeId: string): Promise<void> => {
  return transportForRole(assigneeId).then((transports) => {
    store.commit(commits.ROLE_TRANSPORTS, transports)
  })
}

const loadDelivery = (orderNumber: string): Promise<void> => {
  return delivery(orderNumber).then((receivedDelivery) => {
    store.commit(commits.DELIVERY, receivedDelivery)
  })
}

const loadCourier = (assigneeId: string): Promise<void> => {
  return transportForCourier(assigneeId).then((transports) => {
    store.commit(commits.COURIER_TRANSPORTS, transports)
  })
}

export const loadDeliveriesOverview = () => {
  if (!deliveryOverviewCallback) {
    return
  }

  deliverySummaries(deliveryOverviewCallback.date).then((deliveryOverviewResponse) => {
    if (deliveryOverviewCallback) {
      deliveryOverviewCallback.callback(deliveryOverviewResponse.deliveries)
    }
  })
}

export const attachDeliveriesChangedCallback = (date: Date, callback: LoadDeliveriesOverviewCallback) => {
  deliveryOverviewCallback = {
    date: date,
    callback: callback
  }
  loadDeliveriesOverview()
}

export const removeDeliveriesChangedCallback = () => {
  deliveryOverviewCallback = null
}
