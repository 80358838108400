/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'due_time_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21 5.446l-4.14-3.453-1.16 1.369 4.14 3.452L21 5.446zM8.292 3.362L7.14 1.993 3 5.437l1.161 1.368 4.131-3.443zM12 3.907c-4.473 0-8.1 3.605-8.1 8.05s3.618 8.05 8.1 8.05c4.473 0 8.1-3.605 8.1-8.05s-3.627-8.05-8.1-8.05zm3.44 4.343L17 9.75v.296l-5.973 5.704h-.31L7 12.2v-.295l1.57-1.49h.31l1.991 1.902 4.26-4.067h.309z" _fill="#132C45"/>'
  }
})
