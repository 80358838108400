import { AdInfos, BusinessHours, CompanyInfos, CompanySettings } from '@/application/settings/models/types'
import {
  businessHours,
  companySettingsOverview,
  updateBusinessHours,
  companyInfos,
  updateCompanyInfos,
  adInfos,
  updateAdInfos,
  deleteAdInfos
} from '@/application/settings/resources/settings-resource'
import { COMPANY_SETTINGS_CHANGED} from '@/application/settings/events'
import { createEventEmittingPromise } from '@/infrastructure/events/create-event-emitting-promise'

class CompanySettingsService {

  companySettingsOverview(): Promise<CompanySettings> {
    return companySettingsOverview()
  }

  companyInfos(): Promise<CompanyInfos> {
    return companyInfos()
  }

  adInfos(): Promise<AdInfos> {
    return adInfos()
  }

  updateAdInfos(data: FormData): Promise<void> {
    return updateAdInfos(data)
  }

  deleteAdInfos() {
    return deleteAdInfos()
  }

  updateCompanyInfos(data: any) {
    return createEventEmittingPromise(
      updateCompanyInfos(data),
      COMPANY_SETTINGS_CHANGED
    )
  }

  businessHours(): Promise<BusinessHours[]> {
    return businessHours()
  }

  updateBusinessHours(data: any) {
    return createEventEmittingPromise(
      updateBusinessHours(data),
      COMPANY_SETTINGS_CHANGED
    )
  }
}

export default new CompanySettingsService()
