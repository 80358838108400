/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_3': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.285 3.833c.953 0 1.714.758 1.715 1.708v14.752C18 21.24 17.238 22 16.285 22h-8.57A1.704 1.704 0 016 20.293V5.54c0-.949.762-1.708 1.715-1.708h2.031V2.358c0-.19.148-.358.36-.358h3.788c.19 0 .36.147.36.358v1.475h2.031zm.106 16.459h-.021V5.54c0-.064-.043-.105-.106-.105H7.693c-.064 0-.106.042-.106.105v14.752c0 .063.042.105.106.105h8.592c.064 0 .106-.042.106-.105zM13.117 8.196c.06-.002.119.027.156.079.037.052.048.12.03.181l-.871 3.06h1.824c.07 0 .135.042.167.109a.212.212 0 01-.014.208l-3.494 5.126a.182.182 0 01-.243.066.207.207 0 01-.09-.248l.956-3.05H9.722a.188.188 0 01-.167-.11.212.212 0 01.014-.207l3.4-5.126a.187.187 0 01.148-.088z" _fill="#132C45"/>'
  }
})
