/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'order_api': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.056 7.104a.5.5 0 01.092-.148l4.62-4.805a.503.503 0 01.111-.085.612.612 0 01.16-.064.552.552 0 01.102 0h10.33a.54.54 0 01.529.551v7.014a4.074 4.074 0 00-1.692.25v-5.91H9.883v3.862a.51.51 0 01-.14.354.472.472 0 01-.34.147H5.691v11.816h3.884c.028.33.097.655.204.965l-.353.353c-.179.178-.33.38-.45.596H4.53a.517.517 0 01-.374-.16.565.565 0 01-.155-.39V7.343a.548.548 0 01.056-.24zm2.486-.303h1.962V4.756L6.542 6.8z" _fill="#132C45"/><path pid="1" d="M10.945 16.546l.008.106-.2.202c-.431.43-.755.947-.956 1.51h-2.26v-1.819h3.408zM13.74 13.2a2.695 2.695 0 00-.033.505l-.074-.001a2.693 2.693 0 00-2.313 1.314H7.537V13.2h6.203zM16.379 10.937a2.693 2.693 0 00-2.052.972h-6.79v-1.818h8.842v.846z" _fill="#132C45"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M23.575 10.686c.084.035.16.086.224.15l-.003.002a.692.692 0 01.002.978l-1.473 1.473.147.146a2.073 2.073 0 010 2.932l-1.18 1.18.2.2a.693.693 0 01-.979.98l-4.607-4.608a.693.693 0 01.979-.979l.202.203 1.18-1.179a2.076 2.076 0 012.932 0l.147.147 1.474-1.474a.692.692 0 01.755-.15zm-3.263 5.882l1.183-1.181.001.005a.691.691 0 000-.98l-1.27-1.27a.692.692 0 00-.98 0l-1.18 1.18 2.246 2.246zM17.836 19.62l.663-.662a.692.692 0 10-.979-.98l-.663.664-.864-.864.663-.663a.692.692 0 00-.979-.98l-.663.664-.894-.893a.692.692 0 10-.979.98l.202.2-1.178 1.18a2.073 2.073 0 000 2.933l.147.146-1.474 1.473a.692.692 0 10.98.98l1.472-1.474.147.147a2.077 2.077 0 002.932 0l1.179-1.18.202.203a.692.692 0 10.979-.98l-.893-.893zm-2.67 2.023a.69.69 0 00.224-.15l1.179-1.181-2.247-2.246-1.18 1.177a.692.692 0 000 .979l1.269 1.27a.69.69 0 00.755.15z" _fill="#132C45"/>'
  }
})
