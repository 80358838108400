

import Icon from '@/components/media/Icon.vue'
import BadgeContainer from '@/components/badge/BadgeContainer.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  components: {
    BadgeContainer,
    Icon
  }
})
export default class ListNavigationItem extends Vue {
  @Prop({
    type: String,
    required: true
  }) private icon!: string

  @Prop({
    type: String,
    default: null
  }) private badge!: string | null

  get badgeContainerClass() {
    return {'badge-circle': this.badge}
  }
}
