import axios from '@/infrastructure/vld-webservice'
import { CustomerSummary } from '@/application/customers/models/CustomerSummary'
import { Customer } from '@/application/customers/models/Customer'
import { Address } from '@/application/shared/Address'
import { CustomerAdd } from '@/application/customers/models/CustomerAdd'
import { RetailerSummary } from '@/application/customers/models/RetailerSummary'
import { RetailerAdd } from '@/application/customers/models/RetailerAdd'
import { Retailer } from '@/application/customers/models/Retailer'
import { IndividualPricingSetting } from '@/application/customers/models/IndividualPricingSetting'

export const customers = (): Promise<CustomerSummary[]> => {
  return axios.get('/cockpit/customers').then((response) => response.data.customers)
}

export const customerDetail = (customerId: string): Promise<Customer> => {
  return axios.get(`/cockpit/customers/${customerId}`).then((response) => response.data)
}

export const editCustomer = (customerId: string, data: Customer): Promise<void> => {
  return axios.put(`/cockpit/customers/${customerId}`, data).then((response) => response.data)
}

export const createCustomer = (data: CustomerAdd): Promise<string> => {
  return axios.post('/cockpit/customers', data).then((response) => response.data.customerId)
}

// TODO
export const addresses = (): Promise<Address[]> => {
  return axios.get('/cockpit/customers/addresses').then((response) => response.data.data)
}

export const retailers = (): Promise<RetailerSummary[]> => {
  return axios.get('/cockpit/customers/retailers').then((response) => response.data.retailers)
}

export const createRetailer = (data: RetailerAdd): Promise<string> => {
  return axios.post('/cockpit/customers/retailers', data).then((response) => response.data.retailerId)
}

export const retailerDetail = (retailerId: string): Promise<Retailer> => {
  return axios.get(`/cockpit/customers/retailers/${retailerId}`).then((response) => response.data)
}

export const editRetailer = (retailerId: string, data: Retailer): Promise<void> => {
  return axios.put(`/cockpit/customers/retailers/${retailerId}`, data).then((response) => response.data)
}

export const editIndividualPricing = (
  retailerId: string,
  individualPricingSettings: IndividualPricingSetting
): Promise<void> => {
  return axios.put(
    `/cockpit/customers/retailers/${retailerId}/individualpricing`,
    individualPricingSettings
  ).then((response) => response.data)
}
