import { MutationTree } from 'vuex'
import { UsersState } from './types'
import localStorage, {authenticatedUserSummaryKey, roleDraftKey, userDraftKey} from '@/infrastructure/local-storage'
import { UserSummary } from '@/application/users/models/types'

export enum commits {
  USER_DRAFT = 'users/userDraft',
  AUTHENTICATED_USER_SUMMARY = 'users/authenticatedUserSummary',
  ROLE_DRAFT = 'users/roleDraft',
  CLEAR = 'users/clear'
}

export const mutations: MutationTree<UsersState> = {

  userDraft(state, draft) {
    if (draft) {
      localStorage.setObjectForKey(userDraftKey, draft)
      state.userDraft = Object.assign({}, draft)
    } else {
      localStorage.setObjectForKey(userDraftKey, null)
      state.userDraft = null
    }
  },

  roleDraft(state, draft: any | null) {
    if (draft) {
      localStorage.setObjectForKey(roleDraftKey, draft)
      state.roleDraft = Object.assign({}, draft)
    } else {
      localStorage.setObjectForKey(roleDraftKey, null)
      state.roleDraft = null
    }
  },

  clear(state) {
    localStorage.setObjectForKey(userDraftKey, null)
    localStorage.setObjectForKey(roleDraftKey, null)
    state.userDraft = null
    state.roleDraft = null
  },

  authenticatedUserSummary(state, userSummary: UserSummary ) {
    localStorage.setObjectForKey(authenticatedUserSummaryKey, userSummary)
    state.authenticatedUserSummary = userSummary
  }
}

