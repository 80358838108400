/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_6': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11 14.458v2.752h2.708l7.987-8.116-2.708-2.751L11 14.458zm12.789-7.492L22.467 8.31 19.76 5.557l1.322-1.342a.711.711 0 011.018 0l1.69 1.717a.74.74 0 010 1.034zM1.06 12.241L0 11.178c.133-.168.315-.355.533-.578.151-.156.848-.778 1.648-.778.527 0 1.52.43 1.52 1.778 0 .846-.315 1.33-.787 2.04-.273.41-.909 1.51-1.121 2.188-.218.677-.055 1.193.218 1.193.26 0 .52-.335.672-.522.14-.15 1.036-1.237 1.387-1.69.46-.579 1.63-1.766 2.993-1.766 1.78 0 2.35 1.585 2.44 2.61H11v1.555H9.51C9.268 20.173 7.656 21 6.67 21c-1.073 0-1.945-.864-1.945-1.92 0-1.058.969-2.941 3.258-3.34-.066-.484-.078-1.112-1.066-1.112-.757 0-1.738 1.212-2.471 2.139-.672.851-1.205 1.529-1.853 1.721a1.777 1.777 0 01-1.6-.285c-.52-.398-.811-1.057-.811-1.853 0-1.505 1.58-3.662 1.732-3.923.182-.304.485-.82.182-.945-.188-.08-.612.317-1.036.759zm5.627 7.248c.424 0 1.109-.49 1.29-2.163-1.296.348-1.738 1.343-1.738 1.716 0 .286.26.447.448.447z" _fill="#132C45"/>'
  }
})
