/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_14_not_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20.903 13.308a6.002 6.002 0 00-7.498 3.52 2.067 2.067 0 00-1.887-.179 2.005 2.005 0 00-1.132 2.62 2.04 2.04 0 001.9 1.26 2.073 2.073 0 00.864-.188 5.96 5.96 0 00.623 1.607l-.071.03c-.455.18-.93.272-1.416.272a3.77 3.77 0 01-3.51-2.335 3.705 3.705 0 011.11-4.281L5.809 5.686l-4.235-.218a.863.863 0 01-.823-.902.862.862 0 01.912-.814l4.788.244a.87.87 0 01.76.535l4.238 10.343c.261-.058.526-.09.792-.092l-2.173-5.298a.853.853 0 01.006-.66.863.863 0 01.476-.462l6.56-2.612a.873.873 0 011.13.475l2.651 6.467a.85.85 0 01.012.616zm-8.897-3.672l4.949-1.97 1.998 4.873-4.948 1.97-2-4.873zm4.748-.407a.357.357 0 01-.203.467l-2.625 1.047a.369.369 0 01-.481-.199.356.356 0 01.201-.467l2.626-1.047a.372.372 0 01.482.2z" _fill="#132C45"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19 14.417a4.583 4.583 0 100 9.167 4.583 4.583 0 000-9.167zm.793 2.998a.281.281 0 01.396 0l.396.396a.281.281 0 010 .396l-.793.793.793.793a.281.281 0 010 .396l-.396.396a.281.281 0 01-.396 0L19 19.792l-.793.793a.281.281 0 01-.396 0l-.396-.396a.281.281 0 010-.396l.793-.793-.793-.793a.281.281 0 010-.396l.396-.396a.281.281 0 01.396 0l.793.793.793-.793z" _fill="#F24949"/>'
  }
})
