import { DateRange } from '@/components/forms/types/DateRange'

export class DefaultDateRange implements DateRange {

  from: Date
  to: Date

  constructor(from: Date, to: Date) {
    this.from = from
    this.to = to
  }

  normalizedRange(): DateRange {
    const isNormal = this.from < this.to
    const from = isNormal ? this.from : this.to
    const to = isNormal ? this.to : this.from
    return new DefaultDateRange(from, to)
  }

  notSameDates(): boolean {
    return this.from.getTime() !== this.to.getTime()
  }

  firstInRange(time: number): boolean {
    return time === this.from.getTime()
  }

  lastInRange(time: number): boolean {
    return time === this.to.getTime()
  }

  isTimeInRange(time: number): boolean {
    return this.from.getTime() <= time && time <= this.to.getTime()
  }

}
