/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkmark_bold': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M18.88 5L22 8.001v.591L10.053 20h-.619L2 12.901v-.591l3.141-2.979h.62l3.982 3.802L18.261 5z"/>'
  }
})
