import { Module } from 'vuex'
import { mutations } from '@/application/customers/store/mutations'
import { getters } from '@/application/customers/store/getters'
import localStorage, { customerDraftKey, retailerDraftKey } from '@/infrastructure/local-storage'
import { CustomersState } from '@/application/customers/store/types'


export const state: CustomersState = {
  customerDraft: localStorage.objectForKey(customerDraftKey),
  retailerDraft: localStorage.objectForKey(retailerDraftKey)
}

export const customers: Module<CustomersState, any> = {
  namespaced: true,
  state,
  mutations,
  getters
}
