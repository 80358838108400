
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import Icon from '@/components/media/Icon.vue'
import ItemList from '@/components/menus/ItemList.vue'
import VNode from '@/components/forms/VNode.vue'
import SelectElement from '@/components/forms/SelectElement.vue'
import { formatDate } from '@/utils/formatter'
import { DateRange } from '@/components/forms/types/DateRange'
import CalendarPicker from '@/components/forms/CalendarPicker.vue'

@Component({
  components: {
    CalendarPicker,
    SelectElement,
    ItemList,
    Icon,
    VNode
  }
})
export default class DateInput extends Vue {

  $refs!: {
    datePicker: CalendarPicker
  }

  @Prop({
    type: Date,
    required: true
  })
    value!: Date

  @Prop({
    type: Object,
    default: null
  })
    dateRange!: DateRange | null

  @Prop({
    type: Date,
    default: null
  })
    openDate!: Date | null

  showDatePicker = false

  get date(): string {
    return formatDate(this.value)
  }

  onClickDateInput(event: any) {
    if (!this.showDatePicker) {
      this.openDatePicker()
    } else {
      this.closeDatePicker()
    }
    event.stopPropagation()
  }

  onSelectDate(date: Date) {
    this.$emit('input', date)
    this.closeDatePicker()
  }

  openDatePicker() {
    document.body.addEventListener('click', this.onClickOutside)
    this.showDatePicker = true
  }

  closeDatePicker() {
    document.body.removeEventListener('click', this.onClickOutside)
    this.showDatePicker = false
  }

  onClickOutside(event: MouseEvent) {
    const clickOnSelectElement =
      (this.$refs.datePicker.$el === event.target
        || this.$refs.datePicker.$el.contains(event.target as Node))
    if (!clickOnSelectElement) {
      this.closeDatePicker()
    }
  }


}
