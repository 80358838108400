export interface OrderedSupplementalService {
  name: string
  iconIndex: number,
  status: OrderedSupplementalServiceStatus
}

export enum OrderedSupplementalServiceStatus {
  INITIAL = 'INITIAL',
  DONE = 'DONE',
  NOT_DONE = 'NOT_DONE'
}
