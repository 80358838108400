

import Vue from 'vue'
import Component from 'vue-class-component'
import Icon from '@/components/media/Icon.vue'
import { Prop } from 'vue-property-decorator'
import { TransportStatus, TransportSummary } from '@/application/deliveries/models/DeliveryOverviewSummary'

@Component({
  components: {
    Icon
  }
})
export default class TransportProgressBar extends Vue {

  @Prop({
    type: Object,
    required: true
  }) transportSummary!: TransportSummary

  get getPickupBarStyleClasses() {
    if (!this.isPickedUp) {
      return 'todo'
    } else if (this.hasMissingLegs) {
      return 'missing'
    } else {
      return 'done'
    }
  }

  get getDropOffBarStyleClasses() {
    if (!this.isFulfilled) {
      return 'todo'
    } else if (this.hasMissingLegs) {
      return 'missing'
    } else {
      return 'done'
    }
  }

  get getPickupLocationStyleClasses() {
    return {
      done: this.isPickedUp && !this.hasMissingLegs,
      missing: this.isPickedUp && this.hasMissingLegs
    }
  }

  get getDropOffLocationStyleClasses() {
    return {
      done: this.isFulfilled && !this.hasMissingLegs,
      missing: this.isFulfilled && this.hasMissingLegs
    }
  }

  get isPickedUp(): boolean {
    return this.transportSummary.status !== TransportStatus.TODO
  }

  get isFulfilled(): boolean {
    return this.transportSummary.status === TransportStatus.FULFILLED
  }

  get hasMissingLegs(): boolean {
    return this.transportSummary.hasMissingLegs
  }

}

