/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_2': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.577 13.023c1.092.736 1.574 1.403 1.377 2.01-.148.46-.63.696-1.405.733l-2.794 5.554-.016.022c-.244.326-.78.598-1.68.598-.655 0-1.444-.155-2.383-.49-2.306-.827-3.142-1.854-3.154-2.696l.002-.055.004-.026 1.003-6.141c-.593-.528-.838-1.023-.69-1.482.197-.607.967-.825 2.259-.707 1.091.1 2.466.433 3.87.937 1.407.504 2.687 1.123 3.607 1.743zm-1.374 2.837c-.973-.103-2.176-.4-3.406-.841-1.23-.442-2.355-.98-3.183-1.521l-.839 5.074c.08.463.704 1.102 2.329 1.684 1.584.567 2.442.482 2.8.177l2.3-4.573zm1.421-1.302c.017-.052-.257-.398-.913-.839-.77-.519-1.857-1.042-3.057-1.472-1.201-.43-2.366-.713-3.277-.797a5.192 5.192 0 00-.492-.025c-.461 0-.708.084-.713.1-.017.05.259.398.913.838.77.52 1.857 1.043 3.057 1.473 1.201.43 2.366.713 3.276.796.773.072 1.19-.026 1.206-.074zM.025 8.948c-.151-.62.38-1.245 1.523-1.89.962-.542 2.284-1.053 3.726-1.44C6.71 5.23 8.105 5.01 9.2 5c1.296-.012 2.048.27 2.199.89.114.469-.166.942-.796 1.42l.548 6.26-.002.028c-.075.835-.984 1.79-3.345 2.426-.782.21-1.458.308-2.035.308-1.121 0-1.746-.341-2.016-.761l-.03-.06-2.352-5.718c-.77-.1-1.232-.375-1.347-.845zm10.051-2.702c-.012-.049-.402-.18-1.144-.18h-.065c-.923.01-2.114.198-3.352.531-1.24.334-2.372.771-3.183 1.229-.692.39-.994.715-.98.768.071.296 2.157.293 4.56-.353 2.404-.647 4.235-1.698 4.164-1.995zM4.63 14.767c.307.344 1.17.52 2.844.07 1.105-.298 1.808-.681 2.197-1.088.165-.173.246-.319.265-.354l-.46-5.225c-.873.476-2.042.922-3.313 1.264-1.268.342-2.498.54-3.485.564l1.952 4.769z" _fill="#132C45"/>'
  }
})
