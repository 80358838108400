/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'minus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M13.429 11H2v2.857h20V11z"/>'
  }
})
