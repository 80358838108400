
import axios from '@/infrastructure/vld-webservice'
import Vue from 'vue'
import Component from 'vue-class-component'
import { applicationService } from '@/application/ApplicationService'
import ContainedButton from '@/components/buttons/ContainedButton.vue'
import ListNavigation from '@/components/navigation/ListNavigation.vue'
import ListNavigationItem from '@/components/navigation/ListNavigationItem.vue'
import NavigationItem from '@/components/navigation/NavigationItem.vue'
import Icon from '@/components/media/Icon.vue'
import TextButton from '@/components/buttons/TextButton.vue'
import SlotButton from '@/components/buttons/SlotButton.vue'
import { routerNames } from '@/application/router/router-definitions'
import { State } from 'vuex-class'
import { DeliveriesState } from '@/application/deliveries/store/types'

@Component({
  components: {
    SlotButton,
    TextButton,
    ContainedButton,
    ListNavigation,
    ListNavigationItem,
    NavigationItem,
    Icon
  }
})
export default class MainNavigation extends Vue {

  @State('deliveries') state!: DeliveriesState

  versionName(): string {
    return process.env.VUE_APP_COCKPIT_VERSION as string
  }

  technicalVersion(): string {
    return `Version: ${process.env.VUE_APP_COCKPIT_TECHNICAL_VERSION as string}`
  }

  get authenticatedUserName(): string {
    const fullName = this.$store.state.users.authenticatedUserSummary?.fullName
    return this.$t('navigation.username', [fullName]) as string
  }

  get createOrderBadge(): string | null {
    return (this.state.shippingOrderDraft) ? '1' : null
  }

  get deliveriesBadge(): string | null {
    const unplannedDeliveries = this.state.unplanned.length + this.state.missing.length
    if (unplannedDeliveries > 0) {
      return String(unplannedDeliveries)
    } else {
      return null
    }
  }

  get isAdmin(): boolean {
    const authenticatedUserSummary = this.$store.state.users.authenticatedUserSummary
    return authenticatedUserSummary && authenticatedUserSummary.isCockpitAdmin
  }

  get repopulateEnabled(): boolean {
    return this.state.repopulateConfiguration.enabled
  }

  createOrderLink() {
    return {name: routerNames.CREATE_ORDER}
  }

  deliveriesLink() {
    return {name: routerNames.DELIVERIES}
  }

  analyticsLink() {
    return {name: routerNames.ANALYTICS}
  }

  deliveriesOverviewLink() {
    return {name: routerNames.DELIVERIES_OVERVIEW}
  }

  onClickLogout() {
    applicationService.logout()
  }

  onClickedRepopulate() {
    return axios.get('/cockpit/deliveries/assignments/repopulate').then((response) => {
      const message = this.$tc('global.dataReseted')
      alert(message)
    })
  }
}
