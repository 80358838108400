/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" d="M20 10.857h-6.857V4h-2.286v6.857H4v2.286h6.857V20h2.286v-6.857H20z"/>'
  }
})
