

import { Vue, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import Icon from '@/components/media/Icon.vue'
import { TransportSegment } from '@/application/deliveries/models/TransportSegment'
import { formatTime, formatDueDate, formatDueDateTitle } from '@/utils/formatter'
import { isSameDay } from '@/utils/date-util'

@Component({
  components: {
    Icon
  }
})
export default class DueDateTimeView extends Vue {
  @Prop({type: Object, required: true}) transportSegment!: TransportSegment

  get date() {
    if (this.isDone) {
      return this.confirmationDate()
    } else {
      return this.dueDate()
    }
  }

  private confirmationDate() {
    return this.$d(this.transportSegment.confirmationDate!, 'short', 'de')
  }

  private dueDate() {
    const dueDateDate = this.transportSegment.dueDate.date
    const dueDateUntil = this.transportSegment.dueDate.dateUntil
    if (dueDateUntil !== null && !isSameDay(dueDateDate, dueDateUntil)) {
      return this.$d(dueDateDate, 'short', 'de') + ' / ' + this.$d(dueDateUntil, 'short', 'de')
    } else {
      return this.$d(dueDateDate, 'short', 'de')
    }
  }

  get initialDate() {
    return formatDueDateTitle(this.transportSegment.dueDate, this.transportSegment.confirmationDate)
  }

  get icon(): string {
    return this.isDone ? 'due_date_done' : 'due_date'
  }

  get isDone(): boolean {
    return this.transportSegment.confirmationDate !== null
  }

  get iconClass(): any {
    return (this.isDone) ? 'success-fill' : 'primary-fill'
  }

}
