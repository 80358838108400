/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trash': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M9.544 2c-.858 0-1.57.706-1.57 1.556v.666H3.638a.678.678 0 00-.465.22.661.661 0 00.049.943c.133.118.307.18.486.17h14.35l-.435 14.869c-.004.158-.089.243-.224.243H6.627c-.136 0-.22-.085-.224-.243L6.01 7.097a.66.66 0 00-.21-.466.676.676 0 00-1.137.5l.393 13.327c.025.827.712 1.542 1.571 1.542H17.4c.859 0 1.546-.715 1.57-1.542l.435-14.902h.912a.676.676 0 00.482-.192.66.66 0 000-.95.676.676 0 00-.482-.192h-4.264v-.666c0-.85-.713-1.556-1.571-1.556H9.544zm0 1.333h4.938c.136 0 .224.088.224.223v.666H9.32v-.666c0-.135.089-.223.224-.223zm-.68 4.431a.669.669 0 00-.666.68v9.334a.674.674 0 001.153.477.663.663 0 00.193-.477V8.445a.663.663 0 00-.194-.483.676.676 0 00-.486-.198zm3.142 0a.669.669 0 00-.666.68v9.334a.674.674 0 001.153.477.663.663 0 00.193-.477V8.445a.663.663 0 00-.194-.483.676.676 0 00-.486-.198zm3.142 0a.669.669 0 00-.666.68v9.334a.674.674 0 001.153.477.663.663 0 00.193-.477V8.445a.663.663 0 00-.194-.483.676.676 0 00-.486-.198z"/>'
  }
})
