/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'deliveryobject': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M20 13h-2v6.4a1.6 1.6 0 01-1.6 1.6H1.6A1.6 1.6 0 010 19.4V4.6A1.6 1.6 0 011.6 3h14.8A1.6 1.6 0 0118 4.6V11h2a1 1 0 010 2zm-4-8H2v14h14V5zm7 6a1 1 0 110 2 1 1 0 010-2z"/>'
  }
})
