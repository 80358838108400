import { render, staticRenderFns } from "./ListNavigationItem.vue?vue&type=template&id=1b3dda0e&scoped=true&"
import script from "./ListNavigationItem.vue?vue&type=script&lang=ts&"
export * from "./ListNavigationItem.vue?vue&type=script&lang=ts&"
import style0 from "./ListNavigationItem.vue?vue&type=style&index=0&id=1b3dda0e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b3dda0e",
  null
  
)

export default component.exports