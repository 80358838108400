
import Vue from 'vue'
import Component from 'vue-class-component'
import { Transport } from '@/application/deliveries/models/transport/types'
import { Prop } from 'vue-property-decorator'
import Icon from '@/components/media/Icon.vue'
import { State } from 'vuex-class'
import DeliveryItemView from './DeliveryItemView.vue'
import DueDateTimeView from './DueDateTimeView.vue'
import { DeliveriesState } from '@/application/deliveries/store/types'
import SelectInput from '@/components/forms/SelectInput.vue'
import { DeliveryLocation } from '@/application/deliveries/models/DeliveryLocation'
import { DeliveryItem } from '@/application/deliveries/models/DeliveryItem'
import ContainedButton from '@/components/buttons/ContainedButton.vue'
import { setDeliveryItemCanceled } from '@/application/deliveries/services/deliveries-service'
import TransportCard from '@/application/deliveries/ui/components/transport/TransportCard.vue'
import { routerNames, routerQueries } from '@/application/router/router-definitions'
import DueDateDateView from '@/application/deliveries/ui/components/transport/DueDateDateView.vue'

@Component({
  components: {
    DueDateDateView,
    TransportCard,
    ContainedButton,
    SelectInput,
    DueDateTimeView,
    DeliveryItemView,
    Icon
  }
})
export default class TransportMissingView extends Vue {

  @Prop({
    type: Object,
    required: true
  })
    transport!: Transport

  @Prop({
    type: Object,
    required: true
  })
    deliveryItemPickup!: DeliveryItem

  @Prop({
    type: Object,
    required: true
  })
    deliveryItemDropOff!: DeliveryItem


  @State('deliveries')
    state!: DeliveriesState

  concatAddressCity(deliveryAddress: DeliveryAddress)  {
    return `${deliveryAddress.street}, ${deliveryAddress.city}`
  }

  titleAddressCity(deliveryAddress: DeliveryAddress)  {
    return `${deliveryAddress.street}` + '\n' + `${deliveryAddress.city}`
  }

  get pickupLocation(): DeliveryLocation {
    return this.transport.pickup.location
  }

  get dropOffLocation(): DeliveryLocation {
    return this.transport.dropOff.location
  }

  onClickTransport(transport: Transport) {
    this.$router.push({
      name: routerNames.DELIVERIES_DELIVERY,
      params: {orderNumber: transport.orderNumber},
      query: {[routerQueries.SELECTED_TRANSPORT_ID]: transport.transportId}
    }).catch((err) => {})
  }

  onClickCancelItem() {
    setDeliveryItemCanceled(this.deliveryItemPickup.deliveryItemId)
  }
}
