/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_9_not_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.973 3.556c0-.85.713-1.556 1.571-1.556h4.938c.858 0 1.571.706 1.571 1.556v.666h4.264a.676.676 0 01.482.192.66.66 0 010 .95.676.676 0 01-.482.192h-.912l-.217 7.447a6.178 6.178 0 00-1.35.11l.22-7.557H3.71a.676.676 0 01-.708-.632.661.661 0 01.173-.482.678.678 0 01.535-.22h4.264v-.666zm7.855 10.35a6.03 6.03 0 00-1.346 1.146V8.445a.668.668 0 01.666-.681.676.676 0 01.486.198.663.663 0 01.194.483v5.46zm-2.593 6.76c.135.471.327.918.568 1.334H6.627c-.86 0-1.546-.715-1.571-1.542L4.663 7.132a.673.673 0 01.656-.684.676.676 0 01.481.183.66.66 0 01.21.466l.393 13.327c.004.158.088.243.224.243h6.608zm1.247-17.333H9.544c-.135 0-.224.088-.224.223v.666h5.386v-.666c0-.135-.088-.223-.224-.223zM8.387 7.966a.676.676 0 01.963-.004.663.663 0 01.194.483v9.333a.674.674 0 01-.673.676.674.674 0 01-.673-.676V8.445a.669.669 0 01.19-.479zm3.142 0a.676.676 0 01.963-.004.663.663 0 01.194.483v9.333a.674.674 0 01-1.153.478.663.663 0 01-.193-.478V8.445a.668.668 0 01.19-.479z" _fill="#132C45"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19 14.417a4.583 4.583 0 100 9.167 4.583 4.583 0 000-9.167zm.793 2.998a.281.281 0 01.396 0l.396.396a.281.281 0 010 .396l-.793.793.793.793a.281.281 0 010 .396l-.396.396a.281.281 0 01-.396 0L19 19.792l-.793.793a.281.281 0 01-.396 0l-.396-.396a.281.281 0 010-.396l.793-.793-.793-.793a.281.281 0 010-.396l.396-.396a.281.281 0 01.396 0l.793.793.793-.793z" _fill="#F24949"/>'
  }
})
