
import Vue from 'vue'
import Component from 'vue-class-component'
import PageTitle from '@/components/text/PageTitle.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import PageHead from '@/components/layout/PageHead.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import Card from '@/components/layout/Card.vue'
import SwitcherInput from '@/components/forms/SwitcherInput.vue'
import TextInput from '@/components/forms/TextInput.vue'
import Icon from '@/components/media/Icon.vue'
import {DeliveryNotificationSettings} from '@/application/settings/models/types'
import deliverySettingsService from '@/application/settings/DeliverySettingsService'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'

@Component({
  components: {
    VerticalLayout,
    Icon,
    TextInput,
    SwitcherInput,
    Card,
    ScrollableSection,
    PageDetail,
    PageHead,
    DetailSection,
    PageTitle
  }
})
export default class DeliveryNotificationView extends Vue {

  deliveryNotificationSettings: DeliveryNotificationSettings | null = null

  mounted() {
    this.loadDeliveryNotificationSettings()
  }

  loadDeliveryNotificationSettings() {
    deliverySettingsService.deliveryNotificationSettings().then((response) => {
      this.deliveryNotificationSettings = response
    })
  }

  onChange(deliveryNotificationSettings: any) {
    const updatedDeliveryNotificationSettings: DeliveryNotificationSettings =
        Object.assign({}, this.deliveryNotificationSettings, deliveryNotificationSettings)

    deliverySettingsService.updateDeliveryNotificationSettings(updatedDeliveryNotificationSettings).then(() => {
      this.loadDeliveryNotificationSettings()
    }).catch(() => {
      this.loadDeliveryNotificationSettings()
    })
  }

}

