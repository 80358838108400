
import { Transport } from '@/application/deliveries/models/transport/types'
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import Icon from '@/components/media/Icon.vue'
import { routerQueries } from '@/application/router/router-definitions'

@Component({
  components: {
    Icon
  }
})
export default class TransportCard extends Vue {

  @Prop({
    type: Object,
    required: true
  })
    transport!: Transport

  @Prop({
    type: Boolean,
    default: true
  })
    draggable!: boolean

  @Prop({
    type: String,
    default: null,
    required: false
  })
    flagIconName!: string

  get styleClasses() {
    return (this.selected) ? 'selected' : null
  }

  get selected(): boolean {
    if (this.$route.query[routerQueries.SELECTED_TRANSPORT_ID] !== undefined) {
      return this.$route.query[routerQueries.SELECTED_TRANSPORT_ID] === this.transport.transportId
    }
    return false
  }

  onDragStart(event: DragEvent) {
    this.$emit('dragstart', event, this.transport)
  }

  onClick(event: any) {
    event.stopPropagation()
    this.$emit('click', this.transport)
  }
}
