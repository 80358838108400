import de from './de'
import fr from './fr'
import it from './it'
import formsLocales from '@/components/forms/locales'

export default {
  de: {
    ...formsLocales.de,
    ...de
  },
  fr: {
    ...formsLocales.fr,
    ...fr
  },
  it: {
    ...formsLocales.it,
    ...it
  }
}
