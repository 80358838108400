class CookieJar {

  hasCookieWithName(name: string): boolean {
    return this.read(name) !== null
  }

  read(name: string): string | null {
    if (typeof document === 'undefined') {
      return null
    }

    return document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.startsWith(`${name}=`)
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(name.length + 1))
      })[0] || null
  }
}

export const cookieJar = new CookieJar()
