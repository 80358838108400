

import Vue from 'vue'
import Component from 'vue-class-component'
import Icon from '@/components/media/Icon.vue'
import { Prop } from 'vue-property-decorator'

@Component({
  name: 'DeliveriesStatusCount',
  components: {
    Icon
  }
})
export default class DeliveriesStatusCount extends Vue {

  @Prop({
    type: String,
    required: true
  }) title!: string

  @Prop({
    type: String,
    required: true
  }) iconName!: string

  @Prop({
    type: Number,
    required: true
  }) count!: number

}

