export const toIsoDate = (date: Date): string => {
  return date.toISOString().split('T')[0]
}

export const isSameDay = (thisDate: Date, thatDate: Date): boolean => {
  return thisDate.getUTCDay() === thatDate.getUTCDay() &&
    thisDate.getUTCMonth() === thatDate.getUTCMonth() &&
    thisDate.getUTCFullYear() === thatDate.getUTCFullYear()
}

export const startOfCurrentMonth = (): Date => {
  const now = new Date(Date.now())
  return new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1))
}

export const endOfCurrentMonth = (): Date => {
  const now = new Date(Date.now())
  return new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, 0))
}
