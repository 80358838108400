
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import Icon from '@/components/media/Icon.vue'

@Component({
  components: {Icon}
})
export default class TextInput extends Vue {

  $refs!: {
    input: HTMLElement
  }

  @Prop({type: String, default: 'text'}) type!: string

  @Prop({type: String, default: ''}) value!: string

  @Prop({type: String, default: ''}) prefix!: string

  @Prop({type: String, default: ''}) placeholder!: string

  @Prop({type: String, default: null}) error!: string | null

  @Prop({type: Boolean, default: false}) readonly!: boolean

  @Prop({type: Number, default: 255}) maxLength!: number

  @Prop({type: Number, default: 1}) lines!: number

  get forwardedListeners() {
    const clonedListeners = Object.assign({}, this.$listeners)
    delete clonedListeners.input
    return clonedListeners
  }

  get isMultiline(): boolean {
    return this.lines > 1
  }

  get textInputClasses(): any {
    return {
      'error': this.error,
      'style-multiline': this.isMultiline
    }
  }

  onInput(e: any) {
    this.$emit('input', e.target.value)
  }

  focus() {
    this.$refs.input.focus()
  }

  blur() {
    this.$refs.input.blur()
  }
}
