import { TransportSegment } from 'src/application/deliveries/models/TransportSegment'
import { OrderedSupplementalService} from '@/application/deliveries/models/OrderedSupplementalService'

export interface Transport {
  transportId: string
  orderNumber: string
  pickup: TransportSegment
  dropOff: TransportSegment
  assigneeId: string | null
  status: TransportStatus,
  derivedRoleIds: string[],
  orderedSupplementalServices: OrderedSupplementalService[],
  autoDispose: boolean
}

export enum TransportStatus {
  TODO = 'TODO',
  IN_PROGRESS = 'IN_PROGRESS',
  UPCOMING = 'UPCOMING'
}

