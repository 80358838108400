
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import TransferTransport from '@/application/deliveries/ui/types/TransferTransport'

@Component
export default class AssigneeCard extends Vue {
  @Prop({type: String, required: true}) private assigneeId!: string
  @Prop({type: Boolean, default: false}) private selected!: boolean

  private transportOver = false

  private get styleClasses() {
    return {
      'active-drop': this.transportOver,
      'selected': this.selected
    }
  }

  private onDragLeave(e: DragEvent) {
    e.preventDefault()
    this.transportOver = false
  }

  private onDragOver(e: DragEvent) {
    e.preventDefault()
    this.transportOver = true
  }

  private onDrop(e: DragEvent) {
    try {
      const transfer = JSON.parse(e.dataTransfer!.getData('text/plain')) as TransferTransport
      this.$emit('dropTransport', transfer, this.assigneeId)
    } finally {
      this.transportOver = false
      e.preventDefault()
    }
  }

  private onClick() {
    this.$emit('click', this.assigneeId)
  }
}
