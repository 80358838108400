
import Vue from 'vue'
import Component from 'vue-class-component'
import PageTitle from '@/components/text/PageTitle.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import PageHead from '@/components/layout/PageHead.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import companySettingsService from '@/application/settings/CompanySettingsService'
import { AdInfos } from '@/application/settings/models/types'
import FormField from '@/components/forms/FormField.vue'
import TextButton from '@/components/buttons/TextButton.vue'
import Card from '@/components/layout/Card.vue'
import Icon from '@/components/media/Icon.vue'
import ModalConfirmation from '@/components/modal/ModalConfirmation.vue'
import Divider from '@/components/layout/Divider.vue'
import ContainedButton from '@/components/buttons/ContainedButton.vue'

@Component({
  components: {
    ContainedButton,
    VerticalLayout,
    ScrollableSection,
    PageDetail,
    PageHead,
    DetailSection,
    PageTitle,
    FormField,
    TextButton,
    Card,
    Icon,
    ModalConfirmation,
    Divider
  }
})
export default class AdInfosView extends Vue {

  $refs!: {
    fileInput: HTMLInputElement
  }

  showDeleteConfirmation = false

  selectedFile: File | null = null

  uploading = false

  adInfos: AdInfos = {
    url: null
  }

  get displayImage(): boolean {
    const infos = this.adInfos
    if (!infos) {
      return false
    }
    return infos.url !== null
        && !this.uploading
  }

  get displayNoImageHint(): boolean {
    return !this.adInfos.url && !this.uploading
  }

  mounted() {
    this.loadAdInfos()
  }

  loadAdInfos() {
    companySettingsService.adInfos().then((response) => {
      this.adInfos = response
    })
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0]
  }

  onUploadImageClicked() {
    const file = this.selectedFile
    if (!file) {
      return
    }

    this.uploading = true
    const data = new FormData()
    data.append('file', file)
    companySettingsService
      .updateAdInfos(data)
      .then(() => {
        this.selectedFile = null
        this.loadAdInfos()
      }).finally(() => {
        this.uploading = false
        this.selectedFile = null
      }
      )
  }

  onSelectImageClicked() {
    this.$refs.fileInput.click()
  }

  onClickDelete() {
    this.showDeleteConfirmation = true
  }

  onClickConfirmationDeleteDiscard() {
    this.showDeleteConfirmation = false
  }

  onClickConfirmationDeleteSave() {
    companySettingsService
      .deleteAdInfos()
      .finally(() => {
        this.loadAdInfos()
      })
    this.showDeleteConfirmation = false
  }

}
