export default class TransferTransport {
  transportId: string
  orderNumber: string
  redirect: boolean

  constructor(transportId: string, orderNumber: string, redirect: boolean) {
    this.transportId = transportId
    this.orderNumber = orderNumber
    this.redirect = redirect
  }
}
