

import Vue from 'vue'
import ContainedButton from '@/components/buttons/ContainedButton.vue'
import PasswordInput from '@/components/forms/PasswordInput.vue'
import Icon from '@/components/media/Icon.vue'
import { applicationService } from '@/application/ApplicationService'
import { randomNumber } from '@/utils/randomizer'
import Component from 'vue-class-component'
import FormField from '@/components/forms/FormField.vue'
import TextInput from '@/components/forms/TextInput.vue'
import usersService from '@/application/users/UsersService'
import {UserSummary} from '@/application/users/models/types'
import {commits} from '@/application/users/store/mutations'

@Component({
  components: {
    TextInput,
    FormField,
    PasswordInput,
    ContainedButton,
    Icon
  }
})
export default class LoginForm extends Vue {

  $refs!: {
    email: TextInput
  }

  email = ''
  password = ''
  errorKey = ''
  imgIndex: number = randomNumber(1, 2)
  imgName = 'normal'

  mounted() {
    const timeoutParameter = 'timeout'
    if (this.$route.params && this.$route.params[timeoutParameter]) {
      this.imgName = 'error'
      this.errorKey = 'timeout'
    }
    this.$refs.email.focus()
  }

  get buttonDisabled() {
    return this.email === '' || this.password === ''
  }

  get notChrome(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase()
    const isEdge = userAgent.indexOf('edg') > -1
    const isOpera = userAgent.indexOf('opr') > -1
    if (isEdge || isOpera) {
      return true
    }
    const isChrome = /Google Inc/.test(window.navigator.vendor)
    return !isChrome
  }

  get error() {
    return this.errorKey ? this.$t(`error.${this.errorKey}`) : null
  }

  get imagePath(): string {
    return `/images/login_${this.imgName}_${this.imgIndex}.svg`
  }

  onClickLogin() {
    if (!this.buttonDisabled) {
      applicationService.login(this.email, this.password)
        .then(
          () => {
            this.fetchUserSummary()
          })
        .catch((error) => {
          this.imgName = 'error'
          this.errorKey = error.errorKey
        })
    }
  }

  fetchUserSummary() {
    usersService
      .authenticatedUserSummary()
      .then((authenticatedUserSummary: UserSummary) => {
        this.$store.commit(commits.AUTHENTICATED_USER_SUMMARY, authenticatedUserSummary)
        this.$router.push('cockpit')
      })
      .catch(() => {
        this.imgName = 'error'
        this.errorKey = 'noAuthenticatedUserSummary'
      })
  }

  downloadChrome() {
    window.open('https://www.google.com/chrome/', '_blank')
  }

  onShowPasswordClicked(showPassword: boolean) {
    this.imgName = (showPassword) ? 'closed_eyes' : 'normal'
  }
}
