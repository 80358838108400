
import Vue from 'vue'
import Component from 'vue-class-component'
import PageTitle from '@/components/text/PageTitle.vue'
import PageHead from '@/components/layout/PageHead.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import Divider from '@/components/layout/Divider.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import SwitcherInput from '@/components/forms/SwitcherInput.vue'
import orderSettingsService from '@/application/settings/OrderSettingsService'
import { OrderTypeHLD } from '@/application/settings/models/types'
import InlineNumberInput from '@/components/forms/InlineNumberInput.vue'

@Component({
  components: {
    SwitcherInput,
    VerticalLayout,
    Divider,
    ScrollableSection,
    PageDetail,
    PageHead,
    PageTitle,
    InlineNumberInput
  }
})
export default class HLDSettingsView extends Vue {

  orderType: OrderTypeHLD = {
    pricePerDelivery: 0,
    deliveryItemsPerDelivery: 0
  }

  mounted() {
    this.loadOrderTypeHLD()
  }

  onChangeSettings(value: any) {
    const updatedOrderType = Object.assign({}, this.orderType, value)
    orderSettingsService.updateOrderTypeHLD(updatedOrderType).then(() => {
      this.loadOrderTypeHLD()
    }).catch(() => {
      this.loadOrderTypeHLD()
    }
    )
  }

  loadOrderTypeHLD() {
    orderSettingsService.orderTypeHLD().then((response) => {
      this.orderType = response
    })
  }

}
