/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sort': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M9.844 6h11.229c.394 0 .714.324.714.722 0 .399-.32.722-.714.722H9.844a.718.718 0 01-.714-.722c0-.398.32-.722.714-.722zm5.99 8.264c0 .4-.32.722-.714.722H9.844a.718.718 0 01-.714-.722c0-.399.32-.722.714-.722h5.276c.395 0 .715.323.715.722zm3.572-3.769c0 .399-.32.722-.714.722H9.844a.718.718 0 01-.714-.722c0-.4.32-.722.714-.722h8.848c.394 0 .714.322.714.722zm-9.562 6.82h2.895c.395 0 .715.323.715.722 0 .399-.32.722-.715.722H9.844a.718.718 0 01-.714-.722c0-.399.32-.722.714-.722zM4.744 6c.395 0 .715.324.715.722l-.001 9.565.829-.838a.709.709 0 01.992.018c.272.275.28.718.018 1.002L5.249 18.54a.707.707 0 01-1.01 0l-2.048-2.07a.728.728 0 01.018-1.003.709.709 0 01.993-.018l.828.846V6.722c0-.398.32-.722.715-.722z"/>'
  }
})
