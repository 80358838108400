/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'visibility_off': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M12 7.579c2.51 0 4.545 2.004 4.545 4.474 0 .581-.118 1.127-.327 1.637l2.655 2.613a10.582 10.582 0 003.118-4.25c-1.573-3.928-5.455-6.71-10-6.71-1.273 0-2.491.223-3.618.625l1.963 1.933A4.474 4.474 0 0112 7.58zM2.91 5.136l2.072 2.04.418.412A10.578 10.578 0 002 12.053c1.573 3.928 5.455 6.71 10 6.71 1.41 0 2.755-.268 3.982-.751l.382.375L19.027 21l1.155-1.136L4.064 4 2.909 5.136zm5.026 4.948l1.41 1.387a2.486 2.486 0 00-.073.582c0 1.485 1.218 2.684 2.727 2.684.2 0 .4-.027.59-.072L14 16.052a4.565 4.565 0 01-2 .474c-2.51 0-4.545-2.004-4.545-4.473 0-.707.181-1.37.481-1.969zm3.919-.698l2.863 2.819.018-.143c0-1.486-1.218-2.685-2.727-2.685l-.154.01z"/>'
  }
})
