

import Vue from 'vue'
import Component from 'vue-class-component'
import UserOverview from '@/application/users/templates/UserOverview.vue'
import RolesOverview from '@/application/users/templates/RolesOverview.vue'
import PageLayout from '@/components/layout/PageLayout.vue'
import TabNavigation from '@/components/navigation/TabNavigation.vue'
import TabNavigationContent from '@/components/navigation/TabNavigationContent.vue'
import PageHead from '@/components/layout/PageHead.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import { featureToggles, featureTogglesConfiguration } from '@/infrastructure/featuretoggles/FeatureToggles'


@Component({
  components: {
    ScrollableSection,
    PageDetail,
    PageHead,
    UserOverview,
    PageLayout,
    TabNavigation,
    TabNavigationContent,
    RolesOverview
  }
})
export default class Customers extends Vue {
  get showPromotions() {
    return featureToggles.isEnabled(featureTogglesConfiguration.promotions)
  }

  get showDetail() {
    return this.$route.name !== 'customers'
  }

}
