

import Vue from 'vue'
import { Watch } from 'vue-property-decorator'
import TabNavigationContent from '@/components/navigation/TabNavigationContent.vue'
import Component from 'vue-class-component'

@Component
export default class TabNavigation extends Vue {

  private contentComponents: any [] = []

  private get activeRoutePath() {
    return this.$route.fullPath
  }

  mounted() {
    this.loadTabs()
  }

  @Watch('activeRoutePath')
  private onChangeActiveRoutePath() {
    for (const contentComponent of this.contentComponents) {
      contentComponent.active = this.isActiveTab(contentComponent)
    }
  }

  private tabTitle(contentComponent: TabNavigationContent) {
    return contentComponent.name
  }

  private loadTabs() {
    const contentComponents: any[] = []
    this.$slots.default!.forEach((element) => {
      if (element.tag) {
        const contentComponent = element.componentInstance as TabNavigationContent
        // @ts-ignore
        contentComponent.active = this.isActiveTab(contentComponent)
        contentComponents.push(contentComponent)
      }
    })
    this.contentComponents = contentComponents
  }

  private isActiveTab(contentComponent: TabNavigationContent) {
    const to = contentComponent.to
    for (const matchedRoutes of this.$route.matched) {
      if (matchedRoutes.path === to) {
        return true
      }
    }
    return false
  }
}
