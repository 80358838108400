
import Vue from 'vue'
import Component from 'vue-class-component'
import Icon from '@/components/media/Icon.vue'
import {Prop} from 'vue-property-decorator'

@Component({
  components: {Icon}
})
export default class ExternalTextLink extends Vue {

  @Prop({type: String, default: null}) private link!: string | null

}
