/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'list': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" d="M3 13h2v-2H3v2zm0 5h2v-2H3v2zM3 8h2V6H3v2zm4 5h14v-2H7v2zm0 5h14v-2H7v2zM7 6v2h14V6H7z"/>'
  }
})
