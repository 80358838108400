import axios from '@/infrastructure/vld-webservice'
import {
  AdInfos,
  BusinessHours,
  CompanyInfos,
  CompanySettings, DeliveryAreaLinks,
  DeliveryDispenseSettings,
  DeliveryNotificationSettings,
  DeliverySettings,
  DeliveryTime,
  DeliveryWindow,
  DispoSettings,
  OrderSettings,
  OrderTypeHLD,
  SupplementalService
} from '@/application/settings/models/types'
import { Address } from '@/application/shared/Address'
import { AxiosRequestConfig } from 'axios'

export const deliverySettingsOverview = (): Promise<DeliverySettings> => {
  return axios.get('/cockpit/settings/delivery').then((response) => response.data)
}

export const dispo = (): Promise<DispoSettings> => {
  return axios.get('/cockpit/settings/delivery/dispo').then((response) => response.data)
}

export const updateDispoStatus = (data: any): Promise<void> => {
  return axios.put('/cockpit/settings/delivery/dispo/status', data).then((response) => response.data)
}

export const updateDispoAddress = (data: Address): Promise<void> => {
  return axios.put('/cockpit/settings/delivery/dispo/address', data).then((response) => response.data)
}

export const deliveryTime = (): Promise<DeliveryTime> => {
  return axios.get('/cockpit/settings/delivery/deliverytime').then((response) => response.data)
}

export const updateDeliveryTime = (data: DeliveryTime): Promise<void> => {
  return axios.put('/cockpit/settings/delivery/deliverytime', data).then((response) => response.data)
}

export const deliveryDispense = (): Promise<DeliveryDispenseSettings> => {
  return axios.get('/cockpit/settings/delivery/deliverydispense').then((response) => response.data)
}

export const deliveryNotificationSettings = (): Promise<DeliveryNotificationSettings> => {
  return axios.get('/cockpit/settings/delivery/deliverynotification').then((response) => response.data)
}

export const deliveryWindows = (): Promise<DeliveryWindow[]> => {
  return axios.get('/cockpit/settings/delivery/deliverywindow').then((response) => response.data)
}

export const createDeliveryWindow = (deliveryWindow: any): Promise<void> => {
  return axios.post('/cockpit/settings/delivery/deliverywindow', deliveryWindow).then(() => {
    return
  })
}

export const updateDeliveryWindow = (deliveryWindowId: string, deliveryWindow: DeliveryWindow): Promise<void> => {
  return axios.put(`/cockpit/settings/delivery/deliverywindow/${deliveryWindowId}`, deliveryWindow).then(() => {
    return
  })
}

export const removeDeliveryWindow = (deliveryWindowId: string): Promise<void> => {
  return axios.delete(`/cockpit/settings/delivery/deliverywindow/${deliveryWindowId}`).then(() => {
    return
  })
}

export const supplementalServices = (): Promise<SupplementalService[]> => {
  return axios.get('/cockpit/settings/delivery/supplementalservice').then((response) => response.data)
}

export const deliveryAreaLinks = (): Promise<DeliveryAreaLinks> => {
  return axios.get('/cockpit/settings/delivery/deliveryarea').then((response) => response.data)
}

export const createSupplementalService = (supplementalService: any): Promise<void> => {
  return axios.post('/cockpit/settings/delivery/supplementalservice', supplementalService).then(() => {
    return
  })
}

export const updateSupplementalService = (
  supplementalServiceId: string,
  supplementalService: SupplementalService): Promise<void> => {
  return axios.put(`/cockpit/settings/delivery/supplementalservice/${supplementalServiceId}`, supplementalService)
    .then(() => {
      return
    })
}

export const removeSupplementalService = (supplementalServiceId: string): Promise<void> => {
  return axios.delete(`/cockpit/settings/delivery/supplementalservice/${supplementalServiceId}`).then(() => {
    return
  })
}


export const updateDeliveryDispense = (data: DeliveryDispenseSettings): Promise<void> => {
  return axios.put('/cockpit/settings/delivery/deliverydispense', data).then((response) => response.data)
}

export const updateDeliveryNotificationSettings = (data: DeliveryNotificationSettings): Promise<void> => {
  return axios.put('/cockpit/settings/delivery/deliverynotification', data).then((response) => response.data)
}

export const companySettingsOverview = (): Promise<CompanySettings> => {
  return axios.get('/cockpit/settings/company').then((response) => response.data)
}

export const companyInfos = (): Promise<CompanyInfos> => {
  return axios.get('/cockpit/settings/companyinfos').then((response) => response.data)
}

export const adInfos = (): Promise<AdInfos> => {
  return axios.get('/cockpit/settings/ads').then((response) => response.data)
}

export const updateAdInfos = (data: FormData): Promise<void> => {
  const config = {
    header : {
      'Content-Type' : 'multipart/form-data'
    }
  } as AxiosRequestConfig
  return axios.post(
    '/cockpit/settings/ads',
    data,
    config
  ).then((response) => response.data)
}

export const deleteAdInfos = (): Promise<void> => {
  return axios.delete('/cockpit/settings/ads').then(() => {
    return
  })
}

export const updateCompanyInfos = (data: any): Promise<void> => {
  return axios.put('/cockpit/settings/companyinfos', data).then((response) => response.data)
}

export const businessHours = (): Promise<BusinessHours[]> => {
  return axios.get('/cockpit/settings/company/businesshours').then((response) => response.data)
}

export const updateBusinessHours = (data: any): Promise<void> => {
  return axios.put('/cockpit/settings/company/businesshours', data).then((response) => response.data)
}

export const orderSettingsOverview = (): Promise<OrderSettings> => {
  return axios.get('/cockpit/settings/order').then((response) => response.data)
}

export const orderTypeHLD = (): Promise<OrderTypeHLD> => {
  return axios.get('/cockpit/settings/order/hld').then((response) => response.data)
}

export const updateOrderTypeHLD = (data: OrderTypeHLD): Promise<void> => {
  return axios.put('/cockpit/settings/order/hld', data).then((response) => response.data)
}
