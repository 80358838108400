/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'inprogress': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" d="M23.367 9.788a7.776 7.776 0 01-.7 7.424A8.034 8.034 0 0116 20.739h-4.175l1.65 1.626a.527.527 0 010 .75l-.74.73a.54.54 0 01-.757 0L8.16 20.089a.525.525 0 01-.001-.752l3.818-3.768a.537.537 0 01.758 0l.74.727a.525.525 0 010 .752l-1.65 1.625h4.167a5.936 5.936 0 004.161-1.698 5.747 5.747 0 001.724-4.095 5.743 5.743 0 00-1.72-4.094 1.034 1.034 0 010-1.48 1.07 1.07 0 011.497 0 7.848 7.848 0 011.682 2.43.198.198 0 01.032.052zM.633 14.212a7.776 7.776 0 01.7-7.424A8.034 8.034 0 018 3.261h4.175l-1.65-1.626a.527.527 0 010-.75l.74-.73a.54.54 0 01.757 0l3.818 3.756a.525.525 0 01.001.752l-3.818 3.768a.537.537 0 01-.758 0l-.74-.727a.525.525 0 010-.752l1.65-1.625H8.01a5.936 5.936 0 00-4.161 1.698 5.747 5.747 0 00-1.724 4.095c0 1.535.618 3.008 1.72 4.094a1.034 1.034 0 010 1.48 1.07 1.07 0 01-1.497 0 7.848 7.848 0 01-1.682-2.43.198.198 0 01-.032-.052z"/>'
  }
})
