/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon_bag_empty': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" d="M11.8 1a3.944 3.944 0 013.937 3.728h3.956c.501 0 .907.405.907.906v15.06c0 .501-.406.906-.907.906H3.907A.906.906 0 013 20.694V5.634c0-.501.406-.906.907-.906h3.956A3.944 3.944 0 0111.8 1zm2.119 3.728a2.13 2.13 0 00-4.238 0h4.238zm1.824 1.811v1.518c0 .5-.406.906-.907.906a.906.906 0 01-.907-.906V6.539H9.67v1.518c0 .5-.405.906-.907.906a.906.906 0 01-.907-.906V6.539H4.814v13.25h13.972V6.538h-3.043z"/>'
  }
})
