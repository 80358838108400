
import TransportCard from '@/application/deliveries/ui/components/transport/TransportCard.vue'
import { Transport } from '@/application/deliveries/models/transport/types'
import { pickupDone } from '@/application/deliveries/models/transport/index'
import TransferTransport from '@/application/deliveries/ui/types/TransferTransport'
import Component from 'vue-class-component'
import { Vue } from 'vue-property-decorator'
import TransportAssignableView from '@/application/deliveries/ui/components/transport/TransportAssignableView.vue'
import { State } from 'vuex-class'
import PageTitle from '@/components/text/PageTitle.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import { edit, remove, unselect } from '@/application/deliveries/services/deliveries-service'
import Icon from '@/components/media/Icon.vue'
import {
  default as DispoSwitcher,
  DispoOptionChangedEvent
} from '@/application/deliveries/ui/components/transport/DispoSwitcherView.vue'
import { UpdateDeliveryRequest } from '@/application/deliveries/resources/UpdateDeliveryRequest'
import PageHead from '@/components/layout/PageHead.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import Divider from '@/components/layout/Divider.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import { DeliveriesState } from '@/application/deliveries/store/types'
import { routerNames, routerParameters, routerQueries } from '@/application/router/router-definitions'
import OptionsButton from '@/components/buttons/OptionsButton.vue'
import { formatOrderNumber } from '@/application/deliveries/utils/formatter'
import { formatCurrency } from '@/utils/formatter'
import { OptionsMenuItem } from '@/components/menus/types/OptionsMenuItem'
import OptionsMenu from '@/components/menus/OptionsMenu.vue'
import ModalConfirmation from '@/components/modal/ModalConfirmation.vue'
import CheckboxInput from '@/components/forms/CheckboxInput.vue'
import { removeSubpathFromHistory } from '@/application/router/router-extensions'
import { routeHistory } from '@/application/router'
import {OrderSource} from '@/application/deliveries/models/OrderSource'

@Component({
  components: {
    CheckboxInput,
    OptionsMenu,
    OptionsButton,
    VerticalLayout,
    Divider,
    ScrollableSection,
    PageDetail,
    PageHead,
    DispoSwitcher,
    Icon,
    DetailSection,
    PageTitle,
    TransportAssignableView,
    TransportCard,
    ModalConfirmation
  }
})
export default class DeliveriesDelivery extends Vue {

  @State('deliveries') state!: DeliveriesState

  showDeleteConfirmation = false

  deleteConfirmationSaveButtonEnabled = false

  get note(): string | null {
    return this.state.delivery!.customer.note
  }

  get phone(): string | null {
    return this.state.delivery!.customer.phone
  }

  get price(): string | null {
    return formatCurrency(this.state.delivery!.price)
  }

  get orderNumber(): string {
    return formatOrderNumber(this.$route.params.orderNumber)
  }

  get childRoute(): boolean {
    const routeName = this.$route.name
    if (routeName != null) {
      return [routerNames.DELIVERIES_COURIER_DELIVERY, routerNames.DELIVERIES_ROLE_DELIVERY].includes(routeName)
    }
    return false
  }

  get isUserCockpitAdmin(): boolean {
    const authenticatedUserSummary = this.$store.state.users.authenticatedUserSummary
    return authenticatedUserSummary && authenticatedUserSummary.isCockpitAdmin
  }

  get orderSourceIcon(): string {
    switch (this.state.delivery!.orderSource){
    case OrderSource.COCKPIT:
      return 'order_cockpit'
    case OrderSource.COURIER_APP:
      return 'order_courier'
    case OrderSource.CUSTOMER_APP:
      return 'order_customer'
    case OrderSource.PUBLIC_API:
      return 'order_api'
    case OrderSource.UNKNOWN:
      return 'order_unknown'
    }
  }

  get orderSourceText(): string {
    switch (this.state.delivery!.orderSource){
    case OrderSource.COCKPIT:
      return this.$t('deliveries.order_source_cockpit') as string
    case OrderSource.COURIER_APP:
      return this.$t('deliveries.order_source_courier') as string
    case OrderSource.CUSTOMER_APP:
      return this.$t('deliveries.order_source_customer') as string
    case OrderSource.PUBLIC_API:
      return this.$t('deliveries.order_source_api') as string
    case OrderSource.UNKNOWN:
      return this.$t('deliveries.order_source_unknown') as string
    }
  }

  get options(): OptionsMenuItem [] {
    return [
      {
        identifier: 'remove',
        text: this.$t('deliveries.remove') as string,
        icon: 'trash',
        disabled: false
      }
    ]
  }

  onClickMenuItem() {
    this.deleteConfirmationSaveButtonEnabled = false
    this.showDeleteConfirmation = true
  }

  onClickConfirmationDeleteDiscard() {
    this.showDeleteConfirmation = false
  }

  onClickConfirmationDeleteSave() {
    this.showDeleteConfirmation = false
    const path = this.$router.currentRoute.path
    const deliverySubPath = `/delivery/${this.state.delivery!.orderNumber}`
    const targetPath = (path.includes(routerNames.DELIVERIES_OVERVIEW)) ?
      routerNames.DELIVERIES_OVERVIEW : routerNames.DELIVERIES
    unselect()
    removeSubpathFromHistory(deliverySubPath, routeHistory)
    remove(this.orderNumber).then(() => {
      this.$router
        .push({
          name: targetPath,
          params: {[routerParameters.DISABLE_ROUTE_TO_LAST_CHILD]: 'true'}
        })
        .catch((err) => {})
    })
  }

  transportInProgress(transport: Transport) {
    return pickupDone(transport)
  }

  onDragStart(event: DragEvent, transport: Transport) {
    const transfer = new TransferTransport(
      transport.transportId,
      transport.orderNumber,
      false
    )
    event.dataTransfer!.setData(
      'text/plain',
      JSON.stringify(transfer)
    )
  }

  onClickTransport(transport: Transport) {
    this.$router.push({
      query: {[routerQueries.SELECTED_TRANSPORT_ID]: transport.transportId}
    }).catch((err) => {})
  }

  onDispoOptionChanged(event: DispoOptionChangedEvent) {
    const delivery: UpdateDeliveryRequest = {
      viaDispo: event.dispoOption
    }
    edit(event.orderNumber, delivery)
  }

  onClickBack() {
    switch (this.$route.name) {
    case routerNames.DELIVERIES_COURIER_DELIVERY:
      this.$router.push({
        name: routerNames.DELIVERIES_COURIER,
        params: {courierId: this.$route.params.courierId},
        query: this.$route.query
      }).catch((err) => {})
      break
    case routerNames.DELIVERIES_ROLE_DELIVERY:
      this.$router.push({
        name: routerNames.DELIVERIES_ROLE,
        params: {courierId: this.$route.params.roleId},
        query: this.$route.query
      }).catch((err) => {})
      break
    }
  }
}
