/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'order_cockpit': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.364 7.886a.499.499 0 01.092-.148l4.62-4.805a.504.504 0 01.111-.085.613.613 0 01.16-.064.554.554 0 01.103 0h10.33a.54.54 0 01.528.551v9.381h-1.692V4.688h-8.425v3.863a.51.51 0 01-.14.354.472.472 0 01-.34.147H6v11.816h4.096v1.914H4.837a.517.517 0 01-.374-.16.565.565 0 01-.155-.39V8.125a.547.547 0 01.056-.24zm2.486-.303h1.962V5.538L6.85 7.583z" _fill="#132C45"/><path pid="1" d="M10.096 17.327v1.819H7.845v-1.819h2.25zM10.096 13.982V15.8H7.845v-1.818h2.25zM16.687 10.873H7.845v1.818h8.842v-1.818zM15.943 24.782h4v-2h-4v2zM18.942 21.062h-2a1 1 0 00-1 1v.72h4v-.72a1 1 0 00-1-1z" _fill="#132C45"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M16.942 21.062h2a1 1 0 011 1v.72h3.366a1 1 0 001-1v-6.823a1 1 0 00-1-1H12.577a1 1 0 00-1 1v6.823a1 1 0 001 1h3.366v-.72a1 1 0 011-1zm-3.365-5.103v4.823h8.731v-4.823h-8.731z" _fill="#132C45"/>'
  }
})
