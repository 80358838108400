import axios from '@/infrastructure/vld-webservice'
import {FreeDeliveryItem} from '@/application/freedeliveryitems/types'

export const freeDeliveryItems = async (): Promise<FreeDeliveryItem[]> => {
  const response = await axios.get('/cockpit/customers/freedeliveryitems')
  return response.data.freeDeliveryItems
}

export const postFreeDeliveryItemRange = async (prefix: string, start: number, end: number): Promise<void> =>
  await axios.post('/cockpit/customers/freedeliveryitems/range', {
    prefix,
    start,
    end
  })

export const deleteFreeDeliveryItem = async (id: string): Promise<void> => {
  await axios.delete(`/cockpit/customers/freedeliveryitems/${id}`)
}
