import { MutationTree } from 'vuex'
import { CustomersState } from './types'
import localStorage, { customerDraftKey, retailerDraftKey } from '@/infrastructure/local-storage'

export enum commits {
  CUSTOMER_DRAFT = 'customers/customerDraft',
  RETAILER_DRAFT = 'customers/retailerDraft'
}

export const mutations: MutationTree<CustomersState> = {

  customerDraft(state, draft) {
    if (draft) {
      localStorage.setObjectForKey(customerDraftKey, draft)
      state.customerDraft = Object.assign({}, draft)
    } else {
      localStorage.setObjectForKey(customerDraftKey, null)
      state.customerDraft = null
    }
  },

  retailerDraft(state, draft) {
    if (draft) {
      localStorage.setObjectForKey(retailerDraftKey, draft)
      state.retailerDraft = Object.assign({}, draft)
    } else {
      localStorage.setObjectForKey(retailerDraftKey, null)
      state.retailerDraft = null
    }
  },

  clear(state) {
    localStorage.setObjectForKey(customerDraftKey, null)
    state.customerDraft = null
    localStorage.setObjectForKey(retailerDraftKey, null)
    state.retailerDraft = null
  }
}
