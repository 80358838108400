

import { Prop, Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import Icon from '@/components/media/Icon.vue'
import SwitcherInput from '@/components/forms/SwitcherInput.vue'
import DueDateTimeView from './DueDateTimeView.vue'

export interface DispoOptionChangedEvent {
  orderNumber: string
  dispoOption: boolean
}

@Component({
  components: {
    Icon,
    DueDateTimeView,
    SwitcherInput
  }
})
export default class DispoSwitcherView extends Vue {

  @Prop({
    type: String,
    required: true
  })
  private orderNumber!: string

  @Prop({
    type: Boolean,
    required: true
  })
  private viaDispo!: boolean

  @Prop({
    type: Boolean,
    required: true
  })
  private dispoOption!: boolean

  onDispoSwitcherClicked(event: any) {
    event.stopPropagation()
  }

  onDispoOptionChanged(event: boolean) {
    const dispoEvent: DispoOptionChangedEvent = {
      orderNumber: this.orderNumber,
      dispoOption: event
    }
    this.$emit('dispoOptionChanged', dispoEvent)
  }

}
