import {
  RemoteConfiguration, RemoteConfigurationImpl
} from '@/infrastructure/featuretoggles/RemoteConfiguration'
import { Firebase } from '@/infrastructure/featuretoggles/Firebase'

interface FeatureToggle {
  key: string
  description: string
  fallbackValue: boolean
}

export const featureTogglesConfiguration: { [key: string]: FeatureToggle } = {
  promotions: {
    key: 'promotions',
    description: 'Ability to manage free delivery items',
    fallbackValue: false
  }
}

export class FeatureToggles {
  private readonly remoteConfiguration: RemoteConfiguration

  constructor(remoteConfiguration: RemoteConfiguration) {
    this.remoteConfiguration = remoteConfiguration
  }

  initialize(defaultValues: { [key: string]: FeatureToggle }): void {
    const defaultValueMap: { [key: string]: boolean } = {}
    Object.keys(defaultValues).forEach((key) => {
      defaultValueMap[key] = defaultValues[key].fallbackValue
    })
    this.remoteConfiguration.initialize(defaultValueMap)
  }

  async loadFromRemote(): Promise<void> {
    await this.remoteConfiguration.fetchAndActivate()
  }

  isEnabled(featureToggle: FeatureToggle): boolean {
    const isEnabled = this.remoteConfiguration.getBoolean(featureToggle.key)
    if (isEnabled != null) {
      return isEnabled
    }

    return featureToggle.fallbackValue
  }
}

export const featureToggles: FeatureToggles = new FeatureToggles(
  new RemoteConfigurationImpl(
    new Firebase()
  )
)
