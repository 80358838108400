
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import Card from '@/components/layout/Card.vue'
import MultiSelectChipInput from '@/components/forms/MultiSelectChipInput.vue'
import TimeInput from '@/components/forms/TimeInput.vue'
import Icon from '@/components/media/Icon.vue'
import BottomBar from '@/components/layout/BottomBar.vue'
import SaveDiscardButtons from '@/components/buttons/SaveDiscardButtons.vue'
import Divider from '@/components/layout/Divider.vue'

@Component({
  components: {
    Divider,
    Icon,
    TimeInput,
    MultiSelectChipInput,
    Card,
    BottomBar,
    SaveDiscardButtons
  }
})
export default class DeliveryWindowCard extends Vue {

  @Prop({
    type: Object,
    default: () => {
      return {from: '00:00', to: '00:00'}
    }
  })
    deliveryWindow!: any

  @Prop({
    type: Boolean,
    default: false
  })
    isNewRecord!: boolean

  get timeFrom(): any {
    return this.splitToTime(this.deliveryWindow.from)
  }

  get timeTo(): any {
    return this.splitToTime(this.deliveryWindow.to)
  }

  get readyToSave(): boolean {
    return !(
      this.deliveryWindow.from === null
      || this.deliveryWindow.to === null
      || this.deliveryWindow.from === '00:00'
      || this.deliveryWindow.to === '00:00'
    )
  }

  onChangedTimeFrom(time: any) {
    this.deliveryWindow.from = this.timeToString(time)
    this.onUpdated()
  }

  onChangedTimeTo(time: any) {
    this.deliveryWindow.to = this.timeToString(time)
    this.onUpdated()
  }

  timeToString(time: any): string {
    const hour = ('0' + time.hour).slice(-2)
    const minute = ('0' + time.minute).slice(-2)

    return `${hour}:${minute}`
  }

  splitToTime(value: string): object {
    const splitTime = value.split(':')
    return {
      hour: +splitTime[0],
      minute: +splitTime[1]
    }
  }

  onClickRemove() {
    this.$emit('delete', this.deliveryWindow.deliveryWindowId)
  }

  onUpdated() {
    if (this.isNewRecord) {
      return
    }

    this.$emit('input', this.deliveryWindow)
  }

  onClickDiscard() {
    this.$emit('cancel')
  }

  onClickCreate() {
    this.$emit('save', this.deliveryWindow)
  }

}
