
import Vue from 'vue'
import Component from 'vue-class-component'
import PageTitle from '@/components/text/PageTitle.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import PageHead from '@/components/layout/PageHead.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import FormField from '@/components/forms/FormField.vue'
import companySettingsService from '@/application/settings/CompanySettingsService'
import { CompanyInfos } from '@/application/settings/models/types'
import InlineTextInput from '@/components/forms/InlineTextInput.vue'
import InlinePhoneNumberInput from '@/components/forms/InlinePhoneNumberInput.vue'
import { HttpPrefixedUrlValidator } from '@/components/forms/HttpPrefixedUrlValidator'

@Component({
  components: {
    InlinePhoneNumberInput,
    VerticalLayout,
    ScrollableSection,
    PageDetail,
    PageHead,
    DetailSection,
    PageTitle,
    FormField,
    InlineTextInput
  }
})
export default class CompanyInfosView extends Vue {

  companyInfos: CompanyInfos = {
    name: '',
    phone: '',
    text: null,
    termsUrl: null
  }

  get termsUrlValidator(): HttpPrefixedUrlValidator {
    return new HttpPrefixedUrlValidator(this.$t('settings.companyTermsUrlMustHaveHttps') as string)
  }

  mounted() {
    this.loadCompanyInfos()
  }

  onChangeCompanyInfos(value: any) {
    companySettingsService.updateCompanyInfos(Object.assign({}, this.companyInfos, value)).then(() => {
      this.loadCompanyInfos()
    }).catch(() => {
      this.loadCompanyInfos()
    })
  }

  onChangeTermsUrl(value: string) {
    const terms = value ? value : null
    this.onChangeCompanyInfos({termsUrl: terms})
  }

  loadCompanyInfos() {
    companySettingsService.companyInfos().then((response) => {
      this.companyInfos = response
    })
  }

}
