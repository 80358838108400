import { GetterTree } from 'vuex'
import { UsersState } from '@/application/users/store/types'

export enum getterKeys {
  USER_DRAFT = 'users/hasUserDraft',
  ROLE_DRAFT = 'users/hasUserDraft'
}

export const getters: GetterTree<UsersState, any> = {
  hasUserDraft(state) {
    return state.userDraft !== null
  },
  hasRoleDraft(state) {
    return state.roleDraft !== null
  }
}
