import { Module } from 'vuex'
import { DeliveriesState } from '@/application/deliveries/store/types'
import { mutations } from './mutations'

export const state: DeliveriesState = {
  couriers: [],
  roles: [],
  unplanned: [],
  missing: [],
  courierTransports: [],
  roleTransports: [],
  delivery: null,
  shippingOrderDraft: false,
  repopulateConfiguration: { enabled: false }
}

export const deliveries: Module<DeliveriesState, any> = {
  namespaced: true,
  state,
  mutations
}
