
import Component from 'vue-class-component'
import { Prop, Vue, Watch } from 'vue-property-decorator'
import Icon from '@/components/media/Icon.vue'
import IconButton from '@/components/buttons/IconButton.vue'
import TextInput from '@/components/forms/TextInput.vue'

@Component({
  components: {
    TextInput,
    IconButton,
    Icon
  }
})
export default class InlineNumberInput extends Vue {

  $refs!: {
    textInput: TextInput
  }

  @Prop({
    type: Boolean,
    default: false
  })
    required!: boolean

  @Prop({
    type: Number,
    default: null
  })
    value!: number | null

  @Prop({
    type: Number,
    default: null
  })
    placeholder!: number | null

  @Prop({
    type: String,
    default: null
  })
    inputClass!: string | null

  @Prop({
    type: Number,
    default: 0
  })
    fractionDigits!: number

  localValue: string | null = this.format(this.valueAsString)

  editing = false

  mouseDownControl = false

  get placeHolderAsString() {
    return (this.placeholder !== null) ? this.placeholder.toString() : null
  }

  get showAdd(): boolean {
    return !this.required && !this.editing && (this.localValue === null)
  }

  get existingValue(): boolean {
    return this.value !== null
  }

  get textInputClasses(): string | null {
    const classes = []
    if (this.inputClass) {
      classes.push(this.inputClass)
    }
    if (this.existingValue) {
      classes.push('style-inline existing-value')
    }
    return classes.length > 0 ? classes.join(' ') : null
  }

  get error(): string | null {
    const valAsNumber = Number(this.localValue)
    const isInvalidNumber = Number.isNaN(valAsNumber)
    if (this.required && this.existingValue && isInvalidNumber) {
      return this.$tc('InlineNumberInput.invalidNumber')
    } else if (this.localValue !== null && this.fractionDigits === 0 && !Number.isInteger(valAsNumber)) {
      return this.$tc('InlineNumberInput.onlyInteger')
    }
    return null
  }

  get confirmButtonDisabled(): boolean {
    return this.error !== null
  }

  get valueAsString(): string | null {
    return this.value !== null ? String(this.value) : null
  }

  format(val: string | null): string | null {
    if (this.fractionDigits === 0) {
      return val
    }

    const valAsNumber = Number(val)
    const isInvalidNumber = Number.isNaN(valAsNumber)

    if (!isInvalidNumber) {
      return valAsNumber.toFixed(this.fractionDigits).toString()
    } else {
      return null
    }
  }

  @Watch('value')
  onChangeValue() {
    this.localValue = this.format(this.valueAsString)
  }

  beforeDestroy() {
    window.removeEventListener('mouseup', this.onMouseUp)
  }

  onClickAdd() {
    this.beginEdit()
  }

  onBlur() {
    if (!this.mouseDownControl && this.editing) {
      this.confirm()
    }
  }

  onFocus() {
    this.editing = true
  }

  onKeyEnter() {
    if (!this.editing) {
      this.beginEdit()
    } else {
      this.confirm()
    }
  }

  onKeyEsc() {
    this.cancel()
  }

  onClickCancel() {
    this.cancel()
  }

  onClickConfirm() {
    this.confirm()
  }

  onClickTextInput() {
    if (!this.editing) {
      this.editing = true
    }
  }

  onMouseDownControl(event: MouseEvent) {
    event.preventDefault()
    this.mouseDownControl = true
    window.addEventListener('mouseup', this.onMouseUp)
  }

  onMouseUp() {
    this.mouseDownControl = false
    window.removeEventListener('mouseup', this.onMouseUp)
  }

  private confirm() {
    if (this.error) {
      this.cancel()
    } else {
      this.$emit('input', Number(this.localValue))
      this.endEdit()
    }
  }

  private cancel() {
    this.localValue = this.valueAsString
    this.endEdit()
  }

  private beginEdit() {
    this.editing = true
    this.$nextTick(() => {
      this.$refs.textInput.focus()
    })
  }

  private endEdit() {
    this.editing = false
  }

}
