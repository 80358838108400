
import { Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import TransferTransport from '@/application/deliveries/ui/types/TransferTransport'
import AssigneeCard from '@/application/deliveries/ui/components/assignee/AssigneeCard.vue'
import CourierView from '@/application/deliveries/ui/components/assignee/CourierView.vue'
import RoleView from '@/application/deliveries/ui/components/assignee/RoleView.vue'
import { State } from 'vuex-class'
import { Assignee } from '@/application/deliveries/models/Assignee'
import { disposeTransports } from '@/application/deliveries/services/deliveries-service'
import DetailSection from '@/components/layout/DetailSection.vue'
import { DeliveriesState } from '@/application/deliveries/store/types'
import { routerNames, routerQueries } from '@/application/router/router-definitions'


@Component({
  components: {
    DetailSection,
    AssigneeCard,
    CourierView,
    RoleView
  }
})
export default class DeliveriesAssignees extends Vue {
  @State('deliveries') state!: DeliveriesState

  get roles() {
    return this.state.roles
  }

  get couriers() {
    return this.state.couriers
  }

  onDropTransportOnCourier(transfer: TransferTransport, courierId: string) {
    disposeTransports(
      transfer.orderNumber,
      {
        assigneeId: courierId,
        transportIds: [transfer.transportId]
      })
      .then(() => {
        if (transfer.redirect) {
          this.$router.push({
            name: routerNames.DELIVERIES_COURIER,
            params: {courierId: courierId},
            query: {[routerQueries.SELECTED_TRANSPORT_ID]: transfer.transportId}
          }).catch((err) => {})
        }
      })
  }

  onDropTransportOnRole(transfer: TransferTransport, roleId: string) {
    disposeTransports(transfer.orderNumber, {
      assigneeId: roleId,
      transportIds: [transfer.transportId]
    }).then(() => {
      if (transfer.redirect) {
        this.$router.push({
          name: routerNames.DELIVERIES_ROLE,
          params: {roleId: roleId},
          query: {[routerQueries.SELECTED_TRANSPORT_ID]: transfer.transportId}
        }).catch((err) => {})
      }
    })
  }

  onClickCourier(courierId: string) {
    this.$router.push({
      name: routerNames.DELIVERIES_COURIER,
      params: {courierId: courierId},
      query: this.$route.query
    }).catch((err) => {})
  }

  onClickRole(roleId: string) {
    this.$router.push({
      name: routerNames.DELIVERIES_ROLE,
      params: {roleId: roleId},
      query: this.$route.query
    }).catch((err) => {})
  }

  selectedCourier(courier: Assignee): boolean {
    if (this.$route.params.courierId) {
      return this.$route.params.courierId === courier.assigneeId
    }
    return false
  }

  selectedRole(role: Assignee): boolean {
    if (this.$route.params.roleId) {
      return this.$route.params.roleId === role.assigneeId
    }
    return false
  }
}
