/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_7': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22 7.909a.818.818 0 00-.49-.745l-9.167-4.091a.85.85 0 00-.687 0l-9.168 4.09A.818.818 0 002 7.91c0 .32.19.61.488.744l2.917 1.301-2.917 1.301A.818.818 0 002 12c0 .32.19.611.488.745l2.917 1.3-2.917 1.302A.818.818 0 002 16.09c0 .32.19.612.488.745l9.168 4.091a.85.85 0 00.687 0l9.167-4.09a.818.818 0 00.488-.746c0-.32-.19-.61-.488-.744l-2.914-1.301 2.916-1.301A.818.818 0 0022 12c0-.32-.19-.611-.488-.745l-2.916-1.3 2.916-1.302A.818.818 0 0022 7.91zm-2.849 8.182L12 19.285 4.847 16.09l2.568-1.147 4.24 1.892a.85.85 0 00.688 0l4.24-1.892 2.568 1.147zm0-4.091L12 15.193 4.847 12l2.568-1.148 4.24 1.893a.85.85 0 00.688 0l4.24-1.893L19.151 12zM12 11.102L4.847 7.909l7.152-3.192 7.152 3.192L12 11.102z" _fill="#132C45"/>'
  }
})
