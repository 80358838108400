

import { MapCoordinate } from './types/MapCoordinate'
import Component from 'vue-class-component'
import { Prop, Vue, Watch } from 'vue-property-decorator'
import { fromWSG84 } from './map'
import MapView from './MapView.vue'
import locationSVG from './assets/location'
import { Style, Icon } from 'ol/style'
import { Vector } from 'ol/source'
import VectorLayer from 'ol/layer/Vector'
import Point from 'ol/geom/Point'
import Feature from 'ol/Feature'

@Component({
  components: {MapView}
})
export default class MapMarkerView extends Vue {

  $refs!: {
    map: MapView
  }

  @Prop({type: Object, default: null}) markerCoordinate!: MapCoordinate | null

  @Prop({
    type: Object, default: () => ({latitude: 46.93132019042969, longitude: 7.447328567504883})
  }) defaultCenter!: MapCoordinate

  // non reactive
  vectorSource!: any

  @Watch('markerCoordinate')
  onChangedMarkerCoordinate() {
    this.drawMarker()
  }

  mounted() {
    const markerStyle = new Style({
      image: new Icon({
        src: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(locationSVG)
      })
    })
    this.vectorSource = new Vector({
      features: []
    })
    const markerLayer = new VectorLayer({
      source: this.vectorSource,
      style: markerStyle
    })
    this.$refs.map.addLayer(markerLayer)
    this.drawMarker()
  }

  drawMarker() {
    this.vectorSource.clear()
    if (this.markerCoordinate) {
      this.vectorSource.addFeature(
        new Feature({
          geometry: new Point(
            fromWSG84(this.markerCoordinate)
          )
        })
      )
      this.moveMapTo(this.markerCoordinate, 1)
    } else {
      this.moveMapTo(this.defaultCenter, 500)
    }
  }

  moveMapTo(coordinate: MapCoordinate, resolution: number) {
    setTimeout(() => {
      if (this.$refs.map) {
        this.$refs.map.moveTo(coordinate, resolution)
      }
    }, 100)
  }
}
