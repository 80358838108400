/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'order_cancel': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M8 21v-1.819H3.692V7.956h3.71c.127 0 .25-.05.34-.139.09-.089.141-.21.141-.336v-3.67h8.425V10H18V2.526a.526.526 0 00-.529-.524H7.141a.581.581 0 00-.103 0 .632.632 0 00-.159.06.501.501 0 00-.11.081L2.148 6.708a.472.472 0 00-.092.141.503.503 0 00-.056.228v13.4a.522.522 0 00.529.523H8zM4.542 6.561l1.962-1.943V6.56H4.542zM12.01 14.7l3.303 3.295L12 21.298l1.691 1.692 3.304-3.299L20.298 23l1.69-1.688-3.296-3.296L22 14.707 20.29 13 17 16.293l-3.295-3.29-1.695 1.696z"/>'
  }
})
