export class LocationHelper {

  static host(): string {
    return window.location.host
  }

  static protocol(): string {
    return window.location.protocol
  }

}
