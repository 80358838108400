import { cookieJar } from '@/infrastructure/CookieJar'
import router from '@/application/router'
import { routerNames } from '@/application/router/router-definitions'
import { login, logout } from '@/application/auth-resource'
import { Route } from 'vue-router'

class ApplicationService {

  login(email: string, password: string): Promise<void> {
    return login(email, password).then()
  }

  logout(reason?: any): Promise<void> {
    if (this.isLoggedIn()) {
      return logout().then(() => {
        this.navigateToLogin(reason)
      })
    } else {
      return Promise.resolve(this.navigateToLogin()).then()
    }
  }

  private navigateToLogin(reason?: any): Promise<Route> {
    return router.push({name: routerNames.LOGIN, params: (reason) ? reason : {}})
  }

  isLoggedIn(): boolean {
    return cookieJar.hasCookieWithName('jwt-header') && cookieJar.hasCookieWithName('jwt-expiration')
  }
}

export const applicationService = new ApplicationService()
