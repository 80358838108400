import eventBus from './event-bus'

export function eventConsumer(events: any[]) {
  return {
    mounted() {
      events.forEach((event) => {
        eventBus.$on(event.key, () => {
          // @ts-ignore
          this[event.handler]()
        })
      })
    },
    beforeDestroy() {
      events.forEach((event) => {
        eventBus.$off(event.key)
      })
    }
  }
}
