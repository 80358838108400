/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_8_not_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.41 5.561c0 .859.735 1.561 1.636 1.561.9 0 1.636-.702 1.636-1.561S17.946 4 17.046 4c-.9 0-1.637.702-1.637 1.561zm-2.864 10.537c0-2.186-1.8-3.903-4.091-3.903-2.291 0-4.091 1.717-4.091 3.903 0 2.185 1.8 3.902 4.09 3.902 2.291 0 4.091-1.717 4.091-3.902zm-1.228 0c0 1.483-1.309 2.731-2.863 2.731-1.555 0-2.864-1.248-2.864-2.731 0-1.483 1.309-2.732 2.864-2.732 1.554 0 2.863 1.249 2.863 2.732zM15 12.82l-1.8-1.796 1.964-1.873.654.625c1.064 1.014 2.455 1.639 4.173 1.639V9.854a4.163 4.163 0 01-2.945-1.171L15.49 7.2c-.41-.312-.818-.468-1.31-.468-.49 0-.9.156-1.145.468l-2.29 2.185a1.507 1.507 0 00-.492 1.093c0 .468.164.859.492 1.093l2.618 2.185v2.595A6.505 6.505 0 0115 13.81v-.99zM8.727 8.8a.533.533 0 00-.533-.533H.534a.533.533 0 100 1.066h7.66a.533.533 0 00.533-.533zm-1.624 1.6H2.715a.533.533 0 000 1.067h4.388a.533.533 0 000-1.067zm2.182-4.267H4.897a.533.533 0 000 1.067h4.388a.533.533 0 000-1.067z" _fill="#132C45"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19 14.417a4.583 4.583 0 100 9.167 4.583 4.583 0 000-9.167zm.793 2.998a.281.281 0 01.396 0l.396.396a.281.281 0 010 .396l-.793.793.793.793a.281.281 0 010 .396l-.396.396a.281.281 0 01-.396 0L19 19.792l-.793.793a.281.281 0 01-.396 0l-.396-.396a.281.281 0 010-.396l.793-.793-.793-.793a.281.281 0 010-.396l.396-.396a.281.281 0 01.396 0l.793.793.793-.793z" _fill="#F24949"/>'
  }
})
