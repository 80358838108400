import axios from '@/infrastructure/vld-webservice'
import { AxiosRequestConfig } from 'axios'
import {User, UserSummary} from '@/application/users/models/types'

export const users = () => {
  return axios.get('/cockpit/users').then((response) => response.data.users)
}

export const authenticatedUserSummary = (): Promise<UserSummary> => {
  return axios.get('/cockpit/user').then((response) => response.data)
}

export const userDetail = (userId: string): Promise<User> => {
  return axios.get(`/cockpit/users/${userId}`).then((response) => response.data)
}

export const editUser = (userId: string, data: any): Promise<void> => {
  return axios.put(`/cockpit/users/${userId}`, data).then((response) => response.data)
}

export const createUser = (data: any): Promise<string> => {
  return axios.post('/cockpit/users', data).then((response) => response.data.userId)
}

export const deleteUser = (userId: string): Promise<void> => {
  return axios.delete(`/cockpit/users/${userId}`).then(() => {
    return
  })
}

export const generateToken = (userId: string): Promise<void> => {
  return axios.post(`/cockpit/users/${userId}/token`, {}).then(() => {
    return
  })
}

export const roles = (): Promise<any[]> => {
  return axios.get('/cockpit/roles').then((response) => response.data.roles)
}

export const roleDetail = (roleId: string, ignoreGlobalErrorHandler?: boolean): Promise<any> => {
  const errorHandler = (ignoreGlobalErrorHandler) ? {ignoreGlobalErrorHandler: true} as AxiosRequestConfig : {}
  return axios.get(`/cockpit/roles/${roleId}`, errorHandler).then((response) => {
    return response.data
  })
}

export const createRole = (role: any): Promise<string> => {
  return axios.post('/cockpit/roles', role).then((response) => {
    return response.data.roleId
  })
}

export const editRole = (roleId: string, role: any): Promise<void> => {
  return axios.put(`/cockpit/roles/${roleId}`, role).then(() => {
    return
  })
}

export const deleteRole = (roleId: string): Promise<void> => {
  return axios.delete(`/cockpit/roles/${roleId}`).then(() => {
    return
  })
}

export const rolesCouriers = () => {
  return axios.get('/cockpit/roles/couriers').then((response) => {
    return response.data.couriers
  })
}

export const rolesZones = (): Promise<ZoneSummary[]> => {
  return axios.get('/cockpit/roles/zones').then((response) => {
    return response.data.zones
  })
}
