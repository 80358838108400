import { Firebase } from '@/infrastructure/featuretoggles/Firebase'
import { RemoteConfig } from '@firebase/remote-config'
import { fetchAndActivate, getValue } from 'firebase/remote-config'
import { remoteConfigurationSettings } from '@/infrastructure/featuretoggles/RemoteConfigurationSettings'

export interface RemoteConfiguration {
  initialize(
    defaultValues: { [key: string]: boolean }
  ): void

  fetchAndActivate(): Promise<void>

  getBoolean(key: string): boolean | null
}

export class RemoteConfigurationImpl implements RemoteConfiguration {

  private remoteConfig: RemoteConfig | null = null

  private readonly firebase: Firebase

  constructor(firebase: Firebase) {
    this.firebase = firebase
  }

  initialize(
    defaultValues: { [key: string]: boolean }
  ): void {
    this.firebase.initialize()
    const remoteConfig = this.firebase.remoteConfig()
    if (remoteConfig) {
      remoteConfig.settings = {
        minimumFetchIntervalMillis: remoteConfigurationSettings.fetchMinimumIntervalMillis(),
        fetchTimeoutMillis: remoteConfigurationSettings.fetchTimeoutMillis()
      }

      remoteConfig.defaultConfig = defaultValues
      this.remoteConfig = remoteConfig
    }
  }

  async fetchAndActivate(): Promise<void> {
    if (!this.remoteConfig) {
      return
    }
    await fetchAndActivate(this.remoteConfig)
  }

  getBoolean(key: string): boolean | null {
    if (!this.remoteConfig) {
      return null
    }
    const value = getValue(this.remoteConfig, key)
    return value.asBoolean()
  }
}
