var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"calendar-wrapper"},[_c('div',{staticClass:"calendar-header"},[_c('a',{on:{"click":_vm.onMovePreviousMonth}},[_c('Icon',{staticClass:"chevron-icon",attrs:{"name":"chevron_left"}})],1),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.monthName)+" ")]),_c('a',{on:{"click":_vm.onMoveNextMonth}},[_c('Icon',{staticClass:"chevron-icon",attrs:{"name":"chevron_right"}})],1)]),_c('hr',{staticClass:"divider"}),_c('div',{staticClass:"calendar-weeks"},[_c('div',{staticClass:"weekdays"},_vm._l((_vm.weekdayNames),function(weekday){return _c('div',{staticClass:"weekday"},[_vm._v(" "+_vm._s(weekday)+" ")])}),0),_vm._l((_vm.weeks),function(week){return _c('div',{staticClass:"week-row"},_vm._l((week.days),function(day){return _c('div',{staticClass:"day",class:{
          today: day.isToday,
          'not-in-month': !day.inMonth,
          selected: day.isSelected && !day.dragActive,
          dragged: day.isDragged,
          'first-in-range': day.isFirstInRange,
          'in-range': day.isInRange,
          'last-in-range': day.isLastInRange,
          'disabled': day.isDisabled
        },on:{"click":function($event){return _vm.$emit('selectDay', day)},"mouseenter":function($event){return _vm.$emit('enterDay', day)}}},[_vm._v(" "+_vm._s(day.dayOfMonth)+" ")])}),0)})],2),_c('hr',{staticClass:"divider"}),_c('div',{staticClass:"calendar-footer"},[_c('div',{staticClass:"today",on:{"click":_vm.onMoveToday}},[_vm._v(" "+_vm._s(_vm.$t('Calendar.today'))+" ")]),_c('div',{staticClass:"year-switcher"},[_c('a',{on:{"click":_vm.onMovePreviousYear}},[_c('Icon',{staticClass:"chevron-icon-small",attrs:{"name":"chevron_left"}})],1),_c('span',{staticClass:"year"},[_vm._v(" "+_vm._s(_vm.year)+" ")]),_c('a',{on:{"click":_vm.onMoveNextYear}},[_c('Icon',{staticClass:"chevron-icon-small",attrs:{"name":"chevron_right"}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }