import Vue from 'vue'
import VueRouter, { Route } from 'vue-router'
import LoginForm from '@/application/login/LoginForm.vue'
import CockpitApp from '@/application/CockpitApp.vue'
import Users from '@/application/users/Users.vue'
import UserOverview from '@/application/users/templates/UserOverview.vue'
import UserAdd from '@/application/users/templates/UserAdd.vue'
import UserEdit from '@/application/users/templates/UserEdit.vue'
import RolesAdd from '@/application/users/templates/RolesAdd.vue'
import RolesOverview from '@/application/users/templates/RolesOverview.vue'
import RolesEdit from '@/application/users/templates/RolesEdit.vue'
import Deliveries from '@/application/deliveries/Deliveries.vue'
import DeliveriesOverview from '@/application/deliveries/DeliveriesOverview.vue'
import DeliveriesCourier from '@/application/deliveries/ui/templates/DeliveriesCourier.vue'
import DeliveriesRole from '@/application/deliveries/ui/templates/DeliveriesRole.vue'
import DeliveriesDelivery from '@/application/deliveries/ui/templates/DeliveriesDelivery.vue'
import CreateOrder from '@/application/deliveries/ui/templates/CreateOrder.vue'
import { applicationService } from '@/application/ApplicationService'
import { setAuthenticationErrorHandler } from '@/infrastructure/vld-webservice'
import { routeToLastChildInHistory } from '@/application/router/router-extensions'
import { routerNames } from '@/application/router/router-definitions'
import Customers from '@/application/customers/Customers.vue'
import CustomerOverview from '@/application/customers/ui/templates/CustomersOverview.vue'
import CustomersAdd from '@/application/customers/ui/templates/CustomersAdd.vue'
import CustomersEdit from '@/application/customers/ui/templates/CustomersEdit.vue'
import RetailersOverview from '@/application/customers/ui/templates/RetailersOverview.vue'
import RetailersAdd from '@/application/customers/ui/templates/RetailersAdd.vue'
import RetailersEdit from '@/application/customers/ui/templates/RetailersEdit.vue'
import DeliveryItemQrCodes from '@/application/customers/ui/templates/DeliveryItemQrCodes.vue'
import Settings from '@/application/settings/Settings.vue'
import CompanySettingsOverview from '@/application/settings/templates/CompanySettingsOverview.vue'
import DeliverySettingsOverview from '@/application/settings/templates/DeliverySettingsOverview.vue'
import Dispo from '@/application/settings/templates/Dispo.vue'
import BusinessHours from '@/application/settings/templates/BusinessHoursView.vue'
import DeliveryTimesView from '@/application/settings/templates/DeliveryTimesView.vue'
import OrderSettingsOverview from '@/application/settings/templates/OrderSettingsOverview.vue'
import HLDSettingsView from '@/application/settings/templates/HLDSettingsView.vue'
import DeliveryDispenseView from '@/application/settings/templates/DeliveryDispenseView.vue'
import SupplementalServicesView from '@/application/settings/templates/SupplementalServiceView.vue'
import Analytics from '@/application/analytics/Analytics.vue'
import AnalyticsResult from '@/application/analytics/ui/template/AnalyticsResults.vue'
import CompanyInfosView from '@/application/settings/templates/CompanyInfosView.vue'
import DeliveryAreaView from '@/application/settings/templates/DeliveryAreaView.vue'
import AdInfosView from '@/application/settings/templates/AdInfosView.vue'
import FreeDeliveryItems from '@/application/freedeliveryitems/FreeDeliveryItems.vue'
import FreeDeliveryItemAdd from '@/application/freedeliveryitems/FreeDeliveryItemAdd.vue'
import DeliveryNotificationView from '@/application/settings/templates/DeliveryNotificationView.vue'

Vue.use(VueRouter)

export const routeHistory: Route[] = []

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: routerNames.LOGIN,
      components: {
        default: LoginForm
      },
      beforeEnter: (to, from, next) => {
        if (applicationService.isLoggedIn()) {
          next({name: routerNames.COCKPIT})
          return
        }
        next()
      }
    },
    {
      path: '/cockpit',
      name: routerNames.COCKPIT,
      components: {
        default: CockpitApp
      },
      beforeEnter: (to, from, next) => {
        if (applicationService.isLoggedIn()) {
          next()
          return
        }
        next({name: routerNames.LOGIN})
      },
      redirect: '/cockpit/deliveries',
      children: [
        {
          path: 'users',
          components: {
            section: Users
          },
          children: [
            {
              path: '',
              name: 'users',
              components: {
                contentDetail: UserOverview
              },
              beforeEnter: (to, from, next) => {
                const nextFcn = () => next({name: 'user-overview'})
                routeToLastChildInHistory(to, nextFcn, router, routeHistory)
              }
            },
            {
              path: 'users',
              name: 'user-overview',
              components: {
                contentDetail: UserOverview
              },
              beforeEnter: (to, from, next) => {
                routeToLastChildInHistory(to, next, router, routeHistory)
              }
            },
            {
              path: 'users/new',
              name: 'user-add',
              components: {
                detail: UserAdd,
                contentDetail: UserOverview
              }
            },
            {
              path: 'users/:id',
              name: 'user-edit',
              components: {
                detail: UserEdit,
                contentDetail: UserOverview
              }
            },
            {
              path: 'roles',
              name: 'role-overview',
              components: {
                contentDetail: RolesOverview
              },
              beforeEnter: (to, from, next) => {
                routeToLastChildInHistory(to, next, router, routeHistory)
              }
            },
            {
              path: 'roles/new',
              name: 'role-add',
              components: {
                detail: RolesAdd,
                contentDetail: RolesOverview
              }
            },
            {
              path: 'roles/:id',
              name: 'role-edit',
              components: {
                detail: RolesEdit,
                contentDetail: RolesOverview
              }
            }
          ]
        },
        {
          path: 'deliveries',
          name: routerNames.DELIVERIES,
          components: {
            section: Deliveries
          },
          beforeEnter: (to, from, next) => {
            routeToLastChildInHistory(to, next, router, routeHistory)
          },
          children: [
            {
              path: 'courier/:courierId/:orderNumber',
              name: routerNames.DELIVERIES_COURIER_DELIVERY,
              components: {
                detail: DeliveriesDelivery
              }
            },
            {
              path: 'courier/:courierId',
              name: routerNames.DELIVERIES_COURIER,
              components: {
                detail: DeliveriesCourier
              }
            },
            {
              path: 'role/:roleId/:orderNumber',
              name: routerNames.DELIVERIES_ROLE_DELIVERY,
              components: {
                detail: DeliveriesDelivery
              }
            },
            {
              path: 'role/:roleId',
              name: routerNames.DELIVERIES_ROLE,
              components: {
                detail: DeliveriesRole
              }
            },
            {
              path: 'delivery/:orderNumber',
              name: routerNames.DELIVERIES_DELIVERY,
              components: {
                detail: DeliveriesDelivery
              }
            },
            {
              path: 'create-order/',
              name: routerNames.CREATE_ORDER,
              components: {
                detail: CreateOrder
              }
            }
          ]
        },
        {
          path: 'deliveries-overview',
          name: routerNames.DELIVERIES_OVERVIEW,
          components: {
            section: DeliveriesOverview
          },
          beforeEnter: (to, from, next) => {
            routeToLastChildInHistory(to, next, router, routeHistory)
          },
          children: [
            {
              path: 'delivery/:orderNumber',
              name: routerNames.DELIVERIES_OVERVIEW_DELIVERY,
              components: {
                detail: DeliveriesDelivery
              }
            }
          ]
        },
        {
          path: 'customers',
          components: {
            section: Customers
          },
          children: [
            {
              path: '',
              name: routerNames.CUSTOMERS,
              components: {
                contentDetail: CustomerOverview
              },
              beforeEnter: (to, from, next) => {
                const nextFcn = () => next({name: 'customer-overview'})
                routeToLastChildInHistory(to, nextFcn, router, routeHistory)
              }
            },
            {
              path: 'customers',
              name: 'customer-overview',
              components: {
                contentDetail: CustomerOverview
              },
              beforeEnter: (to, from, next) => {
                routeToLastChildInHistory(to, next, router, routeHistory)
              }
            },
            {
              path: 'customers/new',
              name: routerNames.CREATE_CUSTOMER,
              components: {
                detail: CustomersAdd,
                contentDetail: CustomerOverview
              }
            },
            {
              path: 'customers/:id',
              name: 'customer-edit',
              components: {
                detail: CustomersEdit,
                contentDetail: CustomerOverview
              }
            },
            {
              path: 'retailers',
              name: 'retailer-overview',
              components: {
                contentDetail: RetailersOverview
              },
              beforeEnter: (to, from, next) => {
                routeToLastChildInHistory(to, next, router, routeHistory)
              }
            },
            {
              path: 'retailers/new',
              name: 'retailer-add',
              components: {
                detail: RetailersAdd,
                contentDetail: RetailersOverview
              }
            },
            {
              path: 'retailers/:id',
              name: 'retailer-edit',
              components: {
                detail: RetailersEdit,
                contentDetail: RetailersOverview
              }
            },
            {
              path: 'qrcodes',
              name: 'deliveryitem-qr-codes',
              components: {
                contentDetail: DeliveryItemQrCodes
              }
            },
            {
              path: 'promotions',
              name: routerNames.FREE_DELIVERY_ITEMS,
              components: {
                contentDetail: FreeDeliveryItems
              }
            },
            {
              path: 'promotions/new',
              name: routerNames.CREATE_FREE_DELIVYERY_ITEM,
              components: {
                detail: FreeDeliveryItemAdd,
                contentDetail: FreeDeliveryItems
              }
            }
          ]
        },
        {
          path: 'settings',
          components: {
            section: Settings
          },
          children: [
            {
              path: '',
              name: routerNames.SETTINGS,
              components: {
                contentDetail: CompanySettingsOverview
              },
              beforeEnter: (to, from, next) => {
                const nextFcn = () => next({name: routerNames.SETTINGS_COMPANY})
                routeToLastChildInHistory(to, nextFcn, router, routeHistory)
              }
            },
            {
              path: 'company',
              name: routerNames.SETTINGS_COMPANY,
              components: {
                contentDetail: CompanySettingsOverview
              },
              beforeEnter: (to, from, next) => {
                routeToLastChildInHistory(to, next, router, routeHistory)
              }
            },
            {
              path: 'company/infos',
              name: routerNames.SETTINGS_COMPANY_INFO,
              components: {
                detail: CompanyInfosView,
                contentDetail: CompanySettingsOverview
              }
            },
            {
              path: 'company/ads',
              name: routerNames.SETTINGS_COMPANY_ADS,
              components: {
                detail: AdInfosView,
                contentDetail: CompanySettingsOverview
              }
            },
            {
              path: 'company/businesshours',
              name: routerNames.SETTINGS_DELIVERY_BUSINESS_HOURS,
              components: {
                detail: BusinessHours,
                contentDetail: CompanySettingsOverview
              }
            },
            {
              path: 'delivery',
              name: routerNames.SETTINGS_DELIVERY,
              components: {
                contentDetail: DeliverySettingsOverview
              },
              beforeEnter: (to, from, next) => {
                routeToLastChildInHistory(to, next, router, routeHistory)
              }
            },
            {
              path: 'delivery/dispo',
              name: routerNames.SETTINGS_DELIVERY_DISPO,
              components: {
                detail: Dispo,
                contentDetail: DeliverySettingsOverview
              }
            },
            {
              path: 'delivery/deliverytimes',
              name: routerNames.SETTINGS_DELIVERY_DELIVERY_TIME,
              components: {
                detail: DeliveryTimesView,
                contentDetail: DeliverySettingsOverview
              }
            },
            {
              path: 'delivery/deliveryarea',
              name: routerNames.SETTINGS_DELIVERY_AREA,
              components: {
                detail: DeliveryAreaView,
                contentDetail: DeliverySettingsOverview
              }
            },
            {
              path: 'delivery/deliverydispense',
              name: routerNames.SETTINGS_DELIVERY_DELIVERY_DISPENSE,
              components: {
                detail: DeliveryDispenseView,
                contentDetail: DeliverySettingsOverview
              }
            },
            {
              path: 'delivery/deliverynotification',
              name: routerNames.SETTINGS_DELIVERY_DELIVERY_NOTIFICATIONS,
              components: {
                detail: DeliveryNotificationView,
                contentDetail: DeliverySettingsOverview
              }
            },
            {
              path: 'delivery/supplementalservice',
              name: routerNames.SETTINGS_DELIVERY_SUPPLEMENTAL_SERVICE,
              components: {
                detail: SupplementalServicesView,
                contentDetail: DeliverySettingsOverview
              }
            },
            {
              path: 'order',
              name: routerNames.SETTINGS_ORDER,
              components: {
                contentDetail: OrderSettingsOverview
              },
              beforeEnter: (to, from, next) => {
                routeToLastChildInHistory(to, next, router, routeHistory)
              }
            },
            {
              path: 'order/hld',
              name: routerNames.SETTINGS_ORDER_HLD,
              components: {
                detail: HLDSettingsView,
                contentDetail: OrderSettingsOverview
              }
            }
          ]
        },
        {
          path: 'analytics',
          components: {
            section: Analytics
          },
          children: [
            {
              path: 'analytics',
              name: routerNames.ANALYTICS,
              components: {
                contentDetail: AnalyticsResult
              }
            }
          ]
        }
      ]
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

router.afterEach((to: Route, from: Route) => {
  routeHistory.unshift(to)
})

setAuthenticationErrorHandler(() => {
  applicationService.logout({timeout: 'true'})
})

export default router
