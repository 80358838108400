/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_14': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.057 14.01a.872.872 0 011.13.475.857.857 0 01-.483 1.121l-6.648 2.648a3.735 3.735 0 01-2.354 3.724c-.455.18-.93.272-1.416.272a3.77 3.77 0 01-3.51-2.335 3.705 3.705 0 011.11-4.281L5.809 5.686l-4.235-.218a.863.863 0 01-.823-.902.862.862 0 01.912-.814l4.788.244a.87.87 0 01.76.535l4.238 10.343c.261-.058.526-.09.792-.092l-2.173-5.298a.853.853 0 01.006-.66.863.863 0 01.476-.462l6.56-2.612a.873.873 0 011.13.475l2.651 6.467a.861.861 0 01-.481 1.12l-5.373 2.14c.19.199.358.419.5.656l6.52-2.598zm-5.102-6.344l-4.95 1.97 2 4.873 4.948-1.97-1.998-4.873zm-2.772 10.096a2.04 2.04 0 00-1.899-1.26c-.263 0-.52.049-.766.147a2.005 2.005 0 00-1.132 2.62 2.04 2.04 0 001.9 1.26c.263 0 .521-.05.768-.147a2.005 2.005 0 001.129-2.62zm2.368-8.066a.357.357 0 00.203-.467.372.372 0 00-.482-.199l-2.625 1.047a.356.356 0 00-.203.467.369.369 0 00.482.199l2.625-1.047z" _fill="#132C45"/>'
  }
})
