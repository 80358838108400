

import { Prop, Vue } from 'vue-property-decorator'
import Component from 'vue-class-component'
import DetailSection from '@/components/layout/DetailSection.vue'
import CalendarPicker from '@/components/forms/CalendarPicker.vue'
import { DeliveryOverviewSummary, DeliveryStatus } from '@/application/deliveries/models/DeliveryOverviewSummary'
import Card from '@/components/layout/Card.vue'
import Icon from '@/components/media/Icon.vue'
import DeliveryStatusIndicator from '@/application/deliveries/ui/components/transport/DeliveryStatusIndicator.vue'
import DeliveryStatusCount from '@/application/deliveries/ui/components/transport/DeliveryStatusCount.vue'


@Component({
  components: {
    DeliveryStatusCount,
    DeliveryStatusIndicator,
    Icon,
    Card,
    CalendarPicker,
    DetailSection
  }
})
export default class DeliveriesOverviewSummary extends Vue {

  @Prop({
    type: Array,
    required: true,
    default: []
  })
    deliveries!: DeliveryOverviewSummary[]

  get unplannedDeliveryCount(): number {
    return this.countDeliveriesWithStatus(DeliveryStatus.UNPLANNED)
  }

  get inProgressDeliveryCount(): number {
    return this.countDeliveriesWithStatus(DeliveryStatus.IN_PROGRESS)
  }

  get fulfilledDeliveryCount(): number {
    return this.countDeliveriesWithStatus(DeliveryStatus.FULFILLED)
  }

  private countDeliveriesWithStatus(deliveryStatus: DeliveryStatus) {
    return this.deliveries.filter((summary: DeliveryOverviewSummary) =>
      summary.deliveryStatus === deliveryStatus
    ).length
  }

}

