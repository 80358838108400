
import Vue from 'vue'
import Component from 'vue-class-component'
import customerService from '../../CustomersService'
import PageTitle from '@/components/text/PageTitle.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import ContainedButton from '@/components/buttons/ContainedButton.vue'
import TextButton from '@/components/buttons/TextButton.vue'
import PageHead from '@/components/layout/PageHead.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import Divider from '@/components/layout/Divider.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import SaveDiscardButtons from '@/components/buttons/SaveDiscardButtons.vue'
import { routerParameters } from '@/application/router/router-definitions'
import { CustomerAdd } from '@/application/customers/models/CustomerAdd'
import FormField from '@/components/forms/FormField.vue'
import InlineTextInput from '@/components/forms/InlineTextInput.vue'
import AddressSelectInput from '@/components/forms/address/AddressSelectInput.vue'
import CustomerForm from '@/application/customers/ui/templates/CustomerForm.vue'
import { Customer } from '@/application/customers/models/Customer'

@Component({
  components: {
    AddressSelectInput,
    InlineTextInput,
    SaveDiscardButtons,
    VerticalLayout,
    Divider,
    PageDetail,
    ScrollableSection,
    PageHead,
    ContainedButton,
    TextButton,
    DetailSection,
    PageTitle,
    FormField,
    CustomerForm
  }
})
export default class CustomersAdd extends Vue {

  customer: CustomerAdd = {
    name: '',
    phone: '',
    address: null,
    ...this.$store.state.customers.customerDraft
  } as CustomerAdd

  get disableAddCustomerButton() {
    return this.customer.name !== '' &&
      this.customer.phone !== '' &&
      this.customer.address !== null
  }

  onChangeCustomer(val: CustomerAdd) {
    customerService.saveCustomerDraft(val)
    this.customer = val
  }

  onClickDiscard() {
    customerService.deleteCustomerDraft()
    this.$router.push({name: 'customer-overview', params: {[routerParameters.DISABLE_ROUTE_TO_LAST_CHILD]: 'true'}})
  }

  onClickCreate() {
    customerService.createCustomer(this.customer).then((customerId: string) => {
      this.$router.push(
        {
          name: 'customer-edit',
          params: {id: customerId}
        }
      ).catch((err) => {})
    })
  }


}
