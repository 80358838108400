
import Vue from 'vue'
import Component from 'vue-class-component'
import Card from '@/components/layout/Card.vue'
import deliverySettingsService from '@/application/settings/DeliverySettingsService'
import { DeliverySettings } from '@/application/settings/models/types'
import Icon from '@/components/media/Icon.vue'
import { DELIVERY_SETTINGS_CHANGED } from '@/application/settings/events'
import { eventConsumer } from '@/infrastructure/events/event-consumer'
import { routerNames } from '@/application/router/router-definitions'
import { formatCurrency } from '@/utils/formatter'

@Component({
  components: {
    Icon,
    Card
  },
  mixins: [eventConsumer([
    {
      key: DELIVERY_SETTINGS_CHANGED,
      handler: 'loadDeliverySettings'
    }
  ])]
})
export default class DeliverySettingsOverview extends Vue {

  deliverySettings: DeliverySettings | null = null

  get dispoCardStyleClass(): any {
    return {
      selected: this.$route.name === routerNames.SETTINGS_DELIVERY_DISPO
    }
  }

  get deliveryTimeCardStyleClass(): any {
    return {
      selected: this.$route.name === routerNames.SETTINGS_DELIVERY_DELIVERY_TIME
    }
  }

  get deliveryAreaCardStyleClass(): any {
    return {
      selected: this.$route.name === routerNames.SETTINGS_DELIVERY_AREA
    }
  }

  get supplementalServiceCardStyleClass(): any {
    return {
      selected: this.$route.name === routerNames.SETTINGS_DELIVERY_SUPPLEMENTAL_SERVICE
    }
  }

  get deliveryDispenseCardStyleClass(): any {
    return {
      selected: this.$route.name === routerNames.SETTINGS_DELIVERY_DELIVERY_DISPENSE
    }
  }

  get deliveryNotificationsCardStyleClass(): any {
    return {
      selected: this.$route.name === routerNames.SETTINGS_DELIVERY_DELIVERY_NOTIFICATIONS
    }
  }

  get noDeliveryWindowDefined(): boolean {
    return this.deliverySettings!.deliveryWindows.length === 0
  }

  get noSupplementaryServiceDefined(): boolean {
    return this.deliverySettings!.supplementalServices.length === 0
  }

  mounted() {
    this.loadDeliverySettings()
  }

  iconValue(iconIndex: number): string {
    return 'service_' + iconIndex
  }

  formatSupplementalServicePrice(price: number): string {
    return formatCurrency(price)
  }

  checkmarkIcon(attribute: boolean): object {
    const attributeEnabled = this.deliverySettings && attribute
    if (attributeEnabled) {
      return {
        class: 'icon-enabled',
        name: 'checkmark_circle_solid'
      }
    } else {
      return {
        class: 'icon-disabled',
        name: 'cross_circle_solid'
      }
    }
  }

  onSelectDispo() {
    this.$router.push({name: routerNames.SETTINGS_DELIVERY_DISPO}).catch((err) => {})
  }

  onSelectDeliveryArea() {
    this.$router.push({name: routerNames.SETTINGS_DELIVERY_AREA}).catch((err) => {})
  }

  onSelectDeliveryTimes() {
    this.$router.push({name: routerNames.SETTINGS_DELIVERY_DELIVERY_TIME}).catch((err) => {})
  }

  onSelectSupplementalService() {
    this.$router.push({name: routerNames.SETTINGS_DELIVERY_SUPPLEMENTAL_SERVICE}).catch((err) => {})
  }

  onSelectDeliveryDispense() {
    this.$router.push({name: routerNames.SETTINGS_DELIVERY_DELIVERY_DISPENSE}).catch((err) => {})
  }

  onSelectDeliveryNotifications() {
    this.$router.push({name: routerNames.SETTINGS_DELIVERY_DELIVERY_NOTIFICATIONS}).catch((err) => {})
  }

  loadDeliverySettings() {
    deliverySettingsService.deliverySettingsOverview().then((result) => {
      this.deliverySettings = result
    })
  }
}
