/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_1_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)" fill-rule="evenodd" clip-rule="evenodd"><path pid="0" d="M9.452 6.95l1.657-3.99c-1.62-1.42-3.664-.395-4.299-.076-.091.045-.153.077-.18.084C5.546 2.326 4.702 2 4.118 2c-.679 0-1.1.43-1.1 1.121 0 1.409.918 3.197 1.607 4.338C1.803 9.298 0 13.425 0 16.697 0 21.047 2.938 22 7.515 22c2.206 0 4.331-.186 5.756-1.213A5.999 5.999 0 0113 19.014c-.775 1.145-2.443 1.562-5.485 1.562-4.57 0-6.039-.942-6.039-3.878 0-3.521 2.383-7.907 5.236-8.626-.727 1.008-1.217 2.38-.68 3.93l.954-.308c-.425-1.227-.03-2.261.5-3.007.256.335.533.803.533 1.221h1.006c0-.736-.433-1.426-.77-1.853 2.825.655 5.203 4.921 5.296 8.43a6.015 6.015 0 011.268-1.789 13.846 13.846 0 00-.933-2.931.548.548 0 01.093-.315c.758-1.256 1.724-2.158 2.722-2.502-.616.947-.809 2.001-.429 3.082l.122.057.792-.25.06-.116c-.285-.812-.137-1.572.328-2.27.212.299.343.594.343.838l.096.09h.835l.097-.09c0-.458-.19-.918-.497-1.355 1.687.559 3.234 2.728 3.818 5.032a6.035 6.035 0 011.66 1.609c-.102-3.037-1.932-6.584-4.6-7.753l1.342-3.185-.025-.101a2.5 2.5 0 00-1.01-.537c-.687-.18-1.407-.07-2.147.225-.24.096-.655.3-.652.299-.837-.524-1.552-.796-2.059-.796-.618 0-1.01.394-1.01 1.008 0 .976.511 2.214 1.303 3.523-.676.448-1.298 1.061-1.841 1.802a1.067 1.067 0 00-.128.216c-.894-1.439-2.105-2.674-3.647-3.325zM6.805 4.39c.286-.046.682-.206 1.077-.367.544-.221 1.087-.442 1.346-.363L7.84 7s-.976-.048-1.717.232C5.72 6.555 5.091 5.325 4.785 4.47c.663.11 1.624.006 2.02-.081zm11.132 1.167c-.5.2-.707.273-.91.305a5.76 5.76 0 01-1.556.088c.236.602.661 1.449 1.011 2.037.097-.032.2-.06.31-.082a4.611 4.611 0 011.008-.089l1.081-2.564c-.162.008-.434.1-.944.305z" _fill="#132C45"/><path pid="1" d="M19 14.417a4.583 4.583 0 100 9.167 4.583 4.583 0 000-9.167zM21.083 18l-.65-.667h-.129l-1.774 1.808-.83-.845h-.129l-.654.662v.131l1.548 1.578h.13l2.488-2.535V18z" _fill="#29CC96"/></g><defs><clipPath id="clip0"><path pid="2" _fill="#fff" d="M0 0h24v24H0z"/></clipPath></defs>'
  }
})
