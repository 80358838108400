/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'info_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" d="M11.1 16.5h1.8v-5.4h-1.8v5.4zM12 3c-4.968 0-9 4.032-9 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9zm0 16.2c-3.969 0-7.2-3.231-7.2-7.2 0-3.969 3.231-7.2 7.2-7.2 3.969 0 7.2 3.231 7.2 7.2 0 3.969-3.231 7.2-7.2 7.2zm-.9-9.9h1.8V7.5h-1.8v1.8z"/>'
  }
})
