/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_15': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18 9.665V4.67C18 3.741 17.212 3 16.266 3H7.734C6.77 3 6 3.76 6 4.671v5.146a5.69 5.69 0 001.892 4.215 5.938 5.938 0 003.251 1.5v3.835h-2.62c-.473 0-.848.36-.848.816 0 .456.374.817.847.817h6.956c.472 0 .847-.36.847-.817 0-.455-.374-.816-.847-.816h-2.64V15.55C15.773 15.15 18 12.646 18 9.665zm-10.324.17l.039-5.183 3.015.019-.67 2.79c-.06.248 0 .495.157.703.158.19.414.304.67.304h.827l-.472 1.918a.835.835 0 00.827 1.007.837.837 0 00.827-.627l.71-2.943a.826.826 0 00-.158-.702.885.885 0 00-.67-.304h-.808l.532-2.164 3.803.019v4.974c0 2.279-1.773 4.177-4.019 4.31a4.373 4.373 0 01-3.23-1.12c-.887-.78-1.38-1.842-1.38-3z" _fill="#132C45"/>'
  }
})
