

import Vue from 'vue'
import Component from 'vue-class-component'
import PageLayout from '@/components/layout/PageLayout.vue'
import PageTitle from '@/components/text/PageTitle.vue'
import PageHead from '@/components/layout/PageHead.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import DeliveriesOverviewFilter from '@/application/deliveries/ui/templates/DeliveriesOverviewFilter.vue'
import DeliveriesOverviewList from '@/application/deliveries/ui/templates/DeliveriesOverviewList.vue'
import { Watch } from 'vue-property-decorator'
import { routerNames } from '@/application/router/router-definitions'
import {
  selectDelivery,
  attachDeliveriesChangedCallback,
  removeDeliveriesChangedCallback
} from '@/application/deliveries/services/deliveries-service'
import { DeliveryOverviewSummary } from '@/application/deliveries/models/DeliveryOverviewSummary'
import DeliveriesOverviewSummary from '@/application/deliveries/ui/templates/DeliveriesOverviewSummary.vue'

@Component({
  name: 'DeliveriesOverview',
  components: {
    VerticalLayout,
    PageDetail,
    ScrollableSection,
    PageHead,
    PageTitle,
    PageLayout,
    DeliveriesOverviewList,
    DeliveriesOverviewFilter,
    DeliveriesOverviewSummary
  }
})
export default class DeliveriesOverview extends Vue {

  date: Date = new Date()

  deliveries: DeliveryOverviewSummary[] = []

  onDateChanged(date: Date) {
    this.date = date
    this.loadDeliverySummary()
  }

  loadDeliverySummary() {
    attachDeliveriesChangedCallback(this.date, this.onChangeDeliveryOverview)
  }

  onChangeDeliveryOverview(deliverySummaries: DeliveryOverviewSummary[]) {
    this.deliveries = deliverySummaries
  }

  @Watch('$route')
  onChangeRoute() {
    if (this.$route.name === routerNames.DELIVERIES_OVERVIEW_DELIVERY) {
      selectDelivery(this.$route.params.orderNumber).then()
    }
  }

  mounted() {
    this.onChangeRoute()
    this.loadDeliverySummary()
  }

  beforeDestroy() {
    removeDeliveriesChangedCallback()
  }
}
