
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import { uuid } from '@/utils/uuid'
import { Address } from '@/application/shared/Address'
import { formatDueDateTime } from '@/utils/formatter'
import TimeRangeInput from '@/components/forms/TimeRangeInput.vue'
import OptionsMenu from '@/components/menus/OptionsMenu.vue'
import { OptionsMenuItem } from '@/components/menus/types/OptionsMenuItem'
import { TimeRange } from '@/components/forms/types/TimeRange'
import { Time } from '@/components/forms/types/Time'
import SelectInput from '@/components/forms/SelectInput.vue'
import TextSuggestionInput from '@/components/forms/TextSuggestionInput.vue'
import TextInput from '@/components/forms/TextInput.vue'
import DeliveryItemInput from '@/application/deliveries/ui/components/order-draft/DeliveryItemInput.vue'
import { AddressInput } from '@/components/forms/address/types/AddressInput'
import { OrderDraft, OrderDraftCondition } from '@/application/deliveries/models/order-draft/types'
import {
  changeConditionAddress,
  changeConditionDueDate,
  changeConditionLocation,
  changeConditionLocationAddress,
  changeConditionLocationDescription,
  conditionRemovable,
  pickupDeliveryItemAt,
  removeCondition
} from '@/application/deliveries/models/order-draft'
import AddressSuggestionInput from '@/components/forms/address/AddressSuggestionInput.vue'


@Component({
  components: {
    AddressSuggestionInput,
    DeliveryItemInput,
    TextInput,
    TextSuggestionInput,
    SelectInput,
    TimeRangeInput,
    OptionsMenu
  }
})
export default class OrderDraftConditionCard extends Vue {

  $refs!: {
    deliveryItems: DeliveryItemInput[]
  }

  @Prop({type: Object, required: true}) orderDraft!: OrderDraft

  @Prop({type: Object, required: true}) value!: OrderDraftCondition

  @Prop({type: Array, required: true}) addresses!: Address[]

  @Prop({type: Boolean, required: true}) dropOffCondition!: boolean

  showTimeInput = false

  get name(): string {
    if (this.address) {
      return this.address.name
    }
    if (this.value.deliveryLocation) {
      return this.value.deliveryLocation.name
    }
    return ''
  }

  get addressOrDescription(): string {
    if (this.address) {
      return `${this.address.street} ${this.address.city}`
    }
    if (this.value.deliveryLocation && this.value.deliveryLocation.name.length > 0) {
      if (this.value.deliveryLocation.address) {
        return `${this.value.deliveryLocation.address.street} ${this.value.deliveryLocation.address.city}`
      }
      if (this.value.deliveryLocation.description) {
        return this.value.deliveryLocation.description
      }
      return ''
    }

    return ''
  }

  get addressOrDescriptionRequired(): boolean {
    return this.value.deliveryLocation !== null && this.value.deliveryLocation.name.length > 0
  }

  get address(): Address | null {
    if (this.value) {
      return this.value.address
    }
    return null
  }

  get addressSuggestions(): Address[] {
    const needle = this.name.trim().toLowerCase()
    return this.addresses.filter((address) => {
      if (needle.length > 0) {
        return address.name.toLowerCase().startsWith(needle)
      } else {
        return true
      }
    })
  }

  get addItemEnabled(): boolean {
    return this.orderDraft.pickupConditions.filter(
      (condition) => condition === this.value
    ).length > 0
  }

  get dueDate(): string {
    return formatDueDateTime(this.value.dueDate)
  }

  get time(): TimeRange | Time {
    const dateFrom = this.value.dueDate.date
    const dateUntil = this.value.dueDate.dateUntil
    if (dateUntil) {
      return {
        from: {hour: dateFrom.getHours(), minute: dateFrom.getMinutes()},
        until: {hour: dateUntil.getHours(), minute: dateUntil.getMinutes()}
      }
    } else {
      return {hour: dateFrom.getHours(), minute: dateFrom.getMinutes()}
    }
  }

  get options(): OptionsMenuItem [] {
    return [
      {
        identifier: 'remove',
        text: this.$t('orderDraft.removeCondition') as string,
        icon: 'trash',
        disabled: !conditionRemovable(this.orderDraft, this.value)
      }
    ]
  }

  get deliveryItemListeners() {
    if (this.$listeners['mousedown-item']) {
      return {'mousedown-item': this.$listeners['mousedown-item']}
    }
  }

  onClickTime() {
    this.showTimeInput = !this.showTimeInput
    if (this.showTimeInput) {
      setTimeout(() => {
        window.addEventListener('click', this.onClickOutsideTimeInput)
      })
    }
  }

  onClickTimeInput(e: MouseEvent) {
    e.stopPropagation()
  }

  onClickAddItem() {
    pickupDeliveryItemAt(this.orderDraft, this.value)
  }

  onClickConditionOption(item: OptionsMenuItem) {
    if (item.identifier === 'remove') {
      removeCondition(this.orderDraft, this.value)
    }
  }

  onClickOutsideTimeInput() {
    this.showTimeInput = false
    window.removeEventListener('click', this.onClickOutsideTimeInput)
  }

  onChangeConditionName(name: string) {
    changeConditionLocation(this.value, name)
  }

  onSelectConditionSuggestion(suggestion: Address) {
    changeConditionAddress(this.value, suggestion)
  }

  onChangeConditionAddress(addressInput: string | AddressInput) {
    if (typeof addressInput === 'string') {
      changeConditionLocationDescription(this.value, addressInput)
    } else {
      changeConditionLocationAddress(
        this.value,
        {street: addressInput.street, city: addressInput.city},
        {latitude: addressInput.coordinate.latitude, longitude: addressInput.coordinate.longitude}
      )
    }
  }

  onChangedTimeRange(value: Time | TimeRange) {
    const date = this.value.dueDate.date
    const dueDate = ('until' in value)
      ? {date: this.dateFor(date, value.from), dateUntil: this.dateFor(date, value.until)}
      : {date: this.dateFor(date, value), dateUntil: null}
    changeConditionDueDate(this.value, dueDate)
  }

  generateUUID() {
    return uuid()
  }

  dateFor(date: Date, time: Time): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.hour, time.minute)
  }

  renderAddressSuggestion(address: Address) {
    return address.name
  }
}
