/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'upload': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.293 1.668a1 1 0 011.414 0l4.594 4.592a1 1 0 01-1.414 1.415L13 4.789v9.836a1 1 0 01-2 0V4.789L8.113 7.675A1 1 0 116.7 6.26l4.594-4.592zM3.375 14.625a1 1 0 10-2 0v6.125a1.875 1.875 0 001.875 1.875h17.5a1.875 1.875 0 001.875-1.875v-6.125a1 1 0 10-2 0v6H3.375v-6z" _fill="#132C45"/>'
  }
})
