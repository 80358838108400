/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'service_13': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 18.02L14.792 16c.436.947.165 2.166-.607 2.724-.771.558-1.749.243-2.185-.704zm5.15-6.535l2.414.59.436.72L6.906 20l-.436-.72.67-2.288-2.394-3.952c-1.337-2.207-.82-4.955 1.096-6.66l-.297-.49c-.362-.597-.137-1.363.503-1.716.64-.352 1.449-.154 1.81.444l.297.489c2.507-.728 5.263.218 6.6 2.426l2.395 3.952z" _fill="#132C45"/>'
  }
})
