
import Component from 'vue-class-component'
import { Prop, Vue, Watch } from 'vue-property-decorator'
import SelectElement from '@/components/forms/SelectElement.vue'
import TextInput from '@/components/forms/TextInput.vue'
import Icon from '@/components/media/Icon.vue'
import AddressList from '@/components/forms/address/AddressList.vue'
import { findAddress } from '@/components/forms/address/address-resource'
import { AddressInput } from '@/components/forms/address/types/AddressInput'
import { formatAddress } from '@/components/forms/address/address-helper'

@Component({
  components: {
    AddressList,
    Icon,
    TextInput,
    SelectElement
  }
})
export default class AddressSelectInput extends Vue {

  $refs!: {
    selectElement: SelectElement,
    searchInput: TextInput,
    addressList: AddressList,
    addressPicker: HTMLElement
  }

  @Prop({type: Object, default: null}) value!: AddressInput | null

  highlighted: AddressInput | null = null

  open = false

  searchValue = ''

  addresses: AddressInput[] = []

  get formattedValue() {
    return (this.value) ? formatAddress(this.value) : ''
  }

  @Watch('searchValue')
  onChangeSearchValue() {
    this.loadAddresses()
  }

  beforeDestroy() {
    document.body.removeEventListener('click', this.onClickOutside)
  }

  onClickSelectElement() {
    if (this.open) {
      this.closePicker()
    } else {
      this.openPicker()
    }
  }

  onSelectAddress(address: AddressInput) {
    this.selectAddress(address)
  }

  onKeyUp() {
    if (this.open) {
      this.$refs.addressList.highlightPrevious()
    } else {
      this.openPicker()
    }
  }

  onKeyDown() {
    if (this.open) {
      this.$refs.addressList.highlightNext()
    } else {
      this.openPicker()
    }
  }

  onKeyEnter() {
    if (this.open) {
      if (this.highlighted) {
        this.selectAddress(this.highlighted)
      } else {
        this.closePicker()
      }
    }
  }

  onKeyEsc() {
    this.closePicker()
  }

  onHighlightAddress(address: AddressInput | null) {
    if (address) {
      this.highlighted = address
    } else {
      if (this.addresses.length > 0) {
        this.$refs.addressList.highlightFirst()
      }
    }

  }

  onClickOutside(event: MouseEvent) {
    const selectElement = this.$refs.selectElement.$el
    const pickerElement = this.$refs.addressPicker
    const clickOnSelectElement = selectElement === event.target || selectElement.contains(event.target as Node)
    const clickOnAddressPicker = pickerElement === event.target || pickerElement.contains(event.target as Node)
    if (!clickOnSelectElement && !clickOnAddressPicker) {
      this.closePicker()
    }
  }

  loadAddresses() {
    if (this.searchValue.trim().length > 0) {
      findAddress(this.searchValue).then((addresses) => {
        this.addresses = addresses
        if (addresses.length > 0) {
          this.$nextTick(() => {
            this.$refs.addressList.highlightFirst()
          })
        }
      })
    } else {
      this.addresses = []
    }
  }

  selectAddress(address: AddressInput) {
    this.$emit('input', address)
    this.closePicker()
  }

  openPicker() {
    this.open = true
    this.$nextTick(() => {
      document.body.addEventListener('click', this.onClickOutside)
      this.$refs.searchInput.focus()
    })
    if (this.value) {
      // Map needs some minimal loading time
      setTimeout(() => {
        this.searchValue = formatAddress(this.value!)
        this.loadAddresses()
      }, 100)

    }
  }

  closePicker() {
    document.body.removeEventListener('click', this.onClickOutside)
    this.open = false
    this.$refs.selectElement.focus()
  }
}
