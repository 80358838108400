

import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

const colors = [
  '#BFDE9B',
  '#99A1C2',
  '#9BDEAF',
  '#AEC288',
  '#88C2AE',
  '#B0B7D6',
  '#DDDE9B',
  '#9BB4DE',
  '#88B9C2',
  '#B8CCB8',
  '#DEB1CF',
  '#C2889B',
  '#9BDEDE',
  '#BCCAE6',
  '#9BDEC8',
  '#DE9B9B',
  '#D9D4C3',
  '#B0B8BF',
  '#D0D5D9',
  '#DECD9B',
  '#9BC8DE',
  '#DFE4E8',
  '#AAE6AA'
]

@Component
export default class Avatar extends Vue {

  @Prop({
    type: String, required: true, validator: function (value: string) {
      return value.length > 1
    }
  })
  private value!: string

  get backgroundColor() {
    let sum = 0
    for (let i = 0; i < this.value.length; i++) {
      sum += this.value.charCodeAt(i)
    }
    const index = sum % colors.length
    return colors[index]
  }

  get letters() {
    return this.value.substring(0, 2)
  }

}

