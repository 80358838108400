
import Vue from 'vue'
import Component from 'vue-class-component'
import PageTitle from '@/components/text/PageTitle.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import PageHead from '@/components/layout/PageHead.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'
import Divider from '@/components/layout/Divider.vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import FormField from '@/components/forms/FormField.vue'
import SwitcherInput from '@/components/forms/SwitcherInput.vue'
import AddressSelectInput from '@/components/forms/address/AddressSelectInput.vue'
import { AddressInput } from '@/components/forms/address/types/AddressInput'
import deliverySettingsService from '@/application/settings/DeliverySettingsService'

@Component({
  components: {
    SwitcherInput,
    VerticalLayout,
    Divider,
    ScrollableSection,
    PageDetail,
    PageHead,
    DetailSection,
    PageTitle,
    FormField,
    AddressSelectInput
  }
})
export default class Dispo extends Vue {

  dispo: any = {
    enabled: true,
    address: null
  }

  mounted() {
    this.loadDispoSettings()
  }

  onChangeStatus(dispoEnabled: boolean) {
    if (dispoEnabled) {
      deliverySettingsService.enableDispo().then(() => {
        this.loadDispoSettings()
      })
    } else {
      deliverySettingsService.disableDispo().then(() => {
        this.loadDispoSettings()
      })
    }
  }

  onChangeAddress(address: AddressInput) {
    deliverySettingsService.updateDispoAddress(address).then(() => {
      this.loadDispoSettings()
    })
  }

  loadDispoSettings() {
    deliverySettingsService.dispo().then((response) => {
      this.dispo = response
    })

  }
}

