
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class IconButton extends Vue {
  @Prop({
    type: String,
    default: 'grey',
    validator: function (value: string) {
      return [
        'grey',
        'confirm',
        'cancel'
      ].indexOf(value) !== -1
    }
  }) private appearance!: string

  @Prop({
    type: Boolean,
    default: false
  }) private disabled!: boolean

  private get iconClass() {
    return this.disabled ? 'disabled' : ''
  }

  private onClick(e: any) {
    this.$emit('click', e)
  }
}
