/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron_left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.705 7.115l-4.58 4.59 4.58 4.59-1.41 1.41-6-6 6-6 1.41 1.41z" _fill="#132C45"/>'
  }
})
