import { Validator } from '@/components/forms/Validator'

export class HttpPrefixedUrlValidator implements Validator {

  errorMessage: string

  constructor(errorMessage: string) {
    this.errorMessage = errorMessage
  }

  isInvalid(localValue: string): boolean {
    return localValue.match(/^http(s)?:\/\/.*$/g) === null
  }

}
