
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class TabNavigationContent extends Vue {

  @Prop({required: true, type: String}) name!: string

  @Prop({required: true, type: String}) to!: string

  private active = false
}
