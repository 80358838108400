
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import PageTitle from '@/components/text/PageTitle.vue'
import Icon from '@/components/media/Icon.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import QRCode from '@/components/media/QRCode.vue'
import Divider from '@/components/layout/Divider.vue'
import ContainedButton from '@/components/buttons/ContainedButton.vue'
import InlineTextInput from '@/components/forms/InlineTextInput.vue'
import FormField from '@/components/forms/FormField.vue'
import MultiSelectChipInput from '@/components/forms/MultiSelectChipInput.vue'
import SwitcherInput from '@/components/forms/SwitcherInput.vue'
import AddressSelectInput from '@/components/forms/address/AddressSelectInput.vue'
import { Customer } from '@/application/customers/models/Customer'
import TextInput from '@/components/forms/TextInput.vue'
import InlinePhoneNumberInput from '@/components/forms/InlinePhoneNumberInput.vue'
import { AddressInput } from '@/components/forms/address/types/AddressInput'

@Component({
  components: {
    InlinePhoneNumberInput,
    TextInput,
    SwitcherInput,
    MultiSelectChipInput,
    FormField,
    InlineTextInput,
    Divider,
    QRCode,
    DetailSection,
    PageTitle,
    Icon,
    ContainedButton,
    AddressSelectInput
  }
})
export default class CustomerForm extends Vue {

  @Prop({
    type: Object,
    required: true
  })
    customer!: Customer

  get isInEditMode(): boolean {
    return this.customer.customerId !== undefined
  }

  get isPhoneNumberReadOnly(): boolean {
    return this.customer.isLinkedWithAccount
  }

  get readOnlyPhoneNumber(): string | null {
    return (this.customer.phone) ?
      this.customer.phone.replace(/(\d{2})(\d{2})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')
      : null
  }

  get nameInputStyle(): string | null {
    return (this.isInEditMode) ? 'font-large' : null
  }

  onChangeCustomer(value: any) {
    const updatedcustomer = Object.assign({}, this.customer, value)
    if (updatedcustomer.address) {
      updatedcustomer.address.name = this.customer.name
    }
    this.$emit('input', updatedcustomer)
  }

  onChangeAddress(value: AddressInput) {
    const updatedAddress = Object.assign({}, this.customer.address, value)
    this.onChangeCustomer({address: updatedAddress})
  }

}
