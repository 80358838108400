/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dispo-location': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<g _fill="#132C45" fill-rule="evenodd"><path pid="0" d="M9.665 7.556a5.932 5.932 0 00-.15 1.777h-.191v1.778h.553c.185.586.43 1.184.714 1.778H9.324v1.778h1.764v1.777H9.324v1.778h4.695A34.594 34.594 0 0015.5 20H.5V4h8.824v3.556h.341zM4.03 18.222v-1.778H2.265v1.778h1.764zm0-3.555v-1.778H2.265v1.778h1.764zm0-3.556V9.333H2.265v1.778h1.764zm0-3.555V5.778H2.265v1.778h1.764zm3.53 10.666v-1.778H5.794v1.778H7.56zm0-3.555v-1.778H5.794v1.778H7.56zm0-3.556V9.333H5.794v1.778H7.56zm0-3.555V5.778H5.794v1.778H7.56z"/><path pid="1" d="M17.5 3c-3.317 0-6 2.66-6 5.95 0 4.462 6 11.05 6 11.05s6-6.588 6-11.05c0-3.29-2.683-5.95-6-5.95zm0 8.075a2.135 2.135 0 01-2.143-2.125c0-1.173.96-2.125 2.143-2.125 1.183 0 2.143.952 2.143 2.125s-.96 2.125-2.143 2.125z"/></g>'
  }
})
