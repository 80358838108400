/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fragile': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M19 9.146V3.042C19 1.906 18.08 1 16.977 1H7.023C5.897 1 5 1.928 5 3.042v6.29c0 1.949.805 3.829 2.207 5.151 1.08 1.021 2.39 1.648 3.793 1.833v4.688H7.943a.986.986 0 00-.99.998c0 .557.437.998.99.998h8.114c.552 0 .989-.44.989-.998a.986.986 0 00-.989-.998h-3.08V16.34C16.402 15.852 19 12.789 19 9.146zm-12.045.208l.046-6.335 3.517.023-.782 3.411c-.07.302 0 .604.184.859.184.232.483.372.781.372h.966l-.552 2.344a1.023 1.023 0 00.736 1.207c.069.023.16.023.23.023.46 0 .85-.302.965-.766l.828-3.597c.069-.302 0-.604-.184-.858a1.013 1.013 0 00-.782-.372h-.942l.62-2.645 4.437.023v6.08c0 2.785-2.069 5.106-4.69 5.268a4.962 4.962 0 01-3.769-1.369 4.93 4.93 0 01-1.609-3.668z"/>'
  }
})
