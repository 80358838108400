

import { Vue, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import MainLayout from '@/components/layout/MainLayout.vue'
import MainNavigation from '@/application/MainNavigation.vue'
import { loadDeliveriesState, stopLoadDeliveriesState } from '@/application/deliveries/services/deliveries-service'
import { WebsocketStatus } from '@/infrastructure/websocket'
import Icon from '@/components/media/Icon.vue'
import { addGlobalRequestErrorObserver, removeGlobalRequestErrorObserver } from '@/infrastructure/vld-webservice'
import ModalNotification from '@/components/modal/ModalNotification.vue'
import { State } from 'vuex-class'
import { DeliveriesState } from './deliveries/store/types'
import Interval, { IntervalTimerCallback } from '../utils/Interval'
import DocumentTitle from '@/utils/DocumentTitle'
import { applicationService } from '@/application/ApplicationService'

@Component({
  components: {
    ModalNotification,
    Icon,
    MainNavigation,
    MainLayout
  }
})
export default class CockpitApp extends Vue implements IntervalTimerCallback {

  websocketConnected = true
  errorMessage: string | null = null
  showErrorTitle = false

  documentTitle: DocumentTitle = new DocumentTitle()

  titleChangeInterval: Interval = new Interval(this)

  @State('deliveries') state!: DeliveriesState

  get showErrorMessage() {
    return this.errorMessage !== null
  }

  mounted() {
    addGlobalRequestErrorObserver(this.notifyGlobalRequestError)
    loadDeliveriesState(this.notify)
  }

  beforeDestroy() {
    stopLoadDeliveriesState(this.notify)
    removeGlobalRequestErrorObserver(this.notifyGlobalRequestError)
  }

  notify(status: WebsocketStatus) {
    this.websocketConnected = (status !== WebsocketStatus.CONNECTION_LOST)
    if (!applicationService.isLoggedIn()) {
      applicationService.logout()
    }
  }

  get unplannedDeliveries() {
    return this.state.unplanned.length + this.state.missing.length
  }

  @Watch('unplannedDeliveries')
  deliveriesBadge() {
    if (this.unplannedDeliveries > 0) {
      this.titleChangeInterval.start()
    } else {
      this.titleChangeInterval.stop()
      this.documentTitle.resetTitle()
    }
  }

  hideErrorMessage() {
    this.errorMessage = null
  }

  notifyGlobalRequestError(error: any) {
    const data = error.data

    if (data.hasOwnProperty('reason')) {
      this.showErrorTitle = false
      this.errorMessage = error.data.reason
    } else {
      this.showErrorTitle = true
      this.errorMessage = error.data
    }
  }

  onIntervalTimeout(): void {
    this.changeDocumentTitle()
  }

  private changeDocumentTitle(): void {
    if (this.documentTitle.isOriginalTitle()) {
      const title = this.$t('navigation.openDeliveries', [this.unplannedDeliveries]) as string
      this.documentTitle.setPageTitle(title)
    } else {
      this.documentTitle.resetTitle()
    }
  }

}
