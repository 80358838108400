/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'due_date_done': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.556 2v1.8H8.444V2H6.667v1.8h-.89c-.986 0-1.768.81-1.768 1.8L4 18.2c0 .99.791 1.8 1.778 1.8h12.444C19.2 20 20 19.19 20 18.2V5.6c0-.99-.8-1.8-1.778-1.8h-.889V2h-1.777zM17 9.75l-1.56-1.5h-.31l-4.259 4.066-1.99-1.9h-.31L7 11.905v.296l3.717 3.549h.31L17 10.046v-.295z" _fill="#132C45"/>'
  }
})
