import { parser } from '@/infrastructure/json-parser'
export const userDraftKey = 'users.userDraft'
export const roleDraftKey = 'users.roleDraft'
export const authenticatedUserSummaryKey = 'users.authenticatedUserSummary'
export const customerDraftKey = 'customers.customerDraft'
export const retailerDraftKey = 'customers.retailerDraft'
export const shippingOrderDraftKey = 'shippings.draft'

class LocalStorage {
  objectForKey(key: string): any | null {
    const value = localStorage.getItem(key)
    return (value === null) ? value :  JSON.parse(value, parser)
  }

  setObjectForKey(key: string, object: any | null) {
    if (object === null) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, JSON.stringify(object))
    }
  }

  clear() {
    localStorage.clear()
  }
}

export default new LocalStorage()
