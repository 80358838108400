import { Delivery } from '@/application/deliveries/models/Delivery'
import { Assignee } from '@/application/deliveries/models/Assignee'
import { DeliverySummary } from '@/application/deliveries/models/DeliverySummary'
import { MutationTree } from 'vuex'
import { DeliveriesState } from './types'
import { Transport } from '@/application/deliveries/models/transport/types'

export enum commits {
  COURIER_TRANSPORTS = 'deliveries/mutateCourierTransports',
  ROLE_TRANSPORTS = 'deliveries/mutateRoleTransports',
  DELIVERY = 'deliveries/mutateDelivery',
  COURIERS = 'deliveries/mutateCouriers',
  ROLES = 'deliveries/mutateRoles',
  MISSING_DELIVERIES = 'deliveries/mutateMissing',
  UNPLANNED_DELIVERIES = 'deliveries/mutateUnplanned',
  SHIPPING_ORDER_DRAFT = 'deliveries/mutateShippingOrderDraft',
  CLEAR = 'deliveries/clear',
  REPOPULATE_CONFIGURATION = 'deliveries/mutateRepopulateConfiguration'
}

export const mutations: MutationTree<DeliveriesState> = {

  mutateCourierTransports(state, transports: Transport[] = []) {
    state.courierTransports = transports
  },

  mutateRoleTransports(state, transports: Transport[] = []) {
    state.roleTransports = transports
  },

  mutateDelivery(state, delivery: Delivery | null) {
    state.delivery = delivery
  },

  mutateCouriers(state, couriers: Assignee[]) {
    state.couriers = couriers
  },

  mutateRoles(state, roles: Assignee[]) {
    state.roles = roles
  },

  mutateUnplanned(state, unplanned: DeliverySummary[]) {
    state.unplanned = unplanned
  },

  mutateMissing(state, missing: DeliverySummary[]) {
    state.missing = missing
  },

  mutateRepopulateConfiguration(state, repopulateConfiguration: RepopulateConfiguration) {
    state.repopulateConfiguration = repopulateConfiguration
  },

  mutateShippingOrderDraft(state, hasDraft: boolean) {
    state.shippingOrderDraft = hasDraft
  },

  clear(state) {
    state.couriers = []
    state.roles = []
    state.unplanned = []
    state.missing = []
    state.courierTransports = []
    state.roleTransports = []
    state.delivery = null
    state.repopulateConfiguration = { enabled: false }
  }
}
