/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkmark_circle_solid': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm3.033-14h-.258l-3.549 3.389-1.659-1.585h-.258L8 12.046v.246l3.097 2.958h.259l4.977-4.753v-.247L15.033 9z"/>'
  }
})
