import axios from 'axios'
import { parse } from 'envfile'

export class Environment {
  private loadedEnvironment: { [key: string]: string } = {}

  async load(): Promise<void> {
    try {
      const response = await axios.get('/env')
      this.loadedEnvironment = parse(response.data)
    } catch {
      // Fallback to environment
    }
  }

  get(key: string): string | undefined {
    const value = this.loadedEnvironment[key]
    if (value !== undefined) {
      return value
    }
    return process.env[key]
  }
}

export const environment = new Environment()
