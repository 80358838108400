
import Vue from 'vue'
import VerticalLayout from '@/components/layout/VerticalLayout.vue'
import PageHead from '@/components/layout/PageHead.vue'
import PageTitle from '@/components/text/PageTitle.vue'
import PageDetail from '@/components/layout/PageDetail.vue'
import SaveDiscardButtons from '@/components/buttons/SaveDiscardButtons.vue'
import DetailSection from '@/components/layout/DetailSection.vue'
import FormField from '@/components/forms/FormField.vue'
import TextInput from '@/components/forms/TextInput.vue'
import {
  postFreeDeliveryItemRange
} from '@/application/freedeliveryitems/free-delivery-item-resource'
import { routerNames } from '@/application/router/router-definitions'
import { FREE_DELIVERY_ITEMS_CHANGED } from '@/application/customers/events'
import eventBus from '@/infrastructure/events/event-bus'
import ScrollableSection from '@/components/layout/ScrollableSection.vue'

export default Vue.extend({
  name: 'FreeDeliveryItemAdd',
  components: {
    ScrollableSection,
    TextInput,
    FormField,
    DetailSection,
    PageDetail,
    PageTitle,
    PageHead,
    VerticalLayout,
    SaveDiscardButtons
  },
  data: () => ({
    label: '',
    prefix: 'A',
    start: '125',
    quantity: '1'

  }),
  computed: {
    saveButtonEnabled: function (): boolean {
      return this.prefix.length > 0 && this.startNumber > 0 && this.quantityNumber > 0 && this.quantityNumber < 1000
    },
    startNumber: function(): number {
      return Number(this.start)
    },
    quantityNumber: function(): number {
      return Number(this.quantity)
    }
  },
  methods: {
    onLabelChanged: function (newLabel: string) {
      this.label = newLabel
    },
    onClickDiscard: async function () {
      await this.$router.push({
        name: routerNames.FREE_DELIVERY_ITEMS
      })
    },
    onClickCreate: async function () {
      const end = this.startNumber + this.quantityNumber - 1
      await postFreeDeliveryItemRange(this.prefix, this.startNumber, end)
      eventBus.$emit(FREE_DELIVERY_ITEMS_CHANGED)
      await this.$router.push({
        name: routerNames.FREE_DELIVERY_ITEMS
      })
    }
  }
})
