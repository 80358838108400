import axios from '@/infrastructure/vld-webservice'
import { DeliverySummary } from '@/application/deliveries/models/DeliverySummary'
import { Delivery } from '@/application/deliveries/models/Delivery'
import { Transport } from '@/application/deliveries/models/transport/types'
import { AssigneesResponse } from './AssigneesResponse'
import { DisposeTransportRequest } from './DisposeTransportRequest'
import { UpdateDeliveryRequest } from './UpdateDeliveryRequest'
import { toIsoDate } from '@/utils/date-util'
import { DeliverySummariesResponse } from '@/application/deliveries/resources/DeliverySummariesResponse'
import { PlaceOrderCommand } from '@/application/deliveries/models/order-draft/types'

export const unplanned = (): Promise<DeliverySummary[]> => {
  return axios.get('/cockpit/deliveries/unplanned').then((response) => {
    return response.data.deliveries
  })
}

export const missing = (): Promise<DeliverySummary[]> => {
  return axios.get('/cockpit/deliveries/missingLeg').then((response) => {
    return response.data.deliveries
  })
}

export const assignees = (): Promise<AssigneesResponse> => {
  return axios.get('/cockpit/deliveries/assignees').then((response) => {
    return response.data
  })
}

export const transportForCourier = (courierId: string): Promise<Transport[]> => {
  return axios.get(`/cockpit/deliveries/couriers/${courierId}`).then((response) => {
    return response.data
  })
}

export const transportForRole = (roleId: string): Promise<Transport[]> => {
  return axios.get(`/cockpit/deliveries/roles/${roleId}`).then((response) => {
    return response.data
  })
}

export const delivery = (orderNumber: string): Promise<Delivery> => {
  return axios.get(`/cockpit/deliveries/${orderNumber}`).then((response) => {
    return response.data
  })
}

export const dispose = (orderNumber: string, disposeTransport: DisposeTransportRequest): Promise<void> => {
  return axios.put(`/cockpit/deliveries/${orderNumber}/dispose`, disposeTransport).then(() => {
    return
  })
}

export const updateDelivery = (orderNumber: string, deliveryUpdate: UpdateDeliveryRequest): Promise<void> => {
  return axios.put(`/cockpit/deliveries/${orderNumber}`, deliveryUpdate).then(() => {
    return
  })
}

export const removeDelivery = (orderNumber: string): Promise<void> => {
  return axios.delete(`/cockpit/deliveries/${orderNumber}`).then(() => {
    return
  })
}

export const createOrder = (placeOrderCommand: PlaceOrderCommand): Promise<void> => {
  return axios.post('/cockpit/orders', placeOrderCommand).then(() => {
    return
  })
}

export const deliverySummaries = (date: Date): Promise<DeliverySummariesResponse> => {
  const isoDate = toIsoDate(date)
  return axios.get(`/cockpit/deliveries/summaries?date=${isoDate}`).then((response) => {
    return response.data
  })
}

export const cancelDeliveryItem = (deliveryItemId: string): Promise<void> => {
  return axios.put(`/cockpit/deliveries/items/${deliveryItemId}/cancel`, deliveryItemId).then(() => {
    return
  })
}

export const repopulateConfiguration = (): Promise<RepopulateConfiguration> => {
  return axios.get('/cockpit/deliveries/assignments/repopulate/configuration').then((response) => {
    return response.data
  })
}
