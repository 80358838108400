/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav_users': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="#132C45" fill-rule="evenodd" d="M16.91 13.571c-1.31 0-3.35.365-4.91 1.072-1.56-.718-3.6-1.072-4.91-1.072-2.366 0-7.09 1.158-7.09 3.483V20h24v-2.946c0-2.325-4.724-3.483-7.09-3.483zm-4.365 4.822H1.636v-1.34c0-.578 2.793-1.874 5.455-1.874s5.454 1.296 5.454 1.875v1.339zm9.819 0h-8.182v-1.34c0-.492-.218-.92-.567-1.307.96-.321 2.138-.567 3.294-.567 2.662 0 5.455 1.296 5.455 1.875v1.339zM7.09 12.5c2.105 0 3.818-1.682 3.818-3.75S9.196 5 7.091 5C4.985 5 3.273 6.682 3.273 8.75S4.985 12.5 7.09 12.5zm0-5.893c1.2 0 2.182.964 2.182 2.143 0 1.179-.982 2.143-2.182 2.143S4.909 9.929 4.909 8.75c0-1.179.982-2.143 2.182-2.143zm9.818 5.893c2.106 0 3.818-1.682 3.818-3.75S19.015 5 16.91 5c-2.105 0-3.818 1.682-3.818 3.75s1.713 3.75 3.818 3.75zm0-5.893c1.2 0 2.182.964 2.182 2.143 0 1.179-.982 2.143-2.182 2.143s-2.182-.964-2.182-2.143c0-1.179.982-2.143 2.182-2.143z"/>'
  }
})
