
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import Icon from '@/components/media/Icon.vue'

@Component({
  components: {
    Icon
  }
})
export default class OptionsButton extends Vue {

  @Prop({type: Boolean, default: false}) active!: boolean

  @Prop({type: String, default: 'more'}) iconName!: string

  get buttonClass(): any {
    return {
      active: this.active
    }
  }
}
